import {Contract} from './contract';

export class HttpEmployeesResponse {
    success: boolean | undefined;
    message: string | undefined;
    data!: Employee[] | [];
}

export default class HttpEmployeeResponse {
    success: boolean | undefined;
    message: string | undefined;
    data: Employee | undefined;
}

export class Employee {
    id: string | undefined;
    companyId: string | undefined; // Matrix
    departmentId?: string | undefined;
    contract?: Contract;
    occupationId: string | undefined;
    path: string | undefined; // Matrix
    holderId: string | undefined;
    createdAt: Date | undefined;
    name: string | undefined;
    fullName?: string | undefined;
    email: string | undefined;
    phone: string | undefined;
    bank: string | undefined;
    bankBranch: number | undefined;
    currentAccountType: number | undefined;
    currentAccount: number | undefined;
    customerId: string | undefined;
    verificationDigit: number | undefined;
    pix: string | undefined;
    pixKey: string | undefined;
    cnh: number | undefined;
    pisPasep: number | undefined;
    registryId: string | undefined;
    enabled: boolean | undefined;
    role: string | undefined;
    token: string | undefined;
    picture?: string | undefined;
    zipcode?: string | undefined;
    street?: string | undefined;
    addressNumber?: string | undefined;
    complement?: string | undefined;
    district?: string | undefined;
    city?: string | undefined;
    state?: string | undefined;
    country?: string | undefined;
    description?: string | undefined;
    status: string | undefined;
    accountType: string | undefined;
    latitude: number | undefined;
    longitude: number | undefined;
}
