import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { map } from 'rxjs';
import { Company } from 'src/model/company';
import { AppStorageService } from 'src/services/app-storage.service';
import { CompanyService } from 'src/services/company.service';
import { CnpjValidator } from '../app.validators';

@Component({
    selector: 'app-company-new',
    templateUrl: './company-new.component.html',
    styleUrls: ['./company-new.component.scss'],
})
export class CompanyNewComponent implements OnInit {

    @Input() companyPrincipalData: Company | undefined;
    @Input() companyBranchData: Company | undefined;
    @Input() isBranch: boolean | undefined;
    @Output() cancelUpdate = new EventEmitter<boolean>();

    public companyBranch: Company | undefined;
    public companyPrincipal: Company | undefined;
    public formRegister: FormGroup;
    public formTitle = '';
    public isEditing = false;
    public statusTypes = [{key: 'Ativo', value: 'ACTIVE'}, {key: 'Inativo', value: 'INACTIVE'}];
    private saveMessage = [{severity: 'success', summary: 'Empresa cadastrada!'}];
    private updateMessage = [{severity: 'success', summary: 'Empresa atualizada!'}];
    private cancelMessage = [{severity: 'info', summary: 'Cadastro cancelado!'}];

    constructor(
        private appStorageService: AppStorageService,
        private messageService: MessageService,
        private formBuilder: FormBuilder,
        private companyService: CompanyService,
        private http: HttpClient,
    ) {
        this.formRegister = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(80)]],
            cnpj: ['', Validators.required],
            cei: [''],
            status: [''],
            zipcode: ['', Validators.required],
            streetNumber: ['', Validators.required],
            street: ['', Validators.required],
            state: ['', Validators.required],
            city: ['', Validators.required],
            phoneNumber: ['', Validators.required],

        }, {validator: [CnpjValidator]});
    }

    ngOnInit(): void {
        this.companyBranch = this.companyBranchData;
        this.companyPrincipal = this.companyPrincipalData;
        this.formRegister.patchValue(this.companyBranch!);

        if (this.companyBranch?.id) {
            this.isEditing = true;
            this.formTitle = 'Editar Filial';
        } else {
            this.isEditing = false;
            this.formTitle = 'Nova Filial';
        }
    }

    public async onSave(): Promise<void> {
        const {
            name,
            cnpj,
            cei,
            status,
            zipcode,
            streetNumber,
            street,
            state,
            city,
            phoneNumber,
            companyId = this.companyPrincipal?.id,
        } = this.formRegister.value;

        const company: any = {
            name,
            cnpj: cnpj.replace(/\D/g, ''),
            cei: cei.replace(/\D/g, ''),
            status,
            zipcode,
            streetNumber,
            street,
            state,
            city,
            companyId,
            branchAddress: this.companyPrincipal?.branchAddress,
            flexibleJourney: this.companyPrincipal?.flexibleJourney,
            headOffice: this.companyPrincipal?.headOffice,
            homeOffice: this.companyPrincipal?.homeOffice,
            skipAllValidation: this.companyPrincipal?.skipAllValidation,
            phoneNumber: phoneNumber.replace(/\D/g, ''),
            journeyType: this.companyPrincipal?.journeyType,
            journey: this.companyPrincipal?.journey,
        };

        try {
            if (this.isBranch) {

                await this.companyService.saveBranch(company).toPromise();
            } else {
                await this.companyService.save(company).toPromise();
            }
            this.onCancel();
        } catch (error) {
            if ( error === 'CNPJ_IN_USE') {
                this.messageService.add({
                    severity: 'error',
                    summary: 'CNPJ em uso',
                    detail: 'Este CNPJ está sendo usado por outra empresa'
                });
            }
            console.log('Error: ', error);
        }
    }

    public async onUpdate(): Promise<void> {
        const {
            name,
            cnpj,
            cei,
            status,
            zipcode,
            streetNumber,
            street,
            state,
            city,
            phoneNumber,
        } = this.formRegister.value;

        const company = {
            name,
            cnpj: cnpj.replace(/\D/g, ''),
            cei: cei.replace(/\D/g, ''),
            status,
            zipcode,
            streetNumber,
            street,
            state,
            city,
            branchAddress: this.companyPrincipal?.branchAddress,
            flexibleJourney: this.companyPrincipal?.flexibleJourney,
            headOffice: this.companyPrincipal?.headOffice,
            homeOffice: this.companyPrincipal?.homeOffice,
            skipAllValidation: this.companyPrincipal?.skipAllValidation,
            phoneNumber: phoneNumber.replace(/\D/g, ''),
            journeyType: this.companyPrincipal?.journeyType,
            journey: this.companyPrincipal?.journey,
        };

        try {
            await this.companyService.update(company as Company, this.companyBranch?.id as string).toPromise();
        } catch (error) {
            console.log('Error: ', error);
        }
    }

    public findZipcode(): void {
        let cep = '';

        cep = this.formRegister.get('zipcode')?.value;

        if (cep !== '') {
            cep = cep.replace(/\D/g, '');
        }

        if (cep !== '') {
            const validaCep = /^[0-9]{8}$/;

            if (validaCep.test(cep)) {
                const options = {headers: {Anonymous: ''}};

                this.http.get(`https://viacep.com.br/ws/${cep}/json`, options)
                    .pipe(
                        map((dados: any) => {
                            if (dados.erro === true) {
                                this.messageService.add({
                                    severity: 'error',
                                    summary: 'CEP inválido ou inexistente',
                                    detail: 'Verifique o número do CEP novamentes'
                                });
                                this.formRegister.get('zipcode')?.setValue('');
                            }
                            this.populateAddressForm(dados);
                        })
                    ).subscribe();
            }
        } else {
            this.resetEmployeeAddressForm();
        }
    }

    populateAddressForm(data: any): void {
        this.formRegister.patchValue({
            street: data.logradouro,
            district: data.bairro,
            state: data.uf,
            city: data.localidade,
        });
    }

    resetEmployeeAddressForm(): void {
        this.formRegister.patchValue({
            street: null,
            addressNumber: null,
            district: null,
            complement: null,
            city: null,
            state: null,
        });
    }

    onCancel() {
        this.cancelUpdate.emit(false);
        this.ngOnInit();
    }
}
