import {MetaActionTypes} from '../actions/meta.action';
import {MetaReducer} from '@ngrx/store';

export function clearState(reducer: (arg0: any, arg1: any) => any): (state: any, action: any) => any {
    return  (state, action) => {

        if (action.type === MetaActionTypes.LOGOUT) {
            state = undefined;
        }

        if (action.type === MetaActionTypes.FIRE_EMPLOYEE) {
            state = undefined;
        }

        if (action.type === MetaActionTypes.REEMPLOY_EMPLOYEE) {
            state = undefined;
        }

        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<any>[] = [clearState];
