<div>
    <app-spinner></app-spinner>
</div>
<div class="card shadow mb-4">
    <div class="card-body">
        <div class="signature-header d-flex justify-content-between align-items-center" *ngIf="!payment && !newCheckout">
            <span>Assinatura Atual</span>
            <button class="btn btn-danger">
                Cancelar Assinatura
            </button>
        </div>

        <div class="signature-header d-flex justify-content-start align-items-center" *ngIf="payment && !newCheckout">
            <img class="icon-back" src="assets/icon/ArrowLeft/arrow-left.svg" (click)="onBack()" />
            <span>Trocar Assinatura para:</span>
        </div>

        <div class="signature-header d-flex justify-content-start align-items-center" *ngIf="newCheckout">
            <span>Assinar Plano</span>
        </div>

        <div class="signature-header d-flex justify-content-start align-items-center" *ngIf="newCheckout">
            <p>
                Olá, o período de utilizacão acabou, não se preocupe, basta assinar alguns dos planos disponíveis abaixo
                e o sistema voltará a funcionar normalmente.
            </p>
        </div>

        <div class="plans-container" *ngIf="!payment">
            <p-panel [toggleable]="true" [collapsed]="true" expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
                <ng-template pTemplate="header">
                    <div class="panel-header">
                        <div class="panel-header-title">
                            <h3>Grátis por 14 dias</h3>
                        </div>
                    </div>
                </ng-template>

                <ng-template pTemplate="body" >
                    <div class="body-row mt-0">
                        <div class="signature-itens">
                            <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                            <span>Aplicativo Mobile</span>
                        </div>
                        <div class="signature-itens">
                            <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                            <span>Acesso ao Dashboard</span>
                        </div>
                        <div class="signature-itens">
                            <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                            <span>Controle da jornada de trabalho</span>
                        </div>
                        <div class="signature-itens">
                            <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                            <span>Pontos ilimitados</span>
                        </div>
                        <div class="signature-itens">
                            <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                            <span>Histórico diário</span>
                        </div>
                        <div class="signature-itens">
                            <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                            <span>Relatórios por períodos</span>
                        </div>
                        <div class="signature-itens">
                            <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                            <span>Justificativas de faltas</span>
                        </div>
                        <div class="signature-itens">
                            <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                            <span>Função home office</span>
                        </div>
                        <div class="signature-itens">
                            <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                            <span>Função jornada híbrida</span>
                        </div>
                        <div class="signature-itens">
                            <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                            <span>Banco de horas</span>
                        </div>
                        <div class="signature-itens">
                            <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                            <span>Função férias</span>
                        </div>
                        <div class="signature-itens">
                            <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                            <span>Acompanhamento via mapa</span>
                        </div>
                        <div class="signature-itens">
                            <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                            <span>Envio de documentos</span>
                        </div>
                        <div class="signature-itens">
                            <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                            <span>Dados detalhados</span>
                        </div>
                    </div>
                </ng-template>
            </p-panel>

            <div class="divider"></div>

            <p *ngIf="!newCheckout">Deseja trocar de Assinatura?</p>

            <div class="cards d-flex justify-content-between flex-wrap">
                <div class="card-green">
                    <div class="card-green-title">
                        Startup
                    </div>
                    <div class="card-green-subtitle">
                        A mais escolhida
                    </div>
                    <div class="card-green-price d-flex justify-content-center align-items-center">
                        <span>R$ 55,00</span>
                        <small>/ por mês</small>
                    </div>
                    <div class="signature-itens mt-3">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>
                            <span>Até </span> <strong>10 usuários</strong>
                        </span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Aplicativo Mobile</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Acesso ao Dashboard</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Controle da jornada de trabalho</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Pontos ilimitados</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Histórico diário</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Relatórios por períodos</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Função home office</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Função jornada híbrida</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Banco de horas</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Função férias</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Acompanhamento via mapa</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Envio de documentos</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Dados detalhados</span>
                    </div>

                    <button class="card-green-btn" (click)="onCheckout('STUP', 'MENSAL')">
                        Assinar
                    </button>

                    <div class="card-green-footer cursor">
                        <span (click)="onCheckout('STUP', 'ANUAL')">Garanta <strong>30% de desconto</strong> no plano anual</span>
                    </div>
                </div>

                <div class="card-gray">
                    <div class="card-gray-title">
                        Microempresa I
                    </div>
                    <div class="card-green-subtitle">&nbsp;</div>
                    <div class="card-green-price d-flex justify-content-center align-items-center">
                        <span>R$ 120,00</span>
                        <small>/ por mês</small>
                    </div>
                    <div class="signature-itens mt-3">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>
                            <span>Até </span> <strong>25 usuários</strong>
                        </span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Aplicativo Mobile</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Acesso ao Dashboard</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Controle da jornada de trabalho</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Pontos ilimitados</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Histórico diário</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Relatórios por períodos</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Função home office</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Função jornada híbrida</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Banco de horas</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Função férias</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Acompanhamento via mapa</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Envio de documentos</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Dados detalhados</span>
                    </div>

                    <button class="card-green-btn" (click)="onCheckout('ME1', 'MENSAL')">
                        Assinar
                    </button>

                    <div class="card-green-footer cursor">
                        <span (click)="onCheckout('ME1', 'ANUAL')">Garanta <strong>30% de desconto</strong> no plano anual</span>
                    </div>
                </div>

                <div class="card-gray">
                    <div class="card-gray-title">
                        Microempresa II
                    </div>
                    <div class="card-green-subtitle">&nbsp;</div>
                    <div class="card-green-price d-flex justify-content-center align-items-center">
                        <span>R$ 210,00</span>
                        <small>/ por mês</small>
                    </div>
                    <div class="signature-itens mt-3">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>
                            <span>Até </span> <strong>50 usuários</strong>
                        </span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Aplicativo Mobile</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Acesso ao Dashboard</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Controle da jornada de trabalho</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Pontos ilimitados</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Histórico diário</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Relatórios por períodos</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Função home office</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Função jornada híbrida</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Banco de horas</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Função férias</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Acompanhamento via mapa</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Envio de documentos</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Dados detalhados</span>
                    </div>

                    <button class="card-green-btn" (click)="onCheckout('ME2', 'MENSAL')">
                        Assinar
                    </button>

                    <div class="card-green-footer cursor">
                        <span (click)="onCheckout('ME2', 'ANUAL')">Garanta <strong>30% de desconto</strong> no plano anual</span>
                    </div>
                </div>

                <div class="card-gray">
                    <div class="card-gray-title">
                        Pequena Empresa
                    </div>
                    <div class="card-green-subtitle">&nbsp;</div>
                    <div class="card-green-price d-flex justify-content-center align-items-center">
                        <span>R$ 350,00</span>
                        <small>/ por mês</small>
                    </div>
                    <div class="signature-itens mt-3">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>
                            <span>Até </span> <strong>100 usuários</strong>
                        </span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Aplicativo Mobile</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Acesso ao Dashboard</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Controle da jornada de trabalho</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Pontos ilimitados</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Histórico diário</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Relatórios por períodos</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Função home office</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Função jornada híbrida</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Banco de horas</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Função férias</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Acompanhamento via mapa</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Envio de documentos</span>
                    </div>
                    <div class="signature-itens">
                        <img src="assets/icon/Check/check.svg" class="items-icon-green"/>
                        <span>Dados detalhados</span>
                    </div>

                    <button class="card-green-btn" (click)="onCheckout('PE', 'MENSAL')">
                        Assinar
                    </button>

                    <div class="card-green-footer cursor">
                        <span (click)="onCheckout('PE', 'ANUAL')">Garanta <strong>30% de desconto</strong> no plano anual</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
