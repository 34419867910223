export default  class ChannelNotification {
    public static JUSTIFICATION_CREATED_TYPE = 'JUSTIFICATION_CREATED';
    public static START_EMPLOYEE_CSV_IMPORT_TYPE = 'START_EMPLOYEE_CSV_IMPORT';
    public static ERROR_EMPLOYEE_CSV_IMPORT_TYPE = 'ERROR_EMPLOYEE_CSV_IMPORT';
    public static END_EMPLOYEE_CSV_IMPORT_TYPE = 'END_EMPLOYEE_CSV_IMPORT';

    type: string | undefined;
    holderId: string | undefined;
    companyId: string | undefined;
    employeeId: string | undefined;
    description: string | undefined;
    createdAt: string | undefined;
}
