<div>
    <p-confirmDialog [style]="{width: '432px'}" [baseZIndex]="10000"
        rejectButtonStyleClass="p-button-text"
        acceptButtonStyleClass="p-button-rounded p-button-success"
        acceptLabel="Sim"
        rejectLabel="Não"
        acceptIcon="none"
        rejectIcon="none">
    </p-confirmDialog>
    <p-dialog [header]="alertHeader" [(visible)]="displayDialog" [style]="{width: '50vw'}" [baseZIndex]="10000">
        <p>{{ alertDescription }}</p>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" (click)="closeAlert()" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>

    <p-toast></p-toast>


    <a routerLink="/employee/list" class="btn btn-primary btn-sm mb-4 mt-2">
        <span class="fa fa-chevron-left mr-2" aria-hidden="true"></span>Voltar
    </a>


    <form [formGroup]="formRegister" (ngSubmit)="onSubmit()" novalidate>
        <div class="w-100">

            <p-panel
                [toggleable]="false"
                [expandIcon]="'pi pi-angle-down'"
                [collapseIcon]="'pi pi-angle-up'"
                [collapsed]="false">
                <ng-template pTemplate="header">
                    <div class="panel-header">
                        <div class="panel-header-title">
                            <h3 class="border-left-primary pl-3">Cadastrar Funcionário</h3>
                        </div>
                    </div>
                </ng-template>

                <ng-template pTemplate="content">
                    <div class="header-border-fix">
                    </div>
                    <div class="panel-wrapper">
                        <div class="form-content">
                            <div class="panel-header">
                                <div class="panel-header-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-0 w-0" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
                                    </svg>
                                </div>
                                <div class="panel-header-title">
                                    <h3>Dados Pessoais</h3>
                                    <span>E-mail, CPF, Endereço, CEP, Telefone</span>
                                </div>
                            </div>

                            <!-- ROW-01 -->
                            <code>
                            </code>
                            <div class="form-row">
                                <div class="form-group col-lg-4">
                                    <label for="name">Nome *</label>
                                    <input
                                        id="name"
                                        type="text"
                                        placeholder="Digite aqui"
                                        class="form-control"
                                        formControlName="name"
                                        [ngClass]="{
                            'is-invalid': formRegister.get('name')?.hasError('required') && formRegister.get('name')?.touched,
                            'minlength-error': formRegister.get('name')?.hasError('minlength') && formRegister.get('name')?.touched,
                            'maxlength-error': formRegister.get('name')?.hasError('maxlength') && formRegister.get('name')?.touched
                          }">
                                    <div class="invalid-feedback" *ngIf="formRegister.get('name')?.hasError('required')
                          && formRegister.get('name')?.touched">
                                        Nome é requerido
                                    </div>
                                    <div class="ng-invalid text-danger text-validation" *ngIf="formRegister.get('name')?.hasError('minlength')">
                                        Nome deve ter pelo menos 2 caracteres
                                    </div>
                                    <div class="ng-invalid text-danger text-validation" *ngIf="formRegister.get('name')?.hasError('maxlength')">
                                        Nome deve ter no máximo 80 caracteres
                                    </div>
                                    <div class="cng-invalid text-danger text-validation" *ngIf="formRegister.hasError('isNameValid')">
                                        Existem palavras muito longas
                                    </div>
                                </div>

                                <div class="form-group col-lg-4">
                                    <label for="address">E-mail *</label>
                                    <input
                                        id="address"
                                        type="email"
                                        placeholder="Digite aqui"
                                        class="form-control"
                                        formControlName="email"
                                        (focusout)="verifyEmail($event)"
                                        [ngClass]="{
                                        'is-invalid': formRegister.get('email')?.invalid &&
                                        formRegister.get('email')?.touched
                                    }">
                                    <div class="invalid-feedback" *ngIf="formRegister.get('email')?.hasError('required')
                                        && formRegister.get('email')?.touched">
                                        Email é requerido
                                    </div>
                                    <div class="invalid-feedback" *ngIf="formRegister.get('email')?.hasError('email')
                                        && formRegister.get('email')?.touched">
                                        Email inválido
                                    </div>
                                </div>

                                <div class="form-group col-lg-4">
                                    <label for="phone">Telefone *</label>
                                    <p-inputMask
                                        id="phone"
                                        styleClass="form-control"
                                        placeholder="Ex: 85998564575"
                                        mask="(99) 9 9999-9999"
                                        formControlName="phone"
                                        [ngClass]="{
                                            'ng-invalid': formRegister.get('phone')?.hasError('required') && formRegister.get('phone')?.touched,
                                            'ng-dirty': formRegister.get('phone')?.hasError('required') && formRegister.get('phone')?.touched
                                        }">
                                    </p-inputMask>
                                    <div class="ng-invalid text-danger text-validation" *ngIf="formRegister.get('phone')?.hasError('required')
                          && formRegister.get('phone')?.touched">
                                        Numero de telefone é requerido
                                    </div>
                                </div>
                            </div>

                            <!-- ROW-02 -->
                            <div class="form-row">
                                <div class="form-group col-lg-4">
                                    <label for="phone">Número CNH</label>
                                    <input
                                        id="cnh"
                                        type="text"
                                        placeholder="Digite aqui"
                                        maxlength="30"
                                        class="form-control"
                                        formControlName="cnh"
                                        numbersOnly
                                        [ngClass]="{
                                            'ng-invalid': formRegister.get('cnh')?.hasError('maxlength') && formRegister.get('cnh')?.touched,
                                            'ng-dirty': formRegister.get('cnh')?.hasError('maxlength') && formRegister.get('cnh')?.touched
                                        }">
                                        <div class="ng-invalid text-danger text-validation" *ngIf="formRegister.get('cnh')?.hasError('maxlength')">
                                            CNH deve ter no máximo 30 caracteres
                                        </div>
                                </div>

                                <div class="form-group col-lg-4">
                                    <label for="cpfcnpj">CPF *</label>
                                    <p-inputMask
                                        id="cpfcnpj"
                                        styleClass="form-control"
                                        placeholder="Digite aqui"
                                        mask="999.999.999-99"
                                        formControlName="registryId"
                                        [ngClass]="{
                                            'ng-invalid': formRegister.get('registryId')?.hasError('required') && formRegister.get('registryId')?.touched,
                                            'ng-dirty': formRegister.get('registryId')?.hasError('required') && formRegister.get('registryId')?.touched
                                        }"
                                    >
                                    </p-inputMask>
                                    <div class="ng-invalid text-danger text-validation"
                                         *ngIf="formRegister.get('registryId')?.hasError('required') && formRegister.get('registryId')?.touched">
                                        CPF é requerido
                                    </div>
                                    <div class="ng-invalid text-danger text-validation" *ngIf="formRegister.hasError('CPFNotValid')
                                        && formRegister.controls['registryId'].touched">
                                        CPF não é válido
                                    </div>
                                </div>

                                <div class="form-group col-lg-4">
                                    <label for="phone">PIS/PASEP</label>
                                    <p-inputMask
                                        id="pisPasep"
                                        placeholder="Digite aqui"
                                        styleClass="form-control"
                                        mask="999.99999.99-9"
                                        formControlName="pisPasep"
                                    >
                                    </p-inputMask>
                                </div>
                            </div>

                            <!-- ROW-03 -->
                            <div class="form-row">
                                <div class="form-group col-lg-4">
                                    <label for="zipcode">CEP*</label>
                                    <p-inputMask
                                        id="zipcode"
                                        placeholder="Digite aqui"
                                        styleClass="form-control"
                                        mask="99999999"
                                        formControlName="employeeZipcode"
                                        (onBlur)="findZipcode('employee')">
                                        >
                                    </p-inputMask>
                                    <div class="ng-invalid text-danger text-validation"
                                         *ngIf="formRegister.get('employeeZipcode')?.hasError('required') && formRegister.get('employeeZipcode')?.touched">
                                        CEP é requerido
                                    </div>
                                </div>

                                <div class="form-group col-lg-4">
                                    <label for="address">Rua/Avenida *</label>
                                    <input
                                        id="address"
                                        type="text"
                                        placeholder="Digite aqui"
                                        class="form-control"
                                        formControlName="employeeAddress">
                                    <div class="ng-invalid text-danger text-validation"
                                         *ngIf="formRegister.get('employeeAddress')?.hasError('required') && formRegister.get('employeeAddress')?.touched">
                                        Este campo é requerido
                                    </div>
                                </div>

                                <div class="form-group col-lg-4">
                                    <label for="employeeAddressNumber">Número *</label>
                                    <input
                                        id="employeeAddressNumber"
                                        type="text"
                                        maxlength="10"
                                        placeholder="Digite aqui"
                                        class="form-control"
                                        formControlName="employeeAddressNumber"
                                        [ngClass]="{
                                            'ng-invalid': formRegister.get('employeeAddressNumber')?.hasError('maxlength') && formRegister.get('employeeAddressNumber')?.touched,
                                            'ng-dirty': formRegister.get('employeeAddressNumber')?.hasError('maxlength') && formRegister.get('employeeAddressNumber')?.touched
                                        }">
                                    <div class="ng-invalid text-danger text-validation"
                                         *ngIf="formRegister.get('employeeAddressNumber')?.hasError('required') && formRegister.get('employeeAddressNumber')?.touched">
                                        Número é requerido
                                    </div>
                                    <div class="ng-invalid text-danger text-validation" *ngIf="formRegister.get('employeeAddressNumber')?.hasError('maxlength')">
                                        Número deve ter no máximo 5 caracteres
                                    </div>
                                </div>
                            </div>

                            <!-- ROW-04 -->
                            <div class="form-row">
                                <div class="form-group col-lg-6">
                                    <label for="employeeComplement">Complemento</label>
                                    <input
                                        id="employeeComplement"
                                        type="text"
                                        maxlength="30"
                                        placeholder="Digite aqui"
                                        class="form-control"
                                        formControlName="employeeComplement"
                                        [ngClass]="{
                                            'ng-invalid': formRegister.get('employeeComplement')?.hasError('maxlength') && formRegister.get('employeeComplement')?.touched,
                                            'ng-dirty': formRegister.get('employeeComplement')?.hasError('maxlength') && formRegister.get('employeeComplement')?.touched
                                        }">
                                        <div class="ng-invalid text-danger text-validation" *ngIf="formRegister.get('employeeComplement')?.hasError('maxlength')">
                                            Complemento deve ter no máximo 30 caracteres
                                        </div>
                                </div>

                                <div class="form-group col-lg-6">
                                    <label for="district">Bairro *</label>
                                    <input
                                        id="district"
                                        type="text"
                                        maxlength="30"
                                        placeholder="Digite aqui"
                                        class="form-control"
                                        formControlName="employeeDistrict"
                                        [ngClass]="{
                                            'ng-invalid': formRegister.get('employeeDistrict')?.hasError('maxlength') && formRegister.get('employeeDistrict')?.touched,
                                            'ng-dirty': formRegister.get('employeeDistrict')?.hasError('maxlength') && formRegister.get('employeeDistrict')?.touched
                                        }">
                                    <div class="ng-invalid text-danger text-validation"
                                         *ngIf="formRegister.get('employeeDistrict')?.hasError('required') && formRegister.get('employeeDistrict')?.touched">
                                        Bairro é requerido
                                    </div>
                                    <div class="ng-invalid text-danger text-validation" *ngIf="formRegister.get('employeeDistrict')?.hasError('maxlength')">
                                        Bairro deve ter no máximo 30 caracteres
                                    </div>
                                </div>
                            </div>

                            <!-- ROW-05 -->
                            <div class="form-row">
                                <div class="form-group col-lg-6">
                                    <label for="city">Cidade *</label>
                                    <input
                                        id="city"
                                        type="text"
                                        placeholder="Digite aqui"
                                        class="form-control"
                                        formControlName="city"
                                        [ngClass]="{
                                            'ng-invalid': formRegister.get('city')?.hasError('maxlength') && formRegister.get('city')?.touched,
                                            'ng-dirty': formRegister.get('city')?.hasError('maxlength') && formRegister.get('city')?.touched
                                        }">
                                    <div class="ng-invalid text-danger text-validation"
                                         *ngIf="formRegister.get('city')?.hasError('required') && formRegister.get('city')?.touched">
                                        Cidade é requerido
                                    </div>
                                    <div class="ng-invalid text-danger text-validation" *ngIf="formRegister.get('city')?.hasError('maxlength')">
                                        Cidade deve ter no máximo 30 caracteres
                                    </div>
                                </div>

                                <div class="form-group col-lg-6">
                                    <label>Estado *</label>
                                    <select
                                        #stateForm
                                        class="form-control"
                                        formControlName="state"
                                        (change)="onStateChange(stateForm.value)"
                                        [ngClass]="{
                                            'is-invalid': formRegister.get('state')?.hasError('required') && formRegister.get('state')?.touched}">
                                        <option value="">Selecione um estado</option>
                                        <option *ngFor="let state of states"
                                                [value]="state.sigla">{{ state.sigla }}</option>
                                    </select>
                                    <div class="invalid-feedback" *ngIf="formRegister.get('state')?.hasError('required')
                                        && formRegister.get('state')?.touched">
                                        Selecione um estado
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="divider"></div>

                        <div class="form-content">
                            <div class="panel-header">
                                <div class="panel-header-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"/>
                                    </svg>
                                </div>
                                <div class="panel-header-title">
                                    <h3>Dados Bancários</h3>
                                    <span>Banco, Conta, Pix e outros</span>
                                </div>
                            </div>

                            <!-- ROW-01 -->
                            <div class="form-row">
                                <div class="form-group col-lg-4">
                                    <label for="street">Banco</label>
                                    <select
                                        id="city" class="form-control"
                                        formControlName="bank">
                                        <option value="">Selecione um banco</option>
                                        <option *ngFor="let bank of banks" [value]="bank.longname">{{ bank.longname }}</option>
                                    </select>
                                    <div class="invalid-feedback" *ngIf="formRegister.get('bank')?.hasError('required')
                                        && formRegister.get('bank')?.touched">
                                        Banco é requerido
                                    </div>
                                </div>

                                <div class="form-group col-lg-4">
                                    <label for="bankBranch">Agência</label>
                                    <input
                                        id="bankBranch"
                                        type="text"
                                        placeholder="Digite aqui"
                                        class="form-control"
                                        maxlength="4"
                                        formControlName="bankBranch"
                                        [ngClass]="{
                                            'is-invalid': formRegister.get('bankBranch')?.hasError('required') && formRegister.get('bankBranch')?.touched}"
                                        numbersOnly>
                                    <div class="invalid-feedback" *ngIf="formRegister.get('bankBranch')?.hasError('required')
                                        && formRegister.get('bankBranch')?.touched">
                                        Agência é requerido
                                    </div>
                                </div>

                                <div class="form-group col-lg-4">
                                    <label for="current-account-type">Tipo</label>
                                    <select
                                        id="current-account-type" class="form-control"
                                        formControlName="currentAccountType"
                                        [ngClass]="{
                                            'is-invalid': formRegister.get('currentAccountType')?.hasError('required') && formRegister.get('currentAccountType')?.touched}">
                                        <option value="">Selecione um tipo de conta</option>
                                        <option *ngFor="let catype of currentAccountTypeField" [value]="catype">
                                            {{ catype }}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback" *ngIf="formRegister.get('currentAccountType')?.hasError('required')
                                        && formRegister.get('currentAccountType')?.touched">
                                        Selecione um tipo de conta
                                    </div>
                                </div>
                            </div>

                            <!-- ROW-02 -->
                            <div class="form-row">
                                <div class="form-group col-lg-6">
                                    <label for="currentAccount">Conta</label>
                                    <input
                                        id="currentAccount"
                                        type="text"
                                        maxlength="30"
                                        class="form-control"
                                        placeholder="Digite aqui"
                                        formControlName="currentAccount"
                                        [ngClass]="{
                                            'is-invalid': formRegister.get('currentAccount')?.hasError('required') &&
                                            formRegister.get('currentAccount')?.touched,
                                            'ng-dirty': formRegister.get('currentAccount')?.hasError('maxlength') &&
                                            formRegister.get('currentAccount')?.touched
                                        }"
                                        numbersOnly>
                                    <div class="invalid-feedback"
                                         *ngIf="formRegister.get('currentAccount')?.hasError('required') && formRegister.get('currentAccount')?.touched">
                                        Conta é requerida
                                    </div>
                                    <div class="ng-invalid text-danger text-validation" *ngIf="formRegister.get('currentAccount')?.hasError('maxlength')">
                                        Conta deve ter no máximo 18 caracteres
                                    </div>
                                </div>

                                <div class="form-group col-lg-6">
                                    <label for="dv">DV</label>
                                    <input
                                        id="dv"
                                        type="text"
                                        class="form-control"
                                        placeholder="Digite aqui"
                                        maxlength="1"
                                        formControlName="verificationDigit"
                                        [ngClass]="{
                                            'is-invalid': formRegister.get('verificationDigit')?.hasError('required') && formRegister.get('verificationDigit')?.touched}"
                                        >
                                    <div class="invalid-feedback" *ngIf="formRegister.get('verificationDigit')?.hasError('required')
                                        && formRegister.get('verificationDigit')?.touched">
                                        DV é requerido
                                    </div>
                                </div>
                            </div>

                            <!-- ROW-03 -->
                            <div class="form-row">
                                <!-- PIX starts -->
                                <div class="form-group col-lg-6">
                                    <label for="pix-key">Chave Pix</label>
                                    <select
                                        id="pix-key"
                                        class="form-control"
                                        formControlName="pixKey"
                                        (change)="autofocus.focus()">
                                        <option value="">Selecione um tipo de chave</option>
                                        <option
                                            placeholder="Selecione uma chave"
                                            *ngFor="let key of pixKeyField" [value]="key">{{ key }}
                                        </option>
                                    </select>
                                </div>

                                <div class="form-group col-lg-6">
                                    <label for="street">PiX</label>
                                    <input
                                        id="pix"
                                        #autofocus
                                        type="text"
                                        placeholder="Digite aqui"
                                        class="form-control"
                                        [ngClass]="{
                                            'is-invalid': formRegister.get('pix')?.hasError('required') && formRegister.get('pix')?.touched}"
                                        formControlName="pix">
                                    <div class="invalid-feedback" *ngIf="formRegister.get('pix')?.hasError('required')
                                        && formRegister.get('pix')?.touched">
                                        Chave PIX é requerida
                                    </div>
                                </div>
                                <!-- PIX starts -->
                            </div>
                        </div>

                        <div class="divider"></div>

                        <div class="form-content">
                            <div class="panel-header">
                                <div class="panel-header-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0ß" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                                    </svg>
                                </div>
                                <div class="panel-header-title">
                                    <h3>Jornada de Trabalho</h3>
                                    <span>Cargo, período, dias de trabalho</span>
                                </div>
                            </div>

                            <!-- ROW-01 -->
                            <div class="form-row">
                                <div class="form-group col-lg-6">
                                    <app-select-company (selectEvent)="selectValues($event)"></app-select-company>
                                </div>

                                <div class="form-group col-lg-3">
                                    <label for="companyPosition">Departamento *</label>
                                    <div class="d-flex justify-content-start align-items-center">
                                        <select class="form-control w-100" id="cargo" formControlName="departmentId"
                                                style="width: 50%;"
                                                [ngClass]="{
                                                    'is-invalid': formRegister.get('departmentId')?.hasError('required') && formRegister.get('departmentId')?.touched
                                                }">
                                            <option value="">Selecione um Departamento</option>
                                            <option *ngFor="let department of departments"
                                                    value="{{department.id}}">{{department.name}}</option>
                                        </select>
                                    </div>
                                    <div class="custom-invalid-feedback" *ngIf="formRegister.get('departmentId')?.hasError('required')
                                        && formRegister.get('departmentId')?.touched">
                                        Departamento é requerido
                                    </div>
                                </div>

                                <div class="form-group col-lg-3">
                                    <label for="companyPosition">Cargo *</label>
                                    <div class="d-flex justify-content-start align-items-center">
                                        <select class="form-control w-100" id="cargo" formControlName="companyPositionId"
                                                style="width: 50%;"
                                                [ngClass]="{
                                                    'is-invalid': formRegister.get('companyPositionId')?.hasError('required') && formRegister.get('companyPositionId')?.touched
                                                }">
                                            <option value="">Selecione um cargo</option>
                                            <option *ngFor="let item of companiesPositions"
                                                    value="{{item.id}}">{{item.name}}</option>
                                        </select>
                                    </div>
                                    <div class="custom-invalid-feedback" *ngIf="formRegister.get('companyPositionId')?.hasError('required')
                                        && formRegister.get('companyPositionId')?.touched">
                                        Cargo é requerido
                                    </div>
                                </div>
                            </div>

                            <!-- Managr and Regime -->
                            <div class="form-row">
                                <div class="form-group col-lg-6">
                                    <label>É Gestor(a)?</label>
                                    <div class="d-flex mt-2">
                                        <div class="form-check mr-3">
                                            <input class="form-check-input"
                                                   formControlName="role"
                                                   type="radio"
                                                   name="role"
                                                   id="roleTrue"
                                                   value="MANAGER"
                                                   (change)="changeRole($event.target)">
                                            <label class="form-check-label" for="roleTrue">
                                                Da empresa
                                            </label>
                                        </div>
                                        <div class="form-check mr-3">
                                            <input class="form-check-input"
                                                   formControlName="role"
                                                   type="radio"
                                                   name="role"
                                                   id="roleManager"
                                                   value="WORKER"
                                                   (change)="changeRole($event.target)">
                                            <label for="roleManager">
                                                Não é gestor
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input"
                                                   formControlName="role"
                                                   type="radio"
                                                   name="role"
                                                   id="roleDepartment"
                                                   value="DEPARTAMENT_MANAGER"
                                                   (change)="changeRole($event.target)">
                                            <label for="roleDepartment">
                                                Do departamento
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-lg-6">
                                    <label>Regime</label>
                                    <div class="d-flex justify-content-start mt-2">
                                        <div class="form-check mr-3">
                                            <input class="form-check-input"
                                                   formControlName="homeOffice"
                                                   type="radio"
                                                   id="home"
                                                   value="true"
                                                   (change)="homeOfficeToggleChange($event.target)">
                                            <label for="home">
                                                Home Office
                                            </label>
                                        </div>

                                        <div class="form-check mr-3">
                                            <input class="form-check-input"
                                                   formControlName="flexibleJourney"
                                                   type="radio"
                                                   id="flexible"
                                                   value="true"
                                                   (change)="flexibleJourneyToggleChange($event.target)">
                                            <label for="flexible">
                                                Jornada Híbrida
                                            </label>
                                        </div>

                                        <div class="form-check mr-3">
                                            <input class="form-check-input"
                                                   formControlName="headOffice"
                                                   type="radio"
                                                   value="true"
                                                   id="headOffice"
                                                   (change)="headOfficeToggleChange($event.target)">
                                            <label for="flexible">
                                                Padrão
                                            </label>
                                        </div>

                                        <div class="form-check mr-3" *ngIf="thereIsBranc">
                                            <input class="form-check-input"
                                                   formControlName="branchAddress"
                                                   type="radio"
                                                   id="branchAddress"
                                                   value="true"
                                                   (change)="branchAddressToggleChange($event.target)">
                                            <label for="flexible">
                                                Filial
                                            </label>
                                        </div>
                                        <div class="form-check mr-3">
                                            <input class="form-check-input"
                                                   formControlName="skipAllValidation"
                                                   type="radio"
                                                   id="skipAllValidation"
                                                   value="true"
                                                   (change)="skipAllValidationToggleChange($event.target)">
                                            <label for="flexible">
                                                Ponto Livre
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- ROW-02 -->
                            <div class="form-row">
                                <!-- day of the weeks starts -->
                                <div class="form-group col-lg-12">
                                    <label>Dias de trabalho</label>

                                    <div class="mb-3">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" formControlName="selectedJourneyOption" id="inlineRadio1" checked
                                                [value]="1" (change)="selectedJourneyOption = 1; changeJourgeType();">
                                            <label class="form-check-label" for="inlineRadio1">Configurar jornada por semana</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" formControlName="selectedJourneyOption" id="inlineRadio2"
                                                [value]="2" (change)="selectedJourneyOption = 2; changeJourgeType();">
                                            <label class="form-check-label" for="inlineRadio2">Configurar jornada por dia</label>
                                        </div>
                                    </div>

                                    <div class="journey-container p-3">
                                        <div *ngIf="selectedJourneyOption === 2; else byWeek" class="div-fade-in">
                                            <app-journey-by-day [company]="company" (journeyChange)="updateJourney($event)" (updateFormStatus)="updateJoruneyDayFormStatus($event)"></app-journey-by-day>
                                        </div>

                                        <ng-template #byWeek>
                                            <div class="alert alert-warning animated--fade-in" role="alert" *ngIf="showAttentionMessage() && activeJourney">
                                                <strong>ATENÇÃO: </strong>Não podem conter campos vazios ou com 00:00.
                                            </div>
                                            <div class="alert alert-warning animated--fade-in" role="alert" *ngIf="!activeJourney && journeyType === 'week'">
                                                <strong>ATENÇÃO: </strong>Deve existir pelo menos um dia da semana selecionado.
                                            </div>

                                            <div class="p-3 div-fade-in">
                                                <div *ngIf="selectedJourneyOption === 1" class="day-weeks-container d-flex justify-content-between mb-3">
                                                    <div class="btn-day d-flex align-items-center">
                                                        <p-inputSwitch formControlName="enabledMonday"
                                                                       inputId="monday" (ngModelChange)="createJourney()"></p-inputSwitch>
                                                        <label for="journey" class="mb-1">Seg</label>
                                                    </div>
                                                    <div class="btn-day d-flex align-items-center">
                                                        <p-inputSwitch formControlName="enabledTuesday"
                                                                       inputId="tuesday" (ngModelChange)="createJourney()"></p-inputSwitch>
                                                        <label for="journey" class="mb-1">Ter</label>
                                                    </div>
                                                    <div class="btn-day d-flex align-items-center">
                                                        <p-inputSwitch formControlName="enabledWednesday"
                                                                       inputId="wednesday" (ngModelChange)="createJourney()"></p-inputSwitch>
                                                        <label for="journey" class="mb-1">Qua</label>
                                                    </div>
                                                    <div class="btn-day d-flex align-items-center">
                                                        <p-inputSwitch formControlName="enabledThursday"
                                                                       inputId="thursday" (ngModelChange)="createJourney()"></p-inputSwitch>
                                                        <label for="journey" class="mb-1">Qui</label>
                                                    </div>
                                                    <div class="btn-day d-flex align-items-center">
                                                        <p-inputSwitch formControlName="enabledFriday"
                                                                       inputId="friday" (ngModelChange)="createJourney()"></p-inputSwitch>
                                                        <label for="journey" class="mb-1">Sex</label>
                                                    </div>
                                                    <div class="btn-day d-flex align-items-center">
                                                        <p-inputSwitch formControlName="enabledSaturday"
                                                                       inputId="saturday" (ngModelChange)="createJourney()"></p-inputSwitch>
                                                        <label for="journey" class="mb-1">Sab</label>
                                                    </div>
                                                    <div class="btn-day d-flex align-items-center">
                                                        <p-inputSwitch formControlName="enabledSunday"
                                                                       inputId="sunday" (ngModelChange)="createJourney()"></p-inputSwitch>
                                                        <label for="journey" class="mb-1">Dom</label>
                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div class="form-group col-lg-12" style="margin-bottom: 0px;">
                                                        <label>Período</label>
                                                    </div>
                                                    <div class="form-group col-lg-3">
                                                        <div class="p-field mr-4">
                                                            <label class="label-period" for="startTimeFirstPeriod">Início Primeiro Período *</label>
                                                            <p-inputMask class="p-inputtext-sm" id="startTimeFirstPeriod" placeholder="Ex: 00:00" mask="99:99"
                                                                formControlName="startTimeFirstPeriod"
                                                                [disabled]="!activeJourney"
                                                                (ngModelChange)="createJourney()"
                                                                [ngClass]="{
                                                                    'is-invalid': formRegister.get('startTimeFirstPeriod')?.hasError('required') && formRegister.get('startTimeFirstPeriod')?.touched}">
                                                            </p-inputMask>
                                                            <div class="invalid-feedback" *ngIf="formRegister.get('startTimeFirstPeriod')?.hasError('required')
                                                                && formRegister.get('startTimeFirstPeriod')?.touched">
                                                                Campo requerido
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group col-lg-3">
                                                        <label class="label-period" for="endTimeFirstPeriod">Fim Primeiro Período *</label>
                                                        <div class="p-field mr-4">
                                                            <p-inputMask class="p-inputtext-sm" id="endTimeFirstPeriod" placeholder="Ex: 00:00" mask="99:99"
                                                                formControlName="endTimeFirstPeriod"
                                                                [disabled]="!activeJourney"
                                                                (ngModelChange)="createJourney()"
                                                                [ngClass]="{
                                                                    'is-invalid': formRegister.get('endTimeFirstPeriod')?.hasError('required') && formRegister.get('endTimeFirstPeriod')?.touched}">
                                                            </p-inputMask>
                                                            <div class="invalid-feedback" *ngIf="formRegister.get('endTimeFirstPeriod')?.hasError('required')
                                                                && formRegister.get('endTimeFirstPeriod')?.touched">
                                                                Campo requerido
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group col-lg-3">
                                                        <label class="label-period" for="startTimeSecondPeriod">Início Segundo Período *</label>
                                                        <div class="p-field mr-4">
                                                            <p-inputMask class="p-inputtext-sm" id="startTimeSecondPeriod" placeholder="Ex: 00:00" mask="99:99"
                                                                formControlName="startTimeSecondPeriod"
                                                                [disabled]="!activeJourney"
                                                                (ngModelChange)="createJourney()"
                                                                [ngClass]="{
                                                                    'is-invalid': formRegister.get('startTimeSecondPeriod')?.hasError('required') && formRegister.get('startTimeSecondPeriod')?.touched}">
                                                            </p-inputMask>
                                                            <div class="invalid-feedback" *ngIf="formRegister.get('startTimeSecondPeriod')?.hasError('required')
                                                                && formRegister.get('startTimeSecondPeriod')?.touched">
                                                                Campo requerido
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group col-lg-3">
                                                        <label class="label-period" for="endTimeSecondPeriod">Fim Segundo Período *</label>
                                                        <div class="p-field">
                                                            <p-inputMask class="p-inputtext-sm" id="endTimeSecondPeriod" placeholder="Ex: 00:00" mask="99:99"
                                                                formControlName="endTimeSecondPeriod"
                                                                [disabled]="!activeJourney"
                                                                (ngModelChange)="createJourney()"
                                                                [ngClass]="{
                                                                    'is-invalid': formRegister.get('endTimeSecondPeriod')?.hasError('required') && formRegister.get('endTimeSecondPeriod')?.touched}">
                                                            </p-inputMask>
                                                            <div class="invalid-feedback" *ngIf="formRegister.get('endTimeSecondPeriod')?.hasError('required')
                                                                && formRegister.get('endTimeSecondPeriod')?.touched">
                                                                Campo requerido
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="divider"></div>

                        <div class="button-save-container d-flex justify-content-center flex-column">
                            <div class="d-flex justify-content-center mb-3">
                                <button type="submit" *ngIf="journeyType === 'week'" [disabled]="(!formRegister.valid || !formStatus) || loading"
                                        class="btn btn-primary btn-block">
                                    <div *ngIf="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                            <span class="sr-only">Salvando...</span>
                                    </div>
                                    <span *ngIf="!loading">Cadastrar Funcionário</span>
                                </button>
                                <button type="submit" *ngIf="journeyType === 'day'" [disabled]="!formJourneyDayStatus || !formRegister.valid || loading"
                                        class="btn btn-primary btn-block">
                                    <div *ngIf="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                            <span class="sr-only">Salvando...</span>
                                    </div>
                                    <span *ngIf="!loading">Cadastrar Funcionário</span>
                                </button>
                            </div>

                            <div class="d-flex justify-content-center btn-cncel">
                                <button type="button" (click)="backToEmployee()"
                                        class="btn btn-outline-secondary btn-block">
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-panel>
        </div>
    </form>
</div>
