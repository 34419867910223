import {createReducer, on} from '@ngrx/store';
import * as EmployeeActionTypes from '../actions/employee.action';
import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import { Employee } from '../../model/employee';
import { EmployeeState } from '../states/employee.state';

export const adapter: EntityAdapter<Employee> = createEntityAdapter<Employee>();
export const initialState: EmployeeState = adapter.getInitialState({ isLoading: false, error: null });

export const employeeReducer = createReducer( initialState,

    on(EmployeeActionTypes.loadEmployeeRequestAction, (state, {id}) => ({
        ...state,
        isLoading: true
    })),

    on(EmployeeActionTypes.loadEmployeeSuccessAction, (state, {employees}) => {
        return adapter.addMany(employees, {
            ...state,
            isLoading: true
        });
    }),

    on(EmployeeActionTypes.loadEmployeetFailureAction, (state, {error}) => ({
        ...state,
        isLoading: false,
        error
    })),

    on(EmployeeActionTypes.clearEmployeeStateAction, (state) => ({
        ...state = initialState,
    })),
);
