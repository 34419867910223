import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Company } from 'src/model/company';
import { Contract } from 'src/model/contract';

@Component({
    selector: 'app-journey-by-day',
    templateUrl: './journey-by-day.component.html',
    styleUrls: ['./journey-by-day.component.scss'],
})
export class JourneyByDayComponent implements OnInit {
    @Input() company: Company | undefined;
    @Input() contract: Contract | undefined;
    @Output() journeyChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() updateFormStatus: EventEmitter<any> = new EventEmitter<any>();

    public formJourney: FormGroup;
    public journey: any = {};
    public formStatus = false;
    public activeJourney = false;

    constructor(
        private formBuilder: FormBuilder,
    ) {
        this.formJourney = this.formBuilder.group({
            startTimeFirstPeriodDay1: ['00:00'],
            endTimeFirstPeriodDay1: ['00:00'],
            startTimeSecondPeriodDay1: ['00:00'],
            endTimeSecondPeriodDay1: ['00:00'],
            enabledMonday: [false],

            startTimeFirstPeriodDay2: ['00:00',],
            endTimeFirstPeriodDay2: ['00:00',],
            startTimeSecondPeriodDay2: ['00:00',],
            endTimeSecondPeriodDay2: ['00:00',],
            enabledTuesday: [false],

            startTimeFirstPeriodDay3: ['00:00'],
            endTimeFirstPeriodDay3: ['00:00'],
            startTimeSecondPeriodDay3: ['00:00'],
            endTimeSecondPeriodDay3: ['00:00'],
            enabledWednesday: [false],

            startTimeFirstPeriodDay4: ['00:00'],
            endTimeFirstPeriodDay4: ['00:00'],
            startTimeSecondPeriodDay4: ['00:00'],
            endTimeSecondPeriodDay4: ['00:00'],
            enabledThursday: [false],

            startTimeFirstPeriodDay5: ['00:00'],
            endTimeFirstPeriodDay5: ['00:00'],
            startTimeSecondPeriodDay5: ['00:00'],
            endTimeSecondPeriodDay5: ['00:00'],
            enabledFriday: [false],

            startTimeFirstPeriodDay6: ['00:00'],
            endTimeFirstPeriodDay6: ['00:00'],
            startTimeSecondPeriodDay6: ['00:00'],
            endTimeSecondPeriodDay6: ['00:00'],
            enabledSaturday: [false],

            startTimeFirstPeriodDay7: ['00:00'],
            endTimeFirstPeriodDay7: ['00:00'],
            startTimeSecondPeriodDay7: ['00:00'],
            endTimeSecondPeriodDay7: ['00:00'],
            enabledSunday: [false],
        });
        this.formJourney.get('enabledMonday')!.valueChanges.subscribe((enabled) => {
            this.updateMondayValidation(enabled);
        });
        this.formJourney.get('enabledTuesday')!.valueChanges.subscribe((enabled) => {
            this.updateTuesdayValidation(enabled);
        });
        this.formJourney.get('enabledWednesday')!.valueChanges.subscribe((enabled) => {
            this.updateWednesdayValidation(enabled);
        });
        this.formJourney.get('enabledThursday')!.valueChanges.subscribe((enabled) => {
            this.updateThursdayValidation(enabled);
        });
        this.formJourney.get('enabledFriday')!.valueChanges.subscribe((enabled) => {
            this.updateFridayValidation(enabled);
        });
        this.formJourney.get('enabledSaturday')!.valueChanges.subscribe((enabled) => {
            this.updateSaturdayValidation(enabled);
        });
        this.formJourney.get('enabledSunday')!.valueChanges.subscribe((enabled) => {
            this.updateSundayValidation(enabled);
        });
    }

    ngOnInit(): void {
        if (this.company !== undefined) {
            if (this.company?.journeyType === 'day') {
                this.journey = JSON.parse(this.company!.journey!)
                this.initializePage();
            }
        } else {
            if (this.contract?.journeyType === 'day') {
                this.journey = JSON.parse(this.contract!.journey!)
                this.initializePage();
            }
        }

    }

    async initializePage(): Promise<void> {
        const journeyDays: { [key: string]: string[] } = {};

        for (let i = 1; i <= 7; i++) {
            journeyDays[i.toString()] = this.journey[i.toString()].split('-');
        }

        this.formJourney.patchValue({
            startTimeFirstPeriodDay1: journeyDays['1'][0],
            endTimeFirstPeriodDay1: journeyDays['1'][1],
            startTimeSecondPeriodDay1: journeyDays['1'][2],
            endTimeSecondPeriodDay1: journeyDays['1'][3],
            enabledMonday: JSON.parse(journeyDays['1'][4]),

            startTimeFirstPeriodDay2: journeyDays['2'][0],
            endTimeFirstPeriodDay2: journeyDays['2'][1],
            startTimeSecondPeriodDay2: journeyDays['2'][2],
            endTimeSecondPeriodDay2: journeyDays['2'][3],
            enabledTuesday: JSON.parse(journeyDays['2'][4]),

            startTimeFirstPeriodDay3: journeyDays['3'][0],
            endTimeFirstPeriodDay3: journeyDays['3'][1],
            startTimeSecondPeriodDay3: journeyDays['3'][2],
            endTimeSecondPeriodDay3: journeyDays['3'][3],
            enabledWednesday: JSON.parse(journeyDays['3'][4]),

            startTimeFirstPeriodDay4: journeyDays['4'][0],
            endTimeFirstPeriodDay4: journeyDays['4'][1],
            startTimeSecondPeriodDay4: journeyDays['4'][2],
            endTimeSecondPeriodDay4: journeyDays['4'][3],
            enabledThursday: JSON.parse(journeyDays['4'][4]),

            startTimeFirstPeriodDay5: journeyDays['5'][0],
            endTimeFirstPeriodDay5: journeyDays['5'][1],
            startTimeSecondPeriodDay5: journeyDays['5'][2],
            endTimeSecondPeriodDay5: journeyDays['5'][3],
            enabledFriday: JSON.parse(journeyDays['5'][4]),

            startTimeFirstPeriodDay6: journeyDays['6'][0],
            endTimeFirstPeriodDay6: journeyDays['6'][1],
            startTimeSecondPeriodDay6: journeyDays['6'][2],
            endTimeSecondPeriodDay6: journeyDays['6'][3],
            enabledSaturday: JSON.parse(journeyDays['6'][4]),

            startTimeFirstPeriodDay7: journeyDays['7'][0],
            endTimeFirstPeriodDay7: journeyDays['7'][1],
            startTimeSecondPeriodDay7: journeyDays['7'][2],
            endTimeSecondPeriodDay7: journeyDays['7'][3],
            enabledSunday: JSON.parse(journeyDays['7'][4]),

        });

        this.createJourney();
    }

    createJourney() {
        this.journey = {};
        this.checkIfOneAnableExists();
        const enabledSunday = this.formJourney.get(`enabledSunday`)?.value;
        const enabledMonday = this.formJourney.get(`enabledMonday`)?.value;
        const enabledTuesday = this.formJourney.get(`enabledTuesday`)?.value;
        const enabledWednesday = this.formJourney.get(`enabledWednesday`)?.value;
        const enabledThursday = this.formJourney.get(`enabledThursday`)?.value;
        const enabledFriday = this.formJourney.get(`enabledFriday`)?.value;
        const enabledSaturday = this.formJourney.get(`enabledSaturday`)?.value;
        this.journey[1] = this.getJourneyString(this.formJourney, 1, enabledMonday);
        this.journey[2] = this.getJourneyString(this.formJourney, 2, enabledTuesday);
        this.journey[3] = this.getJourneyString(this.formJourney, 3, enabledWednesday);
        this.journey[4] = this.getJourneyString(this.formJourney, 4, enabledThursday);
        this.journey[5] = this.getJourneyString(this.formJourney, 5, enabledFriday);
        this.journey[6] = this.getJourneyString(this.formJourney, 6, enabledSaturday);
        this.journey[7] = this.getJourneyString(this.formJourney, 7, enabledSunday);

        this.journeyChange.emit(this.journey);

    }

    getJourneyString(formGroup: any, day: number, isAnabled: boolean) {
        let isEnabled = isAnabled;
        const startTimeFirstPeriod = formGroup.get(`startTimeFirstPeriodDay${day}`).value;
        const endTimeFirstPeriod = formGroup.get(`endTimeFirstPeriodDay${day}`).value;
        const startTimeSecondPeriod = formGroup.get(`startTimeSecondPeriodDay${day}`).value;
        const endTimeSecondPeriod = formGroup.get(`endTimeSecondPeriodDay${day}`).value;

        return `${startTimeFirstPeriod || '00:00'}-${endTimeFirstPeriod}-${startTimeSecondPeriod}-${endTimeSecondPeriod}-${isEnabled}`;
    }

    resetAllForms() {
        this.formJourney.reset();
        this.formJourney.reset();
        this.formJourney.reset();
        this.formJourney.reset();
        this.formJourney.reset();
        this.formJourney.reset();
        this.formJourney.reset();
    }

    updateMondayValidation(enabled: boolean) {
        const fieldsToValidate = [
          'startTimeFirstPeriodDay1',
          'endTimeFirstPeriodDay1',
          'startTimeSecondPeriodDay1',
          'endTimeSecondPeriodDay1',
        ];

        this.updateValidation(fieldsToValidate, enabled);
    }

    updateTuesdayValidation(enabled: boolean) {
        const fieldsToValidate = [
          'startTimeFirstPeriodDay2',
          'endTimeFirstPeriodDay2',
          'startTimeSecondPeriodDay2',
          'endTimeSecondPeriodDay2',
        ];

        this.updateValidation(fieldsToValidate, enabled);
    }

    updateWednesdayValidation(enabled: boolean) {
        const fieldsToValidate = [

          'startTimeFirstPeriodDay3',
          'endTimeFirstPeriodDay3',
          'startTimeSecondPeriodDay3',
          'endTimeSecondPeriodDay3',
        ];

        this.updateValidation(fieldsToValidate, enabled);
    }

    updateThursdayValidation(enabled: boolean) {
        const fieldsToValidate = [
          'startTimeFirstPeriodDay4',
          'endTimeFirstPeriodDay4',
          'startTimeSecondPeriodDay4',
          'endTimeSecondPeriodDay4',
        ];

        this.updateValidation(fieldsToValidate, enabled);
    }

    updateFridayValidation(enabled: boolean) {
        const fieldsToValidate = [
          'startTimeFirstPeriodDay5',
          'endTimeFirstPeriodDay5',
          'startTimeSecondPeriodDay5',
          'endTimeSecondPeriodDay5',
        ];

        this.updateValidation(fieldsToValidate, enabled);
    }

    updateSaturdayValidation(enabled: boolean) {
        const fieldsToValidate = [
          'startTimeFirstPeriodDay6',
          'endTimeFirstPeriodDay6',
          'startTimeSecondPeriodDay6',
          'endTimeSecondPeriodDay6',
        ];

        this.updateValidation(fieldsToValidate, enabled);
    }

    updateSundayValidation(enabled: boolean) {
        const fieldsToValidate = [
          'startTimeFirstPeriodDay7',
          'endTimeFirstPeriodDay7',
          'startTimeSecondPeriodDay7',
          'endTimeSecondPeriodDay7',
        ];

        this.updateValidation(fieldsToValidate, enabled);
    }

    updateValidation(fieldsToValidate: any[], enabled: boolean) {
        fieldsToValidate.forEach((field) => {
          this.formJourney.get(field)!.clearValidators();
          this.formJourney.get(field)!.updateValueAndValidity();
        });

        if (enabled) {
          fieldsToValidate.forEach((field) => {
            this.formJourney.get(field)!.setValidators([
                Validators.required,
                (control: AbstractControl) => {
                  const value = control.value;
                  if (value === '00:00') {
                    return { invalidTime: true };
                  }
                  return null;
                }
              ]);
              this.formJourney.get(field)!.updateValueAndValidity();
          });
        }

        if (!enabled) {
          fieldsToValidate.forEach((field) => {
            this.formJourney.get(field)!.setValue('00:00');
            this.formJourney.get(field)!.updateValueAndValidity();
          });
        }
    }

    checkIfOneAnableExists() {
        if (
            this.formJourney.get('enabledMonday')!.value === false &&
            this.formJourney.get('enabledTuesday')!.value === false &&
            this.formJourney.get('enabledWednesday')!.value === false &&
            this.formJourney.get('enabledThursday')!.value === false &&
            this.formJourney.get('enabledFriday')!.value === false &&
            this.formJourney.get('enabledSaturday')!.value === false &&
            this.formJourney.get('enabledSunday')!.value === false
        ) {
            this.formStatus = false;
            this.activeJourney = false;
            this.updateFormStatus.emit(this.formStatus);
        } else {
            this.activeJourney = true;
            this.formStatus = this.formJourney.valid;
            this.updateFormStatus.emit(this.formStatus);
        }

    }
}
