<div>
  <form [formGroup]="formRegister" (ngSubmit)="onSave()">
    <div class="w-100">
        <div class="form-row">
            <div class="form-group col-lg-12">
              <label for="name">Nome do Cargo</label>
              <input id="name"
                     type="text"
                     class="form-control"
                     formControlName="name"
                     [ngClass]="{
                       'is-invalid': formRegister.get('name')?.invalid && formRegister.get('name')?.touched
                     }">
              <div class="invalid-feedback" *ngIf="formRegister.get('name')?.hasError('required')
                   && formRegister.get('name')?.touched">Campo nome é requerido
              </div>
              <div class="invalid-feedback" *ngIf="formRegister.get('name')?.hasError('maxlength')
                   && formRegister.get('name')?.touched">Campo excedeu o limite de 120 caracteres
              </div>
            </div>
        </div>

        <div class="text-right pb-5">
            <button type="button" class="btn btn-link mr-2" (click)="onClose()">Cancelar</button>
            <button type="submit" [disabled]="!formRegister.valid" class="btn btn-primary btn-default">Salvar</button>
        </div>
    </div>
  </form>
</div>
