<p-toast></p-toast>

<form [formGroup]="form" class="m-3">

    <div class="row align-items-end">
        <div class="col">
            <div class="calendar-container">
                <p>Início das Férias</p>
                <p-calendar [style]="{'width':'100%'}"
                    #startDate
                    [disabled]="onVacation"
                    [inputStyle]="{'width':'100%'}" [showIcon]="false" [disabled]="onVacation" dataType="string" placeholder="Selecione uma data"
                    dateFormat="dd/mm/yy" (onSelect)="onSelectStartDate($event)"
                    formControlName="startDateInput" inputStyleClass="form-control">
                </p-calendar>
            </div>
        </div>

        <div class="col">
            <div class="calendar-container">
                <p>Fim das Férias</p>
                <p-calendar [style]="{'width':'100%'}"
                    [minDate]="startDate.value"
                    [disabled]="onVacation"
                    [inputStyle]="{'width':'100%'}" [showIcon]="false" [disabled]="onVacation" dataType="string" placeholder="Selecione uma data"
                    dateFormat="dd/mm/yy" formControlName="endDateInput" inputStyleClass="form-control">
                </p-calendar>
            </div>
        </div>

        <div class="col">
            <div class="d-flex justify-content-start btn-container">
                <button class="btn btn-primary btn-prog mr-3" [style.display]="btnProgramar" [disabled]="onVacation" (click)="onProgrammerVacation()">
                    Programar
                </button>

                <button class="btn btn-primary btn-edit mr-3" [style.display]="btnReprogramar" [disabled]="onVacation" (click)="onUpdate()">
                    Editar
                </button>

                <button class="btn btn-link btn-cabcel text-danger" [style.display]="btnExcluir" [disabled]="onVacation" (click)="onDelete()">
                    Cancelar Férias
                </button>
            </div>
        </div>
      </div>
</form>
