import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {SERVER_URL, WS_URL} from '../environments/environment';
import {HttpClient} from '@angular/common/http';
import Notification from '../model/notification';

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {

    private myWebSocket: WebSocket | undefined;
    private messages: Subject<Notification> = new Subject();

    constructor(private httpClient: HttpClient) {
    }

    public connect(): Subject<Notification> {

        if (!this.myWebSocket) {

            this.myWebSocket = new WebSocket(WS_URL);

            this.myWebSocket.addEventListener('open', (e) => {
                this.myWebSocket?.send(JSON.stringify({message: 'connectionID_request', action: 'message'}));
            });
            this.myWebSocket.addEventListener('error', (e) => {
                console.log(e);
                this.myWebSocket?.close();
            });

            this.myWebSocket.addEventListener('message', async (e) => {
                const res = JSON.parse(e.data);
                console.log(res);
                if (res.type === 'connectionID_request_response') {
                    await this.setWebsocketOwnership(res.connectionID);
                } else if (res.type === Notification.REGISTER_CLOCK) {
                    this.messages.next(res);
                }
            });
        }

        return this.messages;
    }

    public close(): void {
        this.myWebSocket?.close();
    }

    public send(message: any): void {
        this.myWebSocket?.send(message);
    }

    private async setWebsocketOwnership(connectionID: string): Promise<any> {
        await this.httpClient.get(`${SERVER_URL}/websocket/set-ownership/${connectionID}`).toPromise();
    }

}
