import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CnpjValidator} from '../app.validators';
import {CompanyService} from 'src/services/company.service';
import {AppStorageService} from 'src/services/app-storage.service';
import HttpCompanyResponse, {Company} from '../../model/company';
import {MessageService} from 'primeng/api';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {EventEmitterService} from '../../services/event-emitter.service';

@Component({
    selector: 'app-company-update',
    templateUrl: './company-update.component.html',
    styleUrls: ['./company-update.component.scss'],
    providers: [MessageService]
})
export class CompanyUpdateComponent implements OnInit {

    @Input() companyData: Company | undefined;
    @Input() companyPrincipalData: Company | undefined;
    @Output() cancelUpdate = new EventEmitter<boolean>();

    public company: Company | undefined;
    public formRegister: FormGroup;
    public formTitle = '';
    public isEditing = false;
    public statusTypes = [{key: 'Ativo', value: 'ACTIVE'}, {key: 'Inativo', value: 'INACTIVE'}];
    private saveMessage = [{severity: 'success', summary: 'Empresa cadastrada!'}];
    private updateMessage = [{severity: 'success', summary: 'Empresa atualizada!'}];
    private cancelMessage = [{severity: 'info', summary: 'Cadastro cancelado!'}];

    constructor(
        private appStorageService: AppStorageService,
        private messageService: MessageService,
        private formBuilder: FormBuilder,
        private companyService: CompanyService,
        private http: HttpClient,
    ) {
        this.formRegister = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(80)]],
            cnpj: ['', Validators.required],
            cei: [''],
            status: [''],
            zipcode: ['', Validators.required],
            streetNumber: ['', Validators.required],
            street: ['', Validators.required],
            state: ['', Validators.required],
            city: ['', Validators.required],
            phoneNumber: ['', Validators.required],

        }, {validator: [CnpjValidator]});
    }

    ngOnInit(): void {
        this.company = this.companyData;
        this.formRegister.patchValue(this.company!);
        if (this.company?.id) {
            this.isEditing = true;
            this.formTitle = 'Editar Empresa';
        } else {
            this.isEditing = false;
            this.formTitle = 'Nova Empresa';
        }
    }

    public async onUpdate(): Promise<void> {

        const {
            name,
            cnpj,
            cei,
            status,
            zipcode,
            streetNumber,
            street,
            state,
            city,
            phoneNumber,
        } = this.formRegister.value;

        const company = {
            name,
            cnpj: cnpj.replace(/\D/g, ''),
            cei: cei.replace(/\D/g, ''),
            status,
            zipcode,
            streetNumber,
            street,
            state,
            city,
            branchAddress: this.companyPrincipalData?.branchAddress,
            flexibleJourney: this.companyPrincipalData?.flexibleJourney,
            headOffice: this.companyPrincipalData?.headOffice,
            homeOffice: this.companyPrincipalData?.homeOffice,
            skipAllValidation: this.companyPrincipalData?.skipAllValidation,
            phoneNumber: phoneNumber.replace(/\D/g, ''),
            journeyType: this.companyPrincipalData?.journeyType,
            journey: this.companyPrincipalData?.journey,
        };

        try {
            await this.companyService.update(company as Company, this.company?.id as string).toPromise();

            setTimeout(() => {
                this.companyService.get(this.company?.id).subscribe(async (response: HttpCompanyResponse) => {
                    await this.appStorageService.setCompany(response.data);
                    this.onCancel();
                   // EventEmitterService.get(EventEmitterService.COMPANY_EVENT_UPDATED).emit(response.data);
                });

            }, 2000);
        } catch (error) {
            console.log('Error: ', error);
        }
    }


    public findZipcode(): void {
        let cep = '';

        cep = this.formRegister.get('zipcode')?.value;

        if (cep !== '') {
            cep = cep.replace(/\D/g, '');
        }

        if (cep !== '') {
            const validaCep = /^[0-9]{8}$/;

            if (validaCep.test(cep)) {
                const options = {headers: {Anonymous: ''}};

                this.http.get(`https://viacep.com.br/ws/${cep}/json`, options)
                    .pipe(
                        map((dados: any) => {
                            if (dados.erro === true) {
                                this.messageService.add({
                                    severity: 'error',
                                    summary: 'CEP inválido ou inexistente',
                                    detail: 'Verifique o número do CEP novamentes'
                                });
                                this.formRegister.get('zipcode')?.setValue('');
                            }
                            this.populateAddressForm(dados);
                        })
                    ).subscribe();
            }
        } else {
            this.resetEmployeeAddressForm();
        }
    }

    populateAddressForm(data: any): void {
        this.formRegister.patchValue({
            street: data.logradouro,
            district: data.bairro,
            state: data.uf,
            city: data.localidade,
        });
    }

    resetEmployeeAddressForm(): void {
        this.formRegister.patchValue({
            street: null,
            addressNumber: null,
            district: null,
            complement: null,
            city: null,
            state: null,
        });
    }

    onCancel() {
        this.cancelUpdate.emit(false);
        this.ngOnInit();
    }
}
