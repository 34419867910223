<p-toast></p-toast>
<p-dialog header="Anexo" [(visible)]="display">
    <img #image [src]="imageUrl" style="width: 481px;" alt="">
    <p-footer>
        <button class="btn btn-link" (click)="hideDialog()">Cancelar</button>
        <a href="javascript:void(0);" (click)="downloadImage(image)" class="btn btn-primary">Baixar</a>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="rejectDialogOpen" [style]="{width: '550px'}" header="Invalidar Justificativa" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="mb-3">
            Você está invalidando uma justificativa, tem certeza disso?
        </div>
        <div class="field">
            <label for="name">Comentário</label>
            <input type="text" pInputText id="name" [(ngModel)]="justificationComment" />
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Não" icon="pi pi-times" class="p-button-text" (click)="hideRejectDialog()"></button>
        <button pButton pRipple label="Sim, invalidar" icon="pi pi-check"
            class="p-button-rounded p-button-danger w-4" (click)="onRejectJustification(justificationDialog!)"></button>
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="acceptDialogOpen" [style]="{width: '550px'}" header="Validar justificativa" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="mb-3">
            Você está validando uma justificativa, tem certeza disso?
        </div>
        <div class="field">
            <label for="name">Comentário</label>
            <input type="text" pInputText id="name" [(ngModel)]="justificationComment" />
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Não" icon="pi pi-times" class="p-button-text" (click)="hideAcceptDialog()"></button>
        <button pButton pRipple label="Sim, invalidar" icon="pi pi-check"
            class="p-button-rounded p-button-success w-4" (click)="onAcceptJustification(justificationDialog!)"></button>
    </ng-template>
</p-dialog>

<div>
    <!-- <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">Justificativa</h1>
    </div> -->

    <app-searchbar-employee
        (searchEvent)="searchJustification($event)"
        (cleanForm)="onCleanForm()"
        #searchBar>
    </app-searchbar-employee>

    <div class="card shadow mb-4">
        <div class="card-body">
            <p-table #dt [value]="justifications" dataKey="id" styleClass="p-datatable-employee" [rowHover]="true"
                     [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
                     [paginator]="true" [filterDelay]="0" sortField="created" [sortOrder]="1"
                     [globalFilterFields]="['employee.name', 'message', 'created']">

                <ng-template pTemplate="caption">
                    <div class="table-header d-flex align-items-center justify-content-end">
                        <button type="button" class="btn btn-sm btn-link ml-3" (click)="searchBar.onCleanForm()">
                            Limpar filtro
                        </button>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr *ngIf="justifications?.length !== 0">
                        <th pSortableColumn="employee.name">Nome
                            <p-sortIcon field="employee.name"></p-sortIcon>
                        </th>
                        <th pSortableColumn="message">Justificativa
                            <p-sortIcon field="message"></p-sortIcon>
                        </th>
                        <th pSortableColumn="message">Comentário
                            <p-sortIcon field="justificationComment"></p-sortIcon>
                        </th>
                        <th pSortableColumn="recordDate">Data de envio
                            <p-sortIcon field="recordDate"></p-sortIcon>
                        </th>
                        <th style="width: 7rem; text-align: center;">Anexo</th>
                        <th style="text-align: center;">Validação</th>
                    </tr>
                    <tr *ngIf="!justificationsFound">
                        <th colspan="4">
                            <div class="d-flex justify-content-center text-muted">
                                <h6>Nenhuma justificativa encontrada</h6>
                            </div>
                        </th>
                    </tr>
                    <tr *ngIf="loading">
                        <th colspan="4">
                            <div class="d-flex justify-content-center text-muted align-items-center p-3">
                                <span class="spinner-grow spinner-grow-sm m-2" role="status" aria-hidden="true"></span>
                                Aguarde estamos carregando os dados...
                            </div>
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-justification let-i>
                    <tr class="p-selectable-row">
                        <td>
                            {{ justification.employee.name }}
                        </td>
                        <td>
                            {{ justification.message }}
                        </td>
                        <td>
                            {{ justification.justificationComment }}
                        </td>
                        <td>
                            {{ justification.recordDate | date:'dd/MM/yyyy' }}
                        </td>
                        <td style="text-align: center">
                            <img #image hidden [src]="url + justification.attachmentId" (error)="image.id = 'true'"/>
                            <button class="btn btn-link btn-icon-primary p-0" *ngIf="image.id === 'true'"
                                    [disabled]="true">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-0 w-0" fill="none" viewBox="0 0 24 24"
                                     stroke="currentColor" stroke-width="1.6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                                </svg>
                            </button>
                            <button class="btn btn-link btn-icon-primary p-0" *ngIf="image.id !== 'true'"
                                    (click)="getJustificationImage(justification?.attachmentId!)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-0 w-0" fill="none" viewBox="0 0 24 24"
                                     stroke="currentColor" stroke-width="1.6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                                </svg>
                            </button>
                        </td>
                        <td style="text-align: center">
                            <!-- start invalidate -->
                            <span *ngIf="checkIsDateIsInvalid(justification.recordDate) === true"
                                            tooltipStyleClass="shadow" [escape]="false"
                                            pTooltip="Só é possível invalidar esta justificativa amanhã"
                                            tooltipPosition="left">
                                <button class="btn btn-link btn-icon-primary validation-btn p-1 mr-3"
                                        [disabled]="justification.status !== 'WAITING' || checkIsDateIsInvalid(justification.recordDate) === true"
                                        [ngClass]="justification.status === 'REJECTED' ? 'btn-invalid-checked' : ''">
                                    <img class="justification-invalidade-icon" src="assets/icon/Ekse/ekse.svg" />
                                </button>
                            </span>

                            <span *ngIf="checkIsDateIsInvalid(justification.recordDate) === false">
                                <button class="btn btn-link btn-icon-primary validation-btn p-1 mr-3"
                                    [disabled]="justification.status !== 'WAITING' || checkIsDateIsInvalid(justification.recordDate) === true"
                                    [ngClass]="justification.status === 'REJECTED' ? 'btn-invalid-checked' : ''"
                                    (click)="rejectDialog(justification)">
                                <img class="justification-invalidade-icon" src="assets/icon/Ekse/ekse.svg"
                                     tooltipStyleClass="shadow" [escape]="false"
                                     pTooltip="Invalidar"
                                     tooltipPosition="top"/>
                                </button>
                            </span>
                            <!-- end invalidate -->

                            <!-- start validate -->
                            <span *ngIf="checkIsDateIsInvalid(justification.recordDate) === true"
                                tooltipStyleClass="shadow" [escape]="false"
                                pTooltip="Só é possível validar esta justificativa amanhã"
                                tooltipPosition="left">
                                <button class="btn btn-link btn-icon-primary validation-btn p-1"
                                        [disabled]="justification.status !== 'WAITING' || checkIsDateIsInvalid(justification.recordDate) === true"
                                        [ngClass]="justification.status === 'ACCEPTED' ? 'btn-valid-checked' : ''">
                                    <img class="justification-validation-icon" src="assets/icon/Check/check.svg"/>
                                </button>
                            </span>
                            <span *ngIf="checkIsDateIsInvalid(justification.recordDate) === false">
                                <button class="btn btn-link btn-icon-primary validation-btn p-1"
                                        [disabled]="justification.status !== 'WAITING' || checkIsDateIsInvalid(justification.recordDate) === true"
                                        [ngClass]="justification.status === 'ACCEPTED' ? 'btn-valid-checked' : ''"
                                        (click)="acceptDialog(justification)">
                                    <img class="justification-validation-icon" src="assets/icon/Check/check.svg"
                                         tooltipStyleClass="shadow" [escape]="false"
                                         pTooltip="Validar"
                                         tooltipPosition="top"/>
                                </button>
                            </span>
                            <!-- end validate -->
                        </td>
                    </tr>
                </ng-template>

            </p-table>
        </div>
    </div>
</div>
