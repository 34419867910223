import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import HttpAbsenceResponse, { Absence } from "src/model/absence";

import {SERVER_URL} from '../environments/environment';
import { Observable } from "rxjs";
import * as moment from "moment";
import { Employee } from "src/model/employee";
import { AppStorageService } from "./app-storage.service";

@Injectable({
    providedIn: 'root'
})

export class AbsenceService {

    private absenceVacationCount: any[] = [];
    private absenceCount: any[] = [];

    constructor(
        private httpClient: HttpClient,
        private appStorageService: AppStorageService,
    ) {
    }

    /**
     *  Save the absence data
     *  path: absence
     */
    public save(data: Absence) {
        return this.httpClient.post<HttpAbsenceResponse>(`${SERVER_URL}/absence`, data);
    }

    /**
     *  Get the last active absence by employee
     *  path: absence/employee/{employeeId}/type/{type}
     */
    public getAbsenceByEmployee(employeeId: string, type: string): Observable<HttpAbsenceResponse> {
        return this.httpClient.get<HttpAbsenceResponse>(`${SERVER_URL}/absence/employee/${employeeId}/type/${type}`);
    }

    /**
     *  Get absence types
     *  path: absence/types
     */
    public getTypes(): Observable<any> {
        return this.httpClient.get<any>(`${SERVER_URL}/absence/types`);
    }

    /**
     *  Get the last active absence by employee
     *  path: absence/employee/{employeeId}/startDate/{startDate}/endDate/{endDate}/type/{type}
     */
    public getAbsenceByEmployees(employeeId: string, startDate: string, endDate: string, type: string): Observable<HttpAbsenceResponse> {
        return this.httpClient.get<HttpAbsenceResponse>(`${SERVER_URL}/absence/employee/${employeeId}/startDate/${startDate}/endDate/${endDate}/type/${type}`);
    }

    /**
     *  Get Absence by Company
     *  path: absence/company/{companyId}/startDate/{startDate}/endDate/{endDate}/type/{type}
     */
    public getAbsenceByCompany(companyId: string, startDate: string, endDate: string, type: string): Observable<HttpAbsenceResponse> {
        return this.httpClient.get<HttpAbsenceResponse>(`${SERVER_URL}/absence/company/${companyId}/startDate/${startDate}/endDate/${endDate}/type/${type}`);
    }

    /**
     *  Update Absence
     *  path: absence/{id}
     */
    public updateAbsence(id: string, data: any): Observable<HttpAbsenceResponse> {
        return this.httpClient.put<HttpAbsenceResponse>(`${SERVER_URL}/absence/${id}`, data);
    }

    /**
     *  Delete Absence
     *  path: absence/{id}
     */
    public deleteAbsence(id: string): Observable<HttpAbsenceResponse> {
        return this.httpClient.delete<HttpAbsenceResponse>(`${SERVER_URL}/absence/${id}`);
    }

    /**
     *  Auxiliary services
     */

    public async checkIfScheduleAbsence(employeeId: string, startDate: string): Promise<boolean> {
        const dataFutura = moment(startDate);
        const dataAtual = moment();

        if (dataFutura.isAfter(dataAtual)) {
            return true;
        } else {
            return false;
        }
    }

    public checkOnVacation(startDateParam: string, endDateParam: string): boolean {
        if (startDateParam && endDateParam) {
            const today = moment();
            const startDate = moment(startDateParam).format('YYYY-MM-DD');
            const endDate = moment(endDateParam).add(1, 'day').format('YYYY-MM-DD');
            if (today.isBetween(startDate, endDate)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    public async countEmployeesInVacation(): Promise<number> {
        this.absenceVacationCount = [];
        const employee: Employee = await this.appStorageService.getEmployee();
        const startOfMonth = moment().clone().startOf('month').subtract(2, 'months').toISOString();
        const endOfMonth = moment().clone().endOf('month').add(2, 'months').toISOString();

        const response: any = await this.getAbsenceByCompany(
            employee.companyId as string, startOfMonth, endOfMonth, 'ALL').toPromise();

        if (Object.keys(response.data).length === 0) {
            return 0;
        } else {
            response.data.map((absence: any) => {
                if (absence.type === 'VACATION') {
                    const today = moment();
                    const startDate = moment(absence.startDate).subtract(1, 'd');
                    const endDate = moment(absence.endDate).add(1, 'd');
                    if (today.isBetween(startDate, endDate)) {
                        this.absenceVacationCount.push(absence);
                    }
                }
            });
        }

        return this.absenceVacationCount.length;
    }

    public async countEmployeesInAbsence(): Promise<number> {
        this.absenceCount = [];
        const employee: Employee = await this.appStorageService.getEmployee();
        const startOfMonth = moment().clone().startOf('month').subtract(2, 'months').toISOString();
        const endOfMonth = moment().clone().endOf('month').add(2, 'months').toISOString();

        const response: any = await this.getAbsenceByCompany(
            employee.companyId as string, startOfMonth, endOfMonth, 'ALL').toPromise();

        if (Object.keys(response.data).length === 0) {
            return 0;
        } else {
            response.data.map((absence: any) => {
                if (absence.type !== 'VACATION') {
                    const today = moment();
                    const startDate = moment(absence.startDate).subtract(1, 'd');
                    const endDate = moment(absence.endDate).add(1, 'd');
                    if (today.isBetween(startDate, endDate)) {
                        this.absenceCount.push(absence);
                    }
                }
            });
        }

        return this.absenceCount.length;
    }

    public async isEmployeeInAbsence(): Promise<any> {
        const employee: Employee = await this.appStorageService.getEmployee();
        const response: any = await this.getAbsenceByEmployee(employee.id as string, 'ALL').toPromise();

        if (response.data.length > 0) {
            if (Object.keys(response!.data!).length !== 0) {
                const startDate = moment(response.data[0].startDate).format('YYYY-MM-DD');
                const endDate = moment(response!.data[0].endDate).add(1, 'day').format('YYYY-MM-DD');
                const today = moment();
                return {value: today.isBetween(startDate, endDate), type: response.data[0].type};
            } else {
                return {value: false, type: response.data[0].type};
            }
        } else {
            return {value: false, type: ''};
        }
    }
}
