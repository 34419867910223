<p-toast></p-toast>
<p-dialog header="Anexo" [(visible)]="display">
    <img #image [src]="imageUrl" style="width: 481px;" alt="">
    <p-footer>
        <button class="btn btn-link" (click)="hideDialog()">Cancelar</button>
        <!-- <a target="_blank" [href]="imageUrl" class="btn btn-primary">Baixar</a> -->
    </p-footer>
</p-dialog>

<p-table #dt [value]="justifications" dataKey="id" styleClass="p-datatable-employee" [rowHover]="true" [rows]="10"
    [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [paginator]="true" [filterDelay]="0"
    sortField="created" [sortOrder]="1" [globalFilterFields]="['message', 'created']">

    <ng-template pTemplate="header">
        <tr *ngIf="justifications?.length !== 0">
            <th pSortableColumn="message">Justificativa
                <p-sortIcon field="message"></p-sortIcon>
            </th>
            <th pSortableColumn="createdAt">Data de envio
                <p-sortIcon field="createdAt"></p-sortIcon>
            </th>
            <th style="width: 7rem; text-align: center;">Anexo</th>
        </tr>
        <tr *ngIf="!justificationsFound">
            <th colspan="4">
                <div class="d-flex justify-content-center text-muted">
                    <h6>Nenhuma justificativa encontrada</h6>
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-justification let-i>
        <tr class="p-selectable-row">
            <td>
                {{ justification.message }}
            </td>
            <td>
                {{ justification.createdAt | date:'dd/MM/yyyy' }}
            </td>
            <td style="text-align: center">
                <img #image hidden [src]="url + justification.attachmentId" (error)="image.id = 'true'" />
                <button class="btn btn-link btn-icon-primary p-0" *ngIf="image.id === 'true'" [disabled]="true">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-0 w-0" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="1.6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                    </svg>
                </button>
                <button class="btn btn-link btn-icon-primary p-0" *ngIf="image.id !== 'true'"
                    (click)="getJustificationImage(justification?.attachmentId!)">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-0 w-0" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="1.6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                    </svg>
                </button>
            </td>
        </tr>
    </ng-template>
</p-table>

