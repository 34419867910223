<div class="file-wrapper">
    <div class="file-input-container" [ngClass]="{'file-error-input': hasError}">
        <div class="d-flex justify-content-between align-items-center">
            <div class="w-100 mr-3">
                <label class="custom-file-upload d-flex">
                    <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event)"
                           accept="{{ allowedExtensions.join(', ') }}">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1"
                         stroke="#5055CB" class="file-upload-icon">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"/>
                    </svg>
                    <div class="d-flex justify-content-between align-items-center w-100">
                        <div *ngIf="!fileName">
                        <span style="font: normal normal normal 14px/19px Nunito Sans; color: #4D4D4D; opacity: 1;">
                            Selecione o arquivo
                        </span>
                        </div>
                        <div>
                        <span style="font: normal normal normal 14px/19px Nunito Sans; color: #4D4D4D; opacity: 1;">
                            {{ fileName }}
                        </span>
                        </div>
                        <div>
                        <span class="mr-3"
                              style="font: normal normal normal 14px/19px Nunito Sans; color: #6D6D6D; opacity: 1;">
                            {{ fileSize }}
                        </span>
                        </div>
                    </div>
                </label>
            </div>
            <div>
                <button class="btn btn-link btn-icon-danger p-0" (click)="clearFile()">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-0 w-0" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor" stroke-width="1.6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
    <span *ngIf="hasError" class="file-error-info">{{messageError}}</span>
</div>
