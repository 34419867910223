import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AppStorageService} from 'src/services/app-storage.service';
import {JustificationService} from 'src/services/justification.service';
import HttpJustificationsResponse, {Justification} from '../../model/justification';
import * as moment from 'moment';
import {JUSTIFICATION_FILE_URL} from '../../environments/environment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {ValidateDatesService} from 'src/services/validate-dates.service';
import {Employee, HttpEmployeesResponse} from 'src/model/employee';
import { forkJoin, map } from 'rxjs';
import { EmployeeService } from 'src/services/employee.service';

@Component({
    selector: 'app-employee-justification-table',
    templateUrl: './employee-justification-table.component.html',
    styleUrls: ['./employee-justification-table.component.scss'],
    providers: [MessageService]
})
export class EmployeeJustificationTableComponent implements OnInit {

    @ViewChild('image', {static: false}) image: ElementRef | undefined;

    public contracts: any[] = [];
    public justifications: any[] = [];
    public imageUrl?: string;
    public filterForm: FormGroup;
    public justification?: Justification[];
    public justificationResponse: any;
    public justificationsFound = false;
    public url = JUSTIFICATION_FILE_URL;
    public loading = false;
    public display = false;
    public justificationIsValid = false;
    public today = moment();
    public rejectDialogOpen = false;
    public acceptDialogOpen = false;
    public justificationDialog: Justification | undefined;
    public justificationComment: string | undefined

    constructor(
        private appStorageService: AppStorageService,
        private justificationService: JustificationService,
        private employeeService: EmployeeService,
        private messageService: MessageService,
        private validateDatesService: ValidateDatesService,
        private formBuilder: FormBuilder,
    ) {
        this.filterForm = this.formBuilder.group({
            startDate: ['', Validators.required],
            endDate: ['', Validators.required],
        });
    }

    async ngOnInit(): Promise<void> {
        await this.initializePage();
    }

    async initializePage(): Promise<void> {
        this.justificationIsValid = false;
        this.justificationsFound = true;
        await this.getJustifications();
    }

    async getJustifications(): Promise<void> {
        this.loading = true;
        const startOfMonth = moment().clone().startOf('month').subtract(1, 'days').toISOString();
        const endOfMonth = moment().clone().endOf('month').toISOString();

        const employee: Employee = await this.appStorageService.getEmployee();

        // const response: any = await this.justificationService
        //     .getJustificationByCompany(employee.companyId!, startOfMonth, endOfMonth)
        //     .toPromise();

        await forkJoin(
            this.justificationService.getJustificationByCompany(employee.companyId!, startOfMonth, endOfMonth),
            this.employeeService.findAllByCompany(employee.companyId!)).pipe(
                map((response: any) => {
                    const httpJustificationResponse: HttpJustificationsResponse = response[0];
                    const httpEmployeeResponse: HttpEmployeesResponse = response[1];

                    httpJustificationResponse.data!.map((justification) => {
                        justification.employee = httpEmployeeResponse.data.find((el) => el.id === justification.employeeId)
                    })
                    this.justificationResponse = httpJustificationResponse
                })
            ).toPromise();

        if (Object.keys(this.justificationResponse.data).length !== 0) {
            this.justifications = await this.justificationResponse.data.sort((a: any, b: any) => {
                return new Date(b.recordDate).getTime() - new Date(a.recordDate).getTime();
            });
            this.loading = false;
        } else {
            this.loading = false;
            this.justificationsFound = false;
        }
    }

    async searchJustificationByDate(startDate: string, endDate: string): Promise<void> {
        this.loading = true;
        this.justificationsFound = true;
        this.justifications = [];

        const convertStartDataToSearch = moment(startDate, 'DD/MM/YYYY').startOf('hour').format(
            'YYYY-MM-DD 00:00:00'
        );
        const convertEtartDataToSearch = moment(endDate, 'DD/MM/YYYY').endOf('hour').format(
            'YYYY-MM-DD 23:59:59'
        );

        const isDateValid = this.validateDatesService.validatePeriods(convertStartDataToSearch, convertEtartDataToSearch);

        if (isDateValid) {
            this.justifications = [];
            const employee: Employee = await this.appStorageService.getEmployee();
            // const response: any = await this.justificationService
            //     .getJustificationByCompany(employee.companyId!, convertStartDataToSearch, convertEtartDataToSearch)
            //     .toPromise();

            await forkJoin(
            this.justificationService.getJustificationByCompany(employee.companyId!, convertStartDataToSearch, convertEtartDataToSearch),
            this.employeeService.findAllByCompany(employee.companyId!)).pipe(
                map((response: any) => {
                    const httpJustificationResponse: HttpJustificationsResponse = response[0];
                    const httpEmployeeResponse: HttpEmployeesResponse = response[1];

                    httpJustificationResponse.data!.map((justification) => {
                        justification.employee = httpEmployeeResponse.data.find((el) => el.id === justification.employeeId)
                    })
                    this.justificationResponse = httpJustificationResponse
                })
            ).toPromise();

            if (Object.keys(this.justificationResponse.data).length > 0) {
                this.justifications = await this.justificationResponse.data.sort((a: any, b: any) => {
                    return new Date(b.recordDate).getTime() - new Date(a.recordDate).getTime();
                });
                this.loading = false;
            } else {
                this.loading = false;
                this.justificationsFound = false;
                this.messageService.add({
                    severity: 'warn',
                    summary: 'Nenhum resultado para o intervalo selecionado!',
                });
            }

        } else {
            this.messageService.add({
                severity: 'warn',
                summary: 'Períodos Incorretos!',
                detail: 'Verifique se os intervalos dos períodos estão corretos'
            });
        }
    }

    async searchJustification(values: any): Promise<void> {
        this.loading = true;
        this.justificationsFound = true;
        this.justifications = [];

        const {employeeId, startDate, endDate} = values;

        const convertStartDataToSearch = moment(startDate, 'DD/MM/YYYY').startOf('hour').format(
            'YYYY-MM-DD 00:00:00'
        );
        const convertEtartDataToSearch = moment(endDate, 'DD/MM/YYYY').endOf('hour').format(
            'YYYY-MM-DD 23:59:59'
        );

        const isDateValid = this.validateDatesService.validatePeriods(convertStartDataToSearch, convertEtartDataToSearch);

        if (isDateValid) {
            if (convertStartDataToSearch && convertEtartDataToSearch && employeeId) {
                const response: any = await this.justificationService.getJustificationByEmployee(
                    employeeId,
                    convertStartDataToSearch,
                    convertEtartDataToSearch
                ).toPromise();

                if (Object.keys(response.data).length > 0) {
                    this.justifications = await response.data.sort((a: any, b: any) => {
                        // @ts-ignore
                        return new Date(b.recordDate).getTime() - new Date(a.recordDate).getTime();
                    });
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.justificationsFound = false;
                    this.messageService.add({
                        severity: 'warn',
                        summary: 'Nenhum resultado para o intervalo selecionado!',
                    });
                }
            } else {
                this.justifications = [];
                const employee: Employee = await this.appStorageService.getEmployee();
                const response: any = await this.justificationService
                    .getJustificationByCompany(employee.companyId!, convertStartDataToSearch, convertEtartDataToSearch)
                    .toPromise();

                if (Object.keys(response.data).length > 0) {
                    this.justifications = await response.data.sort((a: any, b: any) => {
                        return new Date(b.recordDate).getTime() - new Date(a.recordDate).getTime();
                    });
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.justificationsFound = false;
                    this.messageService.add({
                        severity: 'warn',
                        summary: 'Nenhum resultado para o intervalo selecionado!',
                    });
                }
            }
        } else {
            this.messageService.add({
                severity: 'warn',
                summary: 'Períodos Incorretos!',
                detail: 'Verifique se os intervalos dos períodos estão corretos'
            });
        }
    }

    onCleanForm(): void {
        this.ngOnDestroy();
    }

    async ngOnDestroy(): Promise<void> {
        this.justifications = [];
        await this.ngOnInit();
    }

    async getJustificationImage(key: string): Promise<void> {
        this.imageUrl = `${JUSTIFICATION_FILE_URL}${key}`;
        // window.open(this.imageUrl, "_blank");
        this.display = true;
    }

    public async search(): Promise<void> {
        const {
            startDate,
            endDate
        } = this.filterForm.value;

        await this.searchJustificationByDate(
            startDate, endDate
        );
    }

    async rejectDialog(justification: Justification) {
        this.justificationComment = '';
        this.justificationDialog = {...justification};
        this.rejectDialogOpen = true;
    }

    async acceptDialog(justification: Justification) {
        this.justificationComment = '';
        this.justificationDialog = {...justification};
        this.acceptDialogOpen = true;
    }

    async hideRejectDialog() {
        this.rejectDialogOpen = false;
        this.onCleanForm();
    }

    async hideAcceptDialog() {
        this.acceptDialogOpen = false;
        this.onCleanForm();
    }

    async onAcceptJustification(justification: Justification): Promise<void> {
        const justificationValues = {
            id: justification.id,
            contractId: justification.contractId,
            employeeId: justification.employeeId,
            employee: justification.employee,
            companyId: justification.companyId,
            message: justification.message,
            status: justification.status,
            latitude: justification.latitude,
            longitude: justification.longitude,
            recordDate: justification.recordDate,
            recordTime: justification.recordTime,
            recordDateTime: justification.recordDateTime,
            createdAt: justification.createdAt,
            updatedAt: justification.updatedAt,
            attachmentId: justification.attachmentId,
            attachmentType: justification.attachmentType,
            justificationComment: this.justificationComment ? this.justificationComment : 'Sem comentário',
        };

        try {
            await this.justificationService.acceptJustification(justification.id!, justificationValues).toPromise();
            this.acceptDialogOpen = false;
            this.messageService.add({
                severity: 'success',
                summary: 'Confirmado',
                detail: 'Justificativa validada!'
            });
            this.onCleanForm();
        } catch (error) {
            console.log(error);
        }
    }

    async onRejectJustification(justification: Justification): Promise<void> {

        const justificationValues = {
            id: justification.id,
            contractId: justification.contractId,
            employeeId: justification.employeeId,
            employee: justification.employee,
            companyId: justification.companyId,
            message: justification.message,
            status: justification.status,
            latitude: justification.latitude,
            longitude: justification.longitude,
            recordDate: justification.recordDate,
            recordTime: justification.recordTime,
            recordDateTime: justification.recordDateTime,
            createdAt: justification.createdAt,
            updatedAt: justification.updatedAt,
            attachmentId: justification.attachmentId,
            attachmentType: justification.attachmentType,
            justificationComment: this.justificationComment ? this.justificationComment : 'Sem comentário',
        };

        try {
            await this.justificationService.rejectJustification(justification.id!, justificationValues).toPromise();
            this.rejectDialogOpen = false;
            this.messageService.add({
                severity: 'success',
                summary: 'Confirmado',
                detail: 'Justificativa rejeitada!'
            });
            this.onCleanForm();
        } catch (error) {
            console.log(error);
        }
    }

    hideDialog(): void {
        this.display = false;
    }

    public checkIsDateIsInvalid(date: string): any {
        const dateFrom = moment(date);
        return dateFrom.isSame(this.today, 'day');
    }

    downloadImage(img: any) {
        const imgUrl = img.src + '?r=' + Math.floor(Math.random()*100000);
        this.justificationService.downloadJustificationImage(imgUrl);
    }
}
