import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import {MessageService} from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Bank } from 'src/model/banks';
import { Contract } from 'src/model/contract';
import { Employee } from 'src/model/employee';
import { AddressService } from 'src/services/address.service';
import { EmployeeService } from 'src/services/employee.service';


@Component({
  selector: 'app-editing-personal-bankdata',
  templateUrl: './editing-personal-bankdata.component.html',
  styleUrls: ['./editing-personal-bankdata.component.scss'],
  providers: [MessageService, DialogService]
})
export class EditingPersonalBankdataComponent implements OnInit {

//   @Input() contract: Contract = new Contract;
  @Input() inputEmployee: Employee = new Employee;
  @Output() cancelUpdate = new EventEmitter<boolean>();

  public formUpdate: FormGroup;
  public alertHeader = '';
  public alertDescription = '';
  public displayDialog = false;
  public banks: Bank[] = [];
  private messageEmailExists = 'EmailExistsException';
  private messageUsernameExists = 'UsernameExistsException';
  private messagephoneExists = 'PhoneExistsException';
  public currentAccountTypeField = ['Conta Poupança', 'Conta Corrente', 'Conta Salário', 'Conta de Pagamentos'];
  public pixKeyField = ['CPF', 'E-mail', 'Número de telefone celular', 'Chave Aleatória'];
  public editingPersonalBankdat = false;
  public employee?: Employee;

  constructor(
    private formBuilder: FormBuilder,
    private employeeService: EmployeeService,
    private dropDownService: AddressService,
    private messageService: MessageService,
  ) {
    this.formUpdate = this.formBuilder.group({
        bank: [''],
        bankBranch: [''],
        currentAccountType: [''],
        currentAccount: [''],
        verificationDigit: [''],
        pixKey: [''],
        pix: ['', RxwebValidators.required({conditionalExpression: (x: any, y: any) => x.pixKey !== ''})],
    });
  }

  ngOnInit(): void {
    this.initializePage();
  }

  async initializePage() {
    await this.reloadEmployee();
    // const dataBankFields = Object.assign(this.contract);
    // this.formUpdate.patchValue(dataBankFields.employee);
    this.formUpdate.patchValue(this.inputEmployee);
    this.dropDownService.getBanks().subscribe(bank => {
        const sorted = bank.sort((a: any, b:any) => {
            const ca: string = a.longname;
            const cb: string = b.longname;
            return ca.toLowerCase().localeCompare(cb.toLowerCase());
        })

        sorted.map((b: Bank) => {
            this.banks.push({
                longname: b.longname,
            });
        });
    });
  }

  public async onSave(): Promise<void> {
    try {
        const employeeId = this.inputEmployee?.id;

        const employee: any = {
            name: this.inputEmployee?.name,
            email: this.inputEmployee?.email,
            registryId: this.inputEmployee?.registryId,
            phone: this.inputEmployee?.phone,
            state: this.inputEmployee?.state,
            city: this.inputEmployee?.city,
            bank: this.formUpdate.get('bank')?.value,
            bankBranch: this.formUpdate.get('bankBranch')?.value,
            currentAccountType: this.formUpdate.get('currentAccountType')?.value,
            currentAccount: this.formUpdate.get('currentAccount')?.value,
            verificationDigit: this.formUpdate.get('verificationDigit')?.value,
            pixKey: this.formUpdate.get('pixKey')?.value,
            pix: this.formUpdate.get('pix')?.value,
        }
        // const employeeId = this.contract.employee?.id;

        // const employee: any = {
        //     name: this.contract.employee?.name,
        //     email: this.contract.employee?.email,
        //     registryId: this.contract.employee?.registryId,
        //     phone: this.contract.employee?.phone,
        //     state: this.contract.employee?.state,
        //     city: this.contract.employee?.city,
        //     bank: this.formUpdate.get('bank')?.value,
        //     bankBranch: this.formUpdate.get('bankBranch')?.value,
        //     currentAccountType: this.formUpdate.get('currentAccountType')?.value,
        //     currentAccount: this.formUpdate.get('currentAccount')?.value,
        //     verificationDigit: this.formUpdate.get('verificationDigit')?.value,
        //     pixKey: this.formUpdate.get('pixKey')?.value,
        //     pix: this.formUpdate.get('pix')?.value,
        // }

      await this.employeeService.update(employeeId, employee).toPromise();

      this.messageService.add({
          severity: 'success',
          summary: 'Dados bancários atualizado',
          detail: ''
      });

      setTimeout(() => {
          this.onCancel();
      }, 2000)

    } catch (error: any) {
      if (error.code === this.messageEmailExists) {
          this.messageService.add({
              severity: 'error',
              summary: 'Problema no registro',
              detail: 'E-mail já está em uso'
          });
      } else if (error.code === this.messagephoneExists) {
          this.messageService.add({
              severity: 'error',
              summary: 'Problema no registro',
              detail: 'Número telefonico já está em uso'
          });
      } else if (error.code === this.messageUsernameExists) {
          this.messageService.add({
              severity: 'error',
              summary: 'Problema no registro',
              detail: 'Usuário já cadastrado'
          });
      } else {
          this.messageService.add({
              severity: 'error',
              summary: 'Problema no registro',
              detail: 'Verifique as informações fornecidas, ou se o problema persistir contacte a Alphatime'
          });
      }
    }
}

public onEditingPersonalBankdata(): void {
    this.editingPersonalBankdat = true;
}

private async reloadEmployee() {
    const employeeEmail = this.inputEmployee?.email;
    const reloadContract = await this.employeeService.findByEmail(employeeEmail!).toPromise();
    this.employee = Object.assign(reloadContract?.data!);
}

onCancel() {
    // this.cancelUpdate.emit(false);
    // this.initializePage();
    this.editingPersonalBankdat = false;
    this.reloadEmployee();
}

}
