import { Component, Input, OnInit } from '@angular/core';
import { Company } from 'src/model/company';

@Component({
    selector: 'app-company-details',
    templateUrl: './company-details.component.html',
    styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent implements OnInit {

    @Input() company: Company | undefined;

    constructor() {}

    ngOnInit(): void {
    }
}
