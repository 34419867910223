import {Contract} from '../../model/contract';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import HttpCompanyPositionResponse, {CompanyPosition} from 'src/model/company-position';
import {CompanyPositionService} from 'src/services/company-position.service';
import {Store} from '@ngrx/store';
import {ContractState} from 'src/stores/states/contract.state';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import * as ContractAction from '../../stores/actions/contract.action';
import { EventEmitterService } from 'src/services/event-emitter.service';
import { EmployeeConfigurationComponent } from '../employee-configuration/employee-configuration.component';
import { Employee } from 'src/model/employee';
import { ContractService } from 'src/services/contract.service';
import { Company } from 'src/model/company';

@Component({
  selector: 'app-configure-employee',
  templateUrl: './configure-employee.component.html',
  styleUrls: ['./configure-employee.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService]
})
export class ConfigureEmployeeComponent implements OnInit, OnDestroy {

  @ViewChild(EmployeeConfigurationComponent) child: EmployeeConfigurationComponent | undefined;

  public contract: Contract | undefined;
  public employee: Employee | undefined;
  public company: Company | undefined;
  public picture = './assets/icon/Avatar/user.png';
  public profilePicture?: string;
  public option?: string;
  public title?: string | undefined;
  public companiesPosition ? = '';
  public workDays = [];
  public editingPersonalData = false;
  public editingPersonalBankdat = false;
  public editingEmployeeConfiguration = false;
  public editingWorkPlace = false;
  public showBranchAddress = false;
  public companiesPositions: CompanyPosition[] = [];
  public ref: DynamicDialogRef = new DynamicDialogRef();

  constructor(
    private router: Router,
    private contractService: ContractService,
    private store: Store<ContractState>,
    private companyPositionService: CompanyPositionService
  ) {
    EventEmitterService.get(EventEmitterService.HEAD_OFFICE_EVENT_CHECKED).subscribe((event: any) => {
        this.showBranchAddress = event;
    });
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.contract = this.router.getCurrentNavigation()?.extras.state?.contract;
      this.employee = this.router.getCurrentNavigation()?.extras.state?.employee;
      this.company = this.router.getCurrentNavigation()?.extras.state?.company;
      this.option = this.router.getCurrentNavigation()?.extras.state?.option;
    } else {
        this.router.navigate(['/employee/list']);
    }

    if (this.contract?.branchAddress === true || this.contract?.flexibleJourney === true) {
        this.showBranchAddress = true;
    } else {
        this.showBranchAddress = false;
    }
  }

  async ngOnInit(): Promise<void> {
    await this.initializePage();
    // await this.loadContract(this.employee.id);
  }

  ngOnDestroy(): void {
    this.store.dispatch(ContractAction.clearContractStateAction());
  }

  async loadContract(employeeId: string) {
    this.contractService.getContractsByEmployee(employeeId)
  }

  async initializePage(): Promise<void> {
      this.child?.initializePage();

      if (this.option !== 'vacation'){
          this.option = 'about' ;
      }
      if (this.contract){

          await this.loadCompanyPositions();

          this.title = `Perfil de ${this.employee?.name?.split(' ')[0]}`;
          this.profilePicture = this.picture;

          if (this.contract && this.employee?.picture !== undefined) {
              this.profilePicture = `${this.employee?.picture}?${new Date().getTime()}`;
          } else {
              this.profilePicture = this.picture;
          }
      }
  }

  async loadCompanyPositions(): Promise<void> {
    const response: HttpCompanyPositionResponse | undefined = await this.companyPositionService.list(
        this.employee?.companyId as string
    ).toPromise() || undefined;

    const result = response?.data?.find((cp) => {
        return cp.id === this.contract?.companyPositionId;
    });

    this.companiesPosition = result?.name;
  }

  onEditingPersonalData(): void {
      this.editingPersonalData = true;
  }

  onEditingPersonalBankdata(): void {
      this.editingPersonalBankdat = true;
  }

  async onCancelUpdatePersonalBankdata(event: boolean): Promise<void> {
    this.editingPersonalBankdat = event;
    await this.initializePage();
  }

  async onCancelUpdatePersonalData(event: boolean): Promise<void> {
    this.editingPersonalData = event;
    await this.initializePage();
  }

  onEditingConfigureEmployee(): void {
    this.editingEmployeeConfiguration = true;
  }

  onEditingWorkPlace(): void {
    this.editingWorkPlace = true;
  }

  async onCancelConfigureEmployee(event: boolean): Promise<void> {
    this.editingEmployeeConfiguration = event;
    await this.initializePage();
  }

    async onCancelUpdateWorkplace(event: boolean): Promise<void> {
    this.editingWorkPlace = event;
    await this.initializePage();
  }

  async onUpdate(event: any) {
    this.employee = event;
  }
}
