import {Component, OnInit} from '@angular/core';
import {PaymentService} from 'src/services/payment.service';
import {PRODUCTS} from 'src/environments/environment';
import {LoaderService} from 'src/services/loader-service';
import {AppStorageService} from 'src/services/app-storage.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-signature',
    templateUrl: './signature.component.html',
    styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit {

    payment = false;
    public cardsInfo: any[] = [];
    public newCheckout: boolean | false | undefined;
    constructor(
        private loaderService: LoaderService,
        private paymentService: PaymentService,
        private appStorageService: AppStorageService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.paymentService.getCardsInfo().subscribe(cards => {
            cards.map((card) => {
                if (card.cardTitle !== 'Grátis') {
                    this.cardsInfo.push(card);
                }
            });
        });
        this.newCheckout = this.router.url.indexOf('checkout') > 0;
    }

    async onCheckout(plan: string, period: string): Promise<void> {
        this.loaderService.setLoading(true);
        let email: string | undefined = '';

        const employee = await this.appStorageService.getEmployee();

        if (employee) {
            email = employee.email;
        }

        if (email) {
            const periods: 'MENSAL' | 'ANUAL' = period as 'MENSAL' | 'ANUAL';
            const plans: 'STUP' | 'PE' | 'ME1' | 'ME2' = plan as 'STUP' | 'PE' | 'ME1' | 'ME2';
            const priceId: string = PRODUCTS[periods][plans] as string;
            this.paymentService.createCheckoutSession(priceId, email)
                .subscribe((sessionId) => {
                    // @ts-ignore
                    this.paymentService.redirectToCheckout(sessionId.data).then((result) => {
                        this.loaderService.setLoading(false);
                    });
                });
        }
    }

    public onBack(): void {
        this.payment = false;
    }

}
