import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noticePriority'
})
export class NoticePriorityPipe implements PipeTransform {

  transform(priority: string): any {
    let result: string = '';

    if (priority === '1-LOW') {
        result = 'Baixa';
    } else if (priority === '2-MEDIUM') {
        result = 'Média';
    } else {
        result = 'Alta'
    }

    return result;
  }

}
