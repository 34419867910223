import {EventEmitter, Injectable} from '@angular/core';
import {CameraPhoto, CameraResultType, CameraSource, Plugins} from '@capacitor/core';
import {Storage} from 'aws-amplify';

const { Camera } = Plugins;

@Injectable({
    providedIn: 'root'
})
export class UploadService {

    public refreshProfilePicture = new EventEmitter<string>();

    constructor() {}

    public takePicture(): Promise<CameraPhoto> {

        const options: any  = {
            quality: 100,
            allowEditing: false,
            resultType: CameraResultType.Uri,
            source: CameraSource.Photos
        };

        return Camera.getPhoto(options);
    }

    public takeCamera(): Promise<CameraPhoto> {

        const options: any  = {
            quality: 100,
            allowEditing: false,
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera
        };

        return Camera.getPhoto(options);
    }

    public uploadPicture(fileName: string, image: CameraPhoto): Promise<any> {
        return this.upload(fileName, image.webPath).then((result: any) => {
            return result;
        }).catch((err) => {
            console.log('Error=>', err);
        });
    }

    public async upload(fileName: string | undefined, imagePath: any): Promise<any> {
        // const response = await fetch(imagePath);
        // const blob = await imagePath.blob();

        return Storage.put(`${fileName}-profile-image.jpeg`, imagePath, {
            level: 'public',
            contentType: 'image/jpeg',
        }).then((result: any) => {
            return result;
        }).catch((err) => {
            console.log('Error =>', err);
        });
    }

    public async uploadLogo(fileName: string | undefined, imagePath: any): Promise<any> {
        // const response = await fetch(imagePath);
        // const blob = await imagePath.blob();

        return Storage.put(`${fileName}-logo-image.jpeg`, imagePath, {
            level: 'public',
            contentType: 'image/jpeg',
        }).then((result: any) => {
            return result;
        }).catch((err) => {
            console.log('Error =>', err);
        });
    }
}
