export default class HttpCompanyResponse {
  success: boolean | undefined;
  message: string | undefined;
  data: Company | undefined;
}

export class HttpCompanyPlanStatusResponse {
    success: boolean | undefined;
    message: string | undefined;
    data: any | undefined;
}

export class Company {


    public static FREE_PRODUCT_TYPE = 'FREE';
    public static PAID_PRODUCT_TYPE = 'PAID';
    public static PREMIUM_PRODUCT_TYPE = 'PREMIUM';

    public static ACTIVE_STATUS = 'ACTIVE';
    public static INACTIVE_STATUS = 'INACTIVE';
    public static EXPIRED_STATUS = 'EXPIRED';
    public static INCORRECT_PLAN_STATUS = 'INCORRECT_PLAN';

    id: string | undefined;
    companyId: string | undefined;
    name: string | undefined;
    totalEmployees: number | undefined;
    cnpj?: string | undefined;
    cei?: string | undefined;
    status: string | undefined;
    state: string | undefined;
    city: string | undefined;
    productType: string | undefined;
    productName: string | undefined;
    zipcode: string | undefined;
    allowNewUsers?: boolean;
    streetNumber: number | undefined;
    street: string | undefined;
    phoneNumber: string | undefined;
    latitude: string | undefined;
    longitude: string | undefined;
    createdAt: string | undefined;
    updatedAt: string | undefined;
    homeOffice: boolean | undefined;
    headOffice: boolean | undefined;
    flexibleJourney: boolean | undefined;
    branchAddress: boolean | undefined;
    skipAllValidation: boolean | undefined;
    journey: string | undefined;
    journeyType: string | undefined;
    enabledSunday: boolean | undefined;
    enabledMonday: boolean | undefined;
    enabledTuesday: boolean | undefined;
    enabledWednesday: boolean | undefined;
    enabledThursday: boolean | undefined;
    enabledFriday: boolean | undefined;
    enabledSaturday: boolean | undefined;
    startTimeFirstPeriod: string | undefined;
    endTimeFirstPeriod: string | undefined;
    startTimeSecondPeriod: string | undefined;
    endTimeSecondPeriod: string | undefined;
    type: string | undefined;
    path: string | undefined;
    logo: string | undefined;
}
