import {Component} from '@angular/core';
import {AppStorageService} from '../../services/app-storage.service';
import {NavigationStart, Router} from '@angular/router';
import {EventEmitterService} from '../../services/event-emitter.service';

@Component({
    selector: 'app-admin-layout',
    templateUrl: './admin-layout.component.html',
    styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent {

    public showWarning = false;

    constructor(
        private router: Router,
        private appStorageService: AppStorageService) {
        router.events.subscribe(async (val) => {
            if (val instanceof NavigationStart || this.router.url) {
                const company: any = await appStorageService.getCompany();
                if (company != null){
                    this.showWarning = !company.hasOwnProperty('street');
                }else {
                    this.showWarning = false;
                }
            }
        });
        EventEmitterService.get(EventEmitterService.COMPANY_EVENT_UPDATED).subscribe((data: any) => {
            this.appStorageService.getCompany().then((company) => {
                this.showWarning = !company.hasOwnProperty('street');
            });
        });
    }

}
