<p-dialog header="Funcionários" [(visible)]="display" [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
        <div class="p-dialog-title">
            <span>Funcionários</span>
            <span style="font: normal normal normal 16px/22px Nunito Sans; color: #4D4D4D; opacity: 1;"> - {{headerSubtitle}}</span>
        </div>
    </ng-template>
    <div>
        <p-table #dt [value]="workingHourData" dataKey="id" styleClass="p-datatable-employee" [rowHover]="true" [rows]="10"
            [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [paginator]="true" [filterDelay]="0"
            [globalFilterFields]="['employeeName']">
            <ng-template pTemplate="caption">
                <div class="table-header mb-3 pt-2">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input #findAll class="p-inputtext p-component inputsearch" pInputText type="text"
                            (input)="dt.filterGlobal(findAll.value, 'contains')" placeholder="Pesquisar por funcionários" />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="body" let-data let-i>
                <tr class="p-selectable-row" style="margin-top: 1rem;">
                    <td>{{ data.employeeName  }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <p-footer>
        <button class="btn btn-link" (click)="headOfficeHide()">Fechar</button>
    </p-footer>
</p-dialog>

<div style="margin-bottom: 32px; color: #4D4F5C;">
    Jornadas de Trabalho
</div>
<div class="d-flex justify-content-between align-items-center">
    <div style="position: relative;">
        <div>
            <canvas id="MyChart" style="width: 200px;">{{ chart }}</canvas>
        </div>
        <div class="text-doughnut-canva">
            <p class="m-0 text-center">
                <b>{{totalOfWorkingHours}}</b>
            </p>
            <p class="m-0 text-center">Total</p>
        </div>
    </div>
    <div>
        <div class="legend-container" style="width: 175px; margin: 0px 20px;">
            <div class="row mb-3">
                <div class="col-12 p-0 ml-2">
                    Funcionários em:
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-8 p-0">
                    <div class="d-flex justify-content-start align-items-center">
                        <div class="ml-2 mr-2">
                            <img src="assets/icon/ChartIcons/ellipse-50CCC3.svg" alt="">
                        </div>
                        <div>Home Office</div>
                    </div>
                </div>
                <div class="col-4 p-0 text-right" style="color: #4D4F5C; opacity: 0.5;">{{homeOfficeCount}}</div>
            </div>
            <div class="row mb-3">
                <div class="col-8 p-0">
                    <div class="d-flex justify-content-start align-items-center">
                        <div class="ml-2 mr-2">
                            <img src="assets/icon/ChartIcons/ellipse-B850CC.svg" alt="">
                        </div>
                        <div>Jornada Híbrida</div>
                    </div>
                </div>
                <div class="col-4 p-0 text-right" style="color: #4D4F5C; opacity: 0.5;">{{flexibleCount}}</div>
            </div>
            <div class="row mb-3">
                <div class="col-8 p-0">
                    <div class="d-flex justify-content-start align-items-center">
                        <div class="ml-2 mr-2">
                            <img src="assets/icon/ChartIcons/ellipse-A3CC50.svg" alt="">
                        </div>
                        <div>Presencial</div>
                    </div>
                </div>
                <div class="col-4 p-0 text-right" style="color: #4D4F5C; opacity: 0.5;">{{headOfficeCount}}</div>
            </div>
            <div class="row mb-3">
                <div class="col-8 p-0">
                    <div class="d-flex justify-content-start align-items-center">
                        <div class="ml-2 mr-2">
                            <img src="assets/icon/ChartIcons/ellipse-5056CC.svg" alt="">
                        </div>
                        <div>Ponto Livre</div>
                    </div>
                </div>
                <div class="col-4 p-0 text-right" style="color: #4D4F5C; opacity: 0.5;">{{skipAllValidationCount}}</div>
            </div>
        </div>
    </div>
</div>
