import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {RepeatPasswordValidator} from '../app.validators';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    providers: [MessageService]
})
export class ForgotPasswordComponent {

    public requestCodeForm: FormGroup;
    public changePwdForm: FormGroup;
    public showHidePassword = true;
    public showHideConfirmPassword = true;
    public loadingSendCode = false;
    public loadingChangingPwd = false;
    public screen = 0;

    constructor(
        private authService: AuthService,
        private messageService: MessageService,
        private formBuilder: FormBuilder,
        private router: Router
    ) {
        this.requestCodeForm = this.formBuilder.group({
            email: ['', Validators.compose([
                Validators.required,
                Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
            ])]
        });

        this.changePwdForm = this.formBuilder.group({
            code: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(24)]],
            confirmPassword: ['', Validators.required],
        }, {validators: [RepeatPasswordValidator]});
    }

    public async forgotPassword(): Promise<void> {
        try {
            const {email} = this.requestCodeForm.value;
            await this.authService.forgotPassword(email);
            this.screen = 1;
        } catch (error: any) {
            if (error.code === 'UserNotFoundException') {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Usuário não encontrado.',
                    detail: 'Tente novamente'
                });
                return;
            }

            if (error.code === 'NotAuthorizedException') {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Não autorizado.',
                    detail: 'Possivelmente você não alterou sua senha temporária no app Alphatime Ponto'
                });
                return;
            }
        }
    }

    public async forgotPasswordSubmit(): Promise<void> {
        try {
            const {email} = this.requestCodeForm.value;
            const {code, password} = this.changePwdForm.value;
            await this.authService.forgotPasswordSubmit(email, code, password);
        } catch (error: any) {
            if (error.code === 'CodeMismatchException') {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Código de verificação incorreto.',
                    detail: 'Tente novamente'
                });
                return;
            }
        }

        await this.router.navigate(['/login']);
    }

    backToLogin(): void {
        this.router.navigateByUrl('/login');
    }
}
