<p-toast></p-toast>
<div class="card">
    <div class="card-body p-0">
        <div>
            <app-searchbar-employee
                (searchEvent)="findEmployeeByDates($event)"
                (cleanForm)="onCleanForm()">
            </app-searchbar-employee>
        </div>
    </div>
</div>

<div class="card">
    <!-- <div class="card-header">
        <h6>Localização</h6>
    </div> -->
    <div class="card-body p-0">
        <form [formGroup]="mapForm" (ngSubmit)="getFormValues()">
            <div class="form-row">
                <div class="form-group col-lg-12">
                    <label for="name">Localização</label>
                    <input id="city"
                         type="text"
                         class="form-control"
                         formControlName="city"
                         [ngClass]="{
                           'is-invalid': mapForm.get('city')?.invalid && mapForm.get('city')?.touched
                         }">
                    <div class="invalid-feedback" *ngIf="mapForm.get('city')?.hasError('required')
                       && mapForm.get('city')?.touched">Campo localização é requerido
                    </div>
                </div>
                <!-- <p-dropdown
                    placeholder="Ex: Fortaleza, Ceará, Brasil"
                    [options]="cities"
                    optionLabel="nome"
                    optionValue="nome"
                    formControlName="city">
                </p-dropdown> -->
            </div>
            <div class="d-flex justify-content-end btn-search-container">
                <!-- <button type="submit" class="btn btn-link mr-2" [disabled]="!mapForm.valid">Limpar</button> -->
                <button type="button" class="btn btn-sm btn-link mr-3" (click)="onClearLocalization()">
                    Limpar localização
                </button>
                <button type="submit" class="btn btn-primary" [disabled]="!mapForm.valid">Pesquisar</button>
            </div>
        </form>
    </div>
</div>

<div class="loading-box" *ngIf="loading">
    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    Carregando dados...
</div>

<div id='container'>
    <div id='map'></div>
</div>
