<p-dialog header="Funcionários" [(visible)]="display" [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
        <div class="p-dialog-title">
            <span>Funcionários</span>
            <span style="font: normal normal normal 16px/22px Nunito Sans; color: #4D4D4D; opacity: 1;">
                 - {{registerClockHeaderSubtitle}}
            </span>
        </div>
    </ng-template>
    <div>
        <p-table #dt [value]="registerClockData" dataKey="id" styleClass="p-datatable-employee" [rowHover]="true" [rows]="10"
            [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [paginator]="true" [filterDelay]="0"
            [globalFilterFields]="['name']">
            <ng-template pTemplate="caption">
                <div class="table-header mb-3 pt-2">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input #findAll class="p-inputtext p-component inputsearch" pInputText type="text"
                            (input)="dt.filterGlobal(findAll.value, 'contains')" placeholder="Pesquisar por funcionários" />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="body" let-data let-i>
                <tr class="p-selectable-row" style="margin-top: 1rem;">
                    <td>{{ data.name  }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <p-footer>
        <button class="btn btn-link" (click)="registerClockModalHide()">Fechar</button>
    </p-footer>
</p-dialog>

<div class="d-flex justify-content-between align-items-center mb-4">
    <div style="color: #4D4F5C;">
        Ponto <span class="text-muted small">por dia</span>
    </div>
    <div class="col-auto" style="font: normal normal normal 14px/19px Nunito Sans; color: #6D6D6D; opacity: 1;">
        <img class="week-day-icon" src="assets/icon/Calendar/calendar-primary.svg" /> Hoje - {{todayDate | date: 'dd/MM/yy'}}
    </div>
</div>

<div class="d-flex justify-content-between align-items-center" [ngClass]="{'showDayChart': !showDayBarChart}">
    <div>
        <div style="position: relative;">
            <div>
                <canvas id="BarDayChart" style="width: 200px;">{{ dayChart }}</canvas>
            </div>
            <div class="text-doughnut-canva">
                <p class="m-0 text-center">
                    <b>{{totalOfClocks}}</b>
                </p>
                <p class="m-0 text-center">Total</p>
            </div>
        </div>
    </div>
    <div class="w-100">
        <div class="legend-container" style="width: 100%; margin: 0px 20px;">
            <div class="d-flex justify-content-start align-items-center flex-wrap">
                <div class="d-flex justify-content-start align-items-center">
                    <div class="ml-2 mr-2">
                        <img src="assets/icon/ChartIcons/ellipse-7BD261.svg" alt="">
                    </div>
                    <div>Bateu ponto corretamente</div>
                </div>
                <div class="ml-2 mr-2 text-right" style="color: #4D4F5C; opacity: 0.5;">{{correctClock}}</div>
            </div>

            <div class="d-flex justify-content-start align-items-center flex-wrap">
                <div class="d-flex justify-content-start align-items-center">
                    <div class="ml-2 mr-2">
                        <img src="assets/icon/ChartIcons/ellipse-DBDBDB.svg" alt="">
                    </div>
                    <div>Bateu ponto incorretamente</div>
                </div>
                <div class="ml-2 mr-2 text-right" style="color: #4D4F5C; opacity: 0.5;">{{incorrectClock}}</div>
            </div>

            <div class="d-flex justify-content-start align-items-center flex-wrap">
                <div class="d-flex justify-content-start align-items-center">
                    <div class="ml-2 mr-2">
                        <img src="assets/icon/ChartIcons/ellipse-EB6969.svg" alt="">
                    </div>
                    <div>Não bateu o ponto</div>
                </div>
                <div class="ml-2 mr-2 text-right" style="color: #4D4F5C; opacity: 0.5;">{{zeroClock}}</div>
            </div>
        </div>
    </div>
</div>

<div class="d-flex flex-column justify-content-between align-items-center">
    <div style="position: relative;">
        <div [ngClass]="{'showEmptDayBarChart': !showEmptDayBarChart}">
            <div class="d-flex flex-column justify-content-top align-items-center w-100">
                <img src="assets/img/chart/pie-chart-nodata.svg" alt="">
            </div>
        </div>
        <div class="loading-box d-flex justify-content-center align-items-center text-muted p-3" *ngIf="loadingDayChart">
            <span class="spinner-grow spinner-grow-sm m-2" role="status" aria-hidden="true"></span>
            Aguarde carregando os dados...
        </div>
    </div>
</div>
