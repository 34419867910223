<nav class="navbar navbar-expand navbar-light bg-transparent topbar mb-4 static-top">
    <div class="d-sm-flex align-items-center justify-content-between mt-3 ml-5 border-left-primary">
        <h1 class="h3 mb-0 text-gray-800 pl-3">{{pageTitle}}</h1>
        <a
            *ngIf="showEmployeeBtn"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="['/employee', 'list']"
            class="btn-employee-header btn btn-link ml-5">
            <img class="header-btn-icon" src="./assets/icon/Table/table.svg"/>
        </a>
        <a
            *ngIf="showEmployeeBtn"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="['/employee', 'grid']"
            class="btn-employee-header btn btn-link ml-2">
            <img class="header-btn-icon" src="./assets/icon/Grid/grid.svg"/>
        </a>
    </div>
    <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown no-arrow mx-1 pb-3" *ngIf="showCounter">
              <span
                  [ngClass]="{
                    'badge-blue': this.counterDays > 7,
                    'badge-orange': this.counterDays > 2 && this.counterDays <= 7 ,
                    'badge-red': this.counterDays < 2
                  }"
                  class="badge-free-plan-counter badge" *ngIf="isAccountHolder && isFreePlan">
                  {{this.counterDays}} dias restantes
              </span>
        </li>
        <li class="nav-item dropdown no-arrow mx-1">
            <a class="nav-link nav-link-header dropdown-toggle" href="#" id="alertsDropdown" role="button"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img src="assets/icon/Bell/bell.svg" class="header-alert-icon"/>
                <span class="badge-primary badge-counter">{{notifications.length}}</span>
            </a>
            <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                 aria-labelledby="alertsDropdown">
                <div class="notification-list">
                    <a class="dropdown-item d-flex align-items-center" *ngFor="let notification of notifications">
                        <div class="mr-3">
                            <div class="icon-circle bg-icon"
                                 *ngIf="notification.type === 'START_EMPLOYEE_CSV_IMPORT'">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#5055CB" class="w-6 h-6">
                                    <path stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                </svg>
                            </div>
                            <div class="icon-circle bg-icon"
                                 *ngIf="notification.type === 'ERROR_EMPLOYEE_CSV_IMPORT'">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#D62828" class="w-6 h-6">
                                    <path stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                </svg>
                            </div>
                            <div class="icon-circle bg-icon"
                                 *ngIf="notification.type === 'JUSTIFICATION_CREATED'">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#86112E" class="w-6 h-6">
                                    <path stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                </svg>
                            </div>
                        </div>
                        <div>
                            <span class="small item-text">{{notification.message}}</span>
                            <div class="small text-gray-500">{{ timeSince(notification.createdAt) }}</div>
                        </div>
                    </a>
                </div>
                <a *ngIf="notifications.length > 0" class="dropdown-item text-center small text-gray-500" (click)="clearNotifications()">Limpar</a>
            </div>
        </li>
        <div class="topbar-divider d-none d-sm-block"></div>
        <li class="nav-item dropdown no-arrow">
            <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                 aria-labelledby="userDropdown">
                <a class="dropdown-item d-flex" routerLink="/profile">
                    <!-- <i class="bi bi-person-circle mr-2 text-gray-400"></i> -->
                    <img class="ptofile-icon" src="assets/icon/UserCircle/user-circle.svg" />
                    Perfil
                </a>
                 <div class="dropdown-divider"></div>
                <a class="dropdown-item d-flex cursor" *ngIf="isAccountHolder && isFreePlan" routerLink="/signature">
                    <img class="credit-card-icon" src="assets/icon/CreditCard/credit-card.svg" />
                    Assinar Plano
                </a>
                <a class="dropdown-item d-flex cursor" *ngIf="isAccountHolder && !isFreePlan" (click)="openCustomerPortal()">
                    <img class="credit-card-icon" src="assets/icon/CreditCard/credit-card.svg" />
                    Portal do Cliente
                </a>
                <!-- <a class="dropdown-item" routerLink="/change-password">
                    <i class="bi bi-key-fill mr-2 text-gray-400"></i>
                    Segurança
                </a> -->
                <div class="dropdown-divider"></div>
                <a class="dropdown-item d-flex" (click)="signOut()" data-toggle="modal" data-target="#logoutModal">
                    <!-- <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i> -->
                    <img class="logout-icon" src="assets/icon/Logout/logout.svg" />
                    Sair
                </a>
            </div>
            <a class="nav-link nav-link-header dropdown-toggle" href="#" id="userDropdown" role="button"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{employee?.name}}</span>
                <img class="img-profile rounded-circle"
                     [src]="profilePicture ? profilePicture : picture"
                     onerror="this.src='../../assets/icon/Avatar/user.png'"/>
            </a>
        </li>
    </ul>
</nav>
