import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {PrimeNGConfig} from 'primeng/api';
import {Company} from 'src/model/company';
import Ably, {AblyConnectionState} from '../utils/ably';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    public company: Company[] = [];

    constructor(
        private config: PrimeNGConfig,
        private translateService: TranslateService) {
    }

    public ngOnInit(): void {
        this.translateService.setDefaultLang('pt_br');
        this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
        // Ably.connection.on((connectionState: AblyConnectionState) => {
        //     // this.connectionState = connectionState.current;
        //     console.log(connectionState);
        // });
  }
}
