import { Company } from './company';
import {Employee} from './employee';
import {Employer} from './employer';
import * as moment from 'moment';

export default class HttpContractResponse {
    success: boolean | undefined;
    message: string | undefined;
    data: Contract | undefined;
}

export class HttpContractsResponse {
    success: boolean | undefined;
    message: string | undefined;
    data!: Contract[] | [];
}

export class InviteEmployee {
    phoneNumber: string | undefined;
    platform: string | undefined;
}

export class ContractPeriod {
    id: string | undefined;
    departmentId: string | undefined;
    employer: Employer | undefined;
    company: Company | undefined;
    employee: Employee | undefined;
    homeOffice: boolean | undefined;
    flexibleJourney: boolean | undefined;
    branchAddress: boolean | undefined;
    headOffice: boolean | undefined;
    latitude: number | undefined;
    longitude: number | undefined;
    journey: string | undefined;
    startTimeFirstPeriod: moment.Moment | undefined;
    endTimeFirstPeriod: moment.Moment | undefined;
    startTimeSecondPeriod: moment.Moment | undefined;
    endTimeSecondPeriod: moment.Moment | undefined;
}


export class Contract {
    id: string | undefined;
    employer: Employer | undefined;
    employee: Employee | undefined;
    company: Company | undefined;
    employeeId: string | undefined; // Matrix
    companyId: string | undefined; // Matrix
    departmentId?: string | undefined;
    companyPositionId: string | undefined;
    path: string | undefined; // Matrix
    role: string | undefined;
    enabled: boolean | undefined;
    amountOfHours: number | undefined;
    journey: string | undefined;
    journeyType: string | undefined;
    enabledSunday: boolean | undefined;
    enabledMonday: boolean | undefined;
    enabledTuesday: boolean | undefined;
    enabledWednesday: boolean | undefined;
    enabledThursday: boolean | undefined;
    enabledFriday: boolean | undefined;
    enabledSaturday: boolean | undefined;
    homeOffice: boolean | undefined;
    flexibleJourney: boolean | undefined;
    branchAddress: boolean | undefined;
    headOffice: boolean | undefined;
    skipAllValidation: boolean | undefined;
    startTimeFirstPeriod: string | undefined;
    endTimeFirstPeriod: string | undefined;
    startTimeSecondPeriod: string | undefined;
    endTimeSecondPeriod: string | undefined;
    invite?: InviteEmployee | undefined;
    zipcode?: string | undefined;
    address: string | undefined;
    street: string | undefined;
    addressNumber?: string | undefined;
    complement?: string | undefined;
    district?: string | undefined;
    city: string | undefined;
    state: string | undefined;
    status: string | undefined;
    country: string | undefined;
    latitude: number | undefined;
    longitude: number | undefined;
    agreement: boolean | undefined;
    acceptedAt: string | undefined;
    createdAt: string | undefined;
    updatedAt: string | undefined;
}
