import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { Table } from 'primeng/table';

import { Contract } from 'src/model/contract';
import { EmployeeService } from 'src/services/employee.service';

@Component({
    selector: 'app-working-hours-chart',
    templateUrl: './working-hours-chart.component.html',
    styleUrls: ['./working-hours-chart.component.scss'],
})
export class WorkingHoursChartComponent implements OnInit {

    @ViewChild('dt') table?: Table;

    @Input()
    public contracts: any[] = [];

    public chart: any;
    public chartOptions: any;
    public workingHourData: any[] = [];
    public homeOfficeCount = 0;
    public headOfficeCount = 0;
    public flexibleCount = 0;
    public skipAllValidationCount = 0;
    public totalOfWorkingHours = 0;
    public display = false;
    public headerSubtitle = '';

    constructor(
        private employeeService: EmployeeService,
    ) {}

    async ngOnInit(): Promise<void> {
        await this.getWorkingHoursCount();
        this.createWorkingHoursChart();
    }

    async getWorkingHoursCount() {
        const homeCount = this.contracts.filter((contract: Contract) => {
            return contract.homeOffice === true;
        }).length;

        const flexibleCount = this.contracts.filter((contract: Contract) => {
            return contract.flexibleJourney === true;
        }).length;

        const headOffice = this.contracts.filter((contract: Contract) => {
            return contract.headOffice === true;
        }).length;

        const skipAllValidation = this.contracts.filter((contract: Contract) => {
            return contract.skipAllValidation === true;
        }).length;

        this.totalOfWorkingHours = homeCount + flexibleCount + headOffice + skipAllValidation;

        this.homeOfficeCount = homeCount || 0;
        this.flexibleCount = flexibleCount || 0;
        this.headOfficeCount = headOffice || 0;
        this.skipAllValidationCount = skipAllValidation || 0;
    }

    public async createWorkingHoursChart() {
        this.chart = new Chart("MyChart", {
            type: 'doughnut',
            data: {
                // labels: [
                //     `Home Office - ${this.homeOfficeCount}`,
                //     `Jornada Híbrida - ${this.flexibleCount}`,
                //     `Presencial - ${this.headOfficeCount}`,
                //     `Ponto Livre - ${this.skipAllValidationCount}`,
                // ],
                datasets: [
                    {
                        label: 'Funcionários',
                        data: [
                            +this.homeOfficeCount,
                            +this.flexibleCount,
                            +this.headOfficeCount,
                            +this.skipAllValidationCount,
                        ],
                        backgroundColor: [
                            '#50CCC3',
                            '#B850CC',
                            '#A3CC50',
                            '#5056CC',
                        ],
                        hoverBackgroundColor: [
                            '#50CCC3',
                            '#B850CC',
                            '#84A346',
                            '#5056CC',
                        ],
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                cutout: 55,
                layout: {
                    autoPadding: true,
                },
                plugins: {
                    title: {
                        display: false,
                        text: 'Jornadas de Trabalho',
                        font: {
                            size: 16,
                        },
                        align: 'center',
                    },
                    legend: {
                        display: false,
                        // title: {
                        //     display: true,
                        //     text: 'Funcionários em:',
                        //     color: '#43425D',
                        //     font: {
                        //         size: 14,
                        //         style: 'normal',
                        //     },
                        // },
                        // position: 'right',
                        // labels: {
                        //     usePointStyle: true,
                        //     pointStyle: 'circle',
                        // },
                    },
                },
                onClick: (event, elements) => {
                    if (elements  && elements.length > 0) {
                        this.employeesOnheadOffice(elements[0]);
                    }
                }
            },
        });
    }

    public headOfficeHide() {
        this.display = false;
    }

    private employeesOnheadOffice(element: any) {
        if (element.index === 0) {
            const homeOfficeConstracts: any[] = [];
            this.headerSubtitle = 'Home Office'
            this.display = true;
            this.contracts.filter((contract: Contract) => {
                if (contract.homeOffice === true) homeOfficeConstracts.push({
                    id: contract.id,
                    employeeId: contract.employeeId,
                    employeeName: contract.employee?.name,
                })
            });
            this.workingHourData = homeOfficeConstracts.sort((a, b) => a.employeeName.localeCompare(b.employeeName));
        }

        if (element.index === 1) {
            const flexibleJourneyConstracts: any[] = [];
            this.headerSubtitle = 'Jornada Híbrida'
            this.display = true;
            this.contracts.filter((contract: Contract) => {
                if (contract.flexibleJourney === true) flexibleJourneyConstracts.push({
                    id: contract.id,
                    employeeId: contract.employeeId,
                    employeeName: contract.employee?.name,
                })
            });
            this.workingHourData = flexibleJourneyConstracts.sort((a, b) => a.employeeName.localeCompare(b.employeeName));
        }

        if (element.index === 2) {
            const headOfficeConstracts: any[] = [];
            this.headerSubtitle = 'Padrão'
            this.display = true;
            this.contracts.filter((contract: Contract) => {
                if (contract.headOffice === true) headOfficeConstracts.push({
                    id: contract.id,
                    employeeId: contract.employeeId,
                    employeeName: contract.employee?.name,
                })
            });
            this.workingHourData = headOfficeConstracts.sort((a, b) => a.employeeName.localeCompare(b.employeeName));
        }

        if (element.index === 3) {
            const skipAllValidationConstracts: any[] = [];
            this.headerSubtitle = 'Ponto Livre'
            this.display = true;
            this.contracts.filter((contract: Contract) => {
                if (contract.skipAllValidation === true) skipAllValidationConstracts.push({
                    id: contract.id,
                    employeeId: contract.employeeId,
                    employeeName: contract.employee?.name,
                })
                return contract.skipAllValidation === true;
            });
            this.workingHourData = skipAllValidationConstracts.sort((a, b) => a.employeeName.localeCompare(b.employeeName));
        }
    }


}
