import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { forkJoin, map } from 'rxjs';
import { Absence, HttpAbsencesResponse } from 'src/model/absence';
import { Employee, HttpEmployeesResponse } from 'src/model/employee';
import { AbsenceService } from 'src/services/absence.service';
import { AppStorageService } from 'src/services/app-storage.service';
import { EmployeeService } from 'src/services/employee.service';
import { ValidateDatesService } from 'src/services/validate-dates.service';
import TranslateAbsenceTypeHelper from '../shared/translate-absence-type-helper';

@Component({
    selector: 'app-employee-leave-table',
    templateUrl: './employee-leave-table.component.html',
    styleUrls: ['./employee-leave-table.component.scss'],
    providers: [MessageService, ConfirmationService],
})
export class EmployeeLeaveTableComponent implements OnInit, OnDestroy {
    @ViewChild('dt') table?: Table;

    public absenceResponse: any;

    public filterForm: FormGroup;

    public loading = false;

    public absences: any[] = [];
    public absenceTypes = [];

    constructor(
        private appStorageService: AppStorageService,
        private absenceService: AbsenceService,
        private confirmationService: ConfirmationService,
        private employeeService: EmployeeService,
        private formBuilder: FormBuilder,
        private messageService: MessageService,
        private validateDatesService: ValidateDatesService,
    ) {
        this.filterForm = this.formBuilder.group({
            name: [''],
            type: [''],
            startDate: ['', Validators.required],
            endDate: ['', Validators.required],
        });
    }

    async ngOnInit(): Promise<void> {
        const getAbsenceTypes: any = await this.absenceService.getTypes().toPromise();
        this.absenceTypes = getAbsenceTypes.data.filter((type: any) => type !== 'VACATION');
        await this.fetchData().then(r => null);
    }

    async fetchData(): Promise<void> {
        this.loading = true;
        const employee: Employee = await this.appStorageService.getEmployee();
        const startOfMonth = moment().clone().startOf('month').subtract(2,'months').toISOString();
        const endOfMonth   = moment().clone().endOf('month').add(2,'months').toISOString();

        await forkJoin(
            this.absenceService.getAbsenceByCompany(employee.companyId!, startOfMonth, endOfMonth, 'ALL'),
            this.employeeService.findAllByCompany(employee.companyId!)).pipe(
                map((response: any) => {
                    const httpAbsencesResponse: HttpAbsencesResponse = response[0];
                    const httpEmployeeResponse: HttpEmployeesResponse = response[1];

                    httpAbsencesResponse.data = httpAbsencesResponse.data.filter((absence) => absence.type !== 'VACATION');

                    httpAbsencesResponse.data.forEach((absence) => {
                        absence.employee = httpEmployeeResponse.data.find((el) => el.id === absence.employeeId);
                    });

                    this.absenceResponse = httpAbsencesResponse
                })
            ).toPromise();

            const response = this.absenceResponse;

            if (Object.keys(response.data).length !== 0) {
                const absenceArray: any[] = [];
                response.data.map((absence: any) => {
                  const today = moment();
                  const startDate = moment(absence.startDate).subtract(1,'d')
                  const endDate = moment(absence.endDate).add(1,'d')
                //   if(today.isBetween(startDate, endDate)) {
                //       if (absence.status === 'ACTIVE') {
                //           this.absences.push({
                //             id: absence.id,
                //             startDate: moment(absence.startDate).format('DD/MM/YYYY'),
                //             endDate: moment(absence.endDate).format('DD/MM/YYYY'),
                //             employee: absence.employee,
                //             type: absence.type,
                //             employeeOnVacation: this.absenceService.checkOnVacation(absence.startDate, absence.endDate) === true ? true : false
                //           })
                //       }
                //   }

                  if(today.isBetween(startDate, endDate)) {
                      if (absence.status === 'ACTIVE') {
                        const translatedAbsence = this.translateAbsenceType([{
                            id: absence?.id,
                            startDate: absence?.startDate,
                            endDate: absence?.endDate,
                            employee: absence?.employee,
                            type: absence.type,
                            employeeOnVacation: this.absenceService.checkOnVacation(absence?.startDate, absence?.endDate) === true ? true : false
                        }]);
                        absenceArray.push(...translatedAbsence);
                      }
                  }
                });

                this.absences = absenceArray;
            }
            this.loading = false;
    }

    async searchEmployeeLeave(values: any) {
        this.absences = [];
        this.loading = true;
        const {employeeId, startDate, endDate} = values;

        const convertStartDataToSearch = moment(startDate, 'DD/MM/YYYY').startOf('hour').format(
            'YYYY-MM-DD 00:00:00'
        );
        const convertEtartDataToSearch = moment(endDate, 'DD/MM/YYYY').add(1, 'day').format(
            'YYYY-MM-DD 23:59:59'
        );

        const isDateValid = this.validateDatesService.validatePeriods(convertStartDataToSearch, convertEtartDataToSearch);

        if (isDateValid) {
            if (startDate && endDate && employeeId) {
                await forkJoin(
                    this.absenceService.getAbsenceByEmployees(employeeId, convertStartDataToSearch, convertEtartDataToSearch, 'ALL'),
                    this.employeeService.getOne(employeeId)).pipe(
                        map((response: any) => {
                            const httpAbsencesResponse: HttpAbsencesResponse = response[0];
                            const httpEmployeeResponse: any = response[1];

                            httpAbsencesResponse.data = httpAbsencesResponse.data.filter((absence) => absence.type !== 'VACATION');

                            httpAbsencesResponse.data.map((absence) => {
                                absence.employee = httpEmployeeResponse.data
                            });

                            this.absenceResponse = httpAbsencesResponse
                        })
                    ).toPromise();

              const response = this.absenceResponse;

              if (Object.keys(response.data).length > 0) {
                  const absenceArray: any[] = [];

                  if (response.data[0].status === 'ACTIVE') {
                    const translatedAbsence = this.translateAbsenceType([{
                        id: response.data[0]?.id,
                        startDate: response.data[0]?.startDate,
                        endDate: response.data[0]?.endDate,
                        employee: response.data[0]?.employee,
                        type: response.data[0].type,
                        employeeOnVacation: this.absenceService.checkOnVacation(response.data[0]?.startDate, response.data[0]?.endDate) === true ? true : false
                    }]);
                    absenceArray.push(...translatedAbsence);
                  }

                  this.loading = false;
                  this.absences = absenceArray;
              } else {
                  this.loading = false;
                  this.messageService.add({
                    severity:'warn',
                    summary:'Nenhum resultado para o intervalo selecionado!',
                });
              }
              } else {
                  this.loading = true;
                  this.absences = [];
                  const employee: Employee = await this.appStorageService.getEmployee();

                  await forkJoin(
                    this.absenceService.getAbsenceByCompany(employee.companyId!, convertStartDataToSearch, convertEtartDataToSearch, 'ALL'),
                      this.employeeService.findAllByCompany(employee.companyId!)).pipe(
                          map((response: any) => {
                            const httpAbsencesResponse: HttpAbsencesResponse = response[0];
                            const httpEmployeeResponse: HttpEmployeesResponse = response[1];

                            httpAbsencesResponse.data = httpAbsencesResponse.data.filter((absence) => absence.type !== 'VACATION');

                            httpAbsencesResponse.data.forEach((absence) => {
                                absence.employee = httpEmployeeResponse.data.find((el) => el.id === absence.employeeId);
                            });

                            this.absenceResponse = httpAbsencesResponse
                          })
                  ).toPromise();

                  const response = this.absenceResponse;

                  if (Object.keys(response.data).length > 0) {
                    const absenceArray: any[] = [];
                    response.data.map((absence: any) => {
                      if (absence.status === 'ACTIVE') {
                        const translatedAbsence = this.translateAbsenceType([{
                            id: absence?.id,
                            startDate: absence?.startDate,
                            endDate: absence?.endDate,
                            employee: absence?.employee,
                            type: absence.type,
                            employeeOnVacation: this.absenceService.checkOnVacation(absence?.startDate, absence?.endDate) === true ? true : false
                        }]);
                        absenceArray.push(...translatedAbsence);
                      }
                    })
                    this.loading = false;
                    this.absences = absenceArray;
                  } else {
                    this.loading = false;
                    this.messageService.add({
                      severity:'warn',
                      summary:'Nenhum resultado para o intervalo selecionado!',
                  });
              }
            }
          } else {
            this.loading = false;
            this.messageService.add({
              severity:'warn',
              summary:'Períodos Incorretos!',
              detail: 'Verifique se os intervalos dos períodos estão corretos'
            });
        }
    }

    translateAbsenceType(absenceArray: any) {
        return absenceArray.map((absence: any) => {
          let translatedType = TranslateAbsenceTypeHelper.translateAbsenceType(absence.type);

          return {
            id: absence?.id,
            startDate: moment(absence?.startDate).format('DD/MM/YYYY'),
            endDate: moment(absence?.endDate).format('DD/MM/YYYY'),
            employee: absence?.employee,
            type: translatedType,
            employeeOnVacation: this.absenceService.checkOnVacation(absence?.startDate, absence?.endDate) === true ? true : false
          };
        });
    }

    loadFormUpdate(absence: Absence) {
        this.filterForm.patchValue({
            type: absence.type,
            startDate: absence.startDate,
            endDate: absence.endDate,
        })
    }

    reloadTable() {}

    async onUpdate(absence: Absence): Promise<void> {
        const updateStartDateField = this.filterForm.get('startDate');
        const updateEndDateField = this.filterForm.get('endDate');

        const convertUpdateStartDataToSave = moment(updateStartDateField?.value, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const convertUpdateEndDataToSave = moment(updateEndDateField?.value, 'DD/MM/YYYY').format('YYYY-MM-DD');

        if (EmployeeLeaveTableComponent.validateUpdatePeriods(convertUpdateStartDataToSave, convertUpdateEndDataToSave)) {
            updateStartDateField?.setValue(updateStartDateField.value);
            updateEndDateField?.setValue(updateEndDateField.value);

            if (EmployeeLeaveTableComponent.validateFutureDay(convertUpdateStartDataToSave)) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Período Incorreto!',
                    detail: 'A data de início deve ser uma data futura.'
                });
            } else if (EmployeeLeaveTableComponent.validatePastDay(convertUpdateStartDataToSave)) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Período Incorreto!',
                    detail: 'A data de início deve ser uma data futura.'
                });
            } else {
                const updateAbsence: any = {};
                updateAbsence.type = this.filterForm.get('type')?.value;
                updateAbsence.startDate = moment(convertUpdateStartDataToSave).toISOString();
                updateAbsence.endDate = moment(convertUpdateEndDataToSave).toISOString();
                const data = Object.assign(updateAbsence);

                console.log(':::: LOG 01 :::: ', data);
                return;

                this.confirmationService.confirm({
                    message: `Confirmar reprogramação de afastamento para o o período de ${moment(data.startDate).format('DD/MM/YYYY')} à ${moment(data.endDate).format('DD/MM/YYYY')} ?`,
                    header: 'Reprogramar Férias',
                    accept: () => {
                        this.absenceService.updateAbsence(absence.id as string, data).subscribe((response: any) => {
                            // loading here
                            if (response.success) {
                                this.messageService.add({
                                    life: 5000,
                                    severity: 'success',
                                    summary: 'Afastamento Reprogramado!',
                                    detail: 'O colaborador receberá um aviso por e-mail e não poderá bater o ponto nos dias programados de afastamento.'
                                });
                                this.onCleanForm();
                            } else {
                                this.messageService.add({
                                    severity: 'error',
                                    summary: 'Error!',
                                    detail: response.mensagem
                                });
                            }
                        });
                    },
                    reject: () => {
                        this.messageService.add({
                            severity: 'warn',
                            summary: 'Não confirmado',
                            detail: 'Afastamento não confirmado!'
                        });
                        this.filterForm.reset();
                        this.onCleanForm();
                    }
                });
            }

        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Período Incorreto!',
                detail: 'Verifique se os intervalos de datas estão corretos.'
            });
        }
    }

    async confirmDelete(absence: Absence): Promise<void> {
        // open loading
        const deleteStartDateField = this.filterForm.get('startDateInput');
        const deleteEndDateField = this.filterForm.get('endDateInput');

        const convertDeleteStartDataToSave = moment(deleteStartDateField?.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
        const convertDeleteEndDataToSave = moment(deleteEndDateField?.value, 'DD/MM/YYYY').format('DD/MM/YYYY');

        this.confirmationService.confirm({
            message: `Deseja realmente excluir esse Afastamento já programadas? ${convertDeleteStartDataToSave} à ${convertDeleteEndDataToSave} ?`,
            header: 'Excluir Afastamento',
            accept: () => {
                this.absenceService.deleteAbsence(absence.id!).subscribe((response: any) => {
                    // close loading
                    if (response.success) {
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Afastamento Cancelada!',
                        });

                        this.onCleanForm();
                    } else {
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error!',
                            detail: response.mensagem,
                        });
                    }
                });
            },
            reject: () => {
                this.messageService.add({
                    severity: 'warn',
                    summary: 'Não confirmado',
                    detail: 'Afastamento não confirmado!'
                });
            }
        });
    }

    onCleanForm() {
        this.ngOnDestroy();
    }

    ngOnDestroy() {
        this.absences = [];
        this.ngOnInit();
    }

    private static validateUpdatePeriods(
        updateStartVacationDate: any,
        updateEndVacationDate: any): boolean {

        const updateStartDate = moment(updateStartVacationDate);
        const updateEndDate = moment(updateEndVacationDate);

        return (updateStartDate.isBefore(updateEndDate));
    }

    private static validateFutureDay(
        startVacationDate: any): boolean {

        const startDate = moment(startVacationDate);
        const isToday = moment(startDate).isSame(moment(), 'day');

        if (isToday) {
            return true;
        }

        return false;
    }

    private static validatePastDay(
        startVacationDate: any): boolean {

        const startDate = moment(startVacationDate);
        const isPast = moment(startDate).isBefore(moment(), 'day');

        if (isPast) {
            return true;
        }

        return false;
    }
}
