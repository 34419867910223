import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DepartmentCreateComponent } from 'src/app/department-create/department-create.component';
import { Company } from 'src/model/company';
import { Department } from 'src/model/department';
import { DepartmentService } from 'src/services/department.service';
import { EventEmitterService } from 'src/services/event-emitter.service';

@Component({
  selector: 'app-department-table',
  templateUrl: './department-table.component.html',
  styleUrls: ['./department-table.component.scss'],
  providers: [ConfirmationService, DialogService]
})
export class DepartmentTableComponent implements OnInit {

  @Input() company: Company | undefined;
  @Input() companyId: string = '';
  @Output() onSave = new EventEmitter<boolean>();

  public departments: Department[] = [];
  public formGroup: FormGroup;
  public errors: string = '';
  public ref: DynamicDialogRef = new DynamicDialogRef();
  public loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private departmentService: DepartmentService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
  ) {
    this.formGroup = this.formBuilder.group({
        name: ['', [Validators.required, Validators.maxLength(120)]],
    });
  }

  async ngOnInit(): Promise<void> {
    await this.initializePage();
  }

  async initializePage() {
    await this.list();
  }

  onCreate() {
    this.ref = this.dialogService.open(DepartmentCreateComponent, {
      header: 'Cadastrar Departamento',
      width: '50%',
      contentStyle: {"max-height": "400px", "overflow": "visible"},
      baseZIndex: 10000,
      dismissableMask: true,
      data: {values: this.company}
    });

    EventEmitterService.get(EventEmitterService.DEPARTMENT_EVENT_SAVE).subscribe((value: any) => {
        this.onSave.emit(value);
        this.reloadTable();
    });

    this.ref.onClose.subscribe((message) => {
      this.reloadTable();
    });
  }

  public async onUpdate(department: Department) {
    try {
        const departmentParam: Department = department;
        if(department.id) {
            await this.departmentService.update(departmentParam, department.id).toPromise();
        }
        await this.reloadTable();
    } catch (error: any) {
        this.errors = error;
    }
  }

  public async list() {
    this.departments = [];
    this.loading = true;
    try {
        this.departmentService.list(
            this.company?.id!
        ).subscribe( department => {
            const result: Department[] = [];
            department?.data?.map((department: Department) => {
                if (department.status === 'ACTIVE') {
                    result.push(department);
                }
            });

            this.departments = result;
            this.loading = false;
        });
    } catch (error: any) {
        this.errors = error;
    }
  }

  public confirmDelete(companyPositionId: string): void {
    this.confirmationService.confirm({
        message: `Você tem certeza que deseja deletar esse departamento?`,
        header: 'Deletar departamento',
        accept: () => {
            this.onDelete(companyPositionId)
        },
        reject: () => {
            //
        }
    });
  }

  async onDelete(departmentId: string) {
    this.departments = [];
    try {
      await this.departmentService.delete(departmentId).toPromise();
      this.onSave.emit(false);
      await this.reloadTable();
    } catch (error) {
      await this.reloadTable();
    }
  }

  async reloadTable() {
    await this.initializePage();
  }

}
