<!-- <p-toast></p-toast> -->
<div class="mb-3">
    <div class="tabview-body-title d-flex justify-content-between align-items-center" >

    </div>

  <form [formGroup]="formRegister">
    <div class="w-100">
        <div class="mb-3 body-form">
            <div class="form-row" style="display: flex; flex-wrap: initial;">
                <div class="form-group col-lg-8">
                    <label for="city">Nome da empresa*</label>
                    <input id="city" type="text" class="form-control"
                        formControlName="name"
                        [ngClass]="{
                            'is-invalid': formRegister.get('name')?.hasError('required') && formRegister.get('name')?.touched,
                            'maxlength-error': formRegister.get('name')?.hasError('maxlength') && formRegister.get('name')?.touched
                        }">
                    <div class="invalid-feedback" *ngIf="formRegister.get('name')?.hasError('required')
                        && formRegister.get('name')?.touched">Nome é requerido
                    </div>
                    <div class="ng-invalid text-danger text-validation" *ngIf="formRegister.get('name')?.hasError('maxlength')">
                        Nome deve ter no máximo 80 caracteres
                    </div>
                </div>
                <div class="form-group col-lg-4">
                    <label for="status">Status</label>
                    <select id="status"
                      [disabled]=isEditing
                      class="form-control"
                      formControlName="status">
                    <option value="">Selecione um status</option>
                    <option placeholder="Selecione um status"
                        *ngFor="let key of statusTypes" [value]="key.value">{{ key.key }}
                     </option>
                    </select>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-lg-6">
                    <label for="cnpj">CNPJ*</label>
                    <p-inputMask id="cnpj"
                           styleClass="form-control"
                           mask="99.999.999/9999-99"
                           formControlName="cnpj"
                           [ngClass]="{
                            'ng-invalid': formRegister.get('cnpj')?.hasError('CNPJNotValid') && formRegister.get('cnpj')?.touched,
                            'ng-dirty': formRegister.get('cnpj')?.hasError('CNPJNotValid') && formRegister.get('cnpj')?.touched
                           }">
                    </p-inputMask>
                    <div class="ng-invalid text-danger text-validation" *ngIf="formRegister.hasError('CNPJNotValid')
                        && formRegister.controls['cnpj'].touched">CNPJ não é válido
                    </div>
                    <div class="ng-invalid text-danger text-validation" *ngIf="formRegister.get('cnpj')?.hasError('required')
                        && formRegister.get('cnpj')?.touched">CNPJ é requerido
                    </div>
                </div>
                
                <div class="form-group col-lg-6">
                    <label for="cnpj">CEI</label>
                    <p-inputMask
                        id="cei"
                        placeholder="Digite aqui"
                        styleClass="form-control"
                        mask="99.999.99999/99"
                        formControlName="cei"
                    >
                    </p-inputMask>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-lg-6">
                    <label for="zipcode">CEP*</label>
                    <p-inputMask
                        id="zipcode"
                        placeholder="Digite aqui"
                        styleClass="form-control"
                        mask="99999999"
                        formControlName="zipcode"
                        (onBlur)="findZipcode()"
                        [ngClass]="{
                            'ng-invalid': formRegister.get('zipcode')?.hasError('required') && formRegister.get('zipcode')?.touched,
                            'ng-dirty': formRegister.get('zipcode')?.hasError('required') && formRegister.get('zipcode')?.touched
                           }">
                    </p-inputMask>
                    <div class="ng-invalid text-danger text-validation" *ngIf="formRegister.get('zipcode')?.hasError('required')
                        && formRegister.get('zipcode')?.touched">Campo CEP é requerido
                    </div>
                </div>
                <div class="form-group col-lg-6">
                    <label for="street">Endereço*</label>
                    <input id="street" type="text" class="form-control"
                        formControlName="street"
                        [ngClass]="{
                        'is-invalid': formRegister.get('street')?.hasError('required') && formRegister.get('street')?.touched}">
                    <div class="invalid-feedback" *ngIf="formRegister.get('street')?.hasError('required')
                        && formRegister.get('street')?.touched">Campo Rua/Avenida é requerido
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-lg-6">
                    <label for="streetNumber">Número*</label>
                    <input id="streetNumber" type="text" class="form-control"
                        formControlName="streetNumber"
                        [ngClass]="{
                            'is-invalid': formRegister.get('streetNumber')?.hasError('required') && formRegister.get('streetNumber')?.touched
                        }">
                    <div class="invalid-feedback" *ngIf="formRegister.get('number')?.hasError('required')
                        && formRegister.get('number')?.touched">Campo Número é requerido
                    </div>
                </div>
                <div class="form-group col-lg-6">
                    <label for="phoneNumber">Fone*</label>
                    <p-inputMask
                        id="phoneNumber"
                        styleClass="form-control"
                        mask="(99) 9 9999-9999"
                        formControlName="phoneNumber"
                        [ngClass]="{
                            'ng-invalid': formRegister.get('phoneNumber')?.hasError('required') && formRegister.get('phoneNumber')?.touched,
                            'ng-dirty': formRegister.get('phoneNumber')?.hasError('required') && formRegister.get('phoneNumber')?.touched
                        }">
                    </p-inputMask>
                    <div class="ng-invalid text-danger text-validation" *ngIf="formRegister.get('phoneNumber')?.hasError('required')
                        && formRegister.get('phoneNumber')?.touched">Campo Fone é requerido
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-lg-6">
                    <label for="city">Cidade*</label>
                    <input id="city" type="text" class="form-control"
                        formControlName="city"
                        [ngClass]="{
                            'is-invalid': formRegister.get('city')?.hasError('required') && formRegister.get('city')?.touched
                        }">
                    <div class="invalid-feedback" *ngIf="formRegister.get('city')?.hasError('required')
                        && formRegister.get('city')?.touched">Campo Cidade é requerido
                    </div>
                </div>

                <div class="form-group col-lg-6">
                    <label for="state">Estado*</label>
                    <input id="state" type="text" class="form-control"
                        formControlName="state"
                        [ngClass]="{
                            'is-invalid': formRegister.get('state')?.hasError('required') && formRegister.get('state')?.touched
                        }">
                    <div class="invalid-feedback" *ngIf="formRegister.get('state')?.hasError('required')
                        && formRegister.get('state')?.touched">Campo Estado é requerido
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="button-save-container d-flex justify-content-center flex-column">
        <div class="d-flex justify-content-center mb-3">
            <button type="submit" (click)="onSave()" [disabled]="formRegister.invalid"
                    class="btn btn-primary btn-block">
                    Salvar
            </button>
        </div>

        <div class="d-flex justify-content-center btn-cncel">
            <button type="button" (click)="onCancel()"
                    class="btn btn-outline-secondary btn-block">
                Cancelar
            </button>
        </div>
    </div>
  </form>

</div>
