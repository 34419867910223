import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {SERVER_URL} from '../environments/environment';
import HttpCompanyResponse, {Company, HttpCompanyPlanStatusResponse} from 'src/model/company';
import {Observable} from 'rxjs';
import {EventEmitterService} from './event-emitter.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    company: Company[] = [];

    constructor(
        private httpClient: HttpClient) {
    }

    /**
     *  Save Vacation
     *
     */
    public save(data: Company): Observable<HttpCompanyResponse> {
        return this.httpClient.post<HttpCompanyResponse>(`${SERVER_URL}/companies`, data);
    }

    /**
     *  Save Branch
     *
     */
     public saveBranch(data: Company): Observable<HttpCompanyResponse> {
        return this.httpClient.post<HttpCompanyResponse>(`${SERVER_URL}/companies/branch`, data);
    }

    /**
     * Update Company
     */
    public update(company: Company, id: string): Observable<HttpCompanyResponse> {
      const data = Object.assign({}, company);
      // @ts-ignore
      delete data.id;

      return this.httpClient.put<HttpCompanyResponse>(`${SERVER_URL}/companies/${id}`, data);
    }

    /**
     *  Delete Company
     *
     */
    public delete(companyId: string): Observable<HttpCompanyResponse> {
      return this.httpClient.delete<HttpCompanyResponse>(`${SERVER_URL}/companies/${companyId}`);
    }

    /**
     *  Get Companies by Contract ID
     *
     */
    public get(companyId: string | undefined): Observable<HttpCompanyResponse> {
        const response: Observable<HttpCompanyResponse> =
            this.httpClient.get<HttpCompanyResponse>(`${SERVER_URL}/companies/${companyId}`);
        response.subscribe((data) => EventEmitterService.get(EventEmitterService.COMPANY_EVENT_UPDATED).emit(data));

        return response;
    }

    /**
     * Get the company plan status
     */
    public getPlanStatus(email: null | undefined): Observable<HttpCompanyPlanStatusResponse> {
        return this.httpClient.get<HttpCompanyPlanStatusResponse>(`${SERVER_URL}/companies/plan/status/${email}`);
    }

    /**
     *  Get Barnchs
     *
     */
    public getBranches(companyId: string | undefined, type: string | undefined): Observable<HttpCompanyResponse> {
        return this.httpClient.get<HttpCompanyResponse>(`${SERVER_URL}/companies/${companyId}/type/${type}`);
    }

    public async thereIsBranch(companyId: string, type: string): Promise<boolean> {
        const response: Company[] = [];
        let retunValue = false;

        if (type === 'ECONOMIC_GROUP') {
            const getBranchs = await this.getBranches(companyId, 'BRANCH').toPromise();
            if (getBranchs?.data) {
                response.push(getBranchs.data!)
                response.map((companiesGroupBranch: any, index: any) => {
                    const result = companiesGroupBranch.Items;
                    if (result.length > 0 && result[index].status !== 'INACTIVE') {
                        retunValue = true;
                    } else {
                        retunValue = false;
                    }
                })
            }
        } else {
            const getBranchs = await this.getBranches(companyId, 'ASSOCIATED_COMPANY').toPromise();
            if (getBranchs?.data) {
                response.push(getBranchs.data!)
                response.map((companiesGroupBranch: any, index: any) => {
                    const result = companiesGroupBranch.Items;
                    if (result.length > 0 && result[index].status !== 'INACTIVE') {
                        retunValue = true;
                    } else {
                        retunValue = false;
                    }
                })
            }
        }
        return retunValue;
    }
}
