<div>
    <p-confirmDialog [style]="{width: '432px'}" [baseZIndex]="10000"
        rejectButtonStyleClass="p-button-text"
        acceptButtonStyleClass="p-button-rounded p-button-danger"
        acceptLabel="Sim"
        rejectLabel="Não"
        acceptIcon="none"
        rejectIcon="none">
    </p-confirmDialog>

    <div class="card bg-transparent border-none mb-4">
        <div class="card-body">
            <div class="d-flex justify-content-between">

                <div class="pr-3 w-100">
                    <div class="alpha-card-body d-flex justify-content-between align-items-center">
                        <div>
                            <div class="alpha-card-text-top">
                                Total de Funcionários
                            </div>
                            <div class="alpha-card-text-middle contract">{{contractsCardCount || 0}}</div>
                        </div>

                        <div>
                            <div class="alpha-card-text-bottom">
                                <div class="alpha-card-text-bottom-icon d-flex justify-content-center align-items-center">
                                    <img class="icon-contract" src="assets/icon/UserGroupSolid/user-group-solid.svg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pr-3 w-100">
                    <div class="alpha-card-body d-flex justify-content-between align-items-center">
                        <div>
                            <div class="alpha-card-text-top">Funcionários de férias</div>
                            <div class="alpha-card-text-middle vacation">{{vacationsCardCount}}</div>
                        </div>
                        <div>
                            <div class="alpha-card-text-bottom d-flex justify-content-end">
                                <div class="alpha-card-text-bottom-icon d-flex justify-content-center align-items-center">
                                    <img class="icon-vacation" src="assets/icon/SunSolid/sun-solid.svg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pr-3 w-100">
                    <div class="alpha-card-body d-flex justify-content-between align-items-center">
                        <div>
                            <div class="alpha-card-text-top">Funcionários afastados</div>
                            <div class="alpha-card-text-middle vacation">{{absenceCardCount}}</div>
                        </div>
                        <div>
                            <div class="alpha-card-text-bottom d-flex justify-content-end">
                                <div class="alpha-card-text-bottom-icon d-flex justify-content-center align-items-center">
                                    <img class="icon-absence" src="assets/icon/ArrowLeftOnRectangle/arrow-left-on-rectangle.svg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pl-0 w-100">
                    <div class="alpha-card-body d-flex justify-content-between align-items-center">
                        <div>
                            <div class="alpha-card-text-top">Justificativas no mês</div>
                            <div class="alpha-card-text-middle justification">{{justificationCardCount || 0}}</div>
                        </div>
                        <div>
                            <div class="alpha-card-text-bottom d-flex justify-content-end">
                                <div class="alpha-card-text-bottom-icon d-flex justify-content-center align-items-center">
                                    <img class="icon-justification" src="assets/icon/DocumentTextSolid/document-text.svg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Content Row -->

        </div>
    </div>

    <!-- CHART STATISTICS -->
    <div class="card bg-transparent border-none mb-4">
        <div class="card-body">
            <div class="d-flex justify-content-between">
                <div class="alpha-card-chart d-flex justify-content-start mr-3 p-3 w-100">
                    <div *ngIf="contracts.length > 0; else nodata">
                        <app-working-hours-chart [contracts]="contracts"></app-working-hours-chart>
                    </div>
                    <ng-template #nodata>
                        <div class="d-flex flex-column justify-content-top align-items-center w-100" *ngIf="showEmptyChart">
                            <div class="d-flex flex-column justify-content-left align-items-start mb-5 w-100" style="color: #4D4F5C;">
                                <div>Jornadas de Trabalho</div>
                            </div>
                            <img src="assets/img/chart/pie-chart-nodata.svg" alt="">
                        </div>

                        <div class="loading-box d-flex justify-content-center align-items-center text-muted p-3" *ngIf="loadingChart">
                            <span class="spinner-grow spinner-grow-sm m-2" role="status" aria-hidden="true"></span>
                            Aguarde carregando os dados...
                        </div>
                    </ng-template>
                </div>
                <div class="alpha-card-chart mr-3 p-3 w-100">
                    <div>
                        <app-register-clock-by-week-chart></app-register-clock-by-week-chart>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-between mt-3">
                <div class="alpha-card-chart d-flex justify-content-center align-items-top p-3 w-100">
                    <div>
                        <app-register-clock-by-day-chart></app-register-clock-by-day-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- CHART STATISTICS -->

    <p-panel
        [toggleable]="true"
        [expandIcon]="'pi pi-angle-down'"
        [collapseIcon]="'pi pi-angle-up'"
        [collapsed]="true">
        <ng-template pTemplate="header">
            <div class="panel-header">
                <div class="panel-header-title">
                    <h3 class="border-left-primary pl-3">
                        Localização do Funcionário <span>com ponto ativo</span>
                    </h3>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="header-border-fix"></div>
            <div class="panel-wrapper">
                <app-map></app-map>
            </div>
        </ng-template>
    </p-panel>

    <p-panel [toggleable]="true" [expandIcon]="'pi pi-angle-down'" [collapseIcon]="'pi pi-angle-up'" [collapsed]="true">
        <ng-template pTemplate="header">
            <div class="panel-header">
                <div class="panel-header-title">
                    <h3 class="border-left-primary pl-3">Registros de Pontos <span>de hoje {{ dateNow | date: 'dd/MM/yyyy' }}</span></h3>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="header-border-fix"></div>
            <div class="panel-wrapper">
                <p-table #dt [value]="clockRegisters" dataKey="id" styleClass="p-datatable-employee" [rowHover]="true" [rows]="10"
                    [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [paginator]="true" [filterDelay]="0"
                    [globalFilterFields]="['employee.name', 'recordTime', 'address', 'type']">

                    <ng-template pTemplate="caption">
                        <div class="table-header">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input #findAll class="p-inputtext p-component inputsearch" pInputText type="text"
                                    (input)="dt.filterGlobal(findAll.value, 'contains')" placeholder="Pesquisar" />
                            </span>
                        </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="type">Status
                                <p-sortIcon field="type"></p-sortIcon>
                            </th>
                            <th pSortableColumn="employee.name">Nome
                                <p-sortIcon field="name"></p-sortIcon>
                            </th>
                            <th pSortableColumn="recordTime">Horário
                                <p-sortIcon field="recordTime"></p-sortIcon>
                            </th>
                            <th>Endereço</th>
                            <th style="text-align: center">Coordenadas</th>
                        </tr>
                        <tr *ngIf="!clockRegisters">
                            <th colspan="6">
                                <div class="d-flex justify-content-center text-muted">
                                    <h6>Nenhum Registro de Ponto encontrado</h6>
                                </div>
                            </th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-clock let-i>
                        <tr class="p-selectable-row">
                            <td>
                                <span *ngIf="clock.type === 'E'" [class]="'badge badge-green'">Entrada</span>
                                <span *ngIf="clock.type === 'S'" [class]="'badge badge-gray'">Saída</span>
                            </td>
                            <td>{{ clock.employee.name }}</td>
                            <td>{{ clock.recordTime }}</td>
                            <td>{{ clock.address }}</td>
                            <td style="text-align: center">
                                <a href="https://maps.google.com/?q={{ clock.latitude }},{{ clock.longitude }}" target="_blank" class="btn btn-link">
                                   <img class="history-map-icon" src="./assets/icon/Map/map.svg"/>
                                </a>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </ng-template>
    </p-panel>

    <p-panel [toggleable]="true" [expandIcon]="'pi pi-angle-down'" [collapseIcon]="'pi pi-angle-up'" [collapsed]="true">
        <ng-template pTemplate="header">
            <div class="panel-header">
                <div class="panel-header-title">
                    <h3 class="border-left-primary pl-3">Avisos</h3>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="header-border-fix"></div>
            <div class="panel-wrapper">
                <p-table #dt [value]="notices" dataKey="id" styleClass="p-datatable-employee" [rowHover]="true" [rows]="10"
                    [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [paginator]="true" [filterDelay]="0"
                    [globalFilterFields]="['priority', 'notice', 'createdAt']">

                    <ng-template pTemplate="caption">
                        <div class="table-header">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input #findAll class="p-inputtext p-component inputsearch" pInputText type="text"
                                    (input)="dt.filterGlobal(findAll.value, 'contains')" placeholder="Pesquisar por avisos" />
                            </span>
                            <div class="btn-add-notice">
                                <button type="button" class="btn btn-primary" (click)="onCreateNotice()">
                                    <i class="bi bi-plus"></i> Novo Aviso
                                </button>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="priority">Prioridade
                                <p-sortIcon field="priority"></p-sortIcon>
                            </th>
                            <th>Aviso</th>
                            <th pSortableColumn="createdAt">Data de criação
                                <p-sortIcon field="createdAt"></p-sortIcon>
                            </th>
                            <th style="text-align: center">Ações</th>
                        </tr>
                        <tr *ngIf="notices.length === 0 && loading">
                            <th colspan="6">
                                <div class="d-flex justify-content-center text-muted">
                                    <h6>Nenhum aviso encontrado</h6>
                                </div>
                            </th>
                        </tr>
                        <tr *ngIf="!loading">
                            <th colspan="6">
                                <div
                                    class="loading-box d-flex justify-content-center align-items-center text-muted p-3">
                                    <span class="spinner-grow spinner-grow-sm m-2" role="status"
                                          aria-hidden="true"></span>
                                    Aguarde estamos carregando os dados...
                                </div>
                            </th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-notice let-i>
                        <tr class="p-selectable-row" *ngIf="notices.length > 0 && loading">
                            <td>
                                <span [ngClass]="notice.priority === '1-LOW' ? 'badge badge-green' : notice.priority === '2-MEDIUM' ? 'badge badge-orange' : 'badge badge-red'">
                                    {{ notice.priority | noticePriority }}
                                </span>
                            </td>
                            <td>{{ notice.notice  }}</td>
                            <td>{{ notice.createdAt | date:'dd/MM/yyyy' }}</td>
                            <td style="text-align: center">
                                <button class="btn btn-link btn-icon-danger p-0" type="button" (click)="onDeleteNotice(notice.id)">
                                    <img class="icon-trash" src="assets/icon/Trash/trash.svg" />
                                </button>
                                <button class="btn btn-link btn-icon-danger p-0" type="button" (click)="onUpdate(notice)">
                                    <img class="icon-edit" src="assets/icon/PencilAlt/pencil-alt.svg" />
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </ng-template>
    </p-panel>
</div>


