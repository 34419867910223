import {Employee, HttpEmployeesResponse} from 'src/model/employee';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {SERVER_URL} from '../environments/environment';
import {Observable} from 'rxjs';
import HttpEmployeeResponse from '../model/employee';


@Injectable({
    providedIn: 'root'
})
export class EmployeeService {

    constructor(
        private httpClient: HttpClient) {
    }

    /**
     *  Employee get by id
     *
     */
    public getOne(employeeId: string): Observable<any> {
        return this.httpClient.get(`${SERVER_URL}/employees/${employeeId}`);
    }

    /**
     *  Employee Find All
     *
     */
    public findAll(): Observable<any> {
        return this.httpClient.get(`${SERVER_URL}/employees/`);
    }

    /**
     *  Employer Register
     *
     */
    public register(employee: any): Observable<any> {
        return this.httpClient.post(`${SERVER_URL}/employees/`, employee);
    }

    /**
     *  Employer Register
     *
     */
    public update(id: any, data: Employee): Observable<any> {
        return this.httpClient.put(`${SERVER_URL}/employees/${id}`, data);
    }

    /**
     *  Find Employer By Email
     */
    public findByPhone(phone: string): Observable<HttpEmployeeResponse> {
        return this.httpClient.get<HttpEmployeeResponse>(`${SERVER_URL}/employees/phone/${phone}`);
    }

    /**
     *  Find Employer By Company
     */
    public findAllByCompany(companyId: string): Observable<HttpEmployeesResponse> {
        return this.httpClient.get<HttpEmployeesResponse>(`${SERVER_URL}/employees/company/${companyId}`);
    }

    /**
     *  Find Employee By Email
     */
    public findByEmail(email: string): Observable<HttpEmployeeResponse> {
        return this.httpClient.get<HttpEmployeeResponse>(`${SERVER_URL}/employees/email/${email}`);
    }

    /**
     *  Find Employee By Registry id
     */
    public findByRegistryId(registryId: string): Observable<HttpEmployeeResponse> {
        return this.httpClient.get<HttpEmployeeResponse>(`${SERVER_URL}/employees/registryId/${registryId}`);
    }

    public getUploadUrl(data: any): Observable<any>{
        const options = { headers: { 'Content-Type': 'application/json, multipart/form-data' } };
        return this.httpClient.post<any>(`${SERVER_URL}/employees/upload`, data, options);
    }

    /**
     *  Find Employee By Email
     */
    public uploadCSVFile(preSignedUrl: string, file: any): Observable<any> {
        const options = { headers: { Anonymous: '', 'Content-Type': 'multipart/form-data' } };
        return this.httpClient.put<any>(`${preSignedUrl}`, file, options);
    }

    public calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
        const R = 6371; // km
        const dLat = this.toRad(lat2 - lat1);
        const dLon = this.toRad(lon2 - lon1);
        const dlat1 = this.toRad(lat1);
        const dlat2 = this.toRad(lat2);

        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(dlat1) * Math.cos(dlat2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c;

        return d;
    }

    private toRad(value: number): number {
        return value * Math.PI / 180;
    }

}
