import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import * as moment from 'moment';
import {MessageService} from 'primeng/api';
import {Employee} from 'src/model/employee';
import {AppStorageService} from 'src/services/app-storage.service';
import {ContractService} from 'src/services/contract.service';
import * as ContractAction from '../../stores/actions/contract.action';
import * as EmployeeAction from '../../stores/actions/employee.action';
import {ContractState} from 'src/stores/states/contract.state';
import {getContracts} from 'src/stores/selectors/contract.selector';
import { EmployeeService } from 'src/services/employee.service';
import { getEmployees } from 'src/stores/selectors/employee.selector';

@Component({
    selector: 'app-searchbar-employee',
    templateUrl: './searchbar-employee.component.html',
    styleUrls: ['./searchbar-employee.component.scss'],
    providers: [MessageService]
})
export class SearchbarEmployeeComponent implements OnInit {

    @Output() searchEvent = new EventEmitter();
    @Output() cleanForm = new EventEmitter();
    @Output() lastTwelveMonthsClick = new EventEmitter();

    public filterForm: FormGroup;
    public contracts: any[] = [];
    public employees: any;
    public selectedContract: any[] = [];
    public selectedEmployee: any[] = [];

    constructor(
        private store: Store<ContractState>,
        private appStorageService: AppStorageService,
        private employeeService: EmployeeService,
        private contractService: ContractService,
        private formBuilder: FormBuilder,
    ) {
        this.filterForm = this.formBuilder.group({
            startDate: ['', Validators.required],
            endDate: ['', Validators.required],
            employeeId: [''],
        });
    }

    async ngOnInit(): Promise<void>{
        await this.fetchData();
    }

    async fetchData(): Promise<void> {
        const employee: Employee = await this.appStorageService.getEmployee();

        this.employeeService.findAllByCompany(employee.companyId!).subscribe(employee => {
            this.employees = employee.data
        })


        // this.store.dispatch(EmployeeAction.loadEmployeeRequestAction({id: employee.companyId as string}));
        // this.store.select(getEmployees).subscribe((employees) => {
        //     const sorted = employees.sort((a: any, b:any) => {
        //         const ca: string = a.name;
        //         const cb: string = b.name;
        //         return ca.toLowerCase().localeCompare(cb.toLowerCase());
        //     })
        //     sorted.map((employee: any) => {
        //         this.selectedEmployee.push({name: employee?.name,});
        //         this.employees?.push({
        //             // id: contract.id,
        //             employeeId: employee?.id,
        //             name: employee?.name,
        //         });
        //     });
        // });

        // this.store.dispatch(ContractAction.loadContractRequestAction({id: employee.companyId as string}));
        // this.store.select(getContracts).subscribe((contracts) => {
        //     const sorted = contracts.sort((a: any, b:any) => {
        //         const ca: string = a.employee.name;
        //         const cb: string = b.employee.name;
        //         return ca.toLowerCase().localeCompare(cb.toLowerCase());
        //     })
        //     sorted.map((contract: any) => {
        //         this.selectedContract.push({name: contract.employee?.name,});
        //         this.contracts?.push({
        //             id: contract.id,
        //             employeeId: contract.employee?.id,
        //             name: contract.employee?.name,
        //         });
        //     });
        // });
    }

    async getFormValues(): Promise<void> {
        this.searchEvent.emit(this.filterForm.value);
    }

    searchClick() {
        this.lastTwelveMonthsClick.emit(false);
    }

    lastTwelveMonths() {
        const startDate = moment().startOf('M').subtract(0, 'month');
        const endDate = moment().endOf('M').subtract(12, 'month');

        const convertStartDataToSearch = moment(endDate).format('DD/MM/YYYY');
        const convertEndDataToSearch = moment(startDate).format('DD/MM/YYYY');

        this.filterForm.get('startDate')?.setValue(convertStartDataToSearch);
        this.filterForm.get('endDate')?.setValue(convertEndDataToSearch);
        this.lastTwelveMonthsClick.emit(true);
    }

    onCleanForm() {
        this.filterForm.reset({
            startDate: '',
            endDate: '',
            employeeId: '',
        });
        this.cleanForm.emit();
    }
}
