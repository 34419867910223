<div id="wrapper">
    <app-sidebar></app-sidebar>
    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <app-header></app-header>
            <div class="container-fluid px-5">
                <app-spinner></app-spinner>
                                <div class="alert bg-info-light
                                         text-primary d-flex align-items-center shadow mb-2" role="alert"
                                    *ngIf="showWarning">
                                    <i class="bi bi-exclamation-circle text-primary h3 mr-4"></i>
                                    <div>
                                        <h5>As informações da sua empresa estão incompletas.</h5>
                                        <div class="small text-primary">
                                            Algumas funcões da aplicação só funcionarão com as informações devidamente cadastradas.
                                            Sem essas informações não é possível receber notificações, cadastrar empregados, etc.
                                            Para completar o cadastro da empresa,
                                            <a class="" routerLink="/company"> clique aqui</a>
                                        </div>
                                    </div>
                                </div>
                <router-outlet></router-outlet>
            </div>
            <app-footer></app-footer>
        </div>
    </div>
</div>


