import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {forkJoin, of as observableOf} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import * as EmployeeAction from '../actions/employee.action';
import {EmployeeService} from 'src/services/employee.service';
import {ContractService} from '../../services/contract.service';
import {HttpEmployeesResponse} from '../../model/employee';
import {HttpContractsResponse} from '../../model/contract';

@Injectable()
export class EmployeeEffect {

    constructor(
        private employeeService: EmployeeService,
        private contractService: ContractService,
        private actions$: Actions) {
    }

    loadEmployeeRequestEffect$ = createEffect(() => this.actions$.pipe(
        ofType(EmployeeAction.loadEmployeeRequestAction),
        switchMap(action => {
            return forkJoin(this.employeeService.findAllByCompany(action.id), this.contractService.getContractsByCompany(action.id)).pipe(
                map((response: any) => {

                    const httpEmployeesResponse: HttpEmployeesResponse = response[0];
                    const httpContractsResponse: HttpContractsResponse = response[1];

                    httpEmployeesResponse.data.map( (employee) => {
                        employee.contract = httpContractsResponse.data.find( (el) => el.employeeId === employee.id);
                    });

                    return EmployeeAction.loadEmployeeSuccessAction({ employees: httpEmployeesResponse.data});
                }),
                catchError((error: any) => {
                    return observableOf(EmployeeAction.loadEmployeetFailureAction({error}));
                })
            );
        })
    ));

}
