import {createAction, props} from '@ngrx/store';

export enum MetaActionTypes {
    LOGOUT = '[App] logout',
    FIRE_EMPLOYEE = '[App] fire employee',
    REEMPLOY_EMPLOYEE = '[App] reemploe employee',
}

export const logout = createAction(
    MetaActionTypes.LOGOUT
);

export const fireEmployee = createAction(
    MetaActionTypes.FIRE_EMPLOYEE
);

export const reemployEmployee = createAction(
    MetaActionTypes.REEMPLOY_EMPLOYEE
);
