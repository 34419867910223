<p-toast></p-toast>
<!-- ADD HOUR -->
<p-dialog [(visible)]="displayIncreaseDialog" [style]="{width: '40vw'}" [baseZIndex]="10000">
    <ng-template pTemplate="header">
        <div class="d-flex align-items-center">
            <div class="mr-2" style="border-left: 0.25rem solid #5056CC !important;">
               <span class="ml-2">{{alertHeader}}</span>
            </div>
            <span
                [ngClass]="contract?.amountOfHours! < 0  ? 'badge badge-red' : 'badge badge-green'">
                {{hourAmount|| '00:00'}}
            </span>
        </div>
    </ng-template>
    <div>
        <p class="text-16-22">Essa ação pode zerar ou aumentar a quantidade de horas do funcionário, deseja continuar?</p>
        <form [formGroup]="inCreaseForm">
            <div class="form-group col-lg-4 pl-0">
                <div class="p-field">
                    <p-inputMask class="p-inputtext-sm" placeholder="Ex: 00:00" mask="99:99" formControlName="inputIncreaseHour"
                        [ngClass]="{'is-invalid': inCreaseForm.get('inputIncreaseHour')?.hasError('required') &&
                            inCreaseForm.get('inputIncreaseHour')?.touched}">
                    </p-inputMask>
                    <div class="invalid-feedback" *ngIf="inCreaseForm.get('inputIncreaseHour')?.hasError('required')
                        && inCreaseForm.get('inputIncreaseHour')?.touched">
                        Campo requerido
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-lg-12">
                    <label for="notice">Justificativa</label>
                    <textarea class="form-control" rows="3" cols="6" formControlName="inputIncreaseMessage"
                        [ngClass]="{
                            'is-invalid': inCreaseForm.get('inputIncreaseMessage')?.invalid &&
                            inCreaseForm.get('inputIncreaseMessage')?.touched
                        }">
                    </textarea>
                    <div class="invalid-feedback" *ngIf="inCreaseForm.get('inputIncreaseMessage')?.hasError('required') &&
                        inCreaseForm.get('inputIncreaseMessage')?.touched">Justificativa é requerido
                    </div>
                    <div class="invalid-feedback" *ngIf="inCreaseForm.get('inputIncreaseMessage')?.hasError('maxlength') &&
                        inCreaseForm.get('inputIncreaseMessage')?.touched">Campo excedeu o limite de 120 caracteres
                    </div>
                </div>
            </div>
        </form>
        <!-- <p class="text-16-22">Insira um arquivo com as horas do funcionário</p>
        <div class="upload-box mb-2 d-flex flex-column justify-content-center align-items-center">
            <div class="mb-2">
                <img class="upload-icon" src="assets/icon/ArrowUpTray/arrow-up-tray.svg"/> <br>
            </div>
            <div class="text-14-19">
                Arraste para cá ou clique para inserir
            </div>
            <p class="text-14-19" style="opacity: 0.5;">Instrução</p>
        </div> -->
    </div>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-link mr-2" (click)="closeAlert('addHour')">Cancelar</button>
        <button type="submit" class="btn btn-primary btn-default" [disabled]="!inCreaseForm.valid" (click)="onIncreaseHour()">
            Adicionar
        </button>
    </ng-template>
</p-dialog>

<!-- USE HOUR -->
<p-dialog [(visible)]="displayDecreaseDialog" [style]="{width: '40vw'}" [baseZIndex]="10000">
    <ng-template pTemplate="header">
        <div class="d-flex align-items-center">
            <div class="mr-2" style="border-left: 0.25rem solid #5056CC !important;">
               <span class="ml-2">{{alertHeader}}</span>
            </div>
            <span
                [ngClass]="contract?.amountOfHours! < 0  ? 'badge badge-red' : 'badge badge-green'">
                {{hourAmount|| '00:00'}}
            </span>
        </div>
    </ng-template>
    <div>
        <p class="text-16-22">Quantidade de horas a serem adicionadas</p>
        <form [formGroup]="decreaseForm">
            <div class="form-group col-lg-4 pl-0">
                <div class="p-field">
                    <p-inputMask class="p-inputtext-sm" placeholder="Ex: 00:00" mask="99:99" formControlName="inputDecreaseHour"
                        [ngClass]="{
                            'is-invalid': decreaseForm.get('inputDecreaseHour')?.hasError('required') &&
                            decreaseForm.get('inputDecreaseHour')?.touched
                        }">
                    </p-inputMask>
                    <div class="invalid-feedback" *ngIf="decreaseForm.get('inputDecreaseHour')?.hasError('required')
                        && decreaseForm.get('inputDecreaseHour')?.touched">
                        Campo requerido
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-lg-12">
                    <label for="notice">Justificativa</label>
                    <textarea class="form-control"  rows="3" cols="6" formControlName="inputDecreaseMessage"
                        [ngClass]="{
                            'is-invalid': decreaseForm.get('inputDecreaseMessage')?.invalid &&
                            decreaseForm.get('inputDecreaseMessage')?.touched
                        }">
                    </textarea>
                    <div class="invalid-feedback" *ngIf="decreaseForm.get('inputDecreaseMessage')?.hasError('required') &&
                        decreaseForm.get('inputDecreaseMessage')?.touched">Justificativa é requerido
                    </div>
                    <div class="invalid-feedback" *ngIf="decreaseForm.get('inputDecreaseMessage')?.hasError('maxlength') &&
                        decreaseForm.get('inputDecreaseMessage')?.touched">Campo excedeu o limite de 120 caracteres
                    </div>
                </div>
            </div>
        </form>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-link mr-2" (click)="closeAlert('useHour')">Cancelar</button>
        <button type="submit" class="btn btn-primary btn-default" [disabled]="!decreaseForm.valid" (click)="onDecreaseHour()">
            Sim, utilizar
        </button>
    </ng-template>
</p-dialog>

<!-- CLEAN HOUR -->
<p-dialog [header]="alertHeader" [(visible)]="displayClearDialog" [style]="{width: '40vw'}" [baseZIndex]="10000">
    <ng-template pTemplate="header">
        <div class="d-flex align-items-center">
            <div class="mr-2" style="border-left: 0.25rem solid #5056CC !important;">
               <span class="ml-2">{{alertHeader}}</span>
            </div>
            <span
                [ngClass]="contract?.amountOfHours! < 0  ? 'badge badge-red' : 'badge badge-green'">
                {{hourAmount|| '00:00'}}
            </span>
        </div>
    </ng-template>
    <div>
        <p class="text-16-22">Essa ação irá zerar a quantidade de horas do funcionário, deseja continuar?</p>
        <form [formGroup]="clearForm">
            <div class="form-row">
                <div class="form-group col-lg-12">
                    <label for="notice">Justificativa</label>
                    <textarea class="form-control"  rows="3" cols="6" formControlName="inputClearMessage"
                        [ngClass]="{
                            'is-invalid': clearForm.get('inputClearMessage')?.invalid &&
                            clearForm.get('inputClearMessage')?.touched
                        }">
                    </textarea>
                    <div class="invalid-feedback" *ngIf="clearForm.get('inputClearMessage')?.hasError('required') &&
                        clearForm.get('inputClearMessage')?.touched">Justificativa é requerido
                    </div>
                    <div class="invalid-feedback" *ngIf="clearForm.get('inputClearMessage')?.hasError('maxlength') &&
                        clearForm.get('inputClearMessage')?.touched">Campo excedeu o limite de 120 caracteres
                    </div>
                </div>
            </div>
        </form>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-link mr-2" (click)="closeAlert('cleanHour')">Cancelar</button>
        <button type="submit" class="btn btn-primary btn-default" [disabled]="!clearForm.valid" (click)="onClearHour()">
            Sim, zerar
        </button>
    </ng-template>
</p-dialog>

<div>
    <a routerLink="/comp-time" class="btn btn-primary btn-sm mb-4 mt-2">
        <span class="fa fa-chevron-left mr-2" aria-hidden="true"></span>Voltar
    </a>
    <div class="card shadow mb-4">
        <div class="card-body m-4 d-flex justify-content-center text-muted align-items-center p-3" *ngIf="!contract?.id">
            <span class="spinner-grow spinner-grow-sm m-2" role="status" aria-hidden="true"></span>
            Aguarde estamos carregando os dados...
        </div>
        <div class="card-body m-4" *ngIf="contract?.id">
            <div style="border-left: 0.25rem solid #5056CC !important;">
                <h3 class="tm-title ml-2">Gerenciamento de saldo de horas</h3>
            </div>

            <div class="d-flex justify-content-start align-items-center mt-4 employee-info">
                <p-avatar
                    image="assets/img/logo-blue.png"
                    [image]="clock.picture ? clock.picture : picture"
                    onerror="this.src='assets/icon/Avatar/user.png'"
                    styleClass="mr-2"
                    size="large"
                    shape="circle">
                </p-avatar>
                <span class="mr-2 text-20-27">{{ clock.name | uppercase }}</span>
                <span class="mr-2 text-16-22"> | Saldo de horas </span>
                <span
                    [ngClass]="contract?.amountOfHours! < 0 ? 'badge badge-red' : 'badge badge-green'">
                    {{hourAmount || '00:00'}}
                </span>
            </div>

            <div *ngIf="hourAmount">
                <p class="mt-5 mb-4 text-16-22">O que você deseja fazer com essas horas/minutos?</p>

                <div class="cards-container d-flex justify-content-between align-items-center">
                    <div class="card time-managemrnt-card cursor" style="width: 239px; height: 173px;" (click)="openAlert('addHour')">
                        <div class="card-body text-center">
                            <img src="assets/img/clock-plus.png" style="height: 65px;" alt="">
                            <p class="mr-2 text-16-22 text-center mt-4">Quero adicionar mais horas no banco</p>
                        </div>
                    </div>
                    <div class="card time-managemrnt-card" *ngIf="enabledUseHour" style="width: 239px;height: 173px; opacity: 50%;">
                        <div class="card-body text-center">
                            <img src="assets/img/clock-check.png" style="height: 65px;" alt="">
                            <p class="mr-2 text-16-22 text-center mt-4">Quero usar essas horas para justificar algo</p>
                        </div>
                    </div>
                    <div class="card time-managemrnt-card cursor" *ngIf="!enabledUseHour" style="width: 239px;height: 173px;" (click)="openAlert('useHour')">
                        <div class="card-body text-center">
                            <img src="assets/img/clock-check.png" style="height: 65px;" alt="">
                            <p class="mr-2 text-16-22 text-center mt-4">Quero usar essas horas para justificar algo</p>
                        </div>
                    </div>
                    <div class="card time-managemrnt-card cursor" style=" width: 239px;height: 173px;" (click)="openAlert('cleanHour')">
                        <div class="card-body text-center">
                            <img src="assets/img/clock-zero.png" style="height: 65px;" alt="">
                            <p class="mr-2 text-16-22 text-center mt-4">Quero zerar o banco de horas</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
