import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment, SERVER_URL} from '../environments/environment';
import {Observable} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class PaymentService {

    constructor(
        private httpClient: HttpClient) {
    }

    public createCustomerPortal(customerId: string | undefined): Observable<any> {

        const returnUrl = `${window.location.origin}/dashboard'`;

        const body = JSON.stringify({customerId, returnUrl});
        return this.httpClient.post(`${SERVER_URL}/payment/create-customer-portal-session/`, body);
    }

    public async redirectToCheckout(sessionId: string, customerEmail: string): Promise<any> {

        const successUrl = environment.production ? `${window.location.origin}/welcome` : 'http://localhost:4200/welcome';
        const cancelUrl = environment.production ? `${window.location.origin}/canceled` : 'http://localhost:4200/signature';

        return stripe.redirectToCheckout({ sessionId });
    }

    public createCheckoutSession(priceId: string,  customerEmail: string): Observable<any> {
        const successUrl = `${window.location.origin}/dashboard'`;
        const cancelUrl = `${window.location.origin}/signature`;

        const body = JSON.stringify({ priceId, successUrl, cancelUrl, customerEmail });
        return this.httpClient.post(`${SERVER_URL}/payment/create-checkout-session/`, body);
      }

    public getCardsInfo(): Observable<any[]> {
        const options = { headers: { Anonymous: '' } };
        return this.httpClient.get<any[]>('assets/dados/signature_optiond.json', options);
    }

}
