import {createReducer, on} from '@ngrx/store';
import * as ContractActionTypes from '../actions/contract.action';
import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {Contract} from '../../model/contract';
import {ContractState} from '../states/contract.state';

export const adapter: EntityAdapter<Contract> = createEntityAdapter<Contract>();
export const initialState: ContractState = adapter.getInitialState({ isLoading: false, error: null });

export const contractReducer = createReducer( initialState,

    on(ContractActionTypes.loadContractRequestAction, (state, {id}) => ({
        ...state,
        isLoading: true
    })),

    on(ContractActionTypes.loadContractSuccessAction, (state, {contracts}) => {
        return adapter.addMany(contracts, {
            ...state,
            isLoading: true
        });
    }),

    on(ContractActionTypes.loadContractFailureAction, (state, {error}) => ({
        ...state,
        isLoading: false,
        error
    })),

    on(ContractActionTypes.clearContractStateAction, (state) => ({
        ...state = initialState,
    })),
);
