import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import HttpDepartmentResponse, { Department } from 'src/model/department';
import {SERVER_URL} from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DepartmentService {

    constructor(private httpClient: HttpClient) {}

    /**
     *  Save department
     *
     */
    public save(data: Department): Observable<HttpDepartmentResponse> {
        return this.httpClient.post<HttpDepartmentResponse>(`${SERVER_URL}/companies/department`, data);
    }

    /**
     * Update department
     */
    public update(department: Department, id: string): Observable<HttpDepartmentResponse> {
        const data = Object.assign({}, department);
        // @ts-ignore
        delete data.id;

        return this.httpClient.put<HttpDepartmentResponse>(`${SERVER_URL}/companies/department/${id}`, data);
    }

    /**
     *  Delete department
     *
     */
    public delete(departmentId: string): Observable<HttpDepartmentResponse> {
        return this.httpClient.delete<HttpDepartmentResponse>(`${SERVER_URL}/companies/department/${departmentId}`);
    }

    /**
     *  Get department
     *
     */
    public list(companyId: string): Observable<HttpDepartmentResponse> {
        return this.httpClient.get<HttpDepartmentResponse>(`${SERVER_URL}/companies/${companyId}/department`);
    }

}
