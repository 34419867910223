<p-toast></p-toast>
<div>
    <div class="card mb-4" *ngIf="!toEdit">
        <div class="configuration-data justify-content-between">
            <div class="d-flex align-items-center">
                <div class="configuration-data-picture">
                    <img
                      [src]="profilePicture ? profilePicture : picture"
                      onerror="this.src='assets/icon/Avatar/user.png'"
                      alt="perfil"
                      class="rounded-circle d-block avatar-perfil img-thumbnail">
                </div>
                <div class="configuration-data-name">
                    <span>{{ employee?.name }}</span>
                </div>
            </div>
            <div>
                <button type="submit" class="btn btn-primary" (click)="onEdit()">
                    <div class="d-flex justify-content-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                        <span class="ml-2">Editar</span>
                    </div>
                </button>
            </div>
        </div>
        <div class="card-body">
            <div>
                <div class="row body-row">
                    <div class="col-sm body-row-fields">
                        <h3>E-mail</h3>
                        <span>{{ employee?.email }}</span>
                    </div>
                    <div class="col-sm body-row-fields">
                        <h3>Senha</h3>
                        <div class="flex align-items-center">
                            <span>***************</span>
                            <button type="button" class="btn btn-sm btn-link" (click)="onChangePassword()">
                                Alterar
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row body-row">
                    <div class="col-sm body-row-fields">
                        <h3>Telefone</h3>
                        <span>{{ employee?.phone | phone }}</span>
                    </div>
                    <div class="col-sm body-row-fields">
                        <h3>CPF</h3>
                        <span>{{ employee?.registryId }}</span>
                    </div>
                </div>

                <div class="row body-row">
                    <div class="col-sm body-row-fields">
                        <h3>Endereço</h3>
                        <span>{{ employee?.street }}</span>
                    </div>
                    <div class="col-sm body-row-fields">
                        <h3>Número</h3>
                        <span>{{ employee?.addressNumber }}</span>
                    </div>
                </div>

                <div class="row body-row">
                    <div class="col-sm body-row-fields">
                        <h3>Complemento</h3>
                        <span>{{ employee?.complement }}</span>
                    </div>
                    <div class="col-sm body-row-fields">
                        <h3>Bairro</h3>
                        <span>{{ employee?.district }}</span>
                    </div>
                </div>

                <div class="row body-row">
                    <div class="col-sm body-row-fields">
                        <h3>Cidade</h3>
                        <span>{{ employee?.city }}</span>
                    </div>
                    <div class="col-sm body-row-fields">
                        <h3>Estado</h3>
                        <span>{{ employee?.state }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card mb-4" *ngIf="toEdit">
        <div class="configuration-data justify-content-between">
            <div class="d-flex align-items-center">
                <div class="configuration-data-picture">
                    <img
                      [src]="profilePicture ? profilePicture : picture"
                      onerror="this.src='assets/icon/Avatar/user.png'"
                      alt="perfil"
                      class="rounded-circle d-block avatar-perfil img-thumbnail">
                </div>
                <div class="configuration-data-name">
                    <span>{{ employee?.name }}</span>
                </div>
            </div>
            <div>
                <button type="submit" class="btn btn-link mr-3" (click)="onCancel()">
                    Cancelar
                </button>
                <button type="submit" class="btn btn-primary" [disabled]="!profileForm.valid" (click)="onSubmit()">
                    Salvar
                </button>
            </div>
        </div>

        <div class="w-100 form-container">
            <form [formGroup]="profileForm">
                <!-- ROW-01 -->
                <div class="form-row">
                    <div class="form-group col-lg-4">
                        <label for="email">E-mail</label>
                        <input type="email" class="form-control" formControlName="email" [ngClass]="{
                                                'is-invalid': profileForm.get('email')?.hasError('required') && profileForm.get('email')?.touched
                                            }">
                        <div class="invalid-feedback" *ngIf="profileForm.get('email')?.hasError('required')
                                              && profileForm.get('email')?.touched">
                            É obrigatório o preenchimento deste campo!
                        </div>
                        <div class="invalid-feedback" *ngIf="profileForm.get('email')?.hasError('email')
                                              && profileForm.get('email')?.touched">
                            Este endereço de E-mail não é válido!
                        </div>
                    </div>
                    <div class="form-group col-lg-4">
                        <label for="phone">Telefone</label>
                        <p-inputMask
                            id="phone" mask="(99) 9 9999-9999" styleClass="form-control" formControlName="phone"
                            [ngClass]="{
                            'is-invalid': profileForm.get('phone')?.hasError('required') && profileForm.get('phone')?.touched
                            }">
                        </p-inputMask>
                        <div class="invalid-feedback" *ngIf="profileForm.get('phone')?.hasError('required')
                            && profileForm.get('phone')?.touched">
                            É obrigatório o preenchimento deste campo!
                        </div>
                        <div class="invalid-feedback" *ngIf="profileForm.get('phone')?.hasError('minlength')
                            && profileForm.get('phone')?.touched">
                            Campo deve ter no mínimo 10 caracteres!
                        </div>
                    </div>
                    <div class="form-group col-lg-4"></div>
                </div>

                <!-- ROW-02 -->
                <div class="form-row">
                    <div class="form-group col-lg-4">
                        <label for="registry">CPF</label>
                        <p-inputMask
                          id="registry" mask="999.999.999-99" styleClass="form-control" formControlName="registryId"
                          [ngClass]="{
                            'is-invalid': profileForm.get('registryId')?.hasError('required') && profileForm.get('registryId')?.touched
                          }">
                        </p-inputMask>
                        <div class="invalid-feedback" *ngIf="profileForm.get('registryId')?.hasError('required')
                          && profileForm.get('registryId')?.touched">
                          É obrigatório o preenchimento deste campo!
                        </div>
                        <span class="registryid-invalid-feedback" *ngIf="profileForm.errors !== null">Campo inválido!</span>
                    </div>

                    <div class="form-group col-lg-4">
                        <label for="zipcode">CEP*</label>
                        <p-inputMask id="zipcode" placeholder="Digite aqui" styleClass="form-control" mask="99999999"
                            formControlName="zipcode" (onBlur)="findZipcode()">
                        </p-inputMask>
                        <div class="ng-invalid text-danger text-validation"
                            *ngIf="profileForm.get('zipcode')?.hasError('required') && profileForm.get('zipcode')?.touched">
                            CEP é requerido
                        </div>
                    </div>

                    <div class="form-group col-lg-4"></div>
                </div>

                <!-- ROW-03 -->
                <div class="form-row">
                    <div class="form-group col-lg-4">
                        <label for="street">Rua/Avenida</label>
                        <input
                          type="text" id="street" class="form-control" formControlName="street"
                          [ngClass]="{
                            'is-invalid': profileForm.get('street')?.hasError('required') && profileForm.get('street')?.touched
                          }"/>
                        <div class="invalid-feedback" *ngIf="profileForm.get('street')?.hasError('required')
                          && profileForm.get('street')?.touched">
                          É obrigatório o preenchimento deste campo!
                        </div>
                    </div>

                    <div class="form-group col-lg-4">
                        <label for="streetn">Número</label>
                        <input
                          type="text" id="streetn" class="form-control" formControlName="addressNumber"
                          (keypress)="keyPressNumbers($event)"
                          [ngClass]="{
                            'is-invalid': profileForm.get('streetNumber')?.hasError('required') && profileForm.get('streetNumber')?.touched
                          }"/>
                        <div class="invalid-feedback" *ngIf="profileForm.get('streetNumber')?.hasError('required')
                          && profileForm.get('streetNumber')?.touched && isNumber">
                          É obrigatório o preenchimento deste campo!
                        </div>
                        <div class="invalid-feedback" *ngIf="!isNumber">
                          Somente números
                        </div>
                    </div>
                    <div class="form-group col-lg-4"></div>
                </div>

                <!-- ROW-04 -->
                <div class="form-row">
                    <div class="form-group col-lg-4">
                        <label for="complement">Complemento</label>
                        <input type="text" id="complement" class="form-control" formControlName="complement"/>
                    </div>

                    <div class="form-group col-lg-4">
                        <label for="district">Bairro</label>
                        <input
                          type="text" id="district" class="form-control" formControlName="district"
                          [ngClass]="{
                            'is-invalid': profileForm.get('district')?.hasError('required') && profileForm.get('district')?.touched
                          }"/>
                        <div class="invalid-feedback" *ngIf="profileForm.get('district')?.hasError('required')
                          && profileForm.get('district')?.touched">
                          É obrigatório o preenchimento deste campo!
                        </div>
                    </div>

                    <div class="form-group col-lg-4"></div>
                </div>

                <!-- ROW-05 -->
                <div class="form-row">
                    <div class="form-group col-lg-4">
                        <label for="city">Cidade</label>
                        <select
                          id="city" id="city" class="form-control" formControlName="city"
                          [ngClass]="{
                            'is-invalid': profileForm.get('city')?.hasError('required') && profileForm.get('city')?.touched
                          }">
                          <option *ngFor="let city of cities"  [value]="city.nome">{{ city.nome }}</option>
                        </select>
                        <div class="invalid-feedback" *ngIf="profileForm.get('city')?.hasError('required')
                          && profileForm.get('city')?.touched">
                          É obrigatório o preenchimento deste campo!
                        </div>
                    </div>

                    <div class="form-group col-lg-4">
                        <label>Estado</label>
                        <select #stateForm class="form-control" formControlName="state" (change)="onStateChange(stateForm.value)">
                            <option *ngFor="let state of states"  [value]="state.sigla">{{ state.sigla }}</option>
                        </select>
                    </div>

                    <div class="form-group col-lg-4"></div>
                </div>
            </form>
        </div>
    </div>







  <!-- <div class="card mb-4">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <div class="outer">
            <img
              [src]="profilePicture ? profilePicture : picture"
              onerror="this.src='assets/icon/Avatar/user.png'"
              alt="perfil"
              class="rounded-circle d-block mt-4 avatar-perfil img-thumbnail">
            <div class="inner">
              <input class="inputfile" name="imageProfile" type="file" accept="image/*"  (change)="onChange($event)">
              <label>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera" viewBox="0 0 16 16">
                  <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/>
                  <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                </svg>
              </label>
            </div>
          </div>
        </div>
        <div class="col">
          <form [formGroup]="profileForm" (ngSubmit)="onSubmit()" >
            <div class="form-group">
              <label for="name">Nome ou Razão Social</label>
              <input
                type="text"
                id="name" class="form-control" formControlName="name"
                [ngClass]="{
                  'is-invalid': profileForm.get('name')?.hasError('required') && profileForm.get('name')?.touched
              }"/>
              <div class="invalid-feedback" *ngIf="profileForm.get('name')?.hasError('required')
                && profileForm.get('name')?.touched">
                É obrigatório o preenchimento deste campo!
              </div>
            </div>

            <div class="form-group">
              <label for="email">E-mail</label>
              <input
                type="email" class="form-control" formControlName="email"
                [ngClass]="{
                  'is-invalid': profileForm.get('email')?.hasError('required') && profileForm.get('email')?.touched
              }">
              <div class="invalid-feedback" *ngIf="profileForm.get('email')?.hasError('required')
                && profileForm.get('email')?.touched">
                É obrigatório o preenchimento deste campo!
              </div>
              <div class="invalid-feedback" *ngIf="profileForm.get('email')?.hasError('email')
                && profileForm.get('email')?.touched">
                Este endereço de E-mail não é válido!
              </div>
            </div>

            <div class="form-group">
              <label for="phone">Telefone</label>
              <p-inputMask
                id="phone" mask="(99) 9 9999-9999" styleClass="form-control" formControlName="phone"
                [ngClass]="{
                  'is-invalid': profileForm.get('phone')?.hasError('required') && profileForm.get('phone')?.touched
                }">
              </p-inputMask>
              <div class="invalid-feedback" *ngIf="profileForm.get('phone')?.hasError('required')
                && profileForm.get('phone')?.touched">
                É obrigatório o preenchimento deste campo!
              </div>
              <div class="invalid-feedback" *ngIf="profileForm.get('phone')?.hasError('minlength')
                && profileForm.get('phone')?.touched">
                Compo deve ter no mínimo 10 caracteres!
              </div>
            </div>

            <div class="form-group">
              <label for="registry">CPF</label>
              <p-inputMask
                id="registry" mask="999.999.999-99" styleClass="form-control" formControlName="registryId"
                [ngClass]="{
                  'is-invalid': profileForm.get('registryId')?.hasError('required') && profileForm.get('registryId')?.touched
                }">
              </p-inputMask>
              <div class="invalid-feedback" *ngIf="profileForm.get('registryId')?.hasError('required')
                && profileForm.get('registryId')?.touched">
                É obrigatório o preenchimento deste campo!
              </div>
              <span class="registryid-invalid-feedback" *ngIf="profileForm.errors !== null">Campo inválido!</span>
            </div>
            <div class="form-group">
              <label for="street">Rua/Avenida</label>
              <input
                type="text" id="street" class="form-control" formControlName="street"
                [ngClass]="{
                  'is-invalid': profileForm.get('street')?.hasError('required') && profileForm.get('street')?.touched
                }"/>
              <div class="invalid-feedback" *ngIf="profileForm.get('street')?.hasError('required')
                && profileForm.get('street')?.touched">
                É obrigatório o preenchimento deste campo!
              </div>
            </div>

            <div class="form-group">
              <label for="streetn">Número</label>
              <input
                type="text" id="streetn" class="form-control" formControlName="addressNumber"
                (keypress)="keyPressNumbers($event)"
                [ngClass]="{
                  'is-invalid': profileForm.get('streetNumber')?.hasError('required') && profileForm.get('streetNumber')?.touched
                }"/>
              <div class="invalid-feedback" *ngIf="profileForm.get('streetNumber')?.hasError('required')
                && profileForm.get('streetNumber')?.touched && isNumber">
                É obrigatório o preenchimento deste campo!
              </div>
              <div class="invalid-feedback" *ngIf="!isNumber">
                Somente números
              </div>
            </div>

            <div class="form-group">
              <label for="complement">Complemento</label>
              <input type="text" id="complement" class="form-control" formControlName="complement"/>
            </div>

            <div class="form-group">
              <label for="district">Bairro</label>
              <input
                type="text" id="district" class="form-control" formControlName="district"
                [ngClass]="{
                  'is-invalid': profileForm.get('district')?.hasError('required') && profileForm.get('district')?.touched
                }"/>
              <div class="invalid-feedback" *ngIf="profileForm.get('district')?.hasError('required')
                && profileForm.get('district')?.touched">
                É obrigatório o preenchimento deste campo!
              </div>
            </div>

            <div class="form-group">
              <label>Estado</label>
              <select #stateForm class="form-control" formControlName="state" (change)="onStateChange(stateForm.value)">
                <option *ngFor="let state of states"  [value]="state.sigla">{{ state.sigla }}</option>
              </select>
            </div>

            <div class="form-group">
              <label for="city">Cidade</label>
              <select
                id="city" id="city" class="form-control" formControlName="city"
                [ngClass]="{
                  'is-invalid': profileForm.get('city')?.hasError('required') && profileForm.get('city')?.touched
                }">
                <option *ngFor="let city of cities"  [value]="city.nome">{{ city.nome }}</option>
              </select>
              <div class="invalid-feedback" *ngIf="profileForm.get('city')?.hasError('required')
                && profileForm.get('city')?.touched">
                É obrigatório o preenchimento deste campo!
              </div>
            </div>

            <button type="submit" class="btn btn-primary" [disabled]="!profileForm.valid">Salvar</button>
          </form>
        </div>
      </div>
    </div>
  </div> -->
</div>

