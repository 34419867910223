import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ValidateDatesService {
  constructor() {}

  public validatePeriods(
    startJustificationDate: any,
    endjustificationDate: any
  ): boolean {
    const startDate = moment(startJustificationDate);
    const endDate = moment(endjustificationDate);

    const isbefore = startDate.isBefore(endDate);
    const isSame = startDate.isSame(endDate);

    if (isSame) {
        return true;
    } else {
        return isbefore;
    }

  }

  public validateContractPeriod(startFP: any, endFP: any, startSP: any, endSP: any) {
    if (startFP.isBefore(endFP) && startSP.isBefore(endSP)) {
        return true
    } else {
        return false
    }
  }
}
