import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'absenceTypeTextConvert'
})
export class AbsenceTypeTextConvertPipe implements PipeTransform {

  transform(type: string): any {
    let result: string = '';

    if (type === 'MATERNITY') {
        result = 'Licença maternidade';
    } else if (type === 'PATERNITY') {
        result = 'Licença paternidade';
    } else if (type === 'ILLNESS') {
        result = 'Doença';
    } else if (type === 'DECEASE') {
        result = 'Falecimento'
    } else if (type === 'MARRIAGE') {
        result = 'Casamento'
    } else if (type === 'BLOOD_DONATION') {
        result = 'Doação de sangue'
    } else if (type === 'VOTER_REGISTRATION') {
        result = 'Alistamento como eleitor'
    } else if (type === 'MILITARY_SERVICE') {
        result = 'Serviço militar'
    } else if (type === 'COLLEGE_ENTRANCE_EXAM') {
        result = 'Vestibular'
    } else if (type === 'COURT_APPEARANCE') {
        result = 'Comparecimento em juízo'
    } else if (type === 'UNION_REPRESENTATION') {
        result = 'Representação sindical'
    } else if (type === 'PRENATAL_APPOINTMENTS') {
        result = 'Consultas médicas de Pré-Natal'
    } else if (type === 'CANCER_SCREENINGS') {
        result = 'Exames preventivos de câncer'
    } else {
        result = 'Acompanhamento de consulta médica do filho'
    }

    return result;
  }
}
