import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
    selector: 'app-file-input',
    templateUrl: './file-input.component.html',
    styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent {

    @ViewChild('fileInput') fileInput: ElementRef | undefined;
    @Input() allowedExtensions: string[] = [];
    @Output() fileSelected: EventEmitter<any> = new EventEmitter<any>();
    @Input() hasError = false;
    @Input() messageError = 'Dado(s) incorreto(s) no arquivo, por favor, revise-o e envie novamente.';

    fileName = '';
    fileSize = '';

    onFileSelected(event: Event): void {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            const file = input.files[0];
            this.fileName = file.name;
            this.fileSize = this.formatFileSize(file.size);
            this.fileSelected.emit({ fileName: this.fileName, file, fileSize: this.fileSize });
        }
    }

    private formatFileSize(size: number): string {
        const units = ['B', 'KB', 'MB', 'GB', 'TB'];
        let index = 0;
        while (size >= 1024 && index < units.length - 1) {
            size /= 1024;
            index++;
        }
        return `${size.toFixed(2)} ${units[index]}`;
    }

    clearFile(): void {
        if (this.fileInput) {
            this.fileName = '';
            this.fileSize = '';
            this.fileInput.nativeElement.value = '';
            this.hasError = false;
            this.messageError = 'Dado(s) incorreto(s) no arquivo, por favor, revise-o e envie novamente.';
        }
        this.fileSelected.emit(undefined);
    }

}
