import { NominatimResponse } from './../model/nominatim-response.model';
import { Bank } from '../model/banks';
import { StateBr } from '../model/statesbr';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CityBr } from 'src/model/citiesbr';
import {Observable} from 'rxjs';

const BASE_NOMINATIM_URL: string = 'nominatim.openstreetmap.org';
// const DEFAULT_VIEW_BOX: string = 'viewbox=-25.0000%2C70.0000%2C50.0000%2C40.0000';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(
    private http: HttpClient) {}

  public getStatesBr(): Observable<StateBr[]> {
    return this.http.get<StateBr[]>('assets/dados/estadosbr.json');
  }

  public getCitiesBr(idState: number): Observable<CityBr[]> {
    return this.http.get<CityBr[]>('assets/dados/cidadesbr.json')
      .pipe(
        map((cities: CityBr[]) => cities.filter(c => c.estado == idState))
      );
  }

  public getBanks(): Observable<Bank[]> {
    return this.http.get<Bank[]>('assets/dados/bancos.json');
  }

  public addressMapSearch(req?: any): Observable<NominatimResponse[]> {
    let url = `https://${BASE_NOMINATIM_URL}/search?city=${req}&format=json&limit=1`;
    return this.http.get<NominatimResponse[]>(url).pipe(
        map((data: any[]) => data.map((item: any) => new NominatimResponse(
            item.lat,
            item.lon,
            item.display_name
        )))
    )
  }
}
