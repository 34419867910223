import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppStorageService } from 'src/services/app-storage.service';

@Component({
    selector: 'app-chronomete',
    templateUrl: './chronomete.component.html',
    styleUrls: ['./chronomete.component.scss'],
})
export class ChronometeComponent implements OnInit {
    public hoursLabel = '0';
    public minutesLabel = '00';
    public secondsLabel = '00';

    @Input()
    public hours = 0;
    @Input()
    public minutes = 0;
    @Input()
    public seconds = 0;
    @Output() clock = new EventEmitter();
    private timeoutId: any = null;
    private running = false;

    constructor(
        private appStorageService: AppStorageService,
    ) {
        this.hoursLabel = ChronometeComponent.zeroPrefix(`${appStorageService.hours}`, 1);
        this.minutesLabel = ChronometeComponent.zeroPrefix(`${appStorageService.minutes}`, 2);
        this.secondsLabel = ChronometeComponent.zeroPrefix(`${appStorageService.seconds}`, 2);
    }

    private static zeroPrefix(num: any, digit: any) {
        let zero = '';
        for (let i = 0; i < digit; i++) {
          zero += '0';
        }
        return (zero + num).slice(-digit);
      }

    ngOnInit(): void {}

    public start() {
        if (this.running) {
          return;
        }
        this.timer();
        this.running = true;
      }

      public stop() {
        this.running = false;
        clearTimeout(this.timeoutId!);
      }

      private timer() {
        this.timeoutId = setTimeout(() => {
          this.updateTime(); // update Model
          this.timer();
        }, 1000);
      }

      private updateTime() {

        this.seconds++;
        if (this.seconds === 60) {
          this.seconds = 0;
          this.minutes++;
        }
        if (this.minutes === 60) {
          this.minutes = 0;
          this.hours++;
        }
        this.hoursLabel = ChronometeComponent.zeroPrefix(this.hours, 1);
        this.minutesLabel = ChronometeComponent.zeroPrefix(this.minutes, 2);
        this.secondsLabel = ChronometeComponent.zeroPrefix(this.seconds, 2);
        this.clock.emit({ hours: this.hours, minutes: this.minutes, seconds: this.seconds});
      }
}
