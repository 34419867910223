import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import HttpEmployeeResponse, { Employee } from 'src/model/employee';
import { EmployeeService } from 'src/services/employee.service';
import {MessageService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Contract } from 'src/model/contract';
import {AddressService} from 'src/services/address.service';
import {CityBr} from 'src/model/citiesbr';
import {StateBr} from 'src/model/statesbr';
import { AppStorageService } from 'src/services/app-storage.service';
import { NameLengthValidator } from '../app.validators';
import { Store } from '@ngrx/store';
import { EmployeeState } from 'src/stores/states/employee.state';
import * as EmployeeAction from '../../stores/actions/employee.action';

@Component({
  selector: 'app-editing-personal-data',
  templateUrl: './editing-personal-data.component.html',
  styleUrls: ['./editing-personal-data.component.scss'],
  providers: [MessageService, DialogService]
})
export class EditingPersonalDataComponent implements OnInit {

//   @Input() contract: Contract = new Contract;
  @Input() inputEmployee: Employee = new Employee;
  @Output() cancelUpdate = new EventEmitter<boolean>();
  @Output() update = new EventEmitter<Employee>();

  public formUpdate: FormGroup;
  public stateId ? = 0;
  public cities: CityBr[] = [];
  public states: StateBr[] = [];
  public employee?: Employee;
  public alertHeader = '';
  public alertDescription = '';
  public displayDialog = false;
  public editingPersonalData = false;
  private messageEmailExists = 'EmailExistsException';
  private messageUsernameExists = 'UsernameExistsException';
  private messagephoneExists = 'PhoneExistsException';


  constructor(
    private formBuilder: FormBuilder,
    private employeeService: EmployeeService,
    private messageService: MessageService,
    private appStorageService: AppStorageService,
    private http: HttpClient,
    private dropDownService: AddressService,
    private store: Store<EmployeeState>,
  ) {
    this.formUpdate = this.formBuilder.group({
        name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(80)]],
        email: ['', [Validators.required, Validators.email]],
        phone: ['', Validators.required],
        cnh: [''],
        registryId: ['', Validators.required],
        pisPasep: [''],
        zipcode: ['', Validators.required],
        street: ['', Validators.required],
        addressNumber: ['', [Validators.required, Validators.maxLength(10)]],
        complement: [''],
        district: ['', Validators.required],
        city: ['', Validators.required],
        state: ['', Validators.required],
        country: ['Brasil'],
    }, {validator: [NameLengthValidator]});
  }

  ngOnInit(): void {
    this.initializePage();
    this.dropDownService.getStatesBr().subscribe(dados => {
        this.states = dados;
    });
  }

  async initializePage() {
    await this.reloadEmployee();
    this.formUpdate.patchValue(this.employee!);
  }

  async verifyEmail(event: any): Promise<any> {
    const checkEmail = event.target.value;

    if (checkEmail) {
        try {
            const response: HttpEmployeeResponse | undefined = await this.employeeService.findByEmail(checkEmail).toPromise() || undefined;
            if (response?.data) {
                this.formUpdate.get('email')?.setValue('');
                this.messageService.add({
                    severity: 'warn',
                    summary: 'E-mail em uso',
                    detail: ''
                });
            }
        } catch (error) {
            this.formUpdate.get('email')?.setValue(checkEmail);
        }
    }
  }

  public async checkIfPhoneExists(event: any): Promise<void> {
    const checkPhone = event.target.value;
    const phoneFormat = checkPhone.match(/[^_\W]+/g).join('');

    if (checkPhone) {
        try {
            const response: HttpEmployeeResponse | undefined = await this.employeeService.findByPhone(phoneFormat).toPromise() || undefined;
            if (response?.data) {
                this.formUpdate.get('phone')?.setValue('');
                this.messageService.add({
                    severity: 'warn',
                    summary: 'Telefone em uso',
                    detail: ''
                });
                // this.openAlert('Telefone em uso', 'Informe outro número de telefone.');
            }
        } catch (error) {
            this.formUpdate.get('phone')?.setValue(checkPhone);
        }
    }
  }

  public openAlert(header: string, description: string): void {
    this.alertHeader = header;
    this.alertDescription = description;
    this.displayDialog = true;
  }

  public findZipcode(type: string): void {
    let cep = '';
    if (type === 'employee') {
        cep = this.formUpdate.get('zipcode')?.value;
    } else {
        cep = type || '';
    }

    if (cep !== '') {
        cep = cep.replace(/\D/g, '');
    }

    if (cep !== '') {
        const validaCep = /^[0-9]{8}$/;

        if (validaCep.test(cep)) {
            const options = {headers: {Anonymous: ''}};

            this.http.get(`https://viacep.com.br/ws/${cep}/json`, options)
                .pipe(
                    map((dados: any) => {
                        if (dados.erro === true) {
                            this.messageService.add({
                                severity: 'error',
                                summary: 'CEP inválido ou inexistente',
                                detail: 'Verifique o número do CEP novamentes'
                            });
                            this.formUpdate.get('zipcode')?.setValue('');
                        }

                        if (type === 'employee') {
                            this.populateAddressForm(dados);
                        }
                    })
                ).subscribe();
        }
    } else {
        if (type === 'employee') {
            this.resetEmployeeAddressForm();
        }
    }
  }

  public populateAddressForm(data: any): void {
    this.formUpdate.patchValue({
        street: data.logradouro,
        district: data.bairro,
        state: data.uf,
        city: data.localidade,
    });
  }

  public resetEmployeeAddressForm(): void {
    this.formUpdate.patchValue({
        street: null,
        addressNumber: null,
        district: null,
        complement: null,
        city: null,
        state: null,
    });
  }

  public async onSave(): Promise<void> {
      try {
        const employeeId = this.employee?.id;
        const employee = { ...this.employee, ...this.formUpdate.value };
        await this.employeeService.update(employeeId, employee).toPromise();

        this.messageService.add({
            severity: 'success',
            summary: 'Funcionário atualizado',
            detail: ''
        });

        this.update.emit(employee);
        this.store.dispatch(EmployeeAction.clearEmployeeStateAction());

        setTimeout(() => {
            this.onCancel();
        }, 2000)

      } catch (error: any) {
        if (error.code === this.messageEmailExists) {
            this.messageService.add({
                severity: 'error',
                summary: 'Problema no registro',
                detail: 'E-mail já está em uso'
            });
        } else if (error.code === this.messagephoneExists) {
            this.messageService.add({
                severity: 'error',
                summary: 'Problema no registro',
                detail: 'Número telefonico já está em uso'
            });
        } else if (error.code === this.messageUsernameExists) {
            this.messageService.add({
                severity: 'error',
                summary: 'Problema no registro',
                detail: 'Usuário já cadastrado'
            });
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Problema no registro',
                detail: 'Verifique as informações fornecidas, ou se o problema persistir contacte a Alphatime'
            });
        }
      }
  }

  public onCancel() {
    this.editingPersonalData = false;
    this.reloadEmployee();
  }


  public onStateChange(value: string): void {
    this.dropDownService.getStatesBr()
        .subscribe(states => {

            const findState = states.find(state => {
                return state.sigla === value;
            });

            this.stateId = findState?.id;

            this.dropDownService.getCitiesBr(Number(this.stateId)).subscribe((cities: any) => {
                this.cities = cities;
            });
        });
  }

  public onEditingPersonalData(): void {
    this.editingPersonalData = true;
  }

  private async reloadEmployee() {
    const employeeEmail = this.inputEmployee?.email;
    const reloadContract = await this.employeeService.findByEmail(employeeEmail!).toPromise();
    const employeeStorage = await this.appStorageService.getEmployee();

    if ( reloadContract?.data!.email === employeeStorage.email ) {
        await this.appStorageService.setEmployee(reloadContract?.data);
    }

    this.employee = Object.assign(reloadContract?.data!);
  }
}
