import {EventEmitter, Injectable} from '@angular/core';


export class EventEmitterService {

    public static COMPANY_EVENT_UPDATED = 'COMPANY_EVENT_UPDATED';
    public static HEAD_OFFICE_EVENT_CHECKED = 'HEADOFFICE_EVENT_CHECKED';
    public static DEPARTMENT_EVENT_SAVE = 'DEPARTMENT_EVENT_SAVE';
    public static EMPLOYEE_CHANNEL = 'EMPLOYEE_CHANNEL';
    public static COMPANY_CHANNEL = 'COMPANY_CHANNEL';

    private static emitters: {
        [eventName: string]: EventEmitter<any>
    } = {};

    static get(eventName: string): EventEmitter<any> {
        if (!this.emitters[eventName]) {
            this.emitters[eventName] = new EventEmitter<any>();
        }
        return this.emitters[eventName];
    }
}
