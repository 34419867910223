import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {AuthService} from '../services/auth.service';
import {PUBLIC_URL} from '../environments/environment';

@Injectable({providedIn: 'root'})
export class AppAuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService) {
    }

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        try {
            await this.authService.getCurrentAuthenticatedUser();
            return true;
        } catch (e: any) {
            await this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
            return false;
        }
    }
}
