import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import Chart from 'chart.js/auto';
import * as moment from 'moment';
import { forkJoin, map } from 'rxjs';
import JourneyHelp from 'src/app/shared/journey-helper';
import { Employee, HttpEmployeesResponse } from 'src/model/employee';
import HttpRegistersClockResponse, { RegisterClock } from 'src/model/register-clock';
import { AppStorageService } from 'src/services/app-storage.service';
import { EmployeeService } from 'src/services/employee.service';
import { RegisterClockService } from 'src/services/register-clock.service';

@Component({
  selector: 'app-register-clock-by-week-chart',
  templateUrl: './register-clock-by-week-chart.component.html',
  styleUrls: ['./register-clock-by-week-chart.component.scss']
})
export class RegisterClockByWeekChartComponent implements OnInit {

    public employee: Employee | undefined;
    public groupRecordClocks!: any;
    public weekChart: any;
    public chartData: any;
    public startDate = '';
    public endDate = '';
    public startDateWeek = '';
    public currentWeek = 0;
    public endDateWeek = '';
    public firstDatesOfWeeks: string[] = [];
    public chartDataLabels: any[] = [];
    public showWeekBarChart = false;
    public showEmptWeekBarChart = false;
    public loadingWeekChart!: boolean;
    public selectMonthForm!: FormGroup;
    public maxSelectableDate!: Date;
    public initialDate!: Date;
    private clockRegisters: RegisterClock[] = [];
    public selectedWeek: number = 0;
    private clockResponse: any;
    private currentDate = moment().format('YYYY-MM-DD');

  constructor(
    private appStorageService: AppStorageService,
    private employeeService: EmployeeService,
    private registerClockService: RegisterClockService,
    private fb: FormBuilder,
  ) {
    this.initialDate = new Date();
    const today = new Date();
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    this.maxSelectableDate = lastDayOfMonth;
    this.selectMonthForm = this.fb.group({
        selectMonth: [''],
    });
  }

  async ngOnInit(): Promise<void> {
    this.startDateWeek = moment().clone().startOf('month').toISOString();
    this.endDateWeek = moment().clone().endOf('month').toISOString();
    this.startDate = moment().clone().startOf('month').subtract(1, 'day').toISOString();
    this.endDate = moment().clone().endOf('month').toISOString();

    const weekNumber = this.getCurrentWeekOfMonth(this.currentDate);
    this.currentWeek = weekNumber;

    await this.initialFetchData();

    const simulatedEvent = { target: { value: this.currentWeek } };
    await this.getSelectedWeek(simulatedEvent);
  }

  async initialFetchData(): Promise<void> {
    await this.fetchDataByMonth();
    this.showWeekBarChart = false;
    this.showEmptWeekBarChart = false

    if (this.chartData) {
        if (this.chartData.labels.length === 0) {
            this.showWeekBarChart = false;
            this.showEmptWeekBarChart = true
            this.loadingWeekChart = false;
        } else {
            this.showWeekBarChart = true;
            this.showEmptWeekBarChart = false
        }

        this.groupRecordClocks = this.chartData;
        await this.fechData();
        const chartStatus = Chart.getChart('BarWeekChart');
        if (chartStatus != undefined) this.weekChart.destroy();
        this.renderClocksByDayChart();
    }
  }

  async fechData(): Promise<void> {
    if (this.groupRecordClocks !== null) {
        const dataMoment = moment(this.startDateWeek);
        // const dataMoment = moment(this.startDate);
        const arrayAnoMes = [dataMoment.year(), dataMoment.month() + 1];
        const finalWeek = this.combinedMethod(arrayAnoMes[0], arrayAnoMes[1]);
        const removeDuplicatas = finalWeek.filter((valor, indice, self) => {
          return self.indexOf(valor) === indice;
        });

        const allWeeks = this.getWeeksFromLabels(this.groupRecordClocks.labels);
        const targetWeek = allWeeks[this.selectedWeek];
        const result = await this.filterRecordsByWeek(this.groupRecordClocks, targetWeek);

        this.firstDatesOfWeeks = removeDuplicatas;
        this.chartData = await result;
    }
  }

  getCurrentWeekOfMonth(data: any): number {
    const inicioDoMes = moment(data).startOf('month');
    const inicioDaSemana = inicioDoMes.startOf('week');
    const atual = moment(data).startOf('day');

    const diff = atual.diff(inicioDaSemana, 'weeks');
    
    return diff;
  }

  combinedMethod(year: any, month: any) {
    const firstDay = moment(`${year}-${month}-01`);
    const firstDayOfWeek = firstDay.day();

    const firstDaysArray = [];
    const result = [];

    let currentDay = 1 - firstDayOfWeek;
    let currentWeek = 1;

    while (currentDay <= moment(`${year}-${month}`, 'YYYY-MM').daysInMonth()) {
        if (currentDay > 0) {
            const formattedDate = moment(`${year}-${month}-${currentDay}`).format('DD/MM');
            firstDaysArray.push(formattedDate);
        }

        result.push(firstDay.format('DD/MM'));
        firstDay.add(7, 'days');

        currentDay += 7;
        currentWeek++;
    }

    if (firstDaysArray.length >= 4) {
        firstDaysArray.unshift(result[0]);
        return firstDaysArray;
    }

    return result;
  }

  private filterRecordsByWeek(chartData: any, targetWeek: number): any {
    const filteredLabels = chartData.labels.filter((label: any) => {
        const week = moment(label, "DD/MM").isoWeek();
        return week === targetWeek;
      });

      const filteredDatasets = chartData.datasets.map((dataset: any) => {
        return {
          ...dataset,
          data: dataset.data.filter((_: any, index: any) => {
            const label = chartData.labels[index];
            const week = moment(label, "DD/MM").isoWeek();
            return week === targetWeek;
          }),
        };
      });

      return {
        labels: filteredLabels,
        datasets: filteredDatasets,
      };
  }

  private getWeeksFromLabels(labels: string[]): number[] {
    const weeksSet = new Set<number>();
    labels.forEach(label => {
      const week = moment(label, "DD/MM").isoWeek();
      weeksSet.add(week);
    });
    return Array.from(weeksSet);
  }

  private getFirstDatesOfWeeks(labels: string[]): string[] {
    const firstDates: string[] = [];
    let currentWeek = -1;

    labels.forEach(label => {
      const week = moment(label, "DD/MM").isoWeek();

      if (week !== currentWeek) {
        firstDates.push(label);
        currentWeek = week;
      }
    });

    return firstDates;
  }

  public async getSelectedWeek(event: any) {
    const value = (event.target as HTMLSelectElement)?.value;
    const chartStatus = Chart.getChart('BarWeekChart');

    this.selectedWeek = +value ?? '';

    await this.fechData();
    if (chartStatus != undefined) this.weekChart.destroy();
    await this.renderClocksByDayChart();
  }

  async fetchDataByMonth(): Promise<void> {
    this.employee = await this.appStorageService.getEmployee();

    const startDate = this.startDate;
    const endDate = this.endDate;

    await forkJoin(
        this.registerClockService.listByCompany(this.employee.companyId as string, startDate, endDate),
        this.employeeService.findAllByCompany(this.employee.companyId!)).pipe(
            map((response: any) => {
                const httpClockResponse: HttpRegistersClockResponse = response[0];
                const httpEmployeeResponse: HttpEmployeesResponse = response[1];

                httpClockResponse.data!.map((clock) => {
                    clock.employee = httpEmployeeResponse.data.find((el) => el.id === clock.employeeId)
                })
                this.clockResponse = httpClockResponse
            })
        ).toPromise();

    this.clockRegisters = this.clockResponse.data.filter((clocks: any) => {
        return clocks.status !== 'INVALID' && clocks.type === 'E' || clocks.type === 'F';
    });

    // Grouping the records by "employeeId" and "recordDate".
    const groupedRecords: { [key: string]: any[] } = {};

    this.clockRegisters.forEach((record: any) => {
        const journeyDay = moment(record.recordDateTime, "YYYY-MM-DD HH:mm:ss").isoWeekday();
        const key = `${record.employeeId}-${record.recordDate}`;
        if (!groupedRecords[key]) {
          groupedRecords[key] = [];
        }

        const currentContractValue = JSON.parse(record.currentContract);
        const journeyJson: any =  JourneyHelp.getJourneyJson(currentContractValue.journey);
        const startTimeFirstPeriod = moment(journeyJson[journeyDay][0], 'HH:mm');
        const endTimeFirstPeriod = moment(journeyJson[journeyDay][1], 'HH:mm');
        const formattedStartTimeFirstPeriod = startTimeFirstPeriod.format('HH:mm');
        const formattedEndTimeFirstPeriod = endTimeFirstPeriod.format('HH:mm');

        groupedRecords[key].push({
            id: record.id,
            companyId: record.companyId,
            contractId: record.contractId,
            employeeId: record.employee.id,
            name: record.employee.name,
            startTimeFirstPeriod: formattedStartTimeFirstPeriod,
            endTimeFirstPeriod: formattedEndTimeFirstPeriod,
            recordTime: record.recordTime,
            recordDate: record.recordDate,
            type: record.type,
        });
    });

    // Sorting each group by "recordTime".
    const sortedRecords: RegisterClock[][] = Object.values(groupedRecords).map(recordsGroup => {
        return recordsGroup.sort((a, b) => a.recordTime!.localeCompare(b.recordTime!));
    });

    const groupedAndCheckedRecords: any[] = this.groupAndCheckRecords(sortedRecords);

    Object.entries(groupedAndCheckedRecords).forEach(([recordDate, data]) => {
      this.chartDataLabels.push(moment(recordDate).format('DD/MM'));
    });

    this.chartData = this.createChartData(groupedAndCheckedRecords);
  }

  groupAndCheckRecords(sortedRecords: any[][]): any {
    const groupedRecords: any = {};

    sortedRecords.forEach(recordsGroup => {
      recordsGroup.forEach(record => {
        const { recordDate, employeeId } = record;

        if (!groupedRecords[recordDate]) {
          groupedRecords[recordDate] = {
            employees: new Set(),
            onTimeEmployees: new Set(),
            lateEmployees: new Set(),
            missingEmployees: new Set(),
          };
        }

        groupedRecords[recordDate].employees.add(employeeId);

        const { startTimeFirstPeriod, endTimeFirstPeriod, recordTime } = record;
        let isOnTime: boolean;

        const startTimeMoment = moment(startTimeFirstPeriod, 'HH:mm:');
        const endTimeFirstMoment = moment(endTimeFirstPeriod, 'HH:mm');
        const recordTimeMoment = moment(recordTime, 'HH:mm:ss');

        if (recordTime === '00:00:00') {
            groupedRecords[recordDate].missingEmployees.add(employeeId);
        }

        if (recordTimeMoment.isBetween(startTimeMoment, endTimeFirstMoment) || (recordTimeMoment.isBefore(startTimeMoment) && recordTime !== '00:00:00') || recordTime === '08:00:00') {
            isOnTime = this.isWithinTolerance(startTimeFirstPeriod, recordTime);

            if (isOnTime) {
                groupedRecords[recordDate].onTimeEmployees.add(employeeId);
            } else {
                groupedRecords[recordDate].lateEmployees.add(employeeId);
            }
        }
      });
    });

    return groupedRecords;
  }

  createChartData(groupedAndCheckedRecords: any) {
    const labels: string[] = [];
    const datasets: any[] = [];

    const labelsSet = new Set<string>();

    // Extract all the dates from the groupedAndCheckedRecords and store them in a set.
    Object.keys(groupedAndCheckedRecords).forEach(recordDate => {
        labelsSet.add(recordDate);
    });

    // Sort the dates in the set.
    const sortedLabels = Array.from(labelsSet).sort((a, b) => a.localeCompare(b));

    // Create the array "labels" with the sorted dates.
    labels.push(...sortedLabels.map(label => moment(label).format('DD/MM')));

    // Create the "datasets" array with the formatted data.
    const labelsMap: { [recordDate: string]: number } = {};

    sortedLabels.forEach((label, index) => {
        labelsMap[label] = index;
    });

    datasets.push(
        {
            label: 'Bateu ponto corretamente',
            backgroundColor: '#7BD261',
            data: sortedLabels.map(label => groupedAndCheckedRecords[label]?.onTimeEmployees?.size || 0),
        },
        {
            label: 'Bateu ponto incorretamente',
            backgroundColor: '#DBDBDB',
            data: sortedLabels.map(label => groupedAndCheckedRecords[label]?.lateEmployees?.size || 0),
        },
        {
            label: 'Não bateu o ponto',
            backgroundColor: '#EB6969',
            data: sortedLabels.map(label => groupedAndCheckedRecords[label]?.missingEmployees?.size || 0),
        }
    );

    return { labels, datasets };
  }

  isWithinTolerance(time1: string, time2: string): boolean {
    const diff = moment(time1, 'HH:mm:ss').diff(moment(time2, 'HH:mm:ss'), 'minutes');
    const withinTolerance = diff >= -10 && diff <= 10;
    return withinTolerance;
  }

  async getSelectedMonth(event: any): Promise<void> {
    this.selectedWeek = 0;
    this.currentWeek = 0;
    const data = moment(event, 'DD/MM/YYYY').startOf('hour').format('YYYY-MM-DD 00:00:00');
    const dataMoment = moment(data, 'YYYY-MM-DD HH:mm:ss');

    this.startDateWeek = dataMoment.clone().startOf('month').toISOString();
    this.endDateWeek = dataMoment.clone().endOf('month').toISOString();
    this.startDate = dataMoment.clone().startOf('month').subtract(1, 'day').toISOString();
    this.endDate = dataMoment.clone().endOf('month').toISOString();

    this.weekChart.destroy();
    await this.initialFetchData()
  }

  public async renderClocksByDayChart() {
    this.weekChart = new Chart('BarWeekChart', {
        type: 'bar',
        data: {
          labels: this.chartData.labels,
          datasets: [
            {
              label: this.chartData.datasets[0].label,
              backgroundColor: '#7BD261',
              data: this.chartData.datasets[0].data,
            },
            {
              label: this.chartData.datasets[1].label,
              backgroundColor: '#DBDBDB',
              data: this.chartData.datasets[1].data,
            },
            {
              label: this.chartData.datasets[2].label,
              backgroundColor: '#EB6969',
              data: this.chartData.datasets[2].data,
            }
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              display: false,
              stacked: true,
              beginAtZero: true
            }
          },
          plugins: {
            legend: {
              position: 'bottom'
            }
          }
        }
    });
  }

}
