import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AppStorageService} from './app-storage.service';
import {PUBLIC_URL} from '../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ProfileGuardService implements CanActivate {

    constructor(
        private appStorageService: AppStorageService,
        private router: Router
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.getContract();
    }

    public async getContract(): Promise<boolean> {

        const contract = await this.appStorageService.getCurrentContract();

        if (contract?.role === 'MANAGER' || contract?.role === 'DEPARTAMENT_MANAGER') {
            return true;
        } else {
            await this.router.navigate(['/register-time']);
            return false;
        }
    }
}
