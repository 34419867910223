import {createAction, props} from '@ngrx/store';
import {Contract} from '../../model/contract';

export enum ContractActionTypes {

    LOAD_CONTRACT_REQUEST = '[CONTRACT] Load CONTRACT Request',
    LOAD_CONTRACT_FAILURE = '[CONTRACT] Load CONTRACT Failure',
    LOAD_CONTRACT_SUCCESS = '[CONTRACT] Load CONTRACT Success',
    CLEAN_CONTRACT_STATE = '[CONTRACT] Clear CONTRACT state',
}

/**
 * Actions for Load Data
 */
export const loadContractRequestAction = createAction(
    ContractActionTypes.LOAD_CONTRACT_REQUEST,
    props<{ id: string }>()
);

export const loadContractSuccessAction = createAction(
    ContractActionTypes.LOAD_CONTRACT_SUCCESS,
    props<{ contracts: Contract[] }>()
);

export const loadContractFailureAction = createAction(
    ContractActionTypes.LOAD_CONTRACT_FAILURE,
    props<{ error: string }>()
);

export const clearContractStateAction = createAction(
    ContractActionTypes.CLEAN_CONTRACT_STATE,
);
