<p-toast></p-toast>

<form [formGroup]="form" class="m-3">
    <div class="row">
        <div class="col">
            <p>Motivo</p>
            <select
                id="typeAbsence" class="form-control"
                formControlName="typeAbsence">
                <option [disabled]="onAbsence" value="">Selecione um motivo</option>
                <option *ngFor="let type of absenceTypes" [disabled]="onAbsence" [value]="type">{{ type | absenceTypeTextConvert }}</option>
            </select>
        </div>
    </div>
    <div class="row align-items-end">
        <div class="col-3">
            <div class="calendar-container">
                <p>Início do afastamento</p>
                <p-calendar [style]="{'width':'100%'}"
                    #startDate
                    [disabled]="onAbsence"
                    [inputStyle]="{'width':'100%'}" [showIcon]="false" dataType="string" placeholder="Selecione uma data"
                    dateFormat="dd/mm/yy"
                    formControlName="startDateInput" inputStyleClass="form-control">
                </p-calendar>
            </div>
        </div>

        <div class="col-3">
            <div class="calendar-container">
                <p>Fim do afastamento</p>
                <p-calendar [style]="{'width':'100%'}"
                    [minDate]="startDate.value"
                    [disabled]="onAbsence"
                    [inputStyle]="{'width':'100%'}" [showIcon]="false" dataType="string" placeholder="Selecione uma data"
                    dateFormat="dd/mm/yy" formControlName="endDateInput" inputStyleClass="form-control">
                </p-calendar>
            </div>
        </div>

        <div class="col-6">
            <div class="d-flex justify-content-start btn-container">
                <button class="btn btn-primary w-50 mr-3" [style.display]="btnProgramar" [disabled]="onAbsence" (click)="onProgrammerAbsence()">
                    Programar
                </button>

                <button class="btn btn-primary w-50 mr-3" [style.display]="btnReprogramar" [disabled]="onAbsence" (click)="onUpdate()">
                    Editar
                </button>

                <button class="btn btn-link w-50 text-danger" [style.display]="btnExcluir" [disabled]="onAbsence" (click)="onDelete()">
                    Cancelar Afastamento
                </button>
            </div>
        </div>
      </div>
</form>
