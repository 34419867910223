import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Company } from 'src/model/company';
import { HttpContractsResponse } from 'src/model/contract';
import { Employee } from 'src/model/employee';
import { AppStorageService } from 'src/services/app-storage.service';
import { CompanyPositionService } from 'src/services/company-position.service';
import { ContractService } from 'src/services/contract.service';
import { CompanyPosition } from './../../model/company-position';

@Component({
  selector: 'app-company-position-create',
  templateUrl: './company-position-create.component.html',
  styleUrls: ['./company-position-create.component.scss']
})
export class CompanyPositionCreateComponent implements OnInit {

  public companyPosition: CompanyPosition;
  public company?: Company;
  public formRegister: FormGroup;
  public formTitle = '';
  public showSavedMessage = false;
  private employee: Employee | undefined;
  private saveMessage = [ {severity: 'success', summary: 'Cargo cadastrado!'} ]
  private updateMessage = [ {severity: 'success', summary: 'Cargo atualizado!'} ]
  private cancelMessage = [ {severity: 'info', summary: 'Cadastro cancelado!'} ]

  constructor(
    private appStorageService: AppStorageService,
    private contractService: ContractService,
    private companyPositionService: CompanyPositionService,
    private formBuilder: FormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {
    this.formRegister = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(120)]],
    });

    this.companyPosition = config.data.values;
    this.formRegister.patchValue(this.companyPosition);
  }

  ngOnInit(): void {
    this.initializePage();
    if (this.companyPosition.id) {
      this.formTitle = 'Editar Cargo';
    } else {
      this.formTitle = 'Novo Cargo';
    }
  }

  async initializePage() {
    this.employee = await this.appStorageService.getEmployee();
  }

  public async onSave() {
    if (!this.companyPosition.id) {
      const message = this.saveMessage;

      const { name } = this.formRegister.value
      const companyPosition = { name, companyId: this.employee?.companyId };

      try {
        await this.companyPositionService.save(companyPosition as CompanyPosition).toPromise();
        this.formRegister.get('name')?.setValue('');
        this.ref.close(message);
      } catch (error) {
        console.log('Error: ', error);
      }
    } else {
      const message = this.updateMessage;

      const { name } = this.formRegister.value
      const companyPosition = { name, companyId: this.employee?.companyId };

      try {
        await this.companyPositionService.update(companyPosition as CompanyPosition, this.companyPosition.id).toPromise();
        this.ref.close(message);
      } catch (error) {
        console.log('Error: ', error);
      }
    }
  }

  onClose() {
    const message = this.cancelMessage;
    this.ref.close(message);
  }

}
