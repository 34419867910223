import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Contract } from 'src/model/contract';
import { Employee } from 'src/model/employee';
import { AppStorageService } from 'src/services/app-storage.service';
import { ContractService } from 'src/services/contract.service';
import { RegisterClockService } from 'src/services/register-clock.service';
import JourneyHelp from '../shared/journey-helper';
@Component({
    selector: 'app-time-management',
    templateUrl: './time-management.component.html',
    styleUrls: ['./time-management.component.scss'],
    providers: [MessageService]
})
export class TimeManagementComponent implements OnInit {
    public inCreaseForm: FormGroup;
    public decreaseForm: FormGroup;
    public clearForm: FormGroup;
    public contract: Contract | undefined;
    public employee!: Employee;
    public clock: any | undefined;
    public option?: string;
    public alertHeader = '';
    public hourAmount = '';
    public displayIncreaseDialog = false;
    public displayDecreaseDialog = false;
    public displayClearDialog = false;
    public todayAmountOfHours = 0;
    public enabledUseHour = false;
    private format = 'hh:mm';
    public picture = './assets/icon/Avatar/user.png';

    constructor(
        private formBuilder: FormBuilder,
        private contractService: ContractService,
        private messageService: MessageService,
        private registerClockService: RegisterClockService,
        private appStorageService: AppStorageService,
        private router: Router,
    ) {
        this.inCreaseForm = this.formBuilder.group({
            inputIncreaseHour: ['', Validators.required],
            inputIncreaseMessage: ['', [Validators.required, Validators.maxLength(120)]],
        })
        this.decreaseForm = this.formBuilder.group({
            inputDecreaseHour: ['', Validators.required],
            inputDecreaseMessage: ['', [Validators.required, Validators.maxLength(120)]],
        })
        this.clearForm = this.formBuilder.group({
            inputClearMessage: ['', [Validators.required, Validators.maxLength(120)]],
        })

        if (this.router.getCurrentNavigation()?.extras.state) {
            this.clock = this.router.getCurrentNavigation()?.extras.state?.clock;
            this.employee = this.router.getCurrentNavigation()?.extras.state?.employee;
        } else {
            this.router.navigate(['/comp-time']);
        }
    }

    async ngOnInit(): Promise<void> {
        const response = await this.contractService.getContractsByEmployee(this.employee.id).toPromise();
        this.contract = Object.assign(response!.data[0]);

        await this.todayAmountHours(this.employee.id!);
        this.amountOfHours();
        this.enabledUseHour = this.amountOfHours();;
    }

    public openAlert(type: string): void {
        switch (type) {
            case 'addHour':
                this.alertHeader = 'Adicionar horas';
                this.displayIncreaseDialog = true;
                this.displayDecreaseDialog = false;
                this.displayClearDialog = false;
                break;
            case 'useHour':
                if (this.amountOfHours()) {
                    this.messageService.add({
                        life: 5000,
                        severity:'warn',
                        summary:'Saldo negativo!',
                        detail: 'Só é possível usar horas de saldo positivo.'
                    });
                } else {
                    const hour = moment(this.hourAmount.substring(1), this.format).format('hh:mm');
                    this.decreaseForm.get('inputDecreaseHour')?.setValue(hour);
                    this.alertHeader = 'Utilizar horas';
                    this.displayDecreaseDialog = true;
                    this.displayIncreaseDialog = false;
                    this.displayClearDialog = false;
                }
                break;
            case 'cleanHour':
                this.alertHeader = 'Zerar horas';
                this.displayClearDialog = true;
                this.displayDecreaseDialog = false;
                this.displayIncreaseDialog = false;
                break;
            default:
                break;
        }
    }

    public closeAlert(type: string): void {
        this.alertHeader = '';

        switch (type) {
            case 'addHour':
                this.displayIncreaseDialog = false;
                this.inCreaseForm.reset();
                this.ngOnInit()
                break;
            case 'useHour':
                this.displayDecreaseDialog = false;
                this.decreaseForm.reset();
                this.ngOnInit()
                break;
            case 'cleanHour':
                this.displayClearDialog = false;
                this.clearForm.reset();
                this.ngOnInit()
                break;
            default:
                break;
        }
    }

    public async onIncreaseHour() {
        const timeIncreaseSetting = {
            contractId: this.contract?.id,
            employeeId: this.contract?.id,
            companyId: this.contract?.companyId,
            departmentId: this.contract?.departmentId,
            path: this.contract?.path,
            message: this.inCreaseForm.get('inputIncreaseMessage')?.value,
            value: moment.duration(this.inCreaseForm.get('inputIncreaseHour')?.value).asMinutes(),
            type: 'INCREASE'
        }

        this.contractService.putContractTimeSetting(timeIncreaseSetting, this.contract?.id).subscribe(res => {
            this.closeAlert('addHour');
        }, (err) => {
            console.log('Error :::: ', err);
        });
    }

    public async onDecreaseHour() {
        const timeDecreaseSetting = {
            contractId: this.contract?.id,
            employeeId: this.contract?.id,
            companyId: this.contract?.companyId,
            departmentId: this.contract?.departmentId,
            path: this.contract?.path,
            message: this.decreaseForm.get('inputDecreaseMessage')?.value,
            value: moment.duration(this.decreaseForm.get('inputDecreaseHour')?.value).asMinutes(),
            type: 'DECREASE'
        }

        this.contractService.putContractTimeSetting(timeDecreaseSetting, this.contract?.id).subscribe(res => {
            this.closeAlert('useHour');
        }, (err) => {
            console.log('Error :::: ', err);
        });
    }

    public async onClearHour() {
        const timeClearSetting = {
            contractId: this.contract?.id,
            employeeId: this.contract?.id,
            companyId: this.contract?.companyId,
            departmentId: this.contract?.departmentId,
            path: this.contract?.path,
            message: this.clearForm.get('inputClearMessage')?.value,
            value: 0,
            type: 'CLEAR'
        }

        this.contractService.putContractTimeSetting(timeClearSetting, this.contract?.id).subscribe(res => {
            this.closeAlert('cleanHour');
        }, (err) => {
            console.log('Error :::: ', err);
        });
    }

    public amountOfHours(): boolean {
        const amount = this.contract?.amountOfHours! + this.todayAmountOfHours;
        const hour = Math.floor(Math.abs(amount) / 60);
        const minutes = Math.floor(Math.abs(amount) % 60);

        const amountResult = `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        if(amount! >= 0) {
            this.hourAmount = `+${amountResult}`;
            return false;
        } else {
            this.hourAmount = `-${amountResult}`;
            return true;
        }
    }

    public async todayAmountHours(employeeId: string) {
        const startDate = moment().clone().startOf('days').subtract(1, 'days').toISOString();
        const endDate   = moment().clone().endOf('days').toISOString();

        const response = await this.registerClockService
            .getClockRegistersByEmployeeAndDates(employeeId, startDate, endDate)
            .toPromise();

        const contractParam: any = response?.data.filter(clock => clock.accounted !== true);

        if (contractParam.length > 0) {
            const journeyDay = moment(contractParam[0].recordDateTime, "YYYY-MM-DD HH:mm:ss").isoWeekday();
            const journeyJson: any =  JourneyHelp.getJourneyJson(JSON.parse(contractParam[0].currentContract).journey);

            const stfp = moment(journeyJson[journeyDay][0], 'HH:mm')
            const etfp = moment(journeyJson[journeyDay][1], 'HH:mm')
            const stsp = moment(journeyJson[journeyDay][2], 'HH:mm')
            const etsp = moment(journeyJson[journeyDay][3], 'HH:mm')

            const firstPeriod = etfp.diff(stfp, 'minutes');
            const secondPeriod = etsp.diff(stsp, 'minutes');
            const cargaHorariaDoDia = firstPeriod + secondPeriod;

            let total = 0;

            contractParam!.forEach((el: any, idx: number) => {
                if (idx % 2 != 0) {
                    const prev = moment((contractParam![idx - 1] as any).recordDateTime, 'YYYY-MM-DD HH:mm');
                    const curr = moment(el.recordDateTime, 'YYYY-MM-DD HH:mm');

                    const duration = moment.duration(curr.diff(prev));
                    total = total + (duration.asMinutes());
                }
            });

            let value = 0;

            if (total > 0) {
                if (total < cargaHorariaDoDia) {
                    value = total - cargaHorariaDoDia;
                } else {
                    value = total - cargaHorariaDoDia;
                }
            }
            this.todayAmountOfHours = value;
        } else {
            this.todayAmountOfHours = 0;
        }
    }
}
