import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import HttpCompanyResponse, { Company } from 'src/model/company';
import { Employee } from 'src/model/employee';
import { AppStorageService } from 'src/services/app-storage.service';
import { CompanyService } from 'src/services/company.service';

@Component({
    selector: 'app-select-company',
    templateUrl: './select-company.component.html',
    styleUrls: ['./select-company.component.scss'],
})
export class SelectCompanyComponent implements OnInit {

    @Output() selectEvent = new EventEmitter();

    public formSelect: FormGroup;
    public companies: Company[] = [];
    public company: Company | undefined;
    public employee: Employee | undefined;
    public companyList: any[] = [];
    public branchList: any[] = [];
    public departmentList: any[] = [];

    constructor(
        private appStorageService: AppStorageService,
        private formBuilder: FormBuilder,
        private companyService: CompanyService,
    ) {
        this.formSelect = this.formBuilder.group({
            companyId: ['', Validators.required],
            branchId: [''],
            path: [''],
        });
    }

    async ngOnInit(): Promise<void> {
        await this.initializePage();
    }

    async initializePage(): Promise<void> {
        this.employee = await this.appStorageService.getEmployee();
        this.companyService.get(this.employee.companyId!).subscribe((companyEG: any) => {
            this.company = companyEG.data
        });
        const httpCompanyEconomicGroupResponse = await this.companyService.getBranches(
            this.employee.companyId!, 'ASSOCIATED_COMPANY'
        ).toPromise();

        if(httpCompanyEconomicGroupResponse?.data) {
            const response: Company[] = [];
            response.push(httpCompanyEconomicGroupResponse.data)
            response.map((companiesGroup: any) => {
                const result = companiesGroup.Items;
                result.map((companyGroup: any) => {
                    this.companyList.push(companyGroup);
                })
            })
        }
    }

    async onChange(companyId: string) {
        this.branchList = [];
        this.formSelect.get('path')?.setValue('');
        const response: Company[] = [];
        const getBranchs = await this.companyService.getBranches(companyId, 'BRANCH').toPromise();

        if (getBranchs?.data) {
            response.push(getBranchs.data)
            response.map((companiesGroupBranch: any) => {
                const result = companiesGroupBranch.Items;
                result.map((companyGroupBranch: any, index: any) => {
                    if (result.length > 0 && result[index].status !== 'INACTIVE') {
                        this.branchList.push(companyGroupBranch);
                    }
                })
            })
        } else {
            this.branchList = [];
        }

        const conpanyIdValue = this.formSelect.get('companyId')!.value

        if (this.company?.id === conpanyIdValue) {
            this.formSelect.get('path')?.setValue(companyId)
        } else {
            const conpanyIdresult: any = this.companyList.filter((company: Company) => {
                return company.id === conpanyIdValue
            });
            this.formSelect.get('path')?.setValue(conpanyIdresult[0].path)
        }
        this.selectEvent.emit(this.formSelect.value);
    }

    public async onSelectBranch() {
        const conpanyIdValue = this.formSelect.get('branchId')!.value
        if (conpanyIdValue !== '') {
            const conpanyIdresult: any = this.branchList.filter((company: Company) => {
                return company.id === conpanyIdValue
            });
            this.formSelect.get('path')?.setValue(conpanyIdresult[0].path)
            this.selectEvent.emit(this.formSelect.value);
        } else {
            await this.onChange(this.formSelect.get('companyId')!.value)
        }
    }
}
