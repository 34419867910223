import {Component, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AuthService} from 'src/services/auth.service';
import {StateBr} from 'src/model/statesbr';
import {CityBr} from 'src/model/citiesbr';
import {AddressService} from 'src/services/address.service';
import {EmployeeService} from 'src/services/employee.service';
import {NameLengthValidator, RegisterIdValidator} from '../app.validators';
import * as moment from 'moment';
import {AppStorageService} from 'src/services/app-storage.service';
import {ContractService} from 'src/services/contract.service';
import {Contract} from 'src/model/contract';
import HttpEmployeeResponse, {Employee} from '../../model/employee';
import {Bank} from 'src/model/banks';
import {RxwebValidators} from '@rxweb/reactive-form-validators';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {CompanyService} from 'src/services/company.service';
import HttpCompanyPositionResponse, {CompanyPosition} from 'src/model/company-position';
import {CompanyPositionService} from 'src/services/company-position.service';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {CompanyPositionCreateComponent} from '../company-position-create/company-position-create.component';
import {Store} from '@ngrx/store';
import {ContractState} from '../../stores/states/contract.state';
import * as ContractAction from '../../stores/actions/contract.action';
import {timer} from 'rxjs';
import { Company } from 'src/model/company';
import { Department } from 'src/model/department';
import { DepartmentService } from 'src/services/department.service';
import { JourneyByDayComponent } from 'src/components/journey-by-day/journey-by-day.component';

type Period = 'day' | 'week';

@Component({
    selector: 'app-employee-create',
    templateUrl: './employee-create.component.html',
    styleUrls: ['./employee-create.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class EmployeeCreateComponent implements OnInit {

    @ViewChild(JourneyByDayComponent) journeyByDayComponent!: JourneyByDayComponent;

    public editMode = false;
    public currentEmployeeID: string | undefined;
    public currentContractID: string | undefined;
    public formRegister: FormGroup;
    public states: StateBr[] = [];
    public cities: CityBr[] = [];
    public banks: Bank[] = [];
    public contracts: any[] = [];
    public employees: any[] = [];
    public companiesPositions: CompanyPosition[] = [];
    public departments: Department[] = [];
    public stateId ? = 0;
    public inputCountry = 'Brasil';
    public selectedJourneyOption = 1;
    public occupation?: string;
    public loadSkeleton!: boolean;
    public loading = false;
    public displayDialog = false;
    public alertHeader = '';
    public alertDescription = '';
    public emailExists = false;
    public currentAccountTypeField = ['Conta Poupança', 'Conta Corrente', 'Conta Salário', 'Conta de Pagamentos'];
    public pixKeyField = ['CPF', 'E-mail', 'Número de telefone celular', 'Chave Aleatória'];
    public ref: DynamicDialogRef = new DynamicDialogRef();
    public checkRole = false;
    public checkBrachAddress = false;
    public thereIsBranc = false;
    public activeJourney = false;
    public formStatus = false;
    public formJourneyDayStatus = false;
    private employee: Employee | undefined;
    private contract: Contract | undefined;
    private messageEmailExists = 'EmailExistsException';
    private messageUsernameExists = 'UsernameExistsException';
    private messagephoneExists = 'PnoneExistsException';
    public company: Company | undefined;
    public journeyType: Period = 'week';
    public journeyByWeek: any = {};
    public journeyByDay: any = {}

    constructor(
        private formBuilder: FormBuilder,
        private employeeService: EmployeeService,
        private companyPositionService: CompanyPositionService,
        private dropDownService: AddressService,
        private messageService: MessageService,
        private dialogService: DialogService,
        private appStorageService: AppStorageService,
        private contractService: ContractService,
        private departmentService: DepartmentService,
        private confirmationService: ConfirmationService,
        private store: Store<ContractState>,
        private http: HttpClient,
        private router: Router,
        private companyService: CompanyService,
    ) {
        this.formRegister = this.formBuilder.group({
            name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(80)]],
            role: [''],
            email: ['', [Validators.required, Validators.email]],
            registryId: ['', Validators.required],
            companyId: ['', Validators.required],
            departmentId: ['', Validators.required],
            path: [''],
            city: ['', [Validators.required, Validators.maxLength(30)]],
            state: ['', Validators.required],
            employeeZipcode: ['', Validators.required],
            employeeAddress: ['', Validators.required],
            employeeAddressNumber: ['', [Validators.required, Validators.maxLength(10)]],
            employeeDistrict: ['', [Validators.required, Validators.maxLength(30)]],
            employeeComplement: ['', Validators.maxLength(30)],
            country: [''],
            phone: ['', Validators.required],
            bank: [''],
            bankBranch: ['', RxwebValidators.required({conditionalExpression: (x: any, y: any) => x.bank !== ''})],
            currentAccountType: ['', [RxwebValidators.required({conditionalExpression: (x: any, y: any) => x.bank !== ''})]],
            currentAccount: ['', [RxwebValidators.required({conditionalExpression: (x: any, y: any) => x.bank !== ''}), Validators.maxLength(30)]],
            verificationDigit: ['', RxwebValidators.required({conditionalExpression: (x: any, y: any) => x.bank !== ''})],
            pixKey: [''],
            pix: ['', RxwebValidators.required({conditionalExpression: (x: any, y: any) => x.pixKey !== ''})],
            cnh: ['', Validators.maxLength(30)],
            pisPasep: [''],
            companyPositionId: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(180)]],
            enabledSunday: [''],
            enabledMonday: [''],
            enabledTuesday: [''],
            enabledWednesday: [''],
            enabledThursday: [''],
            enabledFriday: [''],
            enabledSaturday: [''],
            startTimeFirstPeriod: ['00:00', Validators.required],
            endTimeFirstPeriod: ['00:00', Validators.required],
            startTimeSecondPeriod: ['00:00', Validators.required],
            endTimeSecondPeriod: ['00:00', Validators.required],
            selectedJourneyOption:[''],
            contractZipcode: ['', [this.requireValidator(() => this.formRegister.get('branchAddress')?.value, Validators.required)]],
            contractAddress: ['', [this.requireValidator(() => this.formRegister.get('branchAddress')?.value, Validators.required)]],
            contractAddressNumber: ['', [this.requireValidator(() => this.formRegister.get('branchAddress')?.value, Validators.required)]],
            contractDistrict: ['', [this.requireValidator(() => this.formRegister.get('branchAddress')?.value, Validators.required)]],
            contractComplement: [''],
            contractCity: ['', [this.requireValidator(() => this.formRegister.get('branchAddress')?.value, Validators.required)]],
            contractState: ['', [this.requireValidator(() => this.formRegister.get('branchAddress')?.value, Validators.required)]],
            homeOffice: [false],
            branchAddress: [false],
            flexibleJourney: [false],
            headOffice: [false],
            skipAllValidation: [false]
        }, {validator: [RegisterIdValidator, NameLengthValidator]});
        this.formRegister.patchValue({
            enabledSunday: false,
            enabledMonday: false,
            enabledTuesday: false,
            enabledWednesday: false,
            enabledThursday: false,
            enabledFriday: false,
            enabledSaturday: false,
        });

        this.formRegister.get('enabledMonday')!.valueChanges.subscribe((enabled) => {
            this.updatePeriodsValidation(enabled);
        });
        this.formRegister.get('enabledTuesday')!.valueChanges.subscribe((enabled) => {
            this.updatePeriodsValidation(enabled);
        });
        this.formRegister.get('enabledWednesday')!.valueChanges.subscribe((enabled) => {
            this.updatePeriodsValidation(enabled);
        });
        this.formRegister.get('enabledThursday')!.valueChanges.subscribe((enabled) => {
            this.updatePeriodsValidation(enabled);
        });
        this.formRegister.get('enabledFriday')!.valueChanges.subscribe((enabled) => {
            this.updatePeriodsValidation(enabled);
        });
        this.formRegister.get('enabledSaturday')!.valueChanges.subscribe((enabled) => {
            this.updatePeriodsValidation(enabled);
        });
        this.formRegister.get('enabledSunday')!.valueChanges.subscribe((enabled) => {
            this.updatePeriodsValidation(enabled);
        });
    }

    private static validatePeriods(
        startTimeFirstPeriod: any,
        endTimeFirstPeriod: any,
        startTimeSecondPeriod: any,
        endTimeSecondPeriod: any): boolean {

        const now = moment();

        const startFirstPeriod = moment(`${now.format('YYYY-MM-DD')} ${startTimeFirstPeriod.value}`);
        const endFirstPeriod = moment(`${now.format('YYYY-MM-DD')} ${endTimeFirstPeriod.value}`);
        const startSecondPeriod = moment(`${now.format('YYYY-MM-DD')} ${startTimeSecondPeriod.value}`);
        const endSecondPeriod = moment(`${now.format('YYYY-MM-DD')} ${endTimeSecondPeriod.value}`);

        return (startFirstPeriod.isBefore(endFirstPeriod) &&
            startSecondPeriod.isBefore(endSecondPeriod) &&
            endSecondPeriod.isAfter(startFirstPeriod) &&
            startSecondPeriod.isAfter(endFirstPeriod));
    }

    private static validateFlexPeriods(
        startTimeFirstPeriod: any,
        endTimeSecondPeriod: any): boolean {

        const now = moment();

        const startFirstPeriod = moment(`${now.format('YYYY-MM-DD')} ${startTimeFirstPeriod.value}`);
        const endSecondPeriod = moment(`${now.format('YYYY-MM-DD')} ${endTimeSecondPeriod.value}`);

        return (startFirstPeriod.isBefore(endSecondPeriod));
    }

    async ngOnInit(): Promise<void> {
        this.branchAddressCoditionalValidators(this.checkBrachAddress);
        this.dropDownService.getStatesBr().subscribe(dados => {
            this.states = dados;
        });

        this.dropDownService.getBanks().subscribe(bank => {
            const sorted = bank.sort((a: any, b:any) => {
                const ca: string = a.longname;
                const cb: string = b.longname;
                return ca.toLowerCase().localeCompare(cb.toLowerCase());
            })
            sorted.map((b: Bank) => {
                this.banks.push({
                    longname: b.longname,
                });
            });
        });
        await this.initializePage();
        this.formRegister.get('role')?.setValue('WORKER');
    }

    async initializePage(): Promise<void> {
        this.employee = await this.appStorageService.getEmployee();
        const path =  this.employee.path;
        const companyId = path!.split('#', 1);

        const response: HttpCompanyPositionResponse | undefined = await this.companyPositionService.list(
            companyId[0]
        ).toPromise() || undefined;


       const that = this;
       await this.companyService.get(this.employee.companyId).forEach(function(item){
           that.company = item.data;
        });

        response?.data?.map((companiesPosition: CompanyPosition) => {
            this.companiesPositions.push(companiesPosition);
        });

        this.formRegister.get('branchAddress')?.setValue(JSON.stringify(this.company!.branchAddress));
        this.formRegister.get('homeOffice')?.setValue(JSON.stringify(this.company!.homeOffice));
        this.formRegister.get('flexibleJourney')?.setValue(JSON.stringify(this.company!.flexibleJourney));
        this.formRegister.get('skipAllValidation')?.setValue(JSON.stringify(this.company!.skipAllValidation));
        this.formRegister.get('headOffice')?.setValue(JSON.stringify(this.company!.headOffice));

        if (this.company!.journeyType === 'week') {
            this.journeyByWeek = JSON.parse(that.company!.journey!)
            this.selectedJourneyOption = 1;
            this.formRegister.get('selectedJourneyOption')!.setValue(1);

            const journeyByWeek: { [key: string]: string[] } = {};

            for (let i = 1; i <= 7; i++) {
                journeyByWeek[i.toString()] = this.journeyByWeek[i.toString()].split('-');
            }

           this.formRegister.patchValue({
             enabledSunday: JSON.parse(journeyByWeek['7'][4]),
             enabledMonday: JSON.parse(journeyByWeek['1'][4]),
             enabledTuesday: JSON.parse(journeyByWeek['2'][4]),
             enabledWednesday: JSON.parse(journeyByWeek['3'][4]),
             enabledThursday: JSON.parse(journeyByWeek['4'][4]),
             enabledFriday: JSON.parse(journeyByWeek['5'][4]),
             enabledSaturday: JSON.parse(journeyByWeek['6'][4]),
             startTimeFirstPeriod: journeyByWeek['1'][0],
             endTimeFirstPeriod: journeyByWeek['1'][1],
             startTimeSecondPeriod: journeyByWeek['1'][2],
             endTimeSecondPeriod: journeyByWeek['1'][3],
            });
        }else {
            this.journeyType = 'day'
            this.selectedJourneyOption = 2;
            this.formRegister.get('selectedJourneyOption')!.setValue(2);
        }
        if (
            this.formRegister.get('branchAddress')?.value === false &&
            this.formRegister.get('homeOffice')?.value === false &&
            this.formRegister.get('flexibleJourney')?.value === false &&
            this.formRegister.get('skipAllValidation')?.value === false &&
            this.formRegister.get('headOffice')?.value === false) {
            this.formRegister.patchValue({headOffice: "true"});
        }
    }

    updateJoruneyDayFormStatus(value: any) {
        this.formJourneyDayStatus = value;
    }

    createJourney() {
        this.journeyByWeek = {};
        this.checkIfOneAnableExists();
        const enabledSunday = this.formRegister.get(`enabledSunday`)?.value;
        const enabledMonday = this.formRegister.get(`enabledMonday`)?.value;
        const enabledTuesday = this.formRegister.get(`enabledTuesday`)?.value;
        const enabledWednesday = this.formRegister.get(`enabledWednesday`)?.value;
        const enabledThursday = this.formRegister.get(`enabledThursday`)?.value;
        const enabledFriday = this.formRegister.get(`enabledFriday`)?.value;
        const enabledSaturday = this.formRegister.get(`enabledSaturday`)?.value;
        this.journeyByWeek[1] = this.getJourneyString(this.formRegister, enabledMonday);
        this.journeyByWeek[2] = this.getJourneyString(this.formRegister, enabledTuesday);
        this.journeyByWeek[3] = this.getJourneyString(this.formRegister, enabledWednesday);
        this.journeyByWeek[4] = this.getJourneyString(this.formRegister, enabledThursday);
        this.journeyByWeek[5] = this.getJourneyString(this.formRegister, enabledFriday);
        this.journeyByWeek[6] = this.getJourneyString(this.formRegister, enabledSaturday);
        this.journeyByWeek[7] = this.getJourneyString(this.formRegister, enabledSunday);
    }

    getJourneyString(formGroup: any, isAnabled: boolean) {
        let isEnabled = isAnabled;
        const startTimeFirstPeriod = formGroup.get(`startTimeFirstPeriod`).value;
        const endTimeFirstPeriod = formGroup.get(`endTimeFirstPeriod`).value;
        const startTimeSecondPeriod = formGroup.get(`startTimeSecondPeriod`).value;
        const endTimeSecondPeriod = formGroup.get(`endTimeSecondPeriod`).value;

        return `${startTimeFirstPeriod}-${endTimeFirstPeriod}-${startTimeSecondPeriod}-${endTimeSecondPeriod}-${isEnabled}`;
    }

    changeJourgeType() {
        if (this.selectedJourneyOption === 1) {
            this.journeyType = 'week';
            this.createJourney();
            this.formRegister.get(`enabledSunday`)?.setValue(false);
            this.formRegister.get(`enabledMonday`)?.setValue(false);
            this.formRegister.get(`enabledTuesday`)?.setValue(false);
            this.formRegister.get(`enabledWednesday`)?.setValue(false);
            this.formRegister.get(`enabledThursday`)?.setValue(false);
            this.formRegister.get(`enabledFriday`)?.setValue(false);
            this.formRegister.get(`enabledSaturday`)?.setValue(false);
            this.formRegister.get(`startTimeFirstPeriod`)!.setValue('00:00');
            this.formRegister.get(`endTimeFirstPeriod`)!.setValue('00:00');
            this.formRegister.get(`startTimeSecondPeriod`)!.setValue('00:00');
            this.formRegister.get(`endTimeSecondPeriod`)!.setValue('00:00');
        } else {
            this.journeyType = 'day';
            if (this.journeyByDayComponent) {
                this.journeyByDayComponent.resetAllForms();
            }
        }
    }

    updateJourney(newJourney: any) {
        this.journeyByDay = newJourney;
    }

    private async getCompanyPosition(companyId: string): Promise<void> {
        const companyPositionResponse: HttpCompanyPositionResponse | undefined =
            await this.companyPositionService.list(companyId as string).toPromise() || undefined;
        if (companyPositionResponse?.data  && companyPositionResponse.data.length > 0) {
            companyPositionResponse.data?.map((companyPosition: CompanyPosition) => {
                this.companiesPositions?.push(companyPosition);
            });
        }
    }

    public openAlert(header: string, description: string): void {
        this.alertHeader = header;
        this.alertDescription = description;
        this.displayDialog = true;
    }

    public closeAlert(): void {
        this.alertHeader = '';
        this.alertDescription = '';
        this.displayDialog = false;
    }

    public findZipcode(type: string): void {
        let cep = '';
        if (type === 'employee') {
            cep = this.formRegister.get('employeeZipcode')?.value;
        } else if (type === 'contract') {
            cep = this.formRegister.get('contractZipcode')?.value;
        } else {
            cep = type || '';
        }

        if (cep !== '') {
            cep = cep.replace(/\D/g, '');
        }

        if (cep !== '') {
            const validaCep = /^[0-9]{8}$/;

            if (validaCep.test(cep)) {
                // this.resetContractAddressForm();

                const options = {headers: {Anonymous: ''}};

                this.http.get(`https://viacep.com.br/ws/${cep}/json`, options)
                    .pipe(
                        map((dados: any) => {
                            if (dados.erro === true) {
                                this.messageService.add({
                                    severity: 'error',
                                    summary: 'CEP inválido ou inexistente',
                                    detail: 'Verifique o número do CEP novamentes'
                                });
                                this.formRegister.get('employeeCep')?.setValue('');
                            }

                            if (type === 'employee') {
                                this.populateEmployeeAddressForm(dados);
                            } else {
                                this.populateContractAddressForm(dados);
                            }
                        })
                    ).subscribe();
            }
        } else {
            if (type === 'employee') {
                this.resetEmployeeAddressForm();
            } else {
                this.resetContractAddressForm();
            }
        }
    }

    resetEmployeeAddressForm(): void {
        this.formRegister.patchValue({
            employeeAddress: null,
            employeeAddressNumber: null,
            employeeDistrict: null,
            employeeComplement: null,
            employeeCity: null,
            employeeState: null,
        });
    }

    resetContractAddressForm(): void {
        this.formRegister.patchValue({
            contractAddress: null,
            contractAddressNumber: null,
            contractDistrict: null,
            contractComplement: null,
            contractCity: null,
            contractState: null,
        });
    }

    populateContractAddressForm(data: any): void {
        this.formRegister.patchValue({
            contractAddress: data.logradouro,
            contractDistrict: data.bairro,
            contractCity: data.localidade,
            contractState: data.uf,
        });
    }

    populateEmployeeAddressForm(data: any): void {
        this.formRegister.patchValue({
            employeeAddress: data.logradouro,
            employeeDistrict: data.bairro,
            state: data.uf,
            city: data.localidade,
        });

        // this.onStateChange(data.uf);
    }

    onStateChange(value: string): void {
        this.dropDownService.getStatesBr()
            .subscribe(states => {

                const findState = states.find(state => {
                    return state.sigla === value;
                });

                this.stateId = findState?.id;

                this.dropDownService.getCitiesBr(Number(this.stateId)).subscribe((cities: any) => {
                    this.cities = cities;
                });
            });
    }

    public async backToEmployee(): Promise<void> {
        await this.router.navigateByUrl('/employee/list');
    }

    // public async checkIfPhoneExists(event: any): Promise<void> {
    //     const checkPhone = event.target.value;
    //     const phoneFormat = checkPhone.match(/[^_\W]+/g).join('');

    //     if (checkPhone && !this.editMode) {
    //         try {
    //             const response: HttpEmployeeResponse | undefined = await this.employeeService.findByPhone(phoneFormat).toPromise() || undefined;
    //             if (response?.data) {
    //                 this.formRegister.get('phone')?.setValue('');
    //                 this.openAlert('Telefone em uso', 'Informe outro número de telefone.');
    //             }
    //         } catch (error) {
    //             this.formRegister.get('phone')?.setValue(checkPhone);
    //         }
    //     }
    // }

    async verifyEmail(event: any): Promise<any> {
        const checkEmail = event.target.value;

        if (checkEmail && !this.editMode) {
            try {
                const response: HttpEmployeeResponse | undefined = await this.employeeService.findByEmail(checkEmail).toPromise() || undefined;
                if (response?.data) {
                    this.formRegister.get('email')?.setValue('');
                    this.openAlert('E-mail em uso', 'Informe outro endereço de e-mail.');
                }
            } catch (error) {
                this.formRegister.get('email')?.setValue(checkEmail);
            }
        }
    }

    // public async checkIfRegistryIdExists(event: any): Promise<void> {
    //     const checkRegistryId = event.target.value;

    //     if (checkRegistryId && !this.editMode) {
    //         try {
    //             const response: HttpEmployeeResponse | undefined = await this.employeeService.findByRegistryId(checkRegistryId).toPromise() || undefined;
    //             if (response?.data) {
    //                 this.formRegister.get('registryId')?.setValue('');
    //                 this.openAlert('CPF em uso', 'Informe outro número de CPF.');
    //             }
    //         } catch (error) {
    //             this.formRegister.get('registryId')?.setValue(checkRegistryId);
    //         }
    //     }
    // }

    public async homeOfficeToggleChange(event: any): Promise<void> {

        const flexibleJourney = this.formRegister.get('flexibleJourney');
        const headOffice  = this.formRegister.get('headOffice');
        const branchAddress  = this.formRegister.get('branchAddress');
        const skipAllValidation  = this.formRegister.get('skipAllValidation');

        flexibleJourney?.setValue(false);
        headOffice?.setValue(false);
        branchAddress?.setValue(false);
        skipAllValidation?.setValue(false);
        this.branchAddressCoditionalValidators(false);
    }

    public async flexibleJourneyToggleChange(event: any): Promise<any> {

        const homeOffice = this.formRegister.get('homeOffice');
        const headOffice  = this.formRegister.get('headOffice');
        const branchAddress  = this.formRegister.get('branchAddress');
        const skipAllValidation  = this.formRegister.get('skipAllValidation');

        homeOffice?.setValue(false);
        headOffice?.setValue(false);
        branchAddress?.setValue(false);
        skipAllValidation?.setValue(false);
        this.branchAddressCoditionalValidators(false);
    }

    public async headOfficeToggleChange(event: any) {

        const homeOffice  = this.formRegister.get('homeOffice');
        const flexibleJourney  = this.formRegister.get('flexibleJourney');
        const branchAddress  = this.formRegister.get('branchAddress');
        const skipAllValidation  = this.formRegister.get('skipAllValidation');

        homeOffice?.setValue(false);
        flexibleJourney?.setValue(false);
        branchAddress?.setValue(false);
        skipAllValidation?.setValue(false);
        this.branchAddressCoditionalValidators(false);
    }

    public async branchAddressToggleChange(event: any) {

        const homeOffice  = this.formRegister.get('homeOffice');
        const flexibleJourney  = this.formRegister.get('flexibleJourney');
        const headOffice  = this.formRegister.get('headOffice');
        const skipAllValidation  = this.formRegister.get('skipAllValidation');

        homeOffice?.setValue(false);
        flexibleJourney?.setValue(false);
        headOffice?.setValue(false);
        skipAllValidation?.setValue(false);

        this.branchAddressCoditionalValidators(false);
    }

    public async skipAllValidationToggleChange(event: any) {

        this.confirmationService.confirm({
            message: `
                Essa ação irá desabilitar as regras de registro de ponto e seu colaborador
                poderá registra-lo sem limitação de local e horário, cabendo ao gestor fazer a
                conferência usando o histórico e o banco de horas.
            `,
            header: 'Atenção',
            accept: () => {
                const homeOffice  = this.formRegister.get('homeOffice');
                const flexibleJourney  = this.formRegister.get('flexibleJourney');
                const headOffice  = this.formRegister.get('headOffice');
                const branchAddress  = this.formRegister.get('branchAddress');

                homeOffice?.setValue(false);
                flexibleJourney?.setValue(false);
                headOffice?.setValue(false);
                branchAddress?.setValue(false);
                this.branchAddressCoditionalValidators(false);
            },
            reject: () => {
                const skipAllValidation  = this.formRegister.get('skipAllValidation');
                skipAllValidation?.setValue(false);
            }
        })
    }

    public async onCreateCompanyPosition(): Promise<any> {
        this.companiesPositions = [];
        this.ref = this.dialogService.open(CompanyPositionCreateComponent, {
            header: 'Cadastrar Cargo',
            width: '50%',
            contentStyle: {'max-height': '500px', overflow: 'auto'},
            baseZIndex: 10000,
            dismissableMask: true,
            data: {values: ''}
        });

        this.ref.onClose.subscribe((message) => {
            this.getCompanyPosition(this.employee?.companyId as string);
            this.messageService.add({severity: message[0].severity, summary: message[0].summary});
        });
    }

    public async onSubmit(): Promise<any> {
        if (this.formRegister.invalid) {
            return;
        }
        // if (this.editMode) {
        //     await this.update();
        // } else {
        //     await this.register();
        // }
        await this.register();
    }

    async selectValues(value: any) {
        const {companyId, branchId, path} = value;

        const selectedCompany = await this.companyService.get(companyId).toPromise();

        this.thereIsBranc = await this.companyService.thereIsBranch(companyId, selectedCompany?.data!.type!);

        if (companyId !== '' && branchId !== '') {
            this.formRegister.get('companyId')?.setValue(branchId);
            this.formRegister.get('path')?.setValue(path);
            this.getDepartments(branchId);
        } else {
            this.formRegister.get('companyId')?.setValue(companyId);
            this.formRegister.get('path')?.setValue(path);
            this.getDepartments(companyId);
        }
    }

    async getDepartments(companyId: string) {
        this.departments = [];
        this.formRegister.get('departmentId')?.disable();
        try {
            this.departmentService.list(companyId).subscribe( department => {
                const result: Department[] = [];
                department?.data?.map((department: Department) => {
                    if (department.status === 'ACTIVE') {
                        result.push(department);
                    }
                });

                this.departments = result;
                if (this.departments.length === 0) {
                    this.formRegister.get('departmentId')?.disable();
                } else {
                    this.formRegister.get('departmentId')?.enable();
                }
            });
        } catch (error: any) {}
    }

    public async register(): Promise<void> {
        this.loading = true;

        let journeyData: any;

        if (this.journeyType === 'week') {
            journeyData = JSON.stringify(this.journeyByWeek)
        } else {
            journeyData = JSON.stringify(this.journeyByDay)
        }

        const {
            name,
            email,
            registryId,
            phone,
            state,
            city,
            bank,
            bankBranch,
            currentAccountType,
            currentAccount,
            verificationDigit,
            pixKey,
            pix,
            cnh,
            pisPasep,
            companyPositionId,
            enabledSunday,
            enabledMonday,
            enabledTuesday,
            enabledWednesday,
            enabledThursday,
            enabledFriday,
            enabledSaturday,
            startTimeFirstPeriod,
            endTimeFirstPeriod,
            startTimeSecondPeriod,
            endTimeSecondPeriod,
            homeOffice,
            flexibleJourney,
            branchAddress,
            headOffice,
        } = this.formRegister.value;

        // const getCompany = await this.appStorageService.getCompany();

        const employee = {
            companyId: this.formRegister.get('companyId')?.value,
            name,
            // role: this.formRegister.get('role')?.value === 'true' ? 'MANAGER' : 'WORKER',
            role: this.formRegister.get('role')?.value,
            departmentId: this.formRegister.get('departmentId')?.value,
            path: this.formRegister.get('path')?.value,
            email: email.toLowerCase(),
            registryId,
            phone: phone?.match(/[^_\W]+/g).join(''),
            street: this.formRegister.get('employeeAddress')?.value,
            zipcode: this.formRegister.get('employeeZipcode')?.value,
            addressNumber: this.formRegister.get('employeeAddressNumber')?.value,
            district: this.formRegister.get('employeeDistrict')?.value,
            complement: this.formRegister.get('employeeComplement')?.value,
            state,
            city,
            country: this.inputCountry,
            bank,
            bankBranch,
            currentAccountType,
            currentAccount,
            verificationDigit,
            pixKey,
            pix,
            cnh,
            pisPasep,
        };

        const startFirstPeriodField = this.formRegister.get('startTimeFirstPeriod');
        const endFirstPeriodField = this.formRegister.get('endTimeFirstPeriod');
        const startSecondPeriodField = this.formRegister.get('startTimeSecondPeriod');
        const endSecondPeriodField = this.formRegister.get('endTimeSecondPeriod');

        let checkPeriods = true;
        // let checkPeriods = false;

        // if (!!startFirstPeriodField?.value && !!endFirstPeriodField?.value && !!startSecondPeriodField?.value && !!endSecondPeriodField?.value) {
        //     checkPeriods = EmployeeCreateComponent.validatePeriods(
        //         startFirstPeriodField,
        //         endFirstPeriodField,
        //         startSecondPeriodField,
        //         endSecondPeriodField)
        // } else if (!!startFirstPeriodField?.value && !!endSecondPeriodField?.value && endFirstPeriodField?.value === '' && startSecondPeriodField?.value === '') {
        //     checkPeriods = EmployeeCreateComponent.validateFlexPeriods(startFirstPeriodField, endSecondPeriodField);
        // } else {
        //     checkPeriods = false;
        // }

        if (checkPeriods) {
            try {

                const response = await this.employeeService.register(employee).toPromise();

                const contractConfig = {
                    companyId: this.formRegister.get('companyId')?.value,
                    employeeId: response.data?.id,
                    role: this.formRegister.get('role')?.value,
                    departmentId: this.formRegister.get('departmentId')?.value,
                    path: this.formRegister.get('path')?.value,
                    companyPositionId,
                    journeyType: this.journeyType,
                    journey: journeyData,
                    branchAddress: this.formRegister.get('branchAddress')?.value === 'true' ? true : false,
                    homeOffice: this.formRegister.get('homeOffice')?.value === 'true' ? true : false,
                    flexibleJourney: this.formRegister.get('flexibleJourney')?.value === 'true' ? true : false,
                    headOffice: this.formRegister.get('headOffice')?.value === 'true' ? true : false,
                    skipAllValidation: this.formRegister.get('skipAllValidation')?.value === 'true' ? true : false,
                };

                this.contract = Object.assign(contractConfig);

                await this.contractService.save(this.contract as Contract).toPromise();

                this.messageService.add({severity: 'success', summary: `Funcionário(a) ${name} cadastrado(a)`, detail: ''});
                this.store.dispatch(ContractAction.clearContractStateAction());

                timer(2000).toPromise().then(() => {
                    this.loading = false;
                    this.router.navigateByUrl('/employee/list');
                });

            } catch (error: any) {
                console.log(error);

                if (error.code === this.messageEmailExists) {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Problema no registro',
                        detail: 'E-mail já está em uso'
                    });
                } else if (error.code === this.messagephoneExists) {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Problema no registro',
                        detail: 'Número telefonico já está em uso'
                    });
                } else if (error.code === this.messageUsernameExists) {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Problema no registro',
                        detail: 'Usuário já cadastrado'
                    });
                } else {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Problema no registro',
                        detail: 'Verifique as informações fornecidas, ou se o problema persistir contacte a Alphatime'
                    });
                }
            }
        } else {
            this.openAlert('Períodos Incorretos', 'Verifique se os intervalos dos períodos estão corretos');
        }
    }

    public changeRole(event: any): any {
        return event.checked;
    }

    /**
     * @private
     * @method myFormValidator
     * @description      Custom validation rule for Reactive Forms functionality - used where
     *                   conditional validation rule has been triggered
     * @param {*} predicate
     * @param {*} validator
     * @returns {*}
     * @memberof HomePage
     */
    private requireValidator(predicate: any, validator: any): any {
        return ((formControl: FormControl) => {
            if (!formControl.parent) {
                return null;
            }
            if (predicate()) {
                return validator(formControl);
            }
            return null;
        });
    }

    branchAddressCoditionalValidators(branchAddress: any) {
        if (branchAddress === false) {
            this.formRegister.controls['contractZipcode'].clearValidators();
            this.formRegister.controls['contractAddress'].clearValidators();
            this.formRegister.controls['contractAddressNumber'].clearValidators();
            this.formRegister.controls['contractDistrict'].clearValidators();
            this.formRegister.controls['contractCity'].clearValidators();
            this.formRegister.controls['contractState'].clearValidators();
        }

        if (branchAddress === true){
            this.formRegister.controls['contractZipcode'].setValidators([Validators.required]);
            this.formRegister.controls['contractAddress'].setValidators([Validators.required]);
            this.formRegister.controls['contractAddressNumber'].setValidators([Validators.required]);
            this.formRegister.controls['contractDistrict'].setValidators([Validators.required]);
            this.formRegister.controls['contractCity'].setValidators([Validators.required]);
            this.formRegister.controls['contractState'].setValidators([Validators.required]);
        }

        this.formRegister.controls['contractZipcode'].updateValueAndValidity();
        this.formRegister.controls['contractAddress'].updateValueAndValidity();
        this.formRegister.controls['contractAddressNumber'].updateValueAndValidity();
        this.formRegister.controls['contractDistrict'].updateValueAndValidity();
        this.formRegister.controls['contractCity'].updateValueAndValidity();
        this.formRegister.controls['contractState'].updateValueAndValidity();
    }

    checkIfOneAnableExists() {
        if (
            this.formRegister.get('enabledMonday')!.value === false &&
            this.formRegister.get('enabledTuesday')!.value === false &&
            this.formRegister.get('enabledWednesday')!.value === false &&
            this.formRegister.get('enabledThursday')!.value === false &&
            this.formRegister.get('enabledFriday')!.value === false &&
            this.formRegister.get('enabledSaturday')!.value === false &&
            this.formRegister.get('enabledSunday')!.value === false
        ) {
            this.formStatus = false;
            this.activeJourney = false;
        } else {
            this.formStatus = true;
            this.activeJourney = true;
        }
    }

    showAttentionMessage() {
        // Verifique se algum dos campos atende às condições (00:00 ou vazio)
        const startTimeFirstPeriodValue = this.formRegister.get('startTimeFirstPeriod')!.value;
        const endTimeFirstPeriodValue = this.formRegister.get('endTimeFirstPeriod')!.value;
        const startTimeSecondPeriodValue = this.formRegister.get('startTimeSecondPeriod')!.value;
        const endTimeSecondPeriodValue = this.formRegister.get('endTimeSecondPeriod')!.value;

        return (
          startTimeFirstPeriodValue === '00:00' || startTimeFirstPeriodValue === '' ||
          endTimeFirstPeriodValue === '00:00' || endTimeFirstPeriodValue === '' ||
          startTimeSecondPeriodValue === '00:00' || startTimeSecondPeriodValue === '' ||
          endTimeSecondPeriodValue === '00:00' || endTimeSecondPeriodValue === ''
        );
      }

    updatePeriodsValidation(enabled: boolean) {
        const fieldsToValidate = [
          'startTimeFirstPeriod',
          'endTimeFirstPeriod',
          'startTimeSecondPeriod',
          'endTimeSecondPeriod',
        ];

        this.updateValidation(fieldsToValidate, enabled);
    }

    updateValidation(fieldsToValidate: any[], enabled: boolean) {
        fieldsToValidate.forEach((field) => {
          this.formRegister.get(field)!.clearValidators();
          this.formRegister.get(field)!.updateValueAndValidity();
        });

        if (enabled) {
          fieldsToValidate.forEach((field) => {
            this.formRegister.get(field)!.setValidators([
                Validators.required,
                (control: AbstractControl) => {
                  const value = control.value;
                  if (value === '00:00') {
                    return { invalidTime: true };
                  }
                  return null;
                }
              ]);
              this.formRegister.get(field)!.updateValueAndValidity();
          });
        }

        if (!enabled) {
          fieldsToValidate.forEach((field) => {
            this.formRegister.get(field)!.setValue('00:00');
            this.formRegister.get(field)!.updateValueAndValidity();
          });
        }
    }
}
