<p-toast></p-toast>

<p-dialog header="Solicitação de ajuste de horário" [(visible)]="showHourAjustment" [style]="{width: '560px'}">
    <div *ngFor="let cat of clockAdjustmentDialogInfo">
        <div>
            <p>{{cat.clock.name}} solicitou o seguinte ajuste de horário:</p>
            <p>Entrada: {{cat.startTimeFirstPeriod}} |
                Intervalo: {{cat.endTimeFirstPeriod}} às {{cat.startTimeSecondPeriod}} |
                Saída: {{cat.endTimeSecondPeriod}}</p>
            <p>
                <strong>Você autoriza esse ajuste?</strong>
            </p>
        </div>
    </div>
    <p-footer>
        <button class="btn btn-link btn-icon-danger btn-cancel"
                (click)="onRejectClockAdjustment(clockAdjustmentDialogInfo)">Não
        </button>
        <button class="btn btn-success" (click)="onAcceptClockAdjustment(clockAdjustmentDialogInfo)">Sim, autorizo
        </button>
    </p-footer>
</p-dialog>

<p-dialog #timeBanReportDialog header="Baixar relatório de horas" [(visible)]="showTimeBankReportModal" [style]="{width: '760px'}">
    <div *ngIf="showTimeBankReportModal">
        <app-time-bank-form-report #timeBankFormReport></app-time-bank-form-report>
    </div>
    <ng-template pTemplate="footer">
        <button class="btn btn-cancel-primary" (click)="hideTimeBankReportModal()">Cancelar</button>
        <button class="btn btn-sm btn-primary" [disabled]="!isFormValid()" (click)="handleGetTimeBankReportFormValue()">Baixar</button>
    </ng-template>
</p-dialog>

<p-dialog #reportDialog *ngIf="showReportModal" header="Relatório por departamento" [(visible)]="showReportModal" [style]="{width: '560px'}">
    <div>
        <app-select-company (selectEvent)="selectedCompanyIdAndDepartmentId($event)"></app-select-company>
        <form [formGroup]="departmentForm" novalidate>
            <div class="mb-3">
                <label for="companyPosition">Departamento *</label>
                <div class="spinner-border spinner-border-sm text-muted ml-2" *ngIf="loadDepartments" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="d-flex justify-content-start align-items-center">
                    <select class="form-control w-100" formControlName="departmentId"
                            style="width: 50%;" #department (change)="getDepartmentName(department.selectedOptions[0].text)">
                        <option value="">Selecione um Departamento</option>
                        <option *ngFor="let department of departments"
                                value="{{department.id}}">{{department.name}}</option>
                    </select>
                </div>
                <div class="custom-invalid-feedback" *ngIf="departmentForm.get('departmentId')?.hasError('required')
                    && departmentForm.get('departmentId')?.touched">Departamento é requerido
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-lg-6">
                    <p class="searchbar-label">Data Inicial *</p>
                    <p-calendar
                        #departmentStartDate
                        [showIcon]="false"
                        class="input-date"
                        [appendTo]="reportDialog"
                        placeholder="Selecione uma data"
                        formControlName="departmentStartDate"
                        inputStyleClass="form-control"
                        dateFormat="dd/mm/yy"
                        [readonlyInput]="true">
                    </p-calendar>
                </div>
                <div class="form-group col-lg-6">
                    <p class="searchbar-label">Data Final *</p>
                    <p-calendar
                        [showIcon]="false"
                        class="input-date"
                        [appendTo]="reportDialog"
                        [minDate]="departmentStartDate.value"
                        placeholder="Selecione uma data"
                        formControlName="departmentEndDate"
                        inputStyleClass="form-control"
                        dateFormat="dd/mm/yy"
                        [readonlyInput]="true">
                    </p-calendar>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button class="btn btn-link btn-icon-danger btn-cancel" (click)="hideReportModalByDepartment()">Cancelar</button>
        <button class="btn btn-link text-primary" (click)="generatePdfByDepartment(1)" [disabled]="!departmentForm.valid">Gerar Simples</button>
        <button class="btn btn-link text-success" (click)="generatePdfByDepartment(2)" [disabled]="!departmentForm.valid">Gerar Completo</button>
    </p-footer>
</p-dialog>
<!-- overtime endss -->

<app-searchbar-employee
    (searchEvent)="findEmployeeByDates($event)"
    (lastTwelveMonthsClick)="lastTwelveMonthsClick($event)"
    (cleanForm)="onCleanForm()"
    #searchBar>
</app-searchbar-employee>

<!-- Cards Start -->
<div class="row" *ngIf="showCards">
    <div class="pb-4 col-md-12 col-xd-6 col-lg-4 w-100">
        <div class="card shadow py-2">
            <div class="card-body mt-1 mb-1" style="padding: 0px !important;">
                <div class="no-gutters align-items-center">
                    <div class="ct-text-header">
                        <span class="ml-3">Saldo de Horas no período</span>
                    </div>
                    <div class="mt-1 mb-1 ct-border-box-left-primary">
                        <p-skeleton *ngIf="loadSkeleton" styleClass="p-mb-2"></p-skeleton>
                        <div class="font-weight-bold ct-text-body text-gray-800 ml-3" *ngIf="!loadSkeleton">
                            <span>{{ hoursBalance || '00:00' }}</span>
                        </div>
                    </div>
                    <div class="ct-text-footer ml-3">
                        <span>{{searchStartPeriods}} à {{searchEndPeriods}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pb-4 col-md-12 col-xd-6 col-lg-4 w-100">
        <div class="card shadow py-2">
            <div class="card-body mt-1 mb-1" style="padding: 0px !important;">
                <div class="no-gutters align-items-center">
                    <div class="ct-text-header">
                        <span class="ml-3">Horas Extras no período</span>
                    </div>
                    <div class="mt-1 mb-1 ct-border-box-left-secondary">
                        <p-skeleton *ngIf="loadSkeleton" styleClass="p-mb-2"></p-skeleton>
                        <div class="font-weight-bold ct-text-body text-gray-800 ml-3" *ngIf="!loadSkeleton">
                            {{ positiveOvertime || '00:00' }}
                        </div>
                    </div>
                    <div class="ct-text-footer">
                        <span class="ml-3">{{searchStartPeriods}} à {{searchEndPeriods}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pb-4 col-md-12 col-xd-6 col-lg-4 w-100">
        <div class="card shadow py-2">
            <div class="card-body mt-1 mb-1" style="padding: 0px !important;">
                <div class="no-gutters align-items-center">
                    <div class="ct-text-header">
                        <span class="ml-3">Horas Negativas no período</span>
                    </div>
                    <div class="mt-1 mb-1 ct-border-box-left-danger">
                        <p-skeleton *ngIf="loadSkeleton" styleClass="p-mb-2"></p-skeleton>
                        <div class="font-weight-bold ct-text-body text-gray-800 ml-3" *ngIf="!loadSkeleton">
                            {{ negativeOvertime || '00:00' }}
                        </div>
                    </div>
                    <div class="ct-text-footer">
                        <span class="ml-3">{{searchStartPeriods}} à {{searchEndPeriods}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Cards End -->

<div *ngIf="!tableToggle">
    <div class="card shadow mb-4">
        <div class="card-body">
            <div>
                <p-table
                    #dt
                    [value]="groupResult"
                    dataKey="recordDate"
                    sortField="recordDate"
                    styleClass="p-datatable-employee"
                    [sortOrder]="-1"
                    [rows]="10"
                    [showCurrentPageReport]="true"
                    [rowsPerPageOptions]="[10,25,50]"
                    [paginator]="true">

                    <ng-template pTemplate="caption">
                        <div class="table-header">
                            <!-- <button type="button" class="btn btn-sm btn-link ml-3"
                                    (click)="searchBar.onCleanForm()">
                                Limpar filtro
                            </button> -->
                            <button type="button" class="btn btn-sm btn-outline-primary ml-3"
                                    (click)="openTimeBankReportModal()">
                                <div class="d-flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/>
                                    </svg>
                                    <span>Baixar relatório</span>
                                </div>
                            </button>
                        </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr *ngIf="groupResult?.length !== 0">
                            <th style="padding: .3rem .3rem">Saldo de horas totais</th>
                            <th style="padding: .3rem .3rem">Horas do Dia</th>
                            <th pSortableColumn="name" style="width: 30rem; padding: .3rem .3rem">Nome
                                <p-sortIcon field="name"></p-sortIcon>
                            </th>
                            <th pSortableColumn="recordDate" style="padding: .3rem .3rem">Data
                                <p-sortIcon field="recordDate"></p-sortIcon>
                            </th>
                            <th style="padding: .3rem .3rem">Jornada</th>
                            <th style="padding: .3rem .3rem">Ações</th>
                        </tr>
                        <tr *ngIf="!haveClockRegisters && !loadSkeleton">
                            <th colspan="6">
                                <div class="d-flex justify-content-center text-muted">
                                    <h6>Sem histórico de Jornada de Trabalho.</h6>
                                </div>
                            </th>
                        </tr>
                        <tr *ngIf="loadSkeleton">
                            <th colspan="6">
                                <div
                                    class="loading-box d-flex justify-content-center text-muted align-items-center p-3">
                                        <span class="spinner-grow spinner-grow-sm m-2" role="status"
                                              aria-hidden="true"></span>
                                    Aguarde estamos carregando os dados...
                                </div>
                            </th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-groupClocks>
                        <tr>
                            <td style="cursor: pointer;">
                                <span [ngClass]="groupClocks.hoursBalance.startsWith('-') === true
                                    ? 'badge badge-red' : 'badge badge-green'" (click)="manageHour(groupClocks)">
                                    {{groupClocks.hoursBalance}}
                                </span>
                            </td>
                            <td style="padding: .3rem .3rem" class="cel">
                                <span [ngClass]="groupClocks.hoursBalance.startsWith('-') === true
                                    ? 'badge badge-light-red' : 'badge badge-light-green'">
                                    {{groupClocks.totalHours}}
                                </span>
                             </td>
                            <td style="padding: .3rem .3rem">
                                <img
                                    [alt]="groupClocks.name"
                                    [src]="groupClocks.picture ? groupClocks.picture : picture"
                                    onerror="this.src='assets/icon/Avatar/user.png'"
                                    width="35.03"
                                    height="35.03"
                                    class="rounded-circle mr-2"
                                    style="vertical-align: middle"/>
                                {{ groupClocks.name }}
                            </td>
                            <td style="padding: .3rem .3rem"
                                class="cel">{{groupClocks.recordDate | date:'dd/MM/yyyy'}}</td>
                            <td style="padding: .3rem .3rem" class="cel">{{groupClocks.journey}}h</td>
                            <td>
                                <img
                                    class="comp-time-info"
                                    src="assets/icon/Info/info.svg"
                                    tooltipStyleClass="shadow"
                                    [escape]="false"
                                    pTooltip="Entrada: {{groupClocks.recordTime[0].entrada}} <br/> Saída: {{groupClocks.recordTime[groupClocks.recordTime.length - 1].saida}}"
                                    tooltipPosition="top"/>
                                <button class="btn btn-link btn-icon-primary p-0 hours-adjustment-btn"
                                        *ngIf="groupClocks.status === 'WAITING_REVIEW'"
                                        (click)="onHoursAdjustment(groupClocks)">
                                    <img class="hours-adjustment-icon" src="assets/icon/Exclamation/exclamation.svg"
                                         tooltipStyleClass="shadow" [escape]="false"
                                         pTooltip="Solicitação de ajuste"
                                         tooltipPosition="top"/>
                                </button>
                                <!-- <button
                                    class="btn btn-link btn-icon-primary p-0 manager-hours-btn"
                                    (click)="manageHour(groupClocks)">
                                    <img class="hours-adjustment-icon" src="assets/icon/Cog/cog.svg"/>
                                </button> -->
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>


