import {Injectable} from '@angular/core';
import {Auth} from 'aws-amplify';
import {Plugins} from '@capacitor/core';
import {CognitoUser, CognitoUserSession, ISignUpResult} from 'amazon-cognito-identity-js';

const {Storage} = Plugins;

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public async signUp(user: any): Promise<ISignUpResult> {
        const {password, email, name, profile} = user;
        return await Auth.signUp({
            username: email,
            password,
            attributes: {
                email,
                name,
                profile
            },
        });
    }

    /**
     * Sign In method
     *
     */
    public async signIn(username: string, password: string): Promise<void> {
        const cognitoUser: CognitoUser = await Auth.signIn(username, password);
        const session: CognitoUserSession = await Auth.currentSession();
        await Storage.set({
            key: 'session',
            value: JSON.stringify({
                idToken: session.getIdToken().getJwtToken(),
                accessToken: session.getAccessToken().getJwtToken(),
                refreshToken: session.getRefreshToken().getToken()
            })
        });
        await Storage.set({
            key: 'cognitoUser',
            value: JSON.stringify(cognitoUser)
        });
    }

    /**
     * SignOut
     *
     */
    public async signOut(): Promise<void> {
        await Auth.signOut();
    }

    /**
     * Confirm user code
     *
     */
    public async confirmSignUp(username: string, code: string): Promise<void> {
        await Auth.confirmSignUp(username, code);
    }

    public async resendConfirmationCode(username: string): Promise<void> {
        await Auth.resendSignUp(username);
    }

    public async getCurrentSession(): Promise<CognitoUserSession> {
        return await Auth.currentSession();
    }

    public async getCurrentAuthenticatedUser(): Promise<CognitoUser> {
        return await Auth.currentAuthenticatedUser();
    }

    public async forgotPassword(username: string): Promise<any>{
        return await Auth.forgotPassword(username);
    }

    public async forgotPasswordSubmit(username: string, code: string, password: string): Promise<any>{
        return await Auth.forgotPasswordSubmit(username, code, password);
    }

    public async refreshToken(): Promise<void> {
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const currentSession = await Auth.currentSession();
            cognitoUser.refreshSession(currentSession.getRefreshToken(), async (err: any, session: any) => {
                if (err) {
                    console.log('Unable to refresh Token', err);
                    return;
                }
                const { idToken, refreshToken, accessToken } = session;
                await Storage.set({
                    key: 'session',
                    value: JSON.stringify({
                        idToken,
                        accessToken,
                        refreshToken
                    })
                });
            });
        } catch (e) {
            console.log('Unable to refresh Token', e);
        }
    }

    public Encrypt(password: any) {
        let result="";
        for(let i = 0; i < password.length; i++) {
            if (i < password.length-1) {
                result += password.charCodeAt(i)+10;
                result += "-";
            } else {
                result += password.charCodeAt(i)+10;
            }
        }
        return result;
    }

    public Decrypt(password: any) {
        let result = "";
        let array = password.split("-");

        for(let i = 0; i < array.length; i++) {
            result+=String.fromCharCode(array[i]-10);
        }

        return result;
    }

}
