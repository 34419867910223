import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SERVER_URL} from '../environments/environment';
import HttpCompanyPositionResponse, {CompanyPosition} from './../model/company-position';

@Injectable({
    providedIn: 'root'
})
export class CompanyPositionService {

    constructor(private httpClient: HttpClient) {}

    /**
     *  Save Company Position
     *
     */
    public save(data: CompanyPosition): Observable<HttpCompanyPositionResponse> {
        return this.httpClient.post<HttpCompanyPositionResponse>(`${SERVER_URL}/companies/position`, data);
    }

    /**
     * Update Company Position
     */
    public update(companyPosition: CompanyPosition, id: string): Observable<HttpCompanyPositionResponse> {
        const data = Object.assign({}, companyPosition);
        // @ts-ignore
        delete data.id;

        return this.httpClient.put<HttpCompanyPositionResponse>(`${SERVER_URL}/companies/position/${id}`, data);
    }

    /**
     *  Delete Company Position
     *
     */
    public delete(companyPositionId: string): Observable<HttpCompanyPositionResponse> {
        return this.httpClient.delete<HttpCompanyPositionResponse>(`${SERVER_URL}/companies/position/${companyPositionId}`);
    }

    /**
     *  Get Companies by Company ID
     *
     */
    public list(companyId: string): Observable<HttpCompanyPositionResponse> {
        return this.httpClient.get<HttpCompanyPositionResponse>(`${SERVER_URL}/companies/${companyId}/position`);
    }

}
