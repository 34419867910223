import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Absence } from 'src/model/absence';
import { Contract } from 'src/model/contract';
import { Employee } from 'src/model/employee';
import { AbsenceService } from 'src/services/absence.service';

@Component({
    selector: 'app-employee-leave',
    templateUrl: './employee-leave.component.html',
    styleUrls: ['./employee-leave.component.scss'],
})
export class EmployeeLeaveComponent implements OnInit {
    @Input() contract: Contract = new Contract;
    @Input() employee: Employee = new Employee;

    public form: FormGroup;
    public absence!: Absence;

    public btnProgramar?: string;
    public btnReprogramar?: string;
    public btnExcluir?: string;
    public btnEditar?: string;

    public onAbsence = false;
    public employeeInVacation = false;

    public absenceTypes = [];

    constructor(
        private absenceService: AbsenceService,
        private confirmationService: ConfirmationService,
        private formBuilder: FormBuilder,
        private messageService: MessageService,
    ) {
        this.form = this.formBuilder.group({
            typeAbsence: ['', Validators.required],
            startDateInput: ['', Validators.required],
            endDateInput: ['', Validators.required],
        });
    }

    async ngOnInit(): Promise<void> {
        const getAbsenceTypes: any = await this.absenceService.getTypes().toPromise();
        this.absenceTypes = getAbsenceTypes.data.filter((type: any) => type !== 'VACATION');
        const getAbsence: any = await this.absenceService.getAbsenceByEmployee(this.employee.id!, 'ALL').toPromise();

        const absence:any = getAbsence!.data[0];
        this.absence = absence

        if (absence !== undefined) {
            if (absence.type !== 'VACATION') {
                const employeeInAbsence = this.absenceService.checkOnVacation(
                    moment(absence!.startDate).format('DD/MM/YYYY'),
                    moment(absence!.endDate).format('DD/MM/YYYY'));

                if (employeeInAbsence) {
                    this.onAbsence = employeeInAbsence;
                    this.form.get('typeAbsence')?.setValue(absence.type!);
                    this.form.get('startDateInput')?.setValue(moment(absence.startDate!, 'YYYY-MM-DD').format('DD/MM/YYYY'));
                    this.form.get('endDateInput')?.setValue(moment(absence.endDate!, 'YYYY-MM-DD').format('DD/MM/YYYY'));
                } else {
                    this.onAbsence = employeeInAbsence;
                    this.form.get('typeAbsence')?.setValue(absence.type!);
                    this.form.get('startDateInput')?.setValue(moment(absence.startDate!, 'YYYY-MM-DD').format('DD/MM/YYYY'));
                    this.form.get('endDateInput')?.setValue(moment(absence.endDate!, 'YYYY-MM-DD').format('DD/MM/YYYY'));
                    this.btnProgramar = 'none';
                    this.btnReprogramar = 'block';
                    this.btnExcluir = 'block';
                }
            } else {
                if (absence.type === 'VACATION') {
                    if (this.absenceService.checkOnVacation(absence!.startDate!, absence!.endDate!)) {
                        this.onAbsence = true;
                            this.btnProgramar = 'none';
                            this.btnReprogramar = 'none';
                            this.btnExcluir = 'none';
                    } else {
                        const isScheduleAbsence = await this.absenceService.checkIfScheduleAbsence(this.contract.employeeId!, absence!.startDate!);
                        if (isScheduleAbsence) {
                            this.onAbsence = true;
                            this.btnProgramar = 'block';
                            this.btnReprogramar = 'none';
                            this.btnExcluir = 'none';
                        }
                    }
                } else {
                    this.onAbsence = false;
                    this.btnProgramar = 'block';
                    this.btnReprogramar = 'none';
                    this.btnExcluir = 'none';
                }
            }
        } else {
            this.onAbsence = false;
            this.btnProgramar = 'block';
            this.btnReprogramar = 'none';
            this.btnExcluir = 'none';
        }
    }

    async onProgrammerAbsence(): Promise<void> {
        const startDateField = this.form.get('startDateInput');
        const endDateField = this.form.get('endDateInput');

        const convertStartDataToSave = moment(startDateField?.value, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const convertEndDataToSave = moment(endDateField?.value, 'DD/MM/YYYY').format('YYYY-MM-DD');

        if (EmployeeLeaveComponent.validatePeriods(convertStartDataToSave, convertEndDataToSave)) {
            const data: Absence = {
                contractId: this.contract.id,
                employeeId: this.contract.employeeId,
                companyId: this.contract.companyId,
                departmentId: this.contract.departmentId,
                type: this.form.get('typeAbsence')?.value,
                startDate: moment(convertStartDataToSave).toISOString(),
                endDate: moment(convertEndDataToSave).toISOString(),
            };

            this.confirmationService.confirm({
                message: `Confirmar programação de afastamento para o período de ${moment(data.startDate).format('DD/MM/YYYY')} à ${moment(data.endDate).format('DD/MM/YYYY')} ?`,
                header: 'Programar Afastamento',
                accept: () => {
                    this.absenceService.save(data).subscribe((response: any) => {
                        if (response.success) {
                            this.messageService.add({
                                life: 5000,
                                severity: 'success',
                                summary: 'Afastamento Programado!',
                                detail: 'O colaborador receberá um aviso por e-mail e não poderá bater o ponto nos dias em que estiver afastado.'
                            });
                        } else {
                            this.messageService.add({
                                severity: 'error',
                                summary: 'Error!',
                                detail: response.mensagem
                            });
                        }
                        this.ngOnInit();
                    });
                },
                reject: () => {
                    this.messageService.add({
                        severity: 'warn',
                        summary: 'Não confirmado',
                        detail: 'Afastamento não confirmado!'
                    });
                }
            });
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Período Incorreto!',
                detail: 'Verifique se os intervalos de datas estão corretos.'
            });
        }
    }

    async onUpdate(): Promise<void> {
        const updateStartDateField = this.form.get('startDateInput');
        const updateEndDateField = this.form.get('endDateInput');

        const convertUpdateStartDataToSave = moment(updateStartDateField?.value, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const convertUpdateEndDataToSave = moment(updateEndDateField?.value, 'DD/MM/YYYY').format('YYYY-MM-DD');

        if (EmployeeLeaveComponent.validateUpdatePeriods(convertUpdateStartDataToSave, convertUpdateEndDataToSave)) {
            updateStartDateField?.setValue(updateStartDateField.value);
            updateEndDateField?.setValue(updateEndDateField.value);

            const updateAbsence: any = {};
                updateAbsence.startDate = moment(convertUpdateStartDataToSave).toISOString();
                updateAbsence.endDate = moment(convertUpdateEndDataToSave).toISOString();
                updateAbsence.type = this.form.get('typeAbsence')?.value;
                const data = Object.assign(updateAbsence);

                this.confirmationService.confirm({
                    message: `Confirmar reprogramação de afastamento para o o período de ${moment(data.startDate).format('DD/MM/YYYY')} à ${moment(data.endDate).format('DD/MM/YYYY')} ?`,
                    header: 'Reprogramar Férias',
                    accept: () => {
                        this.absenceService.updateAbsence(this.absence?.id!, data).subscribe((response: any) => {
                            if (response.success) {
                                this.messageService.add({
                                    life: 5000,
                                    severity: 'success',
                                    summary: 'Afastamento Reprogramado!',
                                    detail: 'O colaborador receberá um aviso por e-mail e não poderá bater o ponto nos dias programados de afastamento.'
                                });
                                this.ngOnInit();
                            } else {
                                this.messageService.add({
                                    severity: 'error',
                                    summary: 'Error!',
                                    detail: response.mensagem
                                });
                            }
                        });
                    },
                    reject: () => {
                        this.messageService.add({
                            severity: 'warn',
                            summary: 'Não confirmado',
                            detail: 'Afastamento não confirmado!'
                        });
                    }
                });

        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Período Incorreto!',
                detail: 'Verifique se os intervalos de datas estão corretos.'
            });
        }
    }

    async onDelete(): Promise<void> {
        // open loading
        const deleteStartDateField = this.form.get('startDateInput');
        const deleteEndDateField = this.form.get('endDateInput');

        const convertDeleteStartDataToSave = moment(deleteStartDateField?.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
        const convertDeleteEndDataToSave = moment(deleteEndDateField?.value, 'DD/MM/YYYY').format('DD/MM/YYYY');

        this.confirmationService.confirm({
            message: `Deseja realmente excluir esse Afastamento já programadas? ${convertDeleteStartDataToSave} à ${convertDeleteEndDataToSave} ?`,
            header: 'Excluir Afastamento',
            accept: () => {
                this.absenceService.deleteAbsence(this.absence?.id!).subscribe((response: any) => {
                    // close loading
                    if (response.success) {
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Afastamento Cancelada!',
                        });

                        this.ngOnInit();
                        this.btnProgramar = 'block';
                        // this.btnReprogramar = 'none';
                        // this.btnExcluir = 'none';
                    } else {
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error!',
                            detail: response.mensagem,
                        });
                    }
                });
            },
            reject: () => {
                this.messageService.add({
                    severity: 'warn',
                    summary: 'Não confirmado',
                    detail: 'Afastamento não confirmado!'
                });
            }
        });
    }

    private static validateUpdatePeriods(
        updateStartVacationDate: any,
        updateEndVacationDate: any): boolean {

        const updateStartDate = moment(updateStartVacationDate);
        const updateEndDate = moment(updateEndVacationDate);

        return (updateStartDate.isBefore(updateEndDate));
    }

    private static validatePeriods(
        startVacationDate: any,
        endVacationDate: any): boolean {

        const startDate = moment(startVacationDate);
        const endDate = moment(endVacationDate);

        return (startDate.isBefore(endDate));
    }

    private static validateTotalOfDays(
        startVacationDate: any,
        endVacationDate: any): boolean {

        const startDate = moment(startVacationDate);
        const endDate = moment(endVacationDate);
        const totalOfDays = moment.duration(endDate.diff(startDate)).asDays();

        if (totalOfDays >= 30) {
            return true;
        }

        return false;
    }

    private static validateFutureDay(
        startVacationDate: any): boolean {

        const startDate = moment(startVacationDate);
        const isToday = moment(startDate).isSame(moment(), 'day');

        if (isToday) {
            return true;
        }

        return false;
    }

    private static validatePastDay(
        startVacationDate: any): boolean {

        const startDate = moment(startVacationDate);
        const isPast = moment(startDate).isBefore(moment(), 'day');

        if (isPast) {
            return true;
        }

        return false;
    }

}
