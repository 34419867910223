import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {SERVER_URL} from '../environments/environment';
import HttpContractResponse, {Contract, HttpContractsResponse, InviteEmployee} from '../model/contract';
import {Employee} from '../model/employee';
import {Employer} from '../model/employer';
import {Observable} from 'rxjs';
import { ContractTimeSetting } from 'src/model/contract-time-setting';


@Injectable({
    providedIn: 'root'
})
export class ContractService {

    constructor(
        private httpClient: HttpClient) {
    }

    /**
     *  Update Contract
     *
     */
    public updateContract(contactId: string, data: Contract): Observable<HttpContractResponse> {
        return this.httpClient.put<HttpContractResponse>(`${SERVER_URL}/contracts/${contactId}`, data);
    }

    /**
     *  Save Contract
     *
     */
    public save(data: Contract): Observable<HttpContractResponse> {
        return this.httpClient.post<HttpContractResponse>(`${SERVER_URL}/contracts`, data);
    }

    /**
     *  List Contract Company
     *
     */
    public getContractsByCompany(companyId: string | undefined): Observable<HttpContractsResponse> {
        return this.httpClient.get<HttpContractsResponse>(`${SERVER_URL}/contracts/company/${companyId}`);
    }

    /**
     *  List Contract Employees
     *
     */
     public getContractsByEmployee(employeeId: string | undefined): Observable<HttpContractsResponse> {
        return this.httpClient.get<HttpContractsResponse>(`${SERVER_URL}/contracts/employee/${employeeId}`);
    }

    /**
     *  Fire Contract Employee
     *
     */
    public fireEmployeeContract(contractId: string | undefined): Observable<HttpContractResponse> {
        return this.httpClient.put<HttpContractResponse>(`${SERVER_URL}/contracts/fire/${contractId}`, {});
    }

    /**
     *  Reemploy Contract Employee
     *
     */
    public reemployEmployeeContract(contractId: string | undefined): Observable<HttpContractResponse> {
        return this.httpClient.put<HttpContractResponse>(`${SERVER_URL}/contracts/reemploy/${contractId}`, {});
    }

    /**
     *  List Contract Company
     *
     */
     public getContractsByEmployeeAndCompany(employeeId: string, companyId: string): Observable<HttpContractResponse> {
        return this.httpClient.get<HttpContractResponse>(`${SERVER_URL}/contracts/employee/${employeeId}/company/${companyId}`);
    }

    /**
     *  Get current contract
     *
     */
     public getContract(contractId: string) {
        return this.httpClient.get<HttpContractResponse>(`${SERVER_URL}/contracts/${contractId}`);
    }

    /**
     *  Set Time
     *
     */
    public putContractTimeSetting(data: ContractTimeSetting, contractId: string | undefined): Observable<HttpContractResponse> {
        return this.httpClient.put<HttpContractResponse>(`${SERVER_URL}/contracts/time/setting/${contractId}`, data);
    }

    /**
     * Create Contract and Invite Employee
     *
     */
    public createInviteForContract( employer: Employer, employee: Employee, invite?: InviteEmployee): Observable<HttpContractResponse> {

        const contract: any = {};
        contract.employer = employer.id;

        if (employee) {
            contract.employee = employee.id;
        } else if ( invite) {
            contract.employee = null;
            contract.invite = invite;
        }

        return this.httpClient.post<HttpContractResponse>(`${SERVER_URL}/contracts`, contract);
    }

}
