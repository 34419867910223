import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Auth} from 'aws-amplify';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {RepeatPasswordValidator} from '../app.validators';

@Component({
  selector: 'app-complete-new-password',
  templateUrl: './complete-new-password.component.html',
  styleUrls: ['./complete-new-password.component.scss']
})
export class CompleteNewPasswordComponent implements OnInit {

  public formChangePwd: FormGroup;
  public user: any;

  private saveMessage = [ {severity: 'success', summary: 'Senha alterada!'} ]

  constructor(
    private formBuilder: FormBuilder,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) {
    this.formChangePwd = this.formBuilder.group({
        password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(24)]],
        confirmPassword: ['', Validators.required],
    }, {validator: [RepeatPasswordValidator]});

    this.user = config.data.value;
  }

  ngOnInit(): void {
  }

  public async completeNewPassword() {
    const message = this.saveMessage;
    const {
        password
    } = this.formChangePwd.value;

    try {
        await Auth.completeNewPassword(this.user.user, password);
        // await Auth.signIn(this.user.email, password);
        // const response: HttpEmployeeResponse = await this.employeeService.findByEmail(this.user.email).toPromise();
        // await this.appStorageService.setEmployee(response.data);

        this.ref.close(message);
    } catch (e) {
        console.log(e);
    }
  }

}
