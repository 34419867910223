import {NgModule} from '@angular/core';
import {HeaderComponent} from './header/header.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FooterComponent} from './footer/footer.component';
import {AppRoutingModule} from '../app/app-routing.module';
import {SkeletonModule} from 'primeng/skeleton';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SearchbarEmployeeComponent} from './searchbar-employee/searchbar-employee.component';
import {CalendarModule} from 'primeng/calendar';
import {DropdownModule} from 'primeng/dropdown';
import {ToastModule} from 'primeng/toast';
import {SpinnerComponent} from './spinner/spinner.component';
import { CompanyTreeComponent } from './company-tree/company-tree.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import {TreeSelectModule} from 'primeng/treeselect';
import { DepartmentTableComponent } from './department-table/department-table.component';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SelectCompanyComponent } from './select-company/select-company.component';
import { ChronometeComponent } from './chronomete/chronomete.component';
import { WorkingHoursChartComponent } from './working-hours-chart/working-hours-chart.component';
import { RegisterClockByWeekChartComponent } from './register-clock-by-week-chart/register-clock-by-week-chart.component';
import { RegisterClockByDayChartComponent } from './register-clock-by-day-chart/register-clock-by-day-chart.component';
import {DialogModule} from 'primeng/dialog';
import { FileInputComponent } from './file-input/file-input.component';
import { SelectCompaniesComponent } from './select-companies/select-companies.component';
import { JourneyByDayComponent } from './journey-by-day/journey-by-day.component';
import {InputMaskModule} from 'primeng/inputmask';
import { TabViewModule } from 'primeng/tabview';
import {InputSwitchModule} from 'primeng/inputswitch';
import { TimeBankFormReportComponent } from './time-bank-form-report/time-bank-form-report.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        SkeletonModule,
        AppRoutingModule,
        CalendarModule,
        DropdownModule,
        ToastModule,
        TreeSelectModule,
        TableModule,
        ConfirmDialogModule,
        DialogModule,
        InputMaskModule,
        TabViewModule,
        InputSwitchModule
    ],
    exports: [
        HeaderComponent,
        SidebarComponent,
        FooterComponent,
        SearchbarEmployeeComponent,
        SpinnerComponent,
        CompanyTreeComponent,
        DepartmentTableComponent,
        CompanyDetailsComponent,
        SelectCompanyComponent,
        ChronometeComponent,
        WorkingHoursChartComponent,
        RegisterClockByWeekChartComponent,
        RegisterClockByDayChartComponent,
        FileInputComponent,
        SelectCompaniesComponent,
        JourneyByDayComponent,
        FileInputComponent,
        TimeBankFormReportComponent,
    ],
    declarations: [
        HeaderComponent,
        SidebarComponent,
        FooterComponent,
        SearchbarEmployeeComponent,
        SpinnerComponent,
        CompanyTreeComponent,
        DepartmentTableComponent,
        CompanyDetailsComponent,
        SelectCompanyComponent,
        ChronometeComponent,
        WorkingHoursChartComponent,
        RegisterClockByWeekChartComponent,
        RegisterClockByDayChartComponent,
        FileInputComponent,
        SelectCompaniesComponent,
        JourneyByDayComponent,
        FileInputComponent,
        SelectCompaniesComponent,
        TimeBankFormReportComponent,
    ]
})
export class AlphaTimeModule {
}
