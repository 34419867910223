<div>
    <div class="row body-row">
        <div class="col-sm body-row-fields">
            <h3>CNPJ</h3>
            <span>{{ company?.cnpj}}</span>
        </div>
        <div class="col-sm body-row-fields">
            <h3>CEI</h3>
            <span>{{ company?.cei || 'Não informado' }}</span>
        </div>
        <div class="col-sm body-row-fields">
            <h3>Status</h3>
            <span>{{ company?.status === 'ACTIVE' ? 'Ativa' : company?.status ===  'INACTIVE' ? 'Inativa' : '' }}</span>
        </div>
    </div>
    <div class="row body-row">
        <div class="col-sm body-row-fields">
            <h3>Endereço</h3>
            <span>{{ company?.street}}</span>
        </div>
        <div class="col-sm body-row-fields">
            <h3>Número</h3>
            <span>{{ company?.streetNumber}}</span>
        </div>
        <div class="col-sm body-row-fields"></div>
    </div>

    <div class="row body-row">
        <div class="col-sm body-row-fields">
            <h3>CEP</h3>
            <span>{{ company?.zipcode}}</span>
        </div>
        <div class="col-sm body-row-fields">
            <h3>Telefone</h3>
            <span>{{ company?.phoneNumber}}</span>
        </div>
        <div class="col-sm body-row-fields"></div>
    </div>

    <div class="row body-row">
        <div class="col-sm body-row-fields">
            <h3>Cidade</h3>
            <span>{{ company?.city}}</span>
        </div>
        <div class="col-sm body-row-fields">
            <h3>Estado</h3>
            <span>{{ company?.state}}</span>
        </div>
        <div class="col-sm body-row-fields"></div>
    </div>
</div>
