import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ContractEffect } from './effects/contract.effect';
import { contractReducer } from './reduces/contract.reduce';
import { metaReducers } from './reduces/meta.reduce';
import { EmployeeEffect } from './effects/employee.effect';
import { employeeReducer } from './reduces/employee.reduce';


@NgModule({
    imports: [
        CommonModule,
        StoreModule.forRoot({ contractStore: contractReducer, employeeStore: employeeReducer }, { metaReducers }),
        EffectsModule.forRoot([ContractEffect, EmployeeEffect]),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
        }),
    ],
    declarations: []
})
export class RootStoreModule {}
