<!-- <div>
    <button class="btn btn-primary btn-sm" (click)="createJourney()">Atualizar Dados</button>
</div> -->

<div class="alert alert-warning animated--fade-in" role="alert" *ngIf="!formJourney.valid">
    <strong>ATENÇÃO: </strong>Dias marcados como <strong>Jornada Ativa</strong> não podem conter campos vazios ou com 00:00.
</div>

<div class="alert alert-warning animated--fade-in" role="alert" *ngIf="!activeJourney">
    <strong>ATENÇÃO: </strong>Deve existir pelo menos um dia com a jornada ativa.
</div>

<p-tabView styleClass="tabview-custom">
    <p-tabPanel>
        <ng-template pTemplate="header">
            <span>Segunda</span>
        </ng-template>

        <div class="tabview-body div-fade-in">
            <form [formGroup]="formJourney" novalidate>
                <div class="jdb-days-container" id="day1">
                    <div class="form-row">
                        <div class="form-group col-lg-12" style="margin-bottom: 0px;">
                            <label>
                                Período
                            </label>
                        </div>
                        <div class="form-group col-lg-3">
                            <div class="p-field mr-4">
                                <label class="label-period" for="startTimeFirstPeriodDay1">Início Primeiro Período *</label>
                                <p-inputMask class="p-inputtext-sm" id="startTimeFirstPeriodDay1" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="startTimeFirstPeriodDay1"
                                    [disabled]="formJourney.get('enabledMonday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('startTimeFirstPeriodDay1')?.hasError('required') && formJourney.get('startTimeFirstPeriodDay1')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('startTimeFirstPeriodDay1')?.hasError('required')
                                    && formJourney.get('startTimeFirstPeriodDay1')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label class="label-period" for="endTimeFirstPeriodDay1">Fim Primeiro Período *</label>
                            <div class="p-field mr-4">
                                <p-inputMask class="p-inputtext-sm" id="endTimeFirstPeriodDay1" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="endTimeFirstPeriodDay1"
                                    [disabled]="formJourney.get('enabledMonday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('endTimeFirstPeriodDay1')?.hasError('required') && formJourney.get('endTimeFirstPeriodDay1')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('endTimeFirstPeriodDay1')?.hasError('required')
                                    && formJourney.get('endTimeFirstPeriodDay1')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label class="label-period" for="startTimeSecondPeriodDay1">Início Segundo Período *</label>
                            <div class="p-field mr-4">
                                <p-inputMask class="p-inputtext-sm" id="startTimeSecondPeriodDay1" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="startTimeSecondPeriodDay1"
                                    [disabled]="formJourney.get('enabledMonday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('startTimeSecondPeriodDay1')?.hasError('required') && formJourney.get('startTimeSecondPeriodDay1')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('startTimeSecondPeriodDay1')?.hasError('required')
                                    && formJourney.get('startTimeSecondPeriodDay1')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label class="label-period" for="endTimeSecondPeriodDay1">Fim Segundo Período *</label>
                            <div class="p-field">
                                <p-inputMask class="p-inputtext-sm" id="endTimeSecondPeriodDay1" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="endTimeSecondPeriodDay1"
                                    [disabled]="formJourney.get('enabledMonday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('endTimeSecondPeriodDay1')?.hasError('required') && formJourney.get('endTimeSecondPeriodDay1')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('endTimeSecondPeriodDay1')?.hasError('required')
                                    && formJourney.get('endTimeSecondPeriodDay1')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-day d-flex align-items-center mt-4 w-100">
                        <p-inputSwitch formControlName="enabledMonday"
                                       inputId="monday" #mondaySwitch (ngModelChange)="createJourney()"></p-inputSwitch>
                        <label class="mb-1 text-muted">
                            <strong> {{ formJourney.get('enabledMonday')?.value === true ? 'Jornada ativa' : 'Sem jornada (folga)' }}</strong>
                        </label>
                    </div>
                </div>
            </form>
        </div>
    </p-tabPanel>
    <p-tabPanel>
        <ng-template pTemplate="header">
            <span>Terça</span>
        </ng-template>

        <div class="tabview-body div-fade-in">
            <form [formGroup]="formJourney" novalidate>
                <div class="jdb-days-container" id="day2">
                    <div class="form-row">
                        <div class="form-group col-lg-12" style="margin-bottom: 0px;">
                            <label>
                                Período
                            </label>
                        </div>
                        <div class="form-group col-lg-3">
                            <div class="p-field mr-4">
                                <label class="label-period" for="startTimeFirstPeriodDay2">Início Primeiro Período *</label>
                                <p-inputMask class="p-inputtext-sm" id="startTimeFirstPeriodDay2" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="startTimeFirstPeriodDay2"
                                    [disabled]="formJourney.get('enabledTuesday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('startTimeFirstPeriodDay2')?.hasError('required') && formJourney.get('startTimeFirstPeriodDay2')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('startTimeFirstPeriodDay2')?.hasError('required')
                                    && formJourney.get('startTimeFirstPeriodDay2')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label class="label-period" for="endTimeFirstPeriodDay2">Fim Primeiro Período *</label>
                            <div class="p-field mr-4">
                                <p-inputMask class="p-inputtext-sm" id="endTimeFirstPeriodDay2" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="endTimeFirstPeriodDay2"
                                    [disabled]="formJourney.get('enabledTuesday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('endTimeFirstPeriodDay2')?.hasError('required') && formJourney.get('endTimeFirstPeriodDay2')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('endTimeFirstPeriodDay2')?.hasError('required')
                                    && formJourney.get('endTimeFirstPeriodDay2')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label class="label-period" for="startTimeSecondPeriodDay2">Início Segundo Período *</label>
                            <div class="p-field mr-4">
                                <p-inputMask class="p-inputtext-sm" id="startTimeSecondPeriodDay2" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="startTimeSecondPeriodDay2"
                                    [disabled]="formJourney.get('enabledTuesday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('startTimeSecondPeriodDay2')?.hasError('required') && formJourney.get('startTimeSecondPeriodDay2')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('startTimeSecondPeriodDay2')?.hasError('required')
                                    && formJourney.get('startTimeSecondPeriodDay2')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label class="label-period" for="endTimeSecondPeriodDay2">Fim Segundo Período *</label>
                            <div class="p-field">
                                <p-inputMask class="p-inputtext-sm" id="endTimeSecondPeriodDay2" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="endTimeSecondPeriodDay2"
                                    [disabled]="formJourney.get('enabledTuesday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('endTimeSecondPeriodDay2')?.hasError('required') && formJourney.get('endTimeSecondPeriodDay2')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('endTimeSecondPeriodDay2')?.hasError('required')
                                    && formJourney.get('endTimeSecondPeriodDay2')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn-day d-flex align-items-center mt- w-100">
                    <p-inputSwitch formControlName="enabledTuesday"
                                   inputId="monday" #mondaySwitch (ngModelChange)="createJourney()"></p-inputSwitch>
                    <label class="mb-1 text-muted">
                        <strong> {{ formJourney.get('enabledTuesday')?.value === true ? 'Jornada ativa' : 'Sem jornada (folga)' }}</strong>
                    </label>
                </div>
            </form>
        </div>
    </p-tabPanel>
    <p-tabPanel>
        <ng-template pTemplate="header">
            <span>Quarta</span>
        </ng-template>

        <div class="tabview-body div-fade-in">
            <form [formGroup]="formJourney" novalidate>
                <div class="jdb-days-container" id="day3">
                    <div class="form-row">
                        <div class="form-group col-lg-12" style="margin-bottom: 0px;">
                            <label>
                                Período
                            </label>
                        </div>
                        <div class="form-group col-lg-3">
                            <div class="p-field mr-4">
                                <label class="label-period" for="startTimeFirstPeriodDay3">Início Primeiro Período *</label>
                                <p-inputMask class="p-inputtext-sm" id="startTimeFirstPeriodDay3" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="startTimeFirstPeriodDay3"
                                    [disabled]="formJourney.get('enabledWednesday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('startTimeFirstPeriodDay3')?.hasError('required') && formJourney.get('startTimeFirstPeriodDay3')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('startTimeFirstPeriodDay3')?.hasError('required')
                                    && formJourney.get('startTimeFirstPeriodDay3')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label class="label-period" for="endTimeFirstPeriodDay3">Fim Primeiro Período *</label>
                            <div class="p-field mr-4">
                                <p-inputMask class="p-inputtext-sm" id="endTimeFirstPeriodDay3" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="endTimeFirstPeriodDay3"
                                    [disabled]="formJourney.get('enabledWednesday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('endTimeFirstPeriodDay3')?.hasError('required') && formJourney.get('endTimeFirstPeriodDay3')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('endTimeFirstPeriodDay3')?.hasError('required')
                                    && formJourney.get('endTimeFirstPeriodDay3')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label class="label-period" for="startTimeSecondPeriodDay3">Início Segundo Período *</label>
                            <div class="p-field mr-4">
                                <p-inputMask class="p-inputtext-sm" id="startTimeSecondPeriodDay3" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="startTimeSecondPeriodDay3"
                                    [disabled]="formJourney.get('enabledWednesday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('startTimeSecondPeriodDay3')?.hasError('required') && formJourney.get('startTimeSecondPeriodDay3')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('startTimeSecondPeriodDay3')?.hasError('required')
                                    && formJourney.get('startTimeSecondPeriodDay3')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label class="label-period" for="endTimeSecondPeriodDay3">Fim Segundo Período *</label>
                            <div class="p-field">
                                <p-inputMask class="p-inputtext-sm" id="endTimeSecondPeriodDay3" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="endTimeSecondPeriodDay3"
                                    [disabled]="formJourney.get('enabledWednesday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('endTimeSecondPeriodDay3')?.hasError('required') && formJourney.get('endTimeSecondPeriodDay3')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('endTimeSecondPeriodDay3')?.hasError('required')
                                    && formJourney.get('endTimeSecondPeriodDay3')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn-day d-flex align-items-center mt-2 w-100">
                    <p-inputSwitch formControlName="enabledWednesday"
                                   inputId="monday" #mondaySwitch (ngModelChange)="createJourney()"></p-inputSwitch>
                    <label class="mb-1 text-muted">
                        <strong> {{ formJourney.get('enabledWednesday')?.value === true ? 'Jornada ativa' : 'Sem jornada (folga)' }}</strong>
                    </label>
                </div>
            </form>
        </div>
    </p-tabPanel>
    <p-tabPanel>
        <ng-template pTemplate="header">
            <span>Quinta</span>
        </ng-template>

        <div class="tabview-body div-fade-in">
            <form [formGroup]="formJourney" novalidate>
                <div class="jdb-days-container" id="day4">
                    <div class="form-row">
                        <div class="form-group col-lg-12" style="margin-bottom: 0px;">
                            <label>
                                Período
                            </label>
                        </div>
                        <div class="form-group col-lg-3">
                            <div class="p-field mr-4">
                                <label class="label-period" for="startTimeFirstPeriodDay4">Início Primeiro Período *</label>
                                <p-inputMask class="p-inputtext-sm" id="startTimeFirstPeriodDay4" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="startTimeFirstPeriodDay4"
                                    [disabled]="formJourney.get('enabledThursday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('startTimeFirstPeriodDay4')?.hasError('required') && formJourney.get('startTimeFirstPeriodDay4')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('startTimeFirstPeriodDay4')?.hasError('required')
                                    && formJourney.get('startTimeFirstPeriodDay4')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label class="label-period" for="endTimeFirstPeriodDay4">Fim Primeiro Período *</label>
                            <div class="p-field mr-4">
                                <p-inputMask class="p-inputtext-sm" id="endTimeFirstPeriodDay4" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="endTimeFirstPeriodDay4"
                                    [disabled]="formJourney.get('enabledThursday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('endTimeFirstPeriodDay4')?.hasError('required') && formJourney.get('endTimeFirstPeriodDay4')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('endTimeFirstPeriodDay4')?.hasError('required')
                                    && formJourney.get('endTimeFirstPeriodDay4')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label class="label-period" for="startTimeSecondPeriodDay4">Início Segundo Período *</label>
                            <div class="p-field mr-4">
                                <p-inputMask class="p-inputtext-sm" id="startTimeSecondPeriodDay4" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="startTimeSecondPeriodDay4"
                                    [disabled]="formJourney.get('enabledThursday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('startTimeSecondPeriodDay4')?.hasError('required') && formJourney.get('startTimeSecondPeriodDay4')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('startTimeSecondPeriodDay4')?.hasError('required')
                                    && formJourney.get('startTimeSecondPeriodDay4')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label class="label-period" for="endTimeSecondPeriodDay4">Fim Segundo Período *</label>
                            <div class="p-field">
                                <p-inputMask class="p-inputtext-sm" id="endTimeSecondPeriodDay4" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="endTimeSecondPeriodDay4"
                                    [disabled]="formJourney.get('enabledThursday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('endTimeSecondPeriodDay4')?.hasError('required') && formJourney.get('endTimeSecondPeriodDay4')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('endTimeSecondPeriodDay4')?.hasError('required')
                                    && formJourney.get('endTimeSecondPeriodDay4')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn-day d-flex align-items-center mt-2 w-100">
                    <p-inputSwitch formControlName="enabledThursday"
                                   inputId="monday" #mondaySwitch (ngModelChange)="createJourney()"></p-inputSwitch>
                    <label class="mb-1 text-muted">
                        <strong> {{ formJourney.get('enabledThursday')?.value === true ? 'Jornada ativa' : 'Sem jornada (folga)' }}</strong>
                    </label>
                </div>
            </form>
        </div>
    </p-tabPanel>
    <p-tabPanel>
        <ng-template pTemplate="header">
            <span>Sexta</span>
        </ng-template>

        <div class="tabview-body div-fade-in">
            <form [formGroup]="formJourney" novalidate>
                <div class="jdb-days-container" id="day5">
                    <div class="form-row">
                        <div class="form-group col-lg-12" style="margin-bottom: 0px;">
                            <label>
                                Período
                            </label>
                        </div>
                        <div class="form-group col-lg-3">
                            <div class="p-field mr-4">
                                <label class="label-period" for="startTimeFirstPeriodDay5">Início Primeiro Período *</label>
                                <p-inputMask class="p-inputtext-sm" id="startTimeFirstPeriodDay5" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="startTimeFirstPeriodDay5"
                                    [disabled]="formJourney.get('enabledFriday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('startTimeFirstPeriodDay5')?.hasError('required') && formJourney.get('startTimeFirstPeriodDay5')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('startTimeFirstPeriodDay5')?.hasError('required')
                                    && formJourney.get('startTimeFirstPeriodDay5')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label class="label-period" for="endTimeFirstPeriodDay5">Fim Primeiro Período *</label>
                            <div class="p-field mr-4">
                                <p-inputMask class="p-inputtext-sm" id="endTimeFirstPeriodDay5" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="endTimeFirstPeriodDay5"
                                    [disabled]="formJourney.get('enabledFriday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('endTimeFirstPeriodDay5')?.hasError('required') && formJourney.get('endTimeFirstPeriodDay5')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('endTimeFirstPeriodDay5')?.hasError('required')
                                    && formJourney.get('endTimeFirstPeriodDay5')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label class="label-period" for="startTimeSecondPeriodDay5">Início Segundo Período *</label>
                            <div class="p-field mr-4">
                                <p-inputMask class="p-inputtext-sm" id="startTimeSecondPeriodDay5" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="startTimeSecondPeriodDay5"
                                    [disabled]="formJourney.get('enabledFriday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('startTimeSecondPeriodDay5')?.hasError('required') && formJourney.get('startTimeSecondPeriodDay5')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('startTimeSecondPeriodDay5')?.hasError('required')
                                    && formJourney.get('startTimeSecondPeriodDay5')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label class="label-period" for="endTimeSecondPeriodDay5">Fim Segundo Período *</label>
                            <div class="p-field">
                                <p-inputMask class="p-inputtext-sm" id="endTimeSecondPeriodDay5" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="endTimeSecondPeriodDay5"
                                    [disabled]="formJourney.get('enabledFriday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('endTimeSecondPeriodDay5')?.hasError('required') && formJourney.get('endTimeSecondPeriodDay5')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('endTimeSecondPeriodDay5')?.hasError('required')
                                    && formJourney.get('endTimeSecondPeriodDay5')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn-day d-flex align-items-center mt-2 w-100">
                    <p-inputSwitch formControlName="enabledFriday"
                                   inputId="monday" #mondaySwitch (ngModelChange)="createJourney()"></p-inputSwitch>
                    <label class="mb-1 text-muted">
                        <strong> {{ formJourney.get('enabledFriday')?.value === true ? 'Jornada ativa' : 'Sem jornada (folga)' }}</strong>
                    </label>
                </div>
            </form>
        </div>
    </p-tabPanel>
    <p-tabPanel>
        <ng-template pTemplate="header">
            <span>Sábado</span>
        </ng-template>

        <div class="tabview-body div-fade-in">
            <form [formGroup]="formJourney" novalidate>
                <div class="jdb-days-container" id="day6">
                    <div class="form-row">
                        <div class="form-group col-lg-12" style="margin-bottom: 0px;">
                            <label>
                                Período
                            </label>
                        </div>
                        <div class="form-group col-lg-3">
                            <div class="p-field mr-4">
                                <label class="label-period" for="startTimeFirstPeriodDay6">Início Primeiro Período *</label>
                                <p-inputMask class="p-inputtext-sm" id="startTimeFirstPeriodDay6" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="startTimeFirstPeriodDay6"
                                    [disabled]="formJourney.get('enabledSaturday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('startTimeFirstPeriodDay6')?.hasError('required') && formJourney.get('startTimeFirstPeriodDay6')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('startTimeFirstPeriodDay6')?.hasError('required')
                                    && formJourney.get('startTimeFirstPeriodDay6')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label class="label-period" for="endTimeFirstPeriodDay6">Fim Primeiro Período *</label>
                            <div class="p-field mr-4">
                                <p-inputMask class="p-inputtext-sm" id="endTimeFirstPeriodDay6" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="endTimeFirstPeriodDay6"
                                    [disabled]="formJourney.get('enabledSaturday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('endTimeFirstPeriodDay6')?.hasError('required') && formJourney.get('endTimeFirstPeriodDay6')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('endTimeFirstPeriodDay6')?.hasError('required')
                                    && formJourney.get('endTimeFirstPeriodDay6')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label class="label-period" for="startTimeSecondPeriodDay6">Início Segundo Período *</label>
                            <div class="p-field mr-4">
                                <p-inputMask class="p-inputtext-sm" id="startTimeSecondPeriodDay6" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="startTimeSecondPeriodDay6"
                                    [disabled]="formJourney.get('enabledSaturday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('startTimeSecondPeriodDay6')?.hasError('required') && formJourney.get('startTimeSecondPeriodDay6')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('startTimeSecondPeriodDay6')?.hasError('required')
                                    && formJourney.get('startTimeSecondPeriodDay6')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label class="label-period" for="endTimeSecondPeriodDay6">Fim Segundo Período *</label>
                            <div class="p-field">
                                <p-inputMask class="p-inputtext-sm" id="endTimeSecondPeriodDay6" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="endTimeSecondPeriodDay6"
                                    [disabled]="formJourney.get('enabledSaturday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('endTimeSecondPeriodDay6')?.hasError('required') && formJourney.get('endTimeSecondPeriodDay6')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('endTimeSecondPeriodDay6')?.hasError('required')
                                    && formJourney.get('endTimeSecondPeriodDay6')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn-day d-flex align-items-center mt-2 w-100">
                    <p-inputSwitch formControlName="enabledSaturday"
                                   inputId="monday" #mondaySwitch (ngModelChange)="createJourney()"></p-inputSwitch>
                    <label class="mb-1 text-muted">
                        <strong> {{ formJourney.get('enabledSaturday')?.value === true ? 'Jornada ativa' : 'Sem jornada (folga)' }}</strong>
                    </label>
                </div>
            </form>
        </div>
    </p-tabPanel>
    <p-tabPanel>
        <ng-template pTemplate="header">
            <span>Domingo</span>
        </ng-template>

        <div class="tabview-body div-fade-in">
            <form [formGroup]="formJourney" novalidate>
                <div class="jdb-days-container" id="day7">
                    <div class="form-row">
                        <div class="form-group col-lg-12" style="margin-bottom: 0px;">
                            <label>
                                Período
                            </label>
                        </div>
                        <div class="form-group col-lg-3">
                            <div class="p-field mr-4">
                                <label class="label-period" for="startTimeFirstPeriodDay7">Início Primeiro Período *</label>
                                <p-inputMask class="p-inputtext-sm" id="startTimeFirstPeriodDay7" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="startTimeFirstPeriodDay7"
                                    [disabled]="formJourney.get('enabledSunday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('startTimeFirstPeriodDay7')?.hasError('required') && formJourney.get('startTimeFirstPeriodDay7')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('startTimeFirstPeriodDay7')?.hasError('required')
                                    && formJourney.get('startTimeFirstPeriodDay7')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label class="label-period" for="endTimeFirstPeriodDay7">Fim Primeiro Período *</label>
                            <div class="p-field mr-4">
                                <p-inputMask class="p-inputtext-sm" id="endTimeFirstPeriodDay7" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="endTimeFirstPeriodDay7"
                                    [disabled]="formJourney.get('enabledSunday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('endTimeFirstPeriodDay7')?.hasError('required') && formJourney.get('endTimeFirstPeriodDay7')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('endTimeFirstPeriodDay7')?.hasError('required')
                                    && formJourney.get('endTimeFirstPeriodDay7')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label class="label-period" for="startTimeSecondPeriodDay7">Início Segundo Período *</label>
                            <div class="p-field mr-4">
                                <p-inputMask class="p-inputtext-sm" id="startTimeSecondPeriodDay7" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="startTimeSecondPeriodDay7"
                                    [disabled]="formJourney.get('enabledSunday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('startTimeSecondPeriodDay7')?.hasError('required') && formJourney.get('startTimeSecondPeriodDay7')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('startTimeSecondPeriodDay7')?.hasError('required')
                                    && formJourney.get('startTimeSecondPeriodDay7')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label class="label-period" for="endTimeSecondPeriodDay7">Fim Segundo Período *</label>
                            <div class="p-field">
                                <p-inputMask class="p-inputtext-sm" id="endTimeSecondPeriodDay7" placeholder="Ex: 00:00" mask="99:99"
                                    formControlName="endTimeSecondPeriodDay7"
                                    [disabled]="formJourney.get('enabledSunday')?.value === false"
                                    (ngModelChange)="createJourney()"
                                    [ngClass]="{
                                        'is-invalid': formJourney.get('endTimeSecondPeriodDay7')?.hasError('required') && formJourney.get('endTimeSecondPeriodDay7')?.touched}">
                                </p-inputMask>
                                <div class="invalid-feedback" *ngIf="formJourney.get('endTimeSecondPeriodDay7')?.hasError('required')
                                    && formJourney.get('endTimeSecondPeriodDay7')?.touched">
                                    Campo requerido
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn-day d-flex align-items-center mt-2 w-100">
                    <p-inputSwitch formControlName="enabledSunday"
                                   inputId="monday" #mondaySwitch (ngModelChange)="createJourney()"></p-inputSwitch>
                    <label class="mb-1 text-muted">
                        <strong> {{ formJourney.get('enabledSunday')?.value === true ? 'Jornada ativa' : 'Sem jornada (folga)' }}</strong>
                    </label>
                </div>
            </form>
        </div>
    </p-tabPanel>
</p-tabView>
