import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SERVER_URL} from '../environments/environment';
import HttpRegistersClockResponse, { RegisterClock } from '../model/register-clock';
import {BehaviorSubject, Observable} from 'rxjs';
import { HttpRegisterClockResponse } from 'src/model/clock-adjustment';


@Injectable({
    providedIn: 'root'
})
export class RegisterClockService {

    private _groupRecordClocks: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    test$: Observable<any> = this._groupRecordClocks.asObservable();

    constructor(
        private httpClient: HttpClient) {
    }

    public groupRecordClocksObservable() {
        return this._groupRecordClocks;
    }

    get groupRecordClocks(): any {
        return this._groupRecordClocks.getValue();
    }

    set groupRecordClocks(clocks: any) {
        this._groupRecordClocks.next(clocks);
    }

    /**
     *  Add Clock Registers
     *
     */
    public addClockRegister(registerClock: RegisterClock){
        return this.httpClient.post<HttpRegisterClockResponse>(`${SERVER_URL}/clocks/registers/`, registerClock);
    }

    /**
     *  List Clock Registers
     *
     */
    public getClockRegisters(contractId: string, startDate: string, endDate: string): Observable<HttpRegistersClockResponse> {
        return this.httpClient.get<HttpRegistersClockResponse>(`${SERVER_URL}/clocks/registers/${contractId}/startDate/${startDate}/endDate/${endDate}`);
    }

    /**
     *  List Clock Registers by Company
     *
     */
    public listByCompany(companyId: string, startDate: string, endDate: string): Observable<HttpRegistersClockResponse> {
        return this.httpClient.get<HttpRegistersClockResponse>(`${SERVER_URL}/clocks/registers/company/${companyId}/startDate/${startDate}/endDate/${endDate}`);
    }

    /**
     *  List Clock Registers by Employee
     *
     */
    // tslint:disable-next-line:max-line-length
    public getClockRegistersByEmployeeAndDates(employeeId: string | undefined, startDate: string, endDate: string): Observable<HttpRegistersClockResponse> {
        return this.httpClient.get<HttpRegistersClockResponse>(`${SERVER_URL}/clocks/registers/employee/${employeeId}/startDate/${startDate}/endDate/${endDate}`);
    }

    /**
     *  List Clock Registers by Contract and Date
     *
     */
    public getClockRegistersByDate(contractId: string, date: string): Observable<HttpRegistersClockResponse> {
        return this.httpClient.get<HttpRegistersClockResponse>(`${SERVER_URL}/clocks/registers/${contractId}/date/${date}`);
    }
}
