<p-toast></p-toast>



<div [ngSwitch]="screen">
    <img src="../../assets/img/login/element-a.png" class="imagem-a" alt="">
    <img src="../../assets/img/login/element-b.png" class="imagem-b" alt="">
    <img src="../../assets/img/login/logo-descrition.png" class="imagem-c" alt="">
    <img src="../../assets/img/login/mockup3.png" class="imagem-d" alt="">

    <div class="accessWrapper d-flex justify-content-end align-items-center" *ngSwitchCase="0">
        <div class="conteudo">
            <div class="text-center mt-5">
                <h1 class="h4 text-gray-900 mb-4">Confirme seu e-mail <br/> para recuperar sua senha</h1>
            </div>
            <form class="user" [formGroup]="requestCodeForm" (ngSubmit)="forgotPassword()">
                <div class="form-group">
                    <input type="email" class="form-control form-control-user"
                           id="inputEmail" aria-describedby="emailHelp"
                           formControlName="email" name="email"
                           placeholder="Digite seu e-mail..."/>
                    <div
                        *ngIf="requestCodeForm.get('email')?.hasError('required') && requestCodeForm.controls['email'].touched"
                        class="text-danger small text ml-4">Email é requerido
                    </div>
                    <div
                        *ngIf="requestCodeForm.get('email')?.hasError('email') && requestCodeForm.controls['email'].touched"
                        class="text-danger small text ml-4">Email inválido
                    </div>
                </div>
                <button [disabled]="!requestCodeForm.valid"
                        class="btn btn-primary btn-user btn-block">
                    <span *ngIf="!loadingSendCode">Enviar Código</span>
                    <p-progressSpinner
                        styleClass="custom-spinner"
                        [style]="{width: '20px', height: '20px'}"
                        *ngIf="loadingSendCode"></p-progressSpinner>
                </button>
                <button class="btn btn-primary btn-user btn-block mb-5" (click)="backToLogin()">
                    <span>Cancelar</span>
                </button>
            </form>
        </div>
    </div>

    <div class="accessWrapper d-flex justify-content-end align-items-center" *ngSwitchCase="1">
        <div class="conteudo">
            <div class="text-center">
                <h1 class="h4 text-gray-900 mb-4 mt-5">Recuperar Senha</h1>
            </div>
            <form class="user" [formGroup]="changePwdForm" (ngSubmit)="forgotPasswordSubmit()">
                <div class="form-group">
                    <input type="text" class="form-control form-control-user"
                           id="inputCodigo"
                           formControlName="code" name="code"
                           placeholder="Código de Recuperação"/>
                    <div
                        *ngIf="changePwdForm.get('code')?.hasError('required') && changePwdForm.controls['code'].touched"
                        class="text-danger small text ml-4">Código de verificação é requerido
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-icons">
                        <i [class]="showHidePassword ? 'bi bi-eye-slash icon' : 'bi bi-eye icon'"
                            (click)="showHidePassword = !showHidePassword">
                        </i>
                        <input [type]="showHidePassword ? 'password' : 'text'"
                               class="form-control form-control-user"
                               id="inputPwd"
                               formControlName="password" name="password"
                               placeholder="Nova Senha"/>
                    </div>
                    <div
                        *ngIf="changePwdForm.get('password')?.hasError('required') && changePwdForm.controls['password'].touched"
                        class="text-danger small text ml-4">Uma senha é requerida
                    </div>
                    <div
                        *ngIf="changePwdForm.get('password')?.hasError('minlength') && changePwdForm.controls['password'].touched"
                        class="text-danger small text ml-4">A senha deve ter no mínimo 8 caracteres
                    </div>
                    <div
                        *ngIf="changePwdForm.get('password')?.hasError('maxlength') && changePwdForm.controls['password'].touched"
                        class="text-danger small text ml-4">A senha deve ter no máximo 24 caracteres
                    </div>

                </div>
                <div class="form-group">
                    <div class="input-icons">
                        <i [class]="showHideConfirmPassword ? 'bi bi-eye-slash icon' : 'bi bi-eye icon'"
                            (click)="showHideConfirmPassword = !showHideConfirmPassword">
                        </i>
                        <input [type]="showHideConfirmPassword ? 'password' : 'text'" class="form-control form-control-user"
                               id="inputConfirmPwd"
                               formControlName="confirmPassword" name="confirmPassword"
                               placeholder="Confirmar Senha"/>
                    </div>
                    <div
                        *ngIf="changePwdForm.hasError('passwordsNotEqual')&& changePwdForm.controls['confirmPassword'].touched"
                        class="text-danger small text ml-4">Senhas não conferem
                    </div>
                </div>
                <button [disabled]="changePwdForm.invalid"
                        class="btn btn-primary btn-user btn-block mb-5">
                    <span *ngIf="!loadingChangingPwd">Salvar</span>
                    <p-progressSpinner
                        styleClass="custom-spinner"
                        [style]="{width: '20px', height: '20px'}"
                        *ngIf="loadingChangingPwd"></p-progressSpinner>
                </button>
            </form>
        </div>
    </div>
</div>
