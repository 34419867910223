import {Component, OnInit, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import {HttpContractsResponse} from 'src/model/contract';
import {AppStorageService} from 'src/services/app-storage.service';
import {ContractService} from 'src/services/contract.service';
import {RegisterClockService} from 'src/services/register-clock.service';
import * as moment from 'moment';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Employee, HttpEmployeesResponse} from 'src/model/employee';
import {ValidateDatesService} from 'src/services/validate-dates.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ClockAjustmentService} from 'src/services/clock-ajustment.service';
import HttpRegistersClockResponse from '../../model/register-clock';
import {NavigationExtras, Router} from '@angular/router';
import {forkJoin, map} from 'rxjs';
import {EmployeeService} from 'src/services/employee.service';
import { ClockReportByDepartmentService } from 'src/services/clock-report-by-department.service';
import { CompanyService } from 'src/services/company.service';
import { DepartmentService } from 'src/services/department.service';
import { Department } from 'src/model/department';
import { CompleteClockReportDepartmentService } from 'src/services/complete-clock-report-department.service';
import JourneyHelp from '../shared/journey-helper';
import { TimeBankFormReportComponent } from 'src/components/time-bank-form-report/time-bank-form-report.component';
import { SimplifiedCompanyReportService } from 'src/services/simplified-company-report.service';
import { DetailedCompanyReportService } from 'src/services/detailed-company-report.service';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-comp-time',
    templateUrl: './comp-time.component.html',
    styleUrls: ['./comp-time.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class CompTimeComponent implements OnInit {

    @ViewChild('dt') table?: Table;
    @ViewChild('timeBankFormReport', { read: TimeBankFormReportComponent }) timeBankFormReport!: TimeBankFormReportComponent;

    public contracts: any[] = [];
    public registerClocks: any[] = [];
    public clocks: any[] = [];
    public clocksCards: any[] = [];
    public clocksResult: any[] = [];
    public clocksTable: any[] = [];
    public overtime: any [] = [];
    public departments: Department[] = [];
    public departmentName = '';
    public companyName = '';
    public positiveOvertime = '';
    public negativeOvertime = '';
    public hoursBalance = '';
    public loadSkeleton!: boolean;
    public haveClockRegisters = false;
    public groupResult: any;
    public tableToggle = false;
    public resultForClackReportByDepartment: any;
    public thereIsBranc = false;
    public companyIdTemp = '';
    public filterForm: FormGroup;
    public departmentForm: FormGroup;
    public disabledBtnFilter = false;
    public toggleBtnReport = false;
    public loadDepartments = false;
    public logo: any;
    public searchStartPeriods: any;
    public searchEndPeriods: any;
    public picture = './assets/icon/Avatar/user.png';
    public defaultCompanyLogo = '../../assets/img/photography.png';
    public companyLogo = '';
    public showCards = false;
    public showHourAjustment = false;
    public showReportModal = false;
    public showTimeBankReportModal = false;
    public clockAdjustmentDialogInfo: any;
    public contractResponse: any;
    public findEmployeeByDatesResponse: any;
    public findEmployeeByDatesResponseForCards: any;
    private employee: Employee | undefined;

    constructor(
        private formBuilder: FormBuilder,
        private appStorageService: AppStorageService,
        private contractService: ContractService,
        private companyService: CompanyService,
        private departmentService: DepartmentService,
        private employeeService: EmployeeService,
        private registerClockService: RegisterClockService,
        private messageService: MessageService,
        private validateDatesService: ValidateDatesService,
        private confirmationService: ConfirmationService,
        private clockAdjustmentService: ClockAjustmentService,
        private http: HttpClient,
        private clockReportByDepartment: ClockReportByDepartmentService,
        private simplifiedCompanyReportService: SimplifiedCompanyReportService,
        private detailedCompanyReportService: DetailedCompanyReportService,
        private completeClockReportDepartment: CompleteClockReportDepartmentService,
        private router: Router,
    ) {
        this.filterForm = this.formBuilder.group({
            startDate: ['', Validators.required],
            endDate: ['', Validators.required],
            employeeId: ['', Validators.required],
        });
        this.departmentForm = this.formBuilder.group({
            companyId: ['', Validators.required],
            departmentId: ['', Validators.required],
            departmentStartDate: ['', Validators.required],
            departmentEndDate: ['', Validators.required],
        })
    }

    ngOnInit(): void {
        this.tableToggle = false;
        this.loadSkeleton = true;
        this.groupResult = [];
        this.showCards = false;
        this.departmentName = '';
        this.companyName = '';
        this.clocks = [];
        this.toggleBtnReport = false;

        this.loadLocalAssetToBase64();
        this.fetchData().then(r => null);
    }

    async fetchData(): Promise<void> {
        this.employee = await this.appStorageService.getEmployee();
        // const response: HttpContractsResponse | null =
        //     await this.contractService.getContractsByCompany(this.employee.companyId).toPromise() || null;

        await forkJoin(
            this.contractService.getContractsByCompany(this.employee.holderId),
            this.employeeService.findAllByCompany(this.employee.companyId!)).pipe(
            map((response: any) => {
                const httpContractResponse: HttpContractsResponse = response[0];
                const httpEmployeeResponse: HttpEmployeesResponse = response[1];

                httpContractResponse.data!.map((contract) => {
                    contract.employee = httpEmployeeResponse.data.find((el) => el.id === contract.employeeId);
                });
                this.contractResponse = httpContractResponse;
            })
        ).toPromise();



        // @ts-ignore
        this.contractResponse.data.map((contract: Contract) => {
            this.contracts.push({
                employeeId: contract.employeeId,
                id: contract.id,
                name: contract.employee.name,
                picture: contract.employee?.picture,
                journey: contract.journey,
                // startTimeFirstPeriod: contract.startTimeFirstPeriod,
                // endTimeFirstPeriod: contract.endTimeFirstPeriod,
                // startTimeSecondPeriod: contract.startTimeSecondPeriod,
                // endTimeSecondPeriod: contract.endTimeSecondPeriod,
            });
        });

        const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD 00:00:00');
        const endOfMonth = moment().clone().endOf('month').format('YYYY-MM-DD 23:59:59');

        let result: any;

        await forkJoin(
            this.registerClockService.listByCompany(this.employee.companyId as string, startOfMonth, endOfMonth),
            this.employeeService.findAllByCompany(this.employee.companyId!)).pipe(
            map((response: any) => {
                const httpRegisterClockResponse: HttpRegistersClockResponse = response[0];
                const httpEmployeeResponse: HttpEmployeesResponse = response[1];

                httpRegisterClockResponse.data!.map((clock) => {
                    clock.employee = httpEmployeeResponse.data.find((el) => el.id === clock.employeeId);
                });
                result = httpRegisterClockResponse;
            })
        ).toPromise();

        this.groupEmployeeWithContract(result.data, true);
    }

    async findEmployeeByDates(values: any): Promise<void> {
        this.loadSkeleton = true;
        this.clocks = [];
        this.groupResult = [];
        const {employeeId, startDate, endDate} = values;

        const convertStartDataToSearch = moment(startDate, 'DD/MM/YYYY').startOf('hour').format('YYYY-MM-DD 00:00:00');
        const convertEtartDataToSearch = moment(endDate, 'DD/MM/YYYY').endOf('hour').format('YYYY-MM-DD 23:59:59');

        const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD 00:00:00');
        const endOfMonth = moment().clone().endOf('month').format('YYYY-MM-DD 23:59:59');

        this.searchStartPeriods = moment(convertStartDataToSearch, 'YYYY-MM-DD').format('DD/MM/YYYY');
        this.searchEndPeriods = moment(convertEtartDataToSearch, 'YYYY-MM-DD').format('DD/MM/YYYY');

        const isDateValid = this.validateDatesService.validatePeriods(convertStartDataToSearch, convertEtartDataToSearch);

        if (isDateValid) {
            if (convertStartDataToSearch && convertEtartDataToSearch && employeeId) {
                this.showCards = true;

                await forkJoin(
                    this.registerClockService.getClockRegistersByEmployeeAndDates(employeeId, convertStartDataToSearch, convertEtartDataToSearch),
                    this.employeeService.getOne(employeeId)).pipe(
                    map((response: any) => {
                        const httpClockResponse: HttpRegistersClockResponse = response[0];
                        const httpEmployeeResponse: any = response[1];

                        httpClockResponse.data!.map((clock) => {
                            clock.employee = httpEmployeeResponse.data;
                        });
                        this.findEmployeeByDatesResponse = httpClockResponse.data;
                    })
                ).toPromise();

                await forkJoin(
                    this.registerClockService.getClockRegistersByEmployeeAndDates(employeeId, startOfMonth, endOfMonth),
                    this.employeeService.getOne(employeeId)).pipe(
                    map((response: any) => {
                        const httpClockResponse: HttpRegistersClockResponse = response[0];
                        const httpEmployeeResponse: any = response[1];

                        httpClockResponse.data!.map((clock) => {
                            clock.employee = httpEmployeeResponse.data;
                        });
                        this.findEmployeeByDatesResponseForCards = httpClockResponse.data;
                    })
                ).toPromise();

                if (this.findEmployeeByDatesResponse.length > 0) {
                    await this.groupEmployeeWithContract(this.findEmployeeByDatesResponse, false);
                    await this.groupEmployeeWithContractToCards(this.findEmployeeByDatesResponseForCards, false);
                    this.toggleBtnReport = true;
                    this.loadSkeleton = false;
                    this.haveClockRegisters = true;
                } else {
                    this.positiveOvertime = '00:00';
                    this.negativeOvertime = '00:00';
                    this.hoursBalance = '00:00';
                    this.loadSkeleton = false;
                    this.haveClockRegisters = false;
                }
            } else {
                this.showCards = false;
                const companyId = this.employee?.companyId || '';

                await forkJoin(
                    this.registerClockService.listByCompany(companyId, convertStartDataToSearch, convertEtartDataToSearch),
                    this.employeeService.findAllByCompany(companyId)).pipe(
                    map((response: any) => {
                        const httpClockResponse: HttpRegistersClockResponse = response[0];
                        const httpEmployeeResponse: HttpEmployeesResponse = response[1];

                        httpClockResponse.data!.map((clock) => {
                            clock.employee = httpEmployeeResponse.data.find((el: any) => el.id === clock.employeeId);
                        });
                        this.findEmployeeByDatesResponse = httpClockResponse;
                    })
                ).toPromise();

                const response = this.findEmployeeByDatesResponse;

                if (response && response?.data.length > 0) {
                    await this.groupEmployeeWithContract(response?.data, false);
                    this.toggleBtnReport = false;
                    this.loadSkeleton = false;
                    this.haveClockRegisters = true;
                } else {
                    this.loadSkeleton = false;
                    this.haveClockRegisters = false;
                }
            }
        } else {
            this.loadSkeleton = false;
            this.haveClockRegisters = false;
            this.messageService.add({
                severity: 'warn',
                summary: 'Períodos Incorretos!',
                detail: 'Verifique se os intervalos dos períodos estão corretos'
            });
        }
    }

    async groupEmployeeWithContract(response: any, isFetchData: boolean): Promise<void> {
        const registers: [] = [];

        const filterValids = await response.filter((clocks: any) => {
            return clocks.status !== 'INVALID';
        });

        const sorted = filterValids.sort((a: any, b: any) => {
            if (a.employeeId === b.employeeId) {
                return a.recordDateTime < b.recordDateTime ? -1 : 1;
            } else {
                return a.employeeId < b.employeeId ? -1 : 1;
            }
        });

        // @ts-ignore
        await sorted.forEach((el: any, idx: number) => {
            if (el.type === 'F') {
                const result = {entrada: '00:00', saida: '00:00'};
                el.total = this.minsToStr(this.strToMins(result.saida) - this.strToMins(result.entrada));
                el.recordTime = result;
            } else {

                if (idx > 0) {
                    el.address = el.address ? el.address.replace('Unnamed Road', 'Rua Desconhecida') : 'Rua Desconhecida';
                    el.recordTimeStr = el.recordDateTime;
                    el.recordTime = moment(el.recordDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
                    el.total = '00:00';
                    if (sorted[idx - 1].type === 'E' && el.type === 'S') {
                        const inputclock = moment(sorted[idx - 1].recordDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
                        const exitClock = moment(el.recordDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
                        const result = {entrada: inputclock, saida: exitClock};
                        el.total = this.minsToStr(this.strToMins(result.saida) - this.strToMins(result.entrada));
                        el.recordTime = result;
                    }

                }
            }

            // @ts-ignore
            registers.push(el);
        });

        registers.map((results: any) => {
            if (results.type === 'S' || results.type === 'F') {
                this.clocks.push({
                    employeeId: results.employee.id,
                    picture: results.employee.picture,
                    departmentId: results.departmentId,
                    name: results.employee.name,
                    address: results.address,
                    recordDate: results.recordDate,
                    recordTime: results.recordTime,
                    recordDateTime: results.recordDateTime,
                    currentContract: results.currentContract,
                    total: results.total,
                    type: results.type,
                    clockAdjustmentId: results.clockAdjustmentId,
                    status: results.status,
                });
            }
        });

        this.clocks = await this.clocks.reduce((clock, curr) => {
            if (!curr.currentContract) {
                return clock;
            }
            const index = this.contracts.findIndex(employee => employee.employeeId === curr.employeeId);

            const journeyDay = moment(curr.recordDateTime, "YYYY-MM-DD HH:mm:ss").isoWeekday();
            let currentContractValue;
            try {
                currentContractValue = JSON.parse(curr.currentContract);
            } catch (error) {
                console.error('Erro ao fazer o parse de currentContract:', error);
                return clock;
            }
            const journeyJson: any =  JourneyHelp.getJourneyJson(currentContractValue.journey);

            const startTimeFirstPeriod = moment(journeyJson[journeyDay][0], 'HH:mm');
            const endtTimeFirstPeriod = moment(journeyJson[journeyDay][1], 'HH:mm');
            const startTimeSecondPeriod = moment(journeyJson[journeyDay][2], 'HH:mm');
            const endTimeSecondPeriod = moment(journeyJson[journeyDay][3], 'HH:mm');

            if (index > -1) {
                curr.startTimeFirstPeriod = startTimeFirstPeriod.format('HH:mm');
                curr.endtTimeFirstPeriod = endtTimeFirstPeriod.format('HH:mm');
                curr.startTimeSecondPeriod = startTimeSecondPeriod.format('HH:mm');
                curr.endTimeSecondPeriod = endTimeSecondPeriod.format('HH:mm');
            }

            const firstPeriod =
                this.dateDifference(moment(curr.endtTimeFirstPeriod, 'HH:mm'), moment(curr.startTimeFirstPeriod, 'HH:mm'));
            const secondPeriod =
                this.dateDifference(moment(curr.endTimeSecondPeriod, 'HH:mm'), moment(curr.startTimeSecondPeriod, 'HH:mm'));
            const cargaHoraria = firstPeriod + secondPeriod;
            const cargaHorariaFormatada = moment.utc(cargaHoraria * 60 * 1000).format('HH:mm');

            clock.push(
                {
                    ...curr,
                    journey: cargaHorariaFormatada,
                    recordTime: {...curr.recordTime, total: curr.total},
                    address: curr.address,
                    hoursBalance: this.getBalance(curr.total, moment(cargaHorariaFormatada, 'HH').format('HH:mm')),
                }
            );
            return clock;
        }, []);

        if (this.clocks.length > 0) {
            this.loadSkeleton = false;
            this.haveClockRegisters = true;
        } else {
            this.loadSkeleton = false;
            this.haveClockRegisters = false;
        }

        await this.filterClock(this.clocks, isFetchData);

        this.disabledBtnFilter = true;
    }

    async groupEmployeeWithContractToCards(response: any, isFetchData: boolean): Promise<void> {
        this.clocksCards = [];
        const registers: [] = [];

        const filterValids = await response.filter((clocks: any) => {
            return clocks.status !== 'INVALID';
        });

        const sorted = filterValids.sort((a: any, b: any) => {
            if (a.employeeId === b.employeeId) {
                return a.recordDateTime < b.recordDateTime ? -1 : 1;
            } else {
                return a.employeeId < b.employeeId ? -1 : 1;
            }
        });

        // @ts-ignore
        await sorted.forEach((el, idx) => {
            if (el.type === 'F') {
                const result = {entrada: '00:00', saida: '00:00'};
                el.total = this.minsToStr(this.strToMins(result.saida) - this.strToMins(result.entrada));
                el.recordTime = result;
            } else {
                if (idx > 0) {
                    el.address = el.address ? el.address.replace('Unnamed Road', 'Rua Desconhecida') : 'Rua Desconhecida';
                    el.recordTimeStr = el.recordDateTime;
                    el.recordTime = moment(el.recordDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
                    el.total = '00:00';
                    if (sorted[idx - 1].type === 'E' && el.type === 'S') {
                        const inputclock = moment(sorted[idx - 1].recordDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
                        const exitClock = moment(el.recordDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
                        const result = {entrada: inputclock, saida: exitClock};
                        el.total = this.minsToStr(this.strToMins(result.saida) - this.strToMins(result.entrada));
                        el.recordTime = result;
                    }
                }
            }

            // @ts-ignore
            registers.push(el);
        });

        registers.map((results: any) => {
            if (results.type === 'S' || results.type === 'F') {
                this.clocksCards.push({
                    employeeId: results.employee.id,
                    picture: results.employee.picture,
                    name: results.employee.name,
                    address: results.address,
                    recordDate: results.recordDate,
                    recordTime: results.recordTime,
                    currentContract: results.currentContract,
                    recordDateTime: results.recordDateTime,
                    total: results.total,
                    type: results.type,
                    clockAdjustmentId: results.clockAdjustmentId,
                    status: results.status,
                });
            }
        });

        this.clocksCards = await this.clocksCards.reduce((clock, curr) => {
            const index = this.contracts.findIndex(employee => employee.employeeId === curr.employeeId);

            const journeyDay = moment(curr.recordDateTime, "YYYY-MM-DD HH:mm:ss").isoWeekday();
            const currentContractValue = JSON.parse(curr.currentContract);
            const journeyJson: any =  JourneyHelp.getJourneyJson(currentContractValue.journey);

            const startTimeFirstPeriod = moment(journeyJson[journeyDay][0], 'HH:mm');
            const endtTimeFirstPeriod = moment(journeyJson[journeyDay][1], 'HH:mm');
            const startTimeSecondPeriod = moment(journeyJson[journeyDay][2], 'HH:mm');
            const endTimeSecondPeriod = moment(journeyJson[journeyDay][3], 'HH:mm');

            if (index > -1) {
                curr.startTimeFirstPeriod = startTimeFirstPeriod.format('HH:mm');
                curr.endtTimeFirstPeriod = endtTimeFirstPeriod.format('HH:mm');
                curr.startTimeSecondPeriod = startTimeSecondPeriod.format('HH:mm');
                curr.endTimeSecondPeriod = endTimeSecondPeriod.format('HH:mm');
            }

            const firstPeriod =
                this.dateDifference(moment(curr.endtTimeFirstPeriod, 'HH:mm'), moment(curr.startTimeFirstPeriod, 'HH:mm'));
            const secondPeriod =
                this.dateDifference(moment(curr.endTimeSecondPeriod, 'HH:mm'), moment(curr.startTimeSecondPeriod, 'HH:mm'));
            const cargaHoraria = firstPeriod + secondPeriod;
            const cargaHorariaFormatada = moment.utc(cargaHoraria * 60 * 1000).format('HH:mm');

            clock.push(
                {
                    ...curr,
                    journey: cargaHorariaFormatada,
                    recordTime: {...curr.recordTime, total: curr.total},
                    address: curr.address,
                    hoursBalance: this.getBalance(curr.total, moment(cargaHorariaFormatada, 'HH').format('HH:mm')),
                }
            );
            return clock;
        }, []);

        if (this.clocksCards.length > 0) {
            this.loadSkeleton = false;
            this.haveClockRegisters = true;
        } else {
            this.loadSkeleton = false;
            this.haveClockRegisters = false;
        }

        // await this.filterClockCards(this.clocksCards, isFetchData);

        this.disabledBtnFilter = true;
    }

    cleanSearch(): void {
        this.clocks = [];
        this.toggleBtnReport = false;
        this.groupResult = [];
        this.ngOnInit();
        this.filterForm.reset({
            startDate: '',
            endDate: '',
            employeeId: '',
        });
    }

    dateDifference(startDate: any, endDate: any): number {
        const duration = moment.duration(endDate.diff(startDate));
        const totalMinutes = duration.asMinutes();
        return Math.abs(totalMinutes);
    }

    async filterClock(value: any, isFetchData: boolean): Promise<void> {
        const grouped = value.reduce((accumulator: any, element: any) => {
            const key = element.recordDate + element.name; // group by recordDate and name, to generate unique key
            accumulator[key] = accumulator[key] || []; // if is not already a grouping then empty array
            accumulator[key].push(element); // else add the element to group
            return accumulator; // and finally return the accumulator for the next iteration
        }, {});

        const finalResult: any[] = [];
        const keys = Object.keys(grouped);
        keys.forEach((key: any, index: any) => {
            finalResult.push({key, value: grouped[key]});
        });

        const result: any[] = [];

        finalResult.forEach(data => {
            const temp = {
                id: data.value[0].employeeId,
                recordDate: data.value[0].recordDate,
                departmentId: data.value[0].departmentId,
                picture: data.value[0].picture,
                name: data.value[0].name,
                address: data.value[0].address,
                journey: data.value[0].journey,
                recordTime: data.value.map((infor: any) => infor.recordTime),
                totalHours: this.totalClock(data.value.map((infor: any) => infor.total)),
                clockAdjustmentId: data.value[0].clockAdjustmentId,
                status: data.value[0].status,
            };

            result.push(
                {
                    ...temp,
                    hoursBalance: temp.totalHours < moment(temp.journey, 'HH:mm').format('HH:mm')
                        ? `-${moment(this.getBalance(temp.totalHours, moment(temp.journey, 'HH:mm').format('HH:mm')), 'HH:mm').format('HH:mm')}`
                        : `+${moment(this.getBalance(temp.totalHours, moment(temp.journey, 'HH:mm').format('HH:mm')), 'HH:mm').format('HH:mm')}`

                }
            );
        });

        const filterResult = result.filter(r => {
            return r.hoursBalance !== '+00:00' || r.status === 'WAITING_REVIEW';
        });

        this.groupResult = filterResult;
        const resultado = await this.calcularHorasBalance(this.groupResult);

        this.positiveOvertime = resultado.saldoPositivoFormatado;
        this.negativeOvertime = resultado.saldoNegativoFormatado;
        this.hoursBalance = resultado.saldoFormatado;

        this.resultForClackReportByDepartment = this.groupResult;
    }

    async calcularHorasBalance(arrayDeObjetos: any) {
        let totalMinutos = 0;
        let totalMinutosPositivos = 0;
        let totalMinutosNegativos = 0;

        arrayDeObjetos.forEach((item: any) => {
        const [sinal, horas, minutos] = item.hoursBalance.match(/^([-+]?)(\d+):(\d+)$/).slice(1);
        const minutosTotais = (sinal === '+' ? 1 : -1) * (parseInt(horas) * 60 + parseInt(minutos));
        totalMinutos += minutosTotais;

        if (minutosTotais > 0) {
            totalMinutosPositivos += minutosTotais;
        } else {
            totalMinutosNegativos += minutosTotais;
        }
        });

        const horas = Math.floor(Math.abs(totalMinutos) / 60);
        const minutos = Math.abs(totalMinutos) % 60;
        const sinal = totalMinutos >= 0 ? '+' : '-';
        const saldoFormatado = `${sinal}${String(horas).padStart(2, '0')}:${String(minutos).padStart(2, '0')}`;

        const horasPositivas = Math.floor(Math.abs(totalMinutosPositivos) / 60);
        const minutosPositivos = Math.abs(totalMinutosPositivos) % 60;
        const sinalPositivo = totalMinutosPositivos >= 0 ? '+' : '-';
        const saldoPositivoFormatado = `${sinalPositivo}${String(horasPositivas).padStart(2, '0')}:${String(minutosPositivos).padStart(2, '0')}`;

        const horasNegativas = Math.floor(Math.abs(totalMinutosNegativos) / 60);
        const minutosNegativos = Math.abs(totalMinutosNegativos) % 60;
        const sinalNegativo = totalMinutosNegativos >= 0 ? '+' : '-';
        const saldoNegativoFormatado = `${sinalNegativo}${String(horasNegativas).padStart(2, '0')}:${String(minutosNegativos).padStart(2, '0')}`;

        return {saldoFormatado, saldoPositivoFormatado, saldoNegativoFormatado}
    }

    async filterClockCards(value: any, isFetchData: boolean): Promise<void> {
        const grouped = value.reduce((accumulator: any, element: any) => {
            const key = element.recordDate + element.name; // group by recordDate and name, to generate unique key
            accumulator[key] = accumulator[key] || []; // if is not already a grouping then empty array
            accumulator[key].push(element); // else add the element to group
            return accumulator; // and finally return the accumulator for the next iteration
        }, {});

        const finalResult: any[] = [];
        const keys = Object.keys(grouped);
        keys.forEach((key: any, index: any) => {
            finalResult.push({key, value: grouped[key]});
        });

        const result: any[] = [];

        finalResult.forEach(data => {
            const temp = {
                id: data.value[0].employeeId,
                recordDate: data.value[0].recordDate,
                picture: data.value[0].picture,
                name: data.value[0].name,
                address: data.value[0].address,
                journey: data.value[0].journey,
                recordTime: data.value.map((infor: any) => infor.recordTime),
                totalHours: this.totalClock(data.value.map((infor: any) => infor.total)),
                clockAdjustmentId: data.value[0].clockAdjustmentId,
                status: data.value[0].status,
            };

            result.push(
                {
                    ...temp,
                    hoursBalance: temp.totalHours < moment(temp.journey, 'HH:mm').format('HH:mm')
                        ? `-${moment(this.getBalance(temp.totalHours, moment(temp.journey, 'HH:mm').format('HH:mm')), 'HH:mm').format('HH:mm')}`
                        : `+${moment(this.getBalance(temp.totalHours, moment(temp.journey, 'HH:mm').format('HH:mm')), 'HH:mm').format('HH:mm')}`

                }
            );
        });

        const filterResult = result.filter(r => {
            return r.hoursBalance !== '+00:00';
        });

        const times: any[] = [];
        filterResult.forEach((fr: any) => {
            const time: any = fr.hoursBalance;
            times.push(time);
        });

        if (!isFetchData) {
            const positiveTimes = this.addOvertime(times, '+');
            const negativeTimes = this.addOvertime(times, '-');
            this.positiveOvertime = positiveTimes;
            this.negativeOvertime = negativeTimes;

            if (this.positiveOvertime > this.negativeOvertime) {
                this.hoursBalance = this.subTotalOvertime(negativeTimes, positiveTimes, '0');
            } else {
                this.hoursBalance = this.subTotalOvertime(positiveTimes, negativeTimes, '0');
            }
        }
    }

    totalClock(timesClock: any): string {
        let hours = 0;
        let minutes = 0;

        timesClock.forEach((time: any) => {
            if (!time) {
                return; // Pula para a próxima iteração se time for undefined ou null
            }
            const split = time.split(':');
            // tslint:disable-next-line:radix
            hours += parseInt(split[0]);
            // tslint:disable-next-line:radix
            minutes += parseInt(split[1]);
        });

        const formattedNumber = (num: any) => ('0' + num).slice(-2);
        hours += Math.floor(minutes / 60);
        minutes = minutes % 60;

        return formattedNumber(hours) + ':' + formattedNumber(minutes);
    }

    totalOvertime(times: any): string {
        const sum = times.reduce((acc: any, time: any) => acc.add(moment.duration(time)), moment.duration());
        return [this.addLeftZero(Math.floor(sum.asHours())), this.addLeftZero(sum.minutes())].join(':');
    }

    addLeftZero(value: any): any {
        return (value < 10 ? '0' : '') + value;
    }

    subTotalOvertime(startTime: string, endTime: string, lunchTime: string): string {
        const start = moment(startTime, 'HH:mm');
        const end = moment(endTime, 'HH:mm');
        const minutes = end.diff(start, 'minutes');
        const interval = moment().hour(0).minute(minutes);
        interval.subtract(lunchTime, 'minutes');
        return interval.format('HH:mm');
    }

    getBalance(total: any, journey: any): string {
        const totalHour = moment(total, 'HH:mm');
        const jornada = moment(journey, 'HH:mm');
        let duration: any;

        if (totalHour > jornada) {
            duration = moment.duration(totalHour.diff(jornada));
        } else {
            duration = moment.duration(jornada.diff(totalHour));
        }

        return moment.utc(duration.as('milliseconds')).format('HH:mm');
    }

    strToMins(t: any): number {
        const s = t.split(':');
        return Number(s[0].trim()) * 60 + Number(s[1].trim());
    }

    minsToStr(t: any): string {
        return Math.trunc(t / 60) + ':' + ('00' + t % 60).slice(-2);
    }

    goBack(): void {
        this.tableToggle = false;
    }

    addOvertime(data: any[], param: string): string {
        const values: any[] = [];

        data.map((r: any) => values.push(r));

        if (param === '-') {
            const result = values.filter((e: any) => e.includes('-'));
            const timesClock = result.map((v: any) => v.substring(1));
            return this.totalOvertime(timesClock);
        } else {
            const result = values.filter((e: any) => e.includes('+'));
            const timesClock = result.map((v: any) => v.substring(1));
            return this.totalOvertime(timesClock);
        }
    }

    showReportModalByDepartment(): void {
        this.showReportModal = true;
    }

    hideReportModalByDepartment(): void {
        this.showReportModal = false;
        this.departmentForm.reset();
        this.departments = [];
    }

    hideTimeBankReportModal(): void {
        this.showTimeBankReportModal = false;
    }

    async selectedCompanyIdAndDepartmentId(values: any){
        this.departmentForm.get('departmentId')?.setValue('');
        this.loadDepartments = true;
        if (values !== null) {
            const {companyId, branchId, path} = values;

            let type = '';

            if (!branchId === false) {
                const selectedCompany = await this.companyService.get(branchId).toPromise();
                type! = selectedCompany?.data?.type!;
            }
            if (!branchId === true) {
                const selectedCompany = await this.companyService.get(companyId).toPromise();
                type! = selectedCompany?.data?.type!;
            }
            this.thereIsBranc = await this.companyService.thereIsBranch(companyId, type);
            if (companyId !== '' && branchId !== '') {
                this.companyIdTemp = branchId;
            } else {
                this.companyIdTemp = companyId;
            }
            this.departmentForm.get('companyId')?.setValue(this.companyIdTemp);
            await this.getDepartments(this.companyIdTemp)
        } else {
            this.companyIdTemp = '';
            await this.getDepartments(this.companyIdTemp)
        }
    }

    async getDepartments(companyId: string) {
        this.departments = [];

        this.companyService.get(companyId).subscribe((company: any) => {
            if (company.data.logo === undefined) {
                this.companyLogo = this.defaultCompanyLogo;
            } else {
                this.companyLogo = company.data.logo;
            }
        })

        try {
            this.departmentService.list(companyId).subscribe( department => {
                const result: Department[] = [];
                department?.data?.map((department: Department) => {
                    if (department.status === 'ACTIVE') {
                        result.push(department);
                    }
                });
                this.departments = result;
            });
            this.loadDepartments = false;
        } catch (error: any) {}
    }

    getDepartmentName(departmentName: string) {
        this.departmentName = departmentName;
    }

    async generatePdfByDepartment(type: number) {
        const departmentIdSelect = this.departmentForm.get('departmentId')?.value;

        const startDateDepartmentInput = this.departmentForm.get('departmentStartDate')?.value
        const endDateDepartmentInput = this.departmentForm.get('departmentEndDate')?.value

        const convertStartDataToSearch = moment(startDateDepartmentInput, 'DD/MM/YYYY').startOf('hour').format('YYYY-MM-DD 00:00:00');
        const convertEtartDataToSearch = moment(endDateDepartmentInput, 'DD/MM/YYYY').endOf('hour').format('YYYY-MM-DD 23:59:59');

        if (type === 1) {
            await this.clockReportByDepartment.fetchData(
                convertStartDataToSearch,
                convertEtartDataToSearch,
                departmentIdSelect,
                this.departmentName,
                this.companyLogo
            );
        }

        if (type === 2) {
            await this.completeClockReportDepartment.fetchData(
                convertStartDataToSearch,
                convertEtartDataToSearch,
                departmentIdSelect,
                this.departmentName,
                this.companyLogo
            );
        }

        const showEmptResultMessage = this.clockReportByDepartment.showEmptResultMessage;

        if (showEmptResultMessage === true) {
            this.showDepartmentReportMessage();
        }

        this.hideReportModalByDepartment();
    }

    showDepartmentReportMessage() {
        this.messageService.add({
            severity: 'warn',
            summary: 'Sem dados!',
            detail: 'Não há dados nese período para gerar um relatório'
        });
    }

    generatePdf(): void {
        const docDefinition: any = {
            content: [
                {
                    columns: [
                        {
                            image: this.logo,
                            width: 100,
                        },
                        [
                            {
                                text: `${moment().format('DD/MM/YYYY HH:mm:ss')}`,
                                bold: true,
                                color: '#333333',
                                width: '*',
                                fontSize: 9,
                                alignment: 'right',
                                margin: [0, 10],
                            },
                            {
                                text: `${this.groupResult[0].name}`,
                                color: '#333333',
                                width: '*',
                                fontSize: 12,
                                bold: true,
                                alignment: 'right',
                                margin: [0, 5],
                            },
                            {
                                text: `${this.groupResult[0].address}`,
                                color: '#333333',
                                width: '*',
                                fontSize: 10,
                                bold: false,
                                alignment: 'right',
                                margin: [0, 0],
                            },
                        ],
                    ],
                },
                '\n\n',
                {
                    width: '100%',
                    alignment: 'center',
                    text: `Jornada do período de ${this.searchStartPeriods} à ${this.searchEndPeriods}`,
                    bold: true,
                    margin: [0, 10, 0, 10],
                    fontSize: 12,
                },
                {
                    layout: {
                        defaultBorder: false,
                        hLineWidth: (i: any, node: any) => {
                            return 1;
                        },
                        vLineWidth: (i: any, node: any) => {
                            return 1;
                        },
                        hLineColor: (i: any, node: any) => {
                            if (i === 1 || i === 0) {
                                return '#bfdde8';
                            }
                            return '#eaeaea';
                        },
                        vLineColor: (i: any, node: any) => {
                            return '#eaeaea';
                        },
                        hLineStyle: (i: any, node: any) => {
                            return null;
                        },
                        paddingLeft: (i: any, node: any) => {
                            return 1;
                        },
                        paddingRight: (i: any, node: any) => {
                            return 1;
                        },
                        paddingTop: (i: any, node: any) => {
                            return 1;
                        },
                        paddingBottom: (i: any, node: any) => {
                            return 1;
                        },
                        fillColor: (rowIndex: any, node: any, columnIndex: any) => {
                            return '#fff';
                        },
                    },
                    table: {
                        headerRows: 1,
                        widths: ['100%'],
                        body: [
                            // starts header
                            [
                                {
                                    text: '',
                                    border: [false, false, false, false],
                                    alignment: 'center',
                                    textTransform: 'uppercase',
                                },
                            ],
                            // ends header

                            ...this.groupResult.map((p: any) => (
                                [
                                    [
                                        {
                                            text: `Data: ${moment(p.recordDate).format('DD/MM/YYYY')} ::::: Total: ${p.totalHours}`,
                                            border: [false, true, false, true],
                                            bold: true,
                                            margin: [0, 15, 0, 2],
                                            alignment: 'left',
                                            fontSize: 10,
                                        },
                                        {
                                            layout: {
                                                defaultBorder: false,
                                                hLineWidth: (i: any, node: any) => {
                                                    return 1;
                                                },
                                                vLineWidth: (i: any, node: any) => {
                                                    return 1;
                                                },
                                                hLineColor: (i: any, node: any) => {
                                                    return '#eaeaea';
                                                },
                                                vLineColor: (i: any, node: any) => {
                                                    return '#eaeaea';
                                                },
                                                hLineStyle: (i: any, node: any) => {
                                                    return null;
                                                },
                                                paddingLeft: (i: any, node: any) => {
                                                    return 10;
                                                },
                                                paddingRight: (i: any, node: any) => {
                                                    return 10;
                                                },
                                                paddingTop: (i: any, node: any) => {
                                                    return 1;
                                                },
                                                paddingBottom: (i: any, node: any) => {
                                                    return 1;
                                                },
                                                fillColor: (rowIndex: any, node: any, columnIndex: any) => {
                                                    return '#fff';
                                                },
                                            },
                                            table: {
                                                headerRows: 1,
                                                widths: ['40%', '40%', '20%'],
                                                body: [
                                                    [
                                                        {
                                                            text: 'Entrada',
                                                            fillColor: '#eaf2f5',
                                                            border: [false, true, false, true],
                                                            margin: [0, 3, 0, 3],
                                                            alignment: 'left',
                                                            textTransform: 'uppercase',
                                                            fontSize: 9,
                                                        },
                                                        {
                                                            text: 'Saida',
                                                            fillColor: '#eaf2f5',
                                                            border: [false, true, false, true],
                                                            margin: [0, 3, 0, 3],
                                                            alignment: 'left',
                                                            textTransform: 'uppercase',
                                                            fontSize: 9,
                                                        },
                                                        {
                                                            text: 'Total',
                                                            fillColor: '#eaf2f5',
                                                            border: [false, true, false, true],
                                                            margin: [0, 3, 0, 3],
                                                            alignment: 'left',
                                                            textTransform: 'uppercase',
                                                            fontSize: 9,
                                                        },
                                                    ],
                                                    ...p.recordTime.map((r: any) => (
                                                        [
                                                            {
                                                                text: r.entrada,
                                                                border: [false, false, false, true],
                                                                margin: [0, 1, 0, 1],
                                                                alignment: 'left',
                                                                fontSize: 9,
                                                            },
                                                            {
                                                                text: r.saida,
                                                                border: [false, false, false, true],
                                                                margin: [0, 1, 0, 1],
                                                                alignment: 'left',
                                                                fontSize: 9,
                                                            },
                                                            {
                                                                text: r.total,
                                                                border: [false, false, false, true],
                                                                margin: [0, 1, 0, 1],
                                                                alignment: 'left',
                                                                fontSize: 9,
                                                            },
                                                        ]
                                                    )),
                                                ]
                                            },
                                        },
                                    ],
                                ]
                            ))
                        ],
                    }
                }
            ]
        };
        const pdfObject = pdfMake.createPdf(docDefinition);
        // pdfObject.download();
        pdfObject.open({}, window.open('', '_blank'));
    }

    confirmHoursAdjustment(): void {
        this.confirmationService.confirm({
            key: 'confirm-valid',
            message: `Você está validando uma justificativa, tem certeza disso?`,
            header: 'Solicitação de ajuste de horário',
            accept: () => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Confirmado',
                    detail: 'Justificativa validada!'
                });
            },
            reject: () => {
                this.messageService.add({
                    severity: 'warn',
                    summary: 'Não confirmado',
                    detail: 'Validação não confirmada!'
                });
            }
        });
    }

    async onHoursAdjustment(clock: any): Promise<void> {
        try {
            const clockAdjustmentRequest = await this.clockAdjustmentService.getClockAdjustment(clock.clockAdjustmentId).toPromise();
            const clockAdjustmentResult: any = clockAdjustmentRequest?.data || null;

            this.clockAdjustmentDialogInfo = [{
                clock,
                ...clockAdjustmentResult,
            }];

            this.showHourAjustment = true;
        } catch (error) {
            console.log(error);
        }
    }

    async onAcceptClockAdjustment(clockAdjustmentResult: any): Promise<void> {
        try {
            const currContract = await this.appStorageService.getCurrentContract();

            const clocAdjustment: any = {
                contractId: clockAdjustmentResult[0].contractId,
                companyId: clockAdjustmentResult[0].companyId,
                employeeId: clockAdjustmentResult[0].employeeId,
                departmentId: clockAdjustmentResult[0].departmentId,
                accounted: false,
                address: clockAdjustmentResult[0].clock.address,
                currentContract: JSON.stringify(currContract),
                path: clockAdjustmentResult[0].path,
                latitude: clockAdjustmentResult[0].latitude,
                longitude: clockAdjustmentResult[0].longitude,
                recordDate: clockAdjustmentResult[0].recordDate,
                startTimeFirstPeriod: clockAdjustmentResult[0].startTimeFirstPeriod,
                endTimeFirstPeriod: clockAdjustmentResult[0].endTimeFirstPeriod,
                startTimeSecondPeriod: clockAdjustmentResult[0].startTimeSecondPeriod,
                endTimeSecondPeriod: clockAdjustmentResult[0].endTimeSecondPeriod,
            };
            const clockAdjustmentId = clockAdjustmentResult[0].id;
            await this.clockAdjustmentService.acceptClockAdjustment(clocAdjustment, clockAdjustmentId).toPromise();

            this.onCloseHoursAdjustment();
        } catch (error) {
            console.log(error);
            this.onCloseHoursAdjustment();
        }
    }

    async onRejectClockAdjustment(clockAdjustmentResult: any): Promise<void> {
        try {
            const currContract = await this.appStorageService.getCurrentContract();

            const clocAdjustment: any = {
                contractId: clockAdjustmentResult[0].contractId,
                companyId: clockAdjustmentResult[0].companyId,
                employeeId: clockAdjustmentResult[0].employeeId,
                departmentId: clockAdjustmentResult[0].departmentId,
                accounted: false,
                address: clockAdjustmentResult[0].clock.address,
                currentContract: JSON.stringify(currContract),
                path: clockAdjustmentResult[0].path,
                latitude: clockAdjustmentResult[0].latitude,
                longitude: clockAdjustmentResult[0].longitude,
                recordDate: clockAdjustmentResult[0].recordDate,
                startTimeFirstPeriod: clockAdjustmentResult[0].startTimeFirstPeriod,
                endTimeFirstPeriod: clockAdjustmentResult[0].endTimeFirstPeriod,
                startTimeSecondPeriod: clockAdjustmentResult[0].startTimeSecondPeriod,
                endTimeSecondPeriod: clockAdjustmentResult[0].endTimeSecondPeriod,
            };
            const clockAdjustmentId = clockAdjustmentResult[0].id;

            await this.clockAdjustmentService.rejectClockAdjustment(clocAdjustment, clockAdjustmentId).toPromise();
            this.onCloseHoursAdjustment();
        } catch (error) {
            console.log(error);
            this.onCloseHoursAdjustment();
        }
    }

    async manageHour(clock: any) {
        // todo
        const employee: any = await this.employeeService.getOne(clock.id).toPromise();

        const navigationExtras: NavigationExtras = {
            state: {
                clock,
                employee: employee.data
            }
        };
        await this.router.navigate(['/time-management'], navigationExtras);
    }

    onCloseHoursAdjustment(): void {
        this.showHourAjustment = false;
        this.clockAdjustmentDialogInfo = [];
        this.showCards = false;
        this.loadSkeleton = true;
        this.clocks = [];
        this.groupResult = [];
        this.toggleBtnReport = false;
        this.ngOnInit();
    }

    loadLocalAssetToBase64(): void {
        this.http.get('../../assets/img/logo-blue.png', {responseType: 'blob'}).subscribe(res => {
            const reader = new FileReader();
            reader.onloadend = () => {
                this.logo = reader.result;
            };
            reader.readAsDataURL(res);
        });
    }

    onCleanForm(): void {
        this.showCards = false;
        this.loadSkeleton = true;
        this.clocks = [];
        this.groupResult = [];
        this.toggleBtnReport = false;
        // this.fetchData().then(r => null);
        this.ngOnInit();
    }

    lastTwelveMonthsClick(event: any): void {
        this.showCards = event;
    }

    openTimeBankReportModal() {
        this.showTimeBankReportModal = true;
    }

    isFormValid(): boolean {
        if (this.timeBankFormReport) {
          return this.timeBankFormReport.formGroup.valid;
        }
        return false;
     }

    async handleGetTimeBankReportFormValue() {
        const formData = this.timeBankFormReport.formData;

        const startDate = formData.startDate;
        const endDate = formData.endDate;

        const convertStartData = moment(startDate, 'DD/MM/YYYY').startOf('hour').format('YYYY-MM-DD 00:00:00');
        const convertEndtData = moment(endDate, 'DD/MM/YYYY').endOf('hour').format('YYYY-MM-DD 23:59:59');

        if (formData.filters === '1') {
            // type: ECONOMIC_GROUP (sede) ou type: ASSOCIATED_COMPANY ()
            const company: any = await this.companyService.get(formData.companyId).toPromise();

            if (formData.type === 'simplified') {
                await this.simplifiedCompanyReportService.fetchData(
                    convertStartData,
                    convertEndtData,
                    formData.companyId,
                    this.companyName,
                    company.data
                );
            }

            if (formData.type === 'detailed') {
                await this.detailedCompanyReportService.fetchData(
                    convertStartData,
                    convertEndtData,
                    company.data
                );
            }

            const showEmptResultMessage = this.clockReportByDepartment.showEmptResultMessage;

            if (showEmptResultMessage === true) {
                this.showDepartmentReportMessage();
            }

            this.hideTimeBankReportModal();
        }

        if (formData.filters === '2') {
            // type: BRANCH (filial)
            const company: any = await this.companyService.get(formData.branchId).toPromise();

            if (formData.type === 'simplified') {
                await this.simplifiedCompanyReportService.fetchData(
                    convertStartData,
                    convertEndtData,
                    formData.branchId,
                    this.companyName,
                    company.data
                );
            }

            if (formData.type === 'detailed') {
                await this.detailedCompanyReportService.fetchData(
                    convertStartData,
                    convertEndtData,
                    company.data
                );
            }

            const showEmptResultMessage = this.clockReportByDepartment.showEmptResultMessage;

            if (showEmptResultMessage === true) {
                this.showDepartmentReportMessage();
            }

            this.hideTimeBankReportModal();
        }

        if (formData.filters === '3') {
            const company: any = await this.companyService.get(formData.companyId).toPromise();

            if (formData.type === 'simplified') {
                await this.clockReportByDepartment.fetchData(
                    convertStartData,
                    convertEndtData,
                    formData.departmentId,
                    this.departmentName,
                    company.data
                );
            }

            if (formData.type === 'detailed') {
                await this.completeClockReportDepartment.fetchData(
                    convertStartData,
                    convertEndtData,
                    formData.departmentId,
                    this.departmentName,
                    company.data
                );
            }

            const showEmptResultMessage = this.clockReportByDepartment.showEmptResultMessage;

            if (showEmptResultMessage === true) {
                this.showDepartmentReportMessage();
            }

            this.hideTimeBankReportModal();
        }
    }
}
