type Product = {
    MENSAL: {
        STUP: string;
        PE: string;
        ME1: string;
        ME2: string;
    };
    ANUAL: {
        STUP: string;
        PE: string;
        ME1: string;
        ME2: string;
    };
};

export const environment = {
    production: true,
    apiKey: 'JDPA5HlKoU4yFrxbtnH7K7boiHZVEG3laGhucOcH',
    ablyApiKey: '_P_hDw.GmTmWw:uS8-81eMlryJgOZXAIK3kNtsOnYwfjUXxH8Gyj6veL8'
};

export const COGNITO = {
    identityPoolId: 'us-east-1:9084ba56-63f2-43b8-af9c-4c1b44247b21',
    region: 'us-east-1',
    userPoolId: 'us-east-1_ojSfYn7gi',
    userPoolWebClientId: '1nj2rthpnnqjgcm013ch1n7qh9',
};

export const S3_BUCKET = {
    name: 'employee-picture-master',
    region: 'us-east-1',
};

export const API_NAME = 'ALPHATIME_API';

// export const SERVER_URL = 'https://wg1loo6vwg.execute-api.us-east-1.amazonaws.com/master';
export const SERVER_URL = 'https://api.alphatime.net';
export const REGISTER_APP_URL = 'http://localhost:4300';
export const WS_URL = 'wss://5cq22xggb1.execute-api.us-east-1.amazonaws.com/master';
export const EMPLOYEE_IMG_URL = 'https://d3b96fki1du9ym.cloudfront.net/public/';
export const JUSTIFICATION_FILE_URL = 'https://d2yodsgszncdde.cloudfront.net/public/';
export const STRIPE_API = 'pk_live_crLLURS5YUEYDsxESoN9CyAG00NBVWgVft';

export const PRODUCTS: Product = {
    MENSAL: {
        STUP: 'price_1LGBtILmDyW1w19xYJSg7dCj',
        PE: 'price_1N6X5QLmDyW1w19xFWSMVng4',
        ME1: 'price_1N6X0KLmDyW1w19xLBUuxAjc',
        ME2: 'price_1LGBwKLmDyW1w19xoglu7GtF'
    },
    ANUAL: {
        STUP: 'price_1LGBtILmDyW1w19xovfgOz6m',
        PE: 'price_1LGBuILmDyW1w19xwLo5N9OE',
        ME1: 'price_1LGBv7LmDyW1w19x3hrs1r5U',
        ME2: 'price_1LGBwKLmDyW1w19x887mwgr2'
    }
};
export const PUBLIC_URL = ['signature'];
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
