import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Company } from 'src/model/company';
import { Department } from 'src/model/department';
import { DepartmentService } from 'src/services/department.service';
import { EventEmitterService } from 'src/services/event-emitter.service';

@Component({
    selector: 'app-department-create',
    templateUrl: './department-create.component.html',
    styleUrls: ['./department-create.component.scss'],
})
export class DepartmentCreateComponent implements OnInit {
    public company: Company;
    public formRegister: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        public config: DynamicDialogConfig,
        private departmentService: DepartmentService,
        public ref: DynamicDialogRef,
    ) {
        this.formRegister = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(120)]],
            companyId: [''],
        });

        this.formRegister.patchValue(config);
        this.company = config.data.values;
    }

    ngOnInit(): void {
        this.initializePage();
    }

    async initializePage() {
    }

    async selectValues(value: any) {
        const {companyId, branchId} = value;

        if (companyId !== '' && branchId !== '') {
            this.formRegister.get('companyId')?.setValue(branchId);
        } else {
            this.formRegister.get('companyId')?.setValue(companyId);
        }
    }

    public async onSave() {
        const { name, companyId } = this.formRegister.value;
        const department = {
            name,
            companyId
        };

        try {
            this.departmentService.save(department as Department).subscribe(response => {});
            EventEmitterService.get(EventEmitterService.DEPARTMENT_EVENT_SAVE).emit(true);
            this.ref.close();
        } catch (error) {
            console.log('Error: ', error)
        }
    }

    onClose() {
        this.ref.close();
    }

}
