import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {AdminLayoutComponent} from './admin/admin-layout.component';
import {AlphaTimeModule} from '../components/componentes.moduel';
import {RouterModule} from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        AlphaTimeModule,
        RouterModule,
    ],
    exports: [ AdminLayoutComponent],
    declarations: [ AdminLayoutComponent]
})
export class LayoutModule {}
