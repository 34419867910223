<form [formGroup]="formSelect" novalidate>
    <div class="form-row">
        <div class="form-group col-lg-6">
            <label for="companyPosition">Empresa *</label>
            <div class="d-flex justify-content-start align-items-center">
                <select class="form-control w-100" id="company" formControlName="companyId"
                        style="width: 50%;"
                        (ngModelChange)="onChange($event)"
                        [ngClass]="{
                            'is-invalid': formSelect.get('companyId')?.hasError('required') && formSelect.get('companyId')?.touched
                        }">
                    <option value="" disabled>Escolha a empresa</option>
                    <option value="{{company?.id}}">{{company?.name}}</option>
                    <option *ngFor="let item of companyList"
                            value="{{item.id}}">{{item.name}}</option>
                </select>
            </div>
            <div class="custom-invalid-feedback" *ngIf="formSelect.get('companyId')?.hasError('required')
                && formSelect.get('companyId')?.touched">
                Empresa é requerido
            </div>
        </div>

        <div class="form-group col-lg-6">
            <label for="companyPosition">Filial</label>
            <div class="d-flex justify-content-start align-items-center">
                <select class="form-control w-100" id="company" formControlName="branchId"
                        style="width: 50%;"
                        (ngModelChange)="onSelectBranch()"
                        [ngClass]="{
                            'is-invalid': formSelect.get('branchId')?.hasError('required') && formSelect.get('branchId')?.touched
                        }">
                    <option value="">Escolha a Filial</option>
                    <option *ngFor="let item of branchList"
                            value="{{item.id}}">{{item.name}}</option>
                </select>
            </div>
        </div>
    </div>
</form>
