import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_URL } from 'src/environments/environment';
import HttpInformationResponse from 'src/model/information';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  constructor(
    private httpClient: HttpClient
  ) { }

  /**
   * Get the attachment by ref
   */
  public getFiles(ref: string) {
    return this.httpClient.get<HttpInformationResponse>(`${SERVER_URL}/attachment/ref/${ref}`);
  }

}
