import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {Observable, Subject} from 'rxjs';
import {WebsocketService} from './websocket-service';
import Notification from '../model/notification';
import {SERVER_URL} from '../environments/environment';
import HttpNoticeResponse, { Notice } from 'src/model/notice';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  //public messages: Subject<Notification>;

  constructor(
      private webSocket: WebsocketService,
      private httpClient: HttpClient,
  ) {
    //this.messages = this.webSocket.connect();
  }

  /**
   *  Notice save
   */
   public createNotice(notice: Notice): Observable<HttpNoticeResponse> {
    return this.httpClient.post<HttpNoticeResponse>(`${SERVER_URL}/notifications/notices`, notice);
   }

   /**
    *  Notice update
    */
   public updateNotice(notice: Notice, id: string): Observable<HttpNoticeResponse> {
    const data = Object.assign({}, notice);

    delete data.id;

    return this.httpClient.put<HttpNoticeResponse>(`${SERVER_URL}/notifications/notices/${id}`, data);
   }

   /**
    *  Notice list by company
    */
   public listByCompany(companyId: string): Observable<HttpNoticeResponse> {
    return this.httpClient.get<HttpNoticeResponse>(`${SERVER_URL}/notifications/notices/company/${companyId}`);
   }

   /**
    *  Notice delete
    */
   public deleteNotice(id: string): Observable<HttpNoticeResponse> {
    return this.httpClient.delete<HttpNoticeResponse>(`${SERVER_URL}/notifications/notices/${id}`);
   }

   /**
    *  Company Register
    *
    */
  public sendContact(data: any): Observable<any> {
    return this.httpClient.post(`${SERVER_URL}/notifications/contact`, data, { headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': '6Z9tNsDftg2YIItddgDpu6QQ0PvrHzCi51oWChKp' }) });
  }

}
