import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {Table} from 'primeng/table';
import {Contract} from 'src/model/contract';
import {JustificationService} from 'src/services/justification.service';
import {JUSTIFICATION_FILE_URL} from '../../environments/environment';

@Component({
    selector: 'app-employee-justifications',
    templateUrl: './employee-justifications.component.html',
    styleUrls: ['./employee-justifications.component.scss'],
    providers: [MessageService]
})
export class EmployeeJustificationsComponent implements OnInit {

    @Input() contract: Contract = new Contract;
    @ViewChild('dt') table?: Table;
    @ViewChild('#image') image: HTMLImageElement | undefined;

    public justifications: any[] = [];
    public imageUrl?: string;
    public date?: string;
    public loadSkeleton!: boolean;
    public filterForm: FormGroup;
    public url = JUSTIFICATION_FILE_URL;
    public justificationsFound = false;
    public display = false;

    constructor(
        private justificationService: JustificationService,
        private formBuilder: FormBuilder,
    ) {
        this.filterForm = this.formBuilder.group({
            startDate: ['', Validators.required],
            endDate: ['', Validators.required],
        });
    }

    async ngOnInit(): Promise<void> {
        this.loadSkeleton = true;
        await this.initializePage();
    }

    async initializePage(): Promise<void> {

        const response: any = await this.justificationService
            .findAllByContract(this.contract.id!)
            .toPromise();
        if (Object.keys(response.data).length !== 0) {
            this.justifications = await response.data.sort((a: any, b: any) => {
                return new Date(b.recordDate).getTime() - new Date(a.recordDate).getTime();
            });
            this.justificationsFound = true;
        } else {
            this.justificationsFound = false;
        }
    }

    async getJustificationImage(key: string): Promise<void> {
        this.imageUrl = `${JUSTIFICATION_FILE_URL}${key}`;
        this.display = true;
    }

    hideDialog(): void {
        this.display = false;
    }

}
