import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppStorageService} from '../../services/app-storage.service';
import {Subject, forkJoin, map, takeUntil} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RegisterClockService} from '../../services/register-clock.service';
import HttpRegistersClockResponse, {RegisterClock} from '../../model/register-clock';
import {Employee, HttpEmployeesResponse} from '../../model/employee';
import * as moment from 'moment';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {HttpClient} from '@angular/common/http';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ValidateDatesService} from 'src/services/validate-dates.service';
import { EmployeeService } from 'src/services/employee.service';
import { AttachmentService } from 'src/services/attachment.service';
import { DomSanitizer } from '@angular/platform-browser';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-employee',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class HistoryComponent implements OnInit, OnDestroy {
    public clockRegisters: RegisterClock[] = [];
    public haveClockRegisters = false;
    public employees: Employee[] = [];
    public searchResult: any[] = [];
    public clockResponse: any;
    public dtTrigger: Subject<any> = new Subject<any>();
    public filterForm: FormGroup;
    public logo: any;
    public loading = false;
    public showPhotosModal = false;
    private employee: Employee | undefined;
    private destroy$ = new Subject<void>();
    searchWithAttachment: boolean = false;
    tooltipText = 'Filtrar com enexos';

    first: number = 0;
    totalRecords: number = 10;
    images: string[] = [];
    currentImage: string | undefined;

    constructor(
        private attachmentService: AttachmentService,
        private formBuilder: FormBuilder,
        private sanitizer: DomSanitizer,
        private appStorageService: AppStorageService,
        private employeeService: EmployeeService,
        private registerClockService: RegisterClockService,
        private messageService: MessageService,
        private validateDatesService: ValidateDatesService,
        private http: HttpClient,
    ) {
        this.filterForm = this.formBuilder.group({
            startDate: ['', Validators.required],
            endDate: ['', Validators.required],
            employeeId: ['', Validators.required],
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    async ngOnInit(): Promise<void> {
        await this.initialization();
    }

    private async initialization(): Promise<void> {
        this.loading = true;
        this.haveClockRegisters = false;
        this.searchResult = [];
        this.loadLocalAssetToBase64();
        await this.fetchData();
    }

    async fetchData(): Promise<void> {
        this.employee = await this.appStorageService.getEmployee();

        const startDate = moment().clone().startOf('month').subtract(1, 'days').toISOString();
        const endDate   = moment().clone().endOf('month').toISOString();

        await forkJoin(
            this.registerClockService.listByCompany(this.employee.companyId as string, startDate, endDate),
            this.employeeService.findAllByCompany(this.employee.companyId!)).pipe(
                map((response: any) => {
                    const httpClockResponse: HttpRegistersClockResponse = response[0];
                    const httpEmployeeResponse: HttpEmployeesResponse = response[1];

                    httpClockResponse.data!.map((clock) => {
                        clock.employee = httpEmployeeResponse.data.find((el) => el.id === clock.employeeId)
                    })
                    this.clockResponse = httpClockResponse
                })
            ).toPromise();

        this.clockRegisters = this.clockResponse.data.filter((clocks: any) => {
            return clocks.status !== 'INVALID';
        });


        if (this.clockRegisters?.length > 0) {
            this.haveClockRegisters = false;
            this.loading = false;
            this.pdfData(this.clockRegisters);
        } else {
            this.haveClockRegisters = true;
            this.loading = false;
        }
    }

    async search(values: any): Promise<void> {
        this.loading = true;
        this.haveClockRegisters = false;
        this.clockRegisters = [];
        this.searchResult = [];
        const {employeeId, startDate, endDate} = values;

        const convertStartDataToSearch = moment(startDate, 'DD/MM/YYYY').startOf('hour').format(
            'YYYY-MM-DD 00:00:00'
        );
        const convertEtartDataToSearch = moment(endDate, 'DD/MM/YYYY').endOf('hour').format(
            'YYYY-MM-DD 23:59:59'
        );
        const isDateValid = this.validateDatesService.validatePeriods(convertStartDataToSearch, convertEtartDataToSearch);

        if (isDateValid) {
            if (convertStartDataToSearch && convertEtartDataToSearch && employeeId) {

                await forkJoin(
                    this.registerClockService.getClockRegistersByEmployeeAndDates(employeeId, convertStartDataToSearch, convertEtartDataToSearch),
                    this.employeeService.getOne(employeeId)).pipe(
                        map((response: any) => {
                            const httpClockResponse: HttpRegistersClockResponse = response[0];
                            const httpEmployeeResponse: any = response[1];

                            httpClockResponse.data!.map((clock) => {
                                clock.employee = httpEmployeeResponse.data
                            })
                            this.clockResponse = httpClockResponse
                        })
                    ).toPromise();

                this.clockRegisters = this.clockResponse.data.filter((clocks: any) => {
                    return clocks.status !== 'INVALID';
                });
            } else {
                const companyId = this.employee?.companyId;

                await forkJoin(
                this.registerClockService.listByCompany(companyId!, convertStartDataToSearch, convertEtartDataToSearch),
                this.employeeService.findAllByCompany(companyId!)).pipe(
                    map((response: any) => {
                        const httpClocskResponse: HttpRegistersClockResponse = response[0];
                        const httpEmployeeResponse: HttpEmployeesResponse = response[1];

                        httpClocskResponse.data!.map((clock) => {
                            clock.employee = httpEmployeeResponse.data.find((el: any) => el.id === clock.employeeId)
                        })
                        this.clockResponse  = httpClocskResponse
                    })
            ).toPromise();

                const response = this.clockResponse;

                if (this.searchWithAttachment) {
                    this.clockRegisters = response!.data.filter((clocks: any) => {
                        return clocks.status !== 'INVALID'  && clocks.hasAttachment === true;
                    });
                } else {
                    this.clockRegisters = response!.data.filter((clocks: any) => {
                        return clocks.status !== 'INVALID';
                    });
                }

            }


            if (this.clockRegisters.length > 0) {
                this.pdfData(this.clockRegisters);
                this.loading = false;
            } else {
                this.loading = false;
                this.haveClockRegisters = true;
                this.messageService.add({
                    severity: 'warn',
                    summary: 'Nenhum resultado para o intervalo selecionado!',
                });
            }
        } else {
            this.loading = false;
            this.haveClockRegisters = true;
            this.messageService.add({
                severity: 'warn',
                summary: 'Períodos Incorretos!',
                detail: 'Verifique se os intervalos dos períodos estão corretos'
            });
        }
    }

    public pdfData(value: any): void {
        const orderResul = value.sort((a: any, b: any) =>
            (a.recordDate < b.recordDate) ? 1 : ((b.recordDate < a.recordDate) ? -1 : 0));

        orderResul.map((results: any) => {
            this.searchResult.push({
                type: results.type === 'S' ? 'Saída' : 'Entrada',
                name: results.employee.name,
                recordDate: results.recordDate,
                recordTime: results.recordTime,
                address: results.address,
            });
        });
    }

    public generatePdf(): void {
        const docDefinition: any = {
            content: [
                {
                    columns: [
                        {
                            image: this.logo,
                            width: 100,
                        },
                        [
                            {
                                text: `Documento gerado em: ${moment().format('DD/MM/YYYY HH:mm:ss')}`,
                                bold: true,
                                color: '#333333',
                                fontSize: 9,
                                alignment: 'right',
                                width: 100,
                            },
                        ],
                    ],
                },
                '\n\n',
                {
                    width: '100%',
                    alignment: 'center',
                    text: 'Registros',
                    bold: true,
                    margin: [0, 10, 0, 10],
                    fontSize: 15,
                },
                {
                    layout: {
                        defaultBorder: false,
                        hLineWidth: (i: any, node: any) => {
                            return 1;
                        },
                        vLineWidth: (i: any, node: any) => {
                            return 1;
                        },
                        hLineColor: (i: any, node: any) => {
                            if (i === 1 || i === 0) {
                                return '#bfdde8';
                            }
                            return '#eaeaea';
                        },
                        vLineColor: (i: any, node: any) => {
                            return '#eaeaea';
                        },
                        hLineStyle: (i: any, node: any) => {
                            return null;
                        },
                        paddingLeft: (i: any, node: any) => {
                            return 10;
                        },
                        paddingRight: (i: any, node: any) => {
                            return 10;
                        },
                        paddingTop: (i: any, node: any) => {
                            return 2;
                        },
                        paddingBottom: (i: any, node: any) => {
                            return 2;
                        },
                        fillColor: (rowIndex: any, node: any, columnIndex: any) => {
                            return '#fff';
                        },
                    },
                    table: {
                        headerRows: 1,
                        widths: ['30%', '40%', '15%', '10%', '10%'],
                        body: [
                            [
                                {
                                    text: 'Nome',
                                    fillColor: '#eaf2f5',
                                    border: [false, true, false, true],
                                    margin: [0, 5, 0, 5],
                                    alignment: 'center',
                                    textTransform: 'uppercase',
                                    fontSize: 10,
                                },
                                {
                                    text: 'Endereço',
                                    fillColor: '#eaf2f5',
                                    border: [false, true, false, true],
                                    margin: [0, 5, 0, 5],
                                    alignment: 'center',
                                    textTransform: 'uppercase',
                                    fontSize: 10,
                                },
                                {
                                    text: 'Data',
                                    fillColor: '#eaf2f5',
                                    border: [false, true, false, true],
                                    margin: [0, 5, 0, 5],
                                    alignment: 'center',
                                    textTransform: 'uppercase',
                                    fontSize: 10,
                                },
                                {
                                    text: 'Hora',
                                    fillColor: '#eaf2f5',
                                    border: [false, true, false, true],
                                    margin: [0, 5, 0, 5],
                                    alignment: 'center',
                                    textTransform: 'uppercase',
                                    fontSize: 10,
                                },
                                {
                                    text: 'Tipo',
                                    fillColor: '#eaf2f5',
                                    border: [false, true, false, true],
                                    margin: [0, 5, 0, 5],
                                    alignment: 'center',
                                    textTransform: 'uppercase',
                                    fontSize: 10,
                                },
                            ],
                            ...this.searchResult.map(p => (
                                [
                                    {
                                        text: p.name,
                                        border: [false, false, false, true],
                                        margin: [0, 5, 0, 5],
                                        alignment: 'left',
                                        fontSize: 8,
                                    },
                                    {
                                        text: p.address,
                                        border: [false, false, false, true],
                                        margin: [0, 5, 0, 5],
                                        alignment: 'left',
                                        fontSize: 8,
                                    },
                                    {
                                        text: moment(p.recordDate).format('DD/MM/YYYY'),
                                        border: [false, false, false, true],
                                        margin: [0, 5, 0, 5],
                                        alignment: 'left',
                                        fontSize: 8,
                                    },
                                    {
                                        text: p.recordTime,
                                        border: [false, false, false, true],
                                        margin: [0, 5, 0, 5],
                                        alignment: 'left',
                                        fontSize: 8,
                                    },
                                    {
                                        text: p.type,
                                        border: [false, false, false, true],
                                        style: p.type === 'Entrada' ? 'entrada' : 'saida',
                                        alignment: 'right',
                                        fontSize: 8,
                                        margin: [0, 5, 0, 5],
                                    },
                                ]
                            ))
                        ],
                    },
                },
                '\n',
                '\n\n',
                '\n\n',
            ],
            styles: {
                entrada: {
                    color: '#13885e',
                },
                saida: {
                    color: '#FA1311'
                },
            },
            defaultStyle: {
                columnGap: 20,
            },
        };
        const pdfObject = pdfMake.createPdf(docDefinition);
        // pdfObject.download();
        pdfObject.open({}, window.open('', '_blank'));
    }

    public loadLocalAssetToBase64(): void {
        this.http.get('../../assets/img/logo-blue.png', {responseType: 'blob'}).subscribe(res => {
            const reader = new FileReader();
            reader.onloadend = () => {
                this.logo = reader.result;
            };
            reader.readAsDataURL(res);
        });
    }

    public async onCleanForm(): Promise<void> {
        this.clockRegisters = [];
        await this.initialization();
    }

    onCheckboxChange(event: any): void {
        if (event.checked.length > 0) {
            this.tooltipText = 'Desmarcar filtrar com enexo'
        } else {
            this.tooltipText = 'Filtrar com enexos'
        }
        this.searchWithAttachment = event.checked;
    }

    public async onBtnShowPhotos(id: string): Promise<void>  {
        this.images = [];
        this.currentImage = '';
        this.totalRecords = 0;

        this.attachmentService.getFiles(id)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (images: any) => {
                    const url = images?.data?.map((image: any) => image.url) || [];
                    this.images = url;
                    this.totalRecords = this.images.length;
                    this.updateCurrentImage();
                    this.showPhotosModal = true;
                },
                error: (err) => {
                    console.error('Erro ao buscar as imagens:', err);
                }
            });
    }

    public onBtnCloseModal() {
        this.destroy$.next();
        this.destroy$.complete();
        this.showPhotosModal = false;
    }

    onPageChange(event: any) {
        this.first = event.first;
        this.updateCurrentImage();
    }
    
    updateCurrentImage() {
        this.currentImage = this.images[this.first];
    }

    async downloadAllImages() {
        if (!this.images || this.images.length === 0) {
          console.error('Nenhuma imagem disponível para download');
          return;
        }

        const promises = this.images.map((imageUrl: string, index: number) => 
            this.fetchAndDownloadImage(imageUrl, `image-${index + 1}.jpg`)
        );
      
        Promise.all(promises)
            .then(() => console.log('Todas as imagens foram baixadas com sucesso'))
            .catch(err => console.error('Erro ao baixar uma ou mais imagens:', err));
    }

    async fetchAndDownloadImage(imageUrl: string, filename: string): Promise<void> {
        try {
            const response = await fetch(imageUrl);
            if (!response.ok) {
                throw new Error(`Erro na resposta da rede: ${response.statusText}`);
            }
            const blob = await response.blob();
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            link.href = url;
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (err) {
            console.error(`Erro ao baixar a imagem (${filename}):`, err);
            return await Promise.reject(err);
        }
      }
      
}
