import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';
import {EventEmitterService} from './event-emitter.service';
import ChannelNotification from '../model/channel-notifications';

declare var Ably: any;
@Injectable({
    providedIn: 'root'
})
export class AblyService {

    private ably: any;
    private employeeChannel: any;
    private companyChannel: any;
    constructor() {
        this.ably = new Ably.Realtime(environment.ablyApiKey);
    }

    public addListenerEmployeeChannel(employeeId: string| undefined): void{
        if (!this.employeeChannel && employeeId){
            this.employeeChannel = this.ably.channels.get(employeeId);

            this.employeeChannel.subscribe(ChannelNotification.START_EMPLOYEE_CSV_IMPORT_TYPE, (message: any) =>
                EventEmitterService.get(EventEmitterService.EMPLOYEE_CHANNEL).emit(message));

            this.employeeChannel.subscribe(ChannelNotification.END_EMPLOYEE_CSV_IMPORT_TYPE, (message: any) =>
                EventEmitterService.get(EventEmitterService.EMPLOYEE_CHANNEL).emit(message));

            this.employeeChannel.subscribe(ChannelNotification.ERROR_EMPLOYEE_CSV_IMPORT_TYPE, (message: any) =>
                EventEmitterService.get(EventEmitterService.EMPLOYEE_CHANNEL).emit(message));
        }
    }

    public addListenerCompanyChannel(companyId: string | undefined): void{
        if (!this.companyChannel && companyId){
            this.companyChannel = this.ably.channels.get(companyId);

            this.companyChannel.subscribe(ChannelNotification.START_EMPLOYEE_CSV_IMPORT_TYPE, (message: any) =>
                EventEmitterService.get(EventEmitterService.COMPANY_CHANNEL).emit(message));

            this.companyChannel.subscribe(ChannelNotification.END_EMPLOYEE_CSV_IMPORT_TYPE, (message: any) =>
                EventEmitterService.get(EventEmitterService.COMPANY_CHANNEL).emit(message));

            this.companyChannel.subscribe(ChannelNotification.ERROR_EMPLOYEE_CSV_IMPORT_TYPE, (message: any) =>
                EventEmitterService.get(EventEmitterService.EMPLOYEE_CHANNEL).emit(message));

            this.companyChannel.subscribe(ChannelNotification.JUSTIFICATION_CREATED_TYPE, (message: any) =>
                EventEmitterService.get(EventEmitterService.COMPANY_CHANNEL).emit(message));
        }
    }
}
