import {createAction, props} from '@ngrx/store';
import { Employee } from 'src/model/employee';

export enum EmployeeActionTypes {

    LOAD_EMPLOYEE_REQUEST = '[EMPLOYEE] Load EMPLOYEE Request',
    LOAD_EMPLOYEE_FAILURE = '[EMPLOYEE] Load EMPLOYEE Failure',
    LOAD_EMPLOYEE_SUCCESS = '[EMPLOYEE] Load EMPLOYEE Success',
    CLEAN_EMPLOYEE_STATE = '[EMPLOYEE] Clear EMPLOYEE state',
}

/**
 * Actions for Load Data
 */
export const loadEmployeeRequestAction = createAction(
    EmployeeActionTypes.LOAD_EMPLOYEE_REQUEST,
    props<{ id: string }>()
);

export const loadEmployeeSuccessAction = createAction(
    EmployeeActionTypes.LOAD_EMPLOYEE_SUCCESS,
    props<{ employees: Employee[]}>()
);

export const loadEmployeetFailureAction = createAction(
    EmployeeActionTypes.LOAD_EMPLOYEE_FAILURE,
    props<{ error: string }>()
);

export const clearEmployeeStateAction = createAction(
    EmployeeActionTypes.CLEAN_EMPLOYEE_STATE,
);
