<p-toast></p-toast>

<app-searchbar-employee
    (searchEvent)="searchVacation($event)"
    (cleanForm)="onCleanForm()"
    #searchBar>
</app-searchbar-employee>

<div class="card shadow mb-4">
    <div class="card-body">
        <p-table #dt [value]="vacations" dataKey="id" styleClass="p-datatable-employee" [rowHover]="true"
                 [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
                 [paginator]="true" [filterDelay]="0"
                 [globalFilterFields]="['name','startDate','endDate']">

            <!-- <ng-template pTemplate="caption">
                <div class="table-header d-flex align-items-center justify-content-end">
                    <button type="button" class="btn btn-sm btn-link ml-3" (click)="searchBar.onCleanForm()">
                        Limpar filtro
                    </button>
                </div>
            </ng-template> -->

            <ng-template pTemplate="header">
                <tr *ngIf="vacations?.length !== 0">
                    <th pSortableColumn="employee">Nome
                        <p-sortIcon field="employee"></p-sortIcon>
                    </th>
                    <th pSortableColumn="start">Data Início
                        <p-sortIcon field="start"></p-sortIcon>
                    </th>
                    <th pSortableColumn="end">Data Fim
                        <p-sortIcon field="end"></p-sortIcon>
                    </th>
                    <th style="width: 7rem; text-align: center;">Configure</th>
                </tr>
                <tr *ngIf="loading">
                    <th colspan="4">
                        <div class="d-flex justify-content-center text-muted align-items-center p-3">
                            <span class="spinner-grow spinner-grow-sm m-2" role="status" aria-hidden="true"></span>
                            Aguarde estamos carregando os dados...
                        </div>
                    </th>
                </tr>
                <tr *ngIf="vacations?.length === 0 && !loading">
                    <th colspan="4">
                        <div class="d-flex justify-content-center align-items-center text-muted not-found-text">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"/>
                            </svg>
                            <h6>Nenhum funcionário se encontra de férias</h6>
                        </div>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-vacation let-i>
                <tr class="p-selectable-row animated--fade-in">
                    <td>
                        {{ vacation.employee.name }}
                    </td>
                    <td>
                        {{ vacation.start | date: 'dd/MM/yyyy' }}
                    </td>
                    <td>
                        {{ vacation.end | date: 'dd/MM/yyyy' }}
                    </td>
                    <td style="text-align: center" *ngIf="!vacation.employeeOnVacation">
                        <p-button
                            styleClass="p-button-rounded p-button-secondary p-button-text"
                            icon="pi pi-cog"
                            (click)="getSelectedContract(vacation.employee)">
                        </p-button>
                    </td>
                    <td style="text-align: center" *ngIf="vacation.employeeOnVacation">
                        <span class="badge status-pending_confirmation">De Férias</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
