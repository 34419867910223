import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SERVER_URL} from '../environments/environment';
import HttpJustificationsResponse, { Justification } from '../model/justification';
import {Observable} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class JustificationService {

    constructor(
        private httpClient: HttpClient) {
    }

    /**
     *  List of Justification by Contract
     *
     */
    public findAllByContract(contractId: string): Observable<HttpJustificationsResponse> {
        return this.httpClient.get<HttpJustificationsResponse>(`${SERVER_URL}/justifications/contract/${contractId}`);
    }

    /**
     *  List of Justification by Company and Date
     *
     */
    public getJustificationByCompany(companyId: string | undefined, startDate: string, endDate: string): Observable<HttpJustificationsResponse> {
        return this.httpClient.get<HttpJustificationsResponse>(`${SERVER_URL}/justifications/company/${companyId}/startDate/${startDate}/endDate/${endDate}`);
    }

    /**
     *  List of Justification by Employee and Date
     *
     */
    public getJustificationByEmployee(employeeId: string, startDate: string, endDate: string): Observable<HttpJustificationsResponse> {
        return this.httpClient.get<HttpJustificationsResponse>(`${SERVER_URL}/justifications/employee/${employeeId}/startDate/${startDate}/endDate/${endDate}`);
    }

    /**
     *  List of Justification by Contract
     *
     */
    public findAllByContractAndDate(contractId: string, date: string): Observable<HttpJustificationsResponse> {
        return this.httpClient.get<HttpJustificationsResponse>(`${SERVER_URL}/justifications/contract/${contractId}/date/${date}`);
    }

    /**
     *  Justification Accept
     *
     */
    public acceptJustification(justificationId: string, data: Justification): Observable<HttpJustificationsResponse> {
        return this.httpClient.put<HttpJustificationsResponse>(`${SERVER_URL}/justifications/accept/${justificationId}`, data);
    }

    /**
     *  Justification Reject
     *
     */
    public rejectJustification(justificationId: string, data: Justification): Observable<HttpJustificationsResponse> {
        return this.httpClient.put<HttpJustificationsResponse>(`${SERVER_URL}/justifications/reject/${justificationId}`, data);
    }


    public downloadJustificationImage(img: any) {
        this.httpClient.get(img, {responseType: 'blob' as 'json'}).subscribe((res: any) => {
            const file = new Blob([res], {type: res.type});

            const blob = window.URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = blob;
            link.download = 'anexo.jpeg';

            // Version link.click() to work at firefox
            link.dispatchEvent(new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            }));

            // firefox
            setTimeout(() => {
              window.URL.revokeObjectURL(blob);
              link.remove();
            }, 100);
    });
  }

}
