<p-toast></p-toast>
<div class="mb-3">
    <div class="tabview-body-title d-flex justify-content-start align-items-center">
        <h3>Jornada de Trabalho</h3>
        <div
            class="info-icon"
            tooltipStyleClass="shadow"
            pTooltip='Você irá configurar uma jornada padrão para empresa, caso deseje editar a jornada de um funcionário específico, vá na aba de "Funcionário"'>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-0 w-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
        </div>
    </div>

    <form [formGroup]="formGroup">
        <div class="w-100">
            <div class="mb-3 body-form">
                <!-- ROW-01 -->

                <div class="form-row">
                    <div class="form-group col-lg-12">
                        <label class="row-label">Regime</label>
                        <div class="d-flex mt-2">
                            <div class="form-check mr-3">
                                <input class="form-check-input"
                                    formControlName="workModality"
                                    type="radio"
                                    id="home"
                                    value="homeOffice"
                                    (change)="workModalityValues()">
                                <label for="home">
                                    Home Office
                                </label>
                            </div>

                            <div class="form-check mr-3">
                                <input class="form-check-input"
                                    formControlName="workModality"
                                    type="radio"
                                    id="flexible"
                                    value="flexibleJourney" (change)="workModalityValues()">
                                <label for="flexible">
                                    Jornada Híbrida
                                </label>
                            </div>
                            <div class="form-check mr-3">
                                <input class="form-check-input"
                                       formControlName="workModality"
                                       type="radio"
                                       id="headOffice"
                                       value="headOffice" (change)="workModalityValues()">
                                <label for="flexible">
                                    Padrão
                                </label>
                            </div>
                            <div class="form-check mr-3" *ngIf="thereIsBranc">
                                <input class="form-check-input"
                                       formControlName="workModality"
                                       type="radio"
                                       id="branchAddress"
                                       value="branchAddress" (change)="workModalityValues()">
                                <label for="flexible">
                                    Filial
                                </label>
                            </div>
                            <div class="form-check mr-3">
                                <input class="form-check-input"
                                       formControlName="workModality"
                                       type="radio"
                                       id="skipAllValidation"
                                       value="skipAllValidation" (change)="workModalityValues()">
                                <label for="flexible">
                                    Ponto Livre
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <hr>

                <!-- ROW-02 -->
                <div class="form-row">
                    <!-- day of the weeks starts -->
                    <div class="form-group col-lg-12">
                        <label class="row-label">Dias de trabalho</label>

                        <div class="mb-3">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="selectedJourneyOption" id="inlineRadio1" checked
                                    formControlName="selectedJourneyOption" [value]="1" (change)="selectedJourneyOption = 1; changeJourgeType();">
                                <label class="form-check-label" for="inlineRadio1">Configurar jornada por semana</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="selectedJourneyOption" id="inlineRadio2"
                                    formControlName="selectedJourneyOption" [value]="2" (change)="selectedJourneyOption = 2; changeJourgeType();">
                                <label class="form-check-label" for="inlineRadio2">Configurar jornada por dia</label>
                            </div>
                        </div>

                        <div class="journey-container p-3">
                            <div *ngIf="selectedJourneyOption === 2" class="div-fade-in">
                                <app-journey-by-day
                                    [company]="company"
                                    (journeyChange)="updateJourney($event)"
                                    (updateFormStatus)="updateJoruneyDayFormStatus($event)">
                                </app-journey-by-day>
                            </div>

                            <div *ngIf="selectedJourneyOption === 1" #byWeek>
                                <div class="alert alert-warning animated--fade-in" role="alert" *ngIf="showAttentionMessage() && activeJourney">
                                    <strong>ATENÇÃO: </strong>Não podem conter campos vazios ou com 00:00.
                                </div>
                                <div class="alert alert-warning animated--fade-in" role="alert" *ngIf="!activeJourney && journeyType === 'week'">
                                    <strong>ATENÇÃO: </strong>Deve existir pelo menos um dia da semana selecionado.
                                </div>
                                <div class="p-3 div-fade-in">
                                    <div class="day-weeks-container d-flex justify-content-between">
                                        <div class="btn-day d-flex align-items-center">
                                            <p-inputSwitch formControlName="enabledMonday" (ngModelChange)="createJourney()" inputId="monday"></p-inputSwitch>
                                            <label for="journey" class="mb-1">Seg</label>
                                        </div>
                                        <div class="btn-day d-flex align-items-center">
                                            <p-inputSwitch formControlName="enabledTuesday" (ngModelChange)="createJourney()" inputId="tuesday"></p-inputSwitch>
                                            <label for="journey" class="mb-1">Ter</label>
                                        </div>
                                        <div class="btn-day d-flex align-items-center">
                                            <p-inputSwitch formControlName="enabledWednesday" (ngModelChange)="createJourney()" inputId="wednesday"></p-inputSwitch>
                                            <label for="journey" class="mb-1">Qua</label>
                                        </div>
                                        <div class="btn-day d-flex align-items-center">
                                            <p-inputSwitch formControlName="enabledThursday" (ngModelChange)="createJourney()" inputId="thursday"></p-inputSwitch>
                                            <label for="journey" class="mb-1">Qui</label>
                                        </div>
                                        <div class="btn-day d-flex align-items-center">
                                            <p-inputSwitch formControlName="enabledFriday" (ngModelChange)="createJourney()" inputId="friday"></p-inputSwitch>
                                            <label for="journey" class="mb-1">Sex</label>
                                        </div>
                                        <div class="btn-day d-flex align-items-center">
                                            <p-inputSwitch formControlName="enabledSaturday" (ngModelChange)="createJourney()" inputId="saturday"></p-inputSwitch>
                                            <label for="journey" class="mb-1">Sab</label>
                                        </div>
                                        <div class="btn-day d-flex align-items-center">
                                            <p-inputSwitch formControlName="enabledSunday" (ngModelChange)="createJourney()" inputId="sunday"></p-inputSwitch>
                                            <label for="journey" class="mb-1">Dom</label>
                                        </div>
                                    </div>

                                    <div class="form-row period-container">
                                        <div class="form-group col-lg-12" style="margin-bottom: 0px; margin-top: 20px;">
                                            <label class="row-label">Período</label>
                                        </div>
                                        <div class="form-group col-lg-3">
                                            <div class="p-field mr-4">
                                                <label class="label-period" for="startTimeFirstPeriod">Início 1° Período *</label>
                                                <p-inputMask class="p-inputtext-sm" id="startTimeFirstPeriod" placeholder="Ex: 00:00" mask="99:99"
                                                    formControlName="startTimeFirstPeriod"
                                                    [disabled]="!activeJourney"
                                                    (ngModelChange)="createJourney()"
                                                    [ngClass]="{
                                                        'is-invalid': formGroup.get('startTimeFirstPeriod')?.hasError('required') && formGroup.get('startTimeFirstPeriod')?.touched}">
                                                </p-inputMask>
                                                <div class="invalid-feedback" *ngIf="formGroup.get('startTimeFirstPeriod')?.hasError('required')
                                                    && formGroup.get('startTimeFirstPeriod')?.touched">
                                                    Campo requerido
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-lg-3">
                                            <label class="label-period" for="endTimeFirstPeriod">Fim 1° Período *</label>
                                            <div class="p-field mr-4">
                                                <p-inputMask class="p-inputtext-sm" id="endTimeFirstPeriod" placeholder="Ex: 00:00" mask="99:99"
                                                    formControlName="endTimeFirstPeriod"
                                                    [disabled]="!activeJourney"
                                                    (ngModelChange)="createJourney()"
                                                    [ngClass]="{
                                                        'is-invalid': formGroup.get('endTimeFirstPeriod')?.hasError('required') && formGroup.get('endTimeFirstPeriod')?.touched}">
                                                </p-inputMask>
                                                <div class="invalid-feedback" *ngIf="formGroup.get('endTimeFirstPeriod')?.hasError('required')
                                                    && formGroup.get('endTimeFirstPeriod')?.touched">
                                                    Campo requerido
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-lg-3">
                                            <label class="label-period" for="startTimeSecondPeriod">Início 2° Período *</label>
                                            <div class="p-field mr-4">
                                                <p-inputMask class="p-inputtext-sm" id="startTimeSecondPeriod" placeholder="Ex: 00:00" mask="99:99"
                                                    formControlName="startTimeSecondPeriod"
                                                    [disabled]="!activeJourney"
                                                    (ngModelChange)="createJourney()"
                                                    [ngClass]="{
                                                        'is-invalid': formGroup.get('startTimeSecondPeriod')?.hasError('required') && formGroup.get('startTimeSecondPeriod')?.touched}">
                                                </p-inputMask>
                                                <div class="invalid-feedback" *ngIf="formGroup.get('startTimeSecondPeriod')?.hasError('required')
                                                    && formGroup.get('startTimeSecondPeriod')?.touched">
                                                    Campo requerido
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-lg-3">
                                            <label class="label-period" for="endTimeSecondPeriod">Fim 2° Período *</label>
                                            <div class="p-field">
                                                <p-inputMask class="p-inputtext-sm" id="endTimeSecondPeriod" placeholder="Ex: 00:00" mask="99:99"
                                                    formControlName="endTimeSecondPeriod"
                                                    [disabled]="!activeJourney"
                                                    (ngModelChange)="createJourney()"
                                                    [ngClass]="{
                                                        'is-invalid': formGroup.get('endTimeSecondPeriod')?.hasError('required') && formGroup.get('startTimeFirstPeriod')?.touched}">
                                                </p-inputMask>
                                                <div class="invalid-feedback" *ngIf="formGroup.get('endTimeSecondPeriod')?.hasError('required')
                                                    && formGroup.get('endTimeSecondPeriod')?.touched">
                                                    Campo requerido
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end button-container">
            <button type="button" class="btn btn-primary" *ngIf="journeyType === 'week'" (click)="onUpdate()" [disabled]="!formGroup.valid || !activeJourney">
                <div class="d-flex justify-content-center">
                    <span class="ml-2">Salvar</span>
                </div>
            </button>
            <button type="button" class="btn btn-primary" *ngIf="journeyType === 'day'" (click)="onUpdate()" [disabled]="!formJourneyDayStatus">
                <div class="d-flex justify-content-center">
                    <span class="ml-2">Salvar</span>
                </div>
            </button>
        </div>
    </form>
</div>
