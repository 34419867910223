import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { Company } from 'src/model/company';
import { Employee } from 'src/model/employee';
import { AppStorageService } from 'src/services/app-storage.service';
import { CompanyPositionService } from 'src/services/company-position.service';
import { CompanyPositionCreateComponent } from '../company-position-create/company-position-create.component';
import HttpCompanyPositionResponse, { CompanyPosition } from './../../model/company-position';

@Component({
  selector: 'app-company-position',
  templateUrl: './company-position.component.html',
  styleUrls: ['./company-position.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService]
})
export class CompanyPositionComponent implements OnInit {

  @ViewChild('dt') table?: Table;

  public companiesPositions: CompanyPosition[] = [];
  public companyPosition?: CompanyPosition;
  public company?: Company;
  public companyName: string | undefined;
  public companyPositionExists = true;
  public companyExists = false;
  public showTitle = false;
  public loading = false;
  public ref: DynamicDialogRef = new DynamicDialogRef();
  public formGroup: FormGroup;
  private employee: Employee | undefined;

  constructor(
    private appStorageService: AppStorageService,
    private companyPositionService: CompanyPositionService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
  ) {
      this.formGroup = this.formBuilder.group({
        name: ['', [Validators.required, Validators.maxLength(120)]],
      });
  }

  ngOnInit(): void {
    this.initializePage();
  }

  async initializePage() {
    this.companyPositionExists = false;
    this.loading = true;
    await this.list();
  }

  onCreate() {
    this.ref = this.dialogService.open(CompanyPositionCreateComponent, {
      header: 'Cadastrar Cargo',
      width: '50%',
      contentStyle: {"max-height": "500px", "overflow": "auto"},
      baseZIndex: 10000,
      dismissableMask: true,
      data: {values: ''}
    });

    this.ref.onClose.subscribe((message) => {
      this.messageService.add({severity: message[0].severity, summary: message[0].summary});
      this.reloadTable();
    });
  }

  async onUpdate(companyPosition: CompanyPosition) {

    try {
        const position: CompanyPosition = companyPosition;

        if(position.id) {
            await this.companyPositionService.update(position, position.id).toPromise();
        }

        this.messageService.add({
            severity: 'success',
            summary: 'Cargo atualizado',
            detail: ''
        });

        setTimeout(() => {
            this.reloadTable();
        }, 2000)

    } catch (error) {

    }
  }

  async list() {
    try {
        this.employee = await this.appStorageService.getEmployee();

        const response: HttpCompanyPositionResponse | undefined = await this.companyPositionService.list (
            this.employee.companyId as string
        ).toPromise() || undefined;

        const result: CompanyPosition[] = []
        response?.data?.map((companiesPosition: CompanyPosition) => {
            result.push(companiesPosition);
        })

        this.companiesPositions = result;
        this.loading = false;
    } catch (error) {
        console.log('Error:', error);
        this.loading = false;
    }
  }

  public confirmDelete(companyPositionId: string): void {
    this.confirmationService.confirm({
        message: `Você tem certeza que deseja deletar esse cargo?`,
        header: 'Deletar cargo',
        accept: () => {
            this.onDelete(companyPositionId)
        },
        reject: () => {
            //
        }
    });
  }

  async onDelete(companyPositionId: string) {
      this.companiesPositions = [];
      this.loading = true;
      try {
        await this.companyPositionService.delete(companyPositionId).toPromise();
        await this.reloadTable();
      } catch (error) {
        this.loading = false;
        await this.reloadTable();
      }
  }

  async reloadTable() {
    this.companiesPositions = [];
    await this.initializePage();
  }
}
