import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Company } from 'src/model/company';
import { CompanyService } from 'src/services/company.service';

type Period = 'day' | 'week';
@Component({
  selector: 'app-working-day',
  templateUrl: './working-day.component.html',
  styleUrls: ['./working-day.component.scss'],
  providers: [MessageService]
})
export class WorkingDayComponent implements OnInit {

    @Input() companyWorkDay: Company | undefined;
    @Output() cancelUpdate = new EventEmitter<boolean>();

    public company: Company | undefined;
    public formGroup: FormGroup;
    public thereIsBranc = false;
    public formJourneyDayStatus = false;
    public activeJourney = false;
    public formStatus = false;

    public selectedJourneyOption!: number;
    public journeyType: Period = 'week';

    public journeyByDay: any = {}
    public journeyByWeek: any = {};

    private saveMessage = [{severity: 'success', summary: 'Empresa cadastrada!'}];
    private updateMessage = [{severity: 'success', summary: 'Empresa atualizada!'}];

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private companyService: CompanyService,
  ) {
    this.formGroup = this.formBuilder.group({
        homeOffice: [false],
        flexibleJourney: [false],
        headOffice: [false],
        branchAddress: [false],
        skipAllValidation: [false],
        workModality: [''],
        enabledSunday: [false],
        enabledMonday: [false],
        enabledTuesday: [false],
        enabledWednesday: [false],
        enabledThursday: [false],
        enabledFriday: [false],
        enabledSaturday: [false],
        selectedJourneyOption:[''],
        startTimeFirstPeriod: ['', Validators.required],
        endTimeFirstPeriod: ['', Validators.required],
        startTimeSecondPeriod: ['', Validators.required],
        endTimeSecondPeriod: ['', Validators.required],
    });
    this.formGroup.patchValue({
        enabledSunday: false,
        enabledMonday: false,
        enabledTuesday: false,
        enabledWednesday: false,
        enabledThursday: false,
        enabledFriday: false,
        enabledSaturday: false,
      });

      this.formGroup.get('enabledMonday')!.valueChanges.subscribe((enabled) => {
        this.updatePeriodsValidation(enabled);
      });
      this.formGroup.get('enabledTuesday')!.valueChanges.subscribe((enabled) => {
        this.updatePeriodsValidation(enabled);
      });
      this.formGroup.get('enabledWednesday')!.valueChanges.subscribe((enabled) => {
        this.updatePeriodsValidation(enabled);
      });
      this.formGroup.get('enabledThursday')!.valueChanges.subscribe((enabled) => {
        this.updatePeriodsValidation(enabled);
      });
      this.formGroup.get('enabledFriday')!.valueChanges.subscribe((enabled) => {
        this.updatePeriodsValidation(enabled);
      });
      this.formGroup.get('enabledSaturday')!.valueChanges.subscribe((enabled) => {
        this.updatePeriodsValidation(enabled);
      });
      this.formGroup.get('enabledSunday')!.valueChanges.subscribe((enabled) => {
        this.updatePeriodsValidation(enabled);
      });

  }

  async ngOnInit(): Promise<void> {
    await this.initializePage();
    // this.formGroup.patchValue(this.company!);
    this.loadWorkModality();
    this.workModalityValues();
  }

  async initializePage() {
    this.company = this.companyWorkDay;
    if (this.company) {
        this.thereIsBranc = await this.companyService.thereIsBranch(this.company.id!, this.company.type!);
    }

    if (this.company!.journey !== undefined) {
        this.journeyByWeek = JSON.parse(this.company!.journey!)
        this.journeyByDay = JSON.parse(this.company!.journey!)

        if (this.company!.journeyType === 'week') {
            this.journeyType = 'week'
            this.selectedJourneyOption = 1;
            this.formGroup.get('selectedJourneyOption')!.setValue(1);

            const journeyByWeek: { [key: string]: string[] } = {};

            for (let i = 1; i <= 7; i++) {
                journeyByWeek[i.toString()] = this.journeyByWeek[i.toString()].split('-');
            }

            this.formGroup.patchValue({
                enabledSunday: JSON.parse(journeyByWeek['7'][4]),
                enabledMonday: JSON.parse(journeyByWeek['1'][4]),
                enabledTuesday: JSON.parse(journeyByWeek['2'][4]),
                enabledWednesday: JSON.parse(journeyByWeek['3'][4]),
                enabledThursday: JSON.parse(journeyByWeek['4'][4]),
                enabledFriday: JSON.parse(journeyByWeek['5'][4]),
                enabledSaturday: JSON.parse(journeyByWeek['6'][4]),
                startTimeFirstPeriod: journeyByWeek['1'][0],
                endTimeFirstPeriod: journeyByWeek['1'][1],
                startTimeSecondPeriod: journeyByWeek['1'][2],
                endTimeSecondPeriod: journeyByWeek['1'][3],
            });
            this.checkIfOneAnableExists();
        } else {
            this.journeyType = 'day'
            this.formGroup.reset();
            this.selectedJourneyOption = 2;
            this.formGroup.get('selectedJourneyOption')!.setValue(2);

            const journeyByday: { [key: string]: string[] } = {};

            for (let i = 1; i <= 7; i++) {
                journeyByday[i.toString()] = this.journeyByDay[i.toString()].split('-');
            }

            this.formGroup.patchValue({
                enabledSunday: false,
                enabledMonday: false,
                enabledTuesday: false,
                enabledWednesday: false,
                enabledThursday: false,
                enabledFriday: false,
                enabledSaturday: false,
            });
        }
    }
  }

  createJourney() {
    this.journeyByWeek = {};
    this.checkIfOneAnableExists();
    const enabledSunday = this.formGroup.get(`enabledSunday`)?.value;
    const enabledMonday = this.formGroup.get(`enabledMonday`)?.value;
    const enabledTuesday = this.formGroup.get(`enabledTuesday`)?.value;
    const enabledWednesday = this.formGroup.get(`enabledWednesday`)?.value;
    const enabledThursday = this.formGroup.get(`enabledThursday`)?.value;
    const enabledFriday = this.formGroup.get(`enabledFriday`)?.value;
    const enabledSaturday = this.formGroup.get(`enabledSaturday`)?.value;
    this.journeyByWeek[1] = this.getJourneyString(this.formGroup, enabledMonday);
    this.journeyByWeek[2] = this.getJourneyString(this.formGroup, enabledTuesday);
    this.journeyByWeek[3] = this.getJourneyString(this.formGroup, enabledWednesday);
    this.journeyByWeek[4] = this.getJourneyString(this.formGroup, enabledThursday);
    this.journeyByWeek[5] = this.getJourneyString(this.formGroup, enabledFriday);
    this.journeyByWeek[6] = this.getJourneyString(this.formGroup, enabledSaturday);
    this.journeyByWeek[7] = this.getJourneyString(this.formGroup, enabledSunday);
  }

  getJourneyString(formGroup: any, isAnabled: boolean) {
    let isEnabled = isAnabled;
    const startTimeFirstPeriod = formGroup.get(`startTimeFirstPeriod`).value;
    const endTimeFirstPeriod = formGroup.get(`endTimeFirstPeriod`).value;
    const startTimeSecondPeriod = formGroup.get(`startTimeSecondPeriod`).value;
    const endTimeSecondPeriod = formGroup.get(`endTimeSecondPeriod`).value;

    return `${startTimeFirstPeriod}-${endTimeFirstPeriod}-${startTimeSecondPeriod}-${endTimeSecondPeriod}-${isEnabled}`;
  }

  updateJourney(newJourney: any) {
    this.journeyByDay = newJourney;
  }

  updateJoruneyDayFormStatus(value: any) {
    this.formJourneyDayStatus = value;
  }

  changeJourgeType() {
    if (this.selectedJourneyOption === 1) {
        this.journeyType = 'week';
        this.createJourney();
        this.formGroup.get(`enabledSunday`)?.setValue(false);
        this.formGroup.get(`enabledMonday`)?.setValue(false);
        this.formGroup.get(`enabledTuesday`)?.setValue(false);
        this.formGroup.get(`enabledWednesday`)?.setValue(false);
        this.formGroup.get(`enabledThursday`)?.setValue(false);
        this.formGroup.get(`enabledFriday`)?.setValue(false);
        this.formGroup.get(`enabledSaturday`)?.setValue(false);
        this.formGroup.get(`startTimeFirstPeriod`)!.setValue('00:00');
        this.formGroup.get(`endTimeFirstPeriod`)!.setValue('00:00');
        this.formGroup.get(`startTimeSecondPeriod`)!.setValue('00:00');
        this.formGroup.get(`endTimeSecondPeriod`)!.setValue('00:00');
    } else {
        this.journeyType = 'day';
    }
  }

  public workModalityValues() {

    if (this.formGroup.get('workModality')?.value === 'homeOffice') {
        this.formGroup.get('homeOffice')?.setValue(true)
    } else {
        this.formGroup.get('homeOffice')?.setValue(false)
    }

    if (this.formGroup.get('workModality')?.value === 'flexibleJourney') {
        this.formGroup.get('flexibleJourney')?.setValue(true)
    } else {
        this.formGroup.get('flexibleJourney')?.setValue(false)
    }

    if (this.formGroup.get('workModality')?.value === 'headOffice') {
        this.formGroup.get('headOffice')?.setValue(true)
    } else {
        this.formGroup.get('headOffice')?.setValue(false)
    }

    if (this.formGroup.get('workModality')?.value === 'branchAddress') {
        this.formGroup.get('branchAddress')?.setValue(true)
    } else {
        this.formGroup.get('branchAddress')?.setValue(false)
    }

    if (this.formGroup.get('workModality')?.value === 'skipAllValidation') {
        this.formGroup.get('skipAllValidation')?.setValue(true)
    } else {
        this.formGroup.get('skipAllValidation')?.setValue(false)
    }
  }

  public loadWorkModality() {
    if (this.companyWorkDay!.homeOffice === true) {
        this.formGroup.get('workModality')?.setValue('homeOffice');
    }
    if (this.companyWorkDay!.flexibleJourney === true) {
        this.formGroup.get('workModality')?.setValue('flexibleJourney');
    }
    if (this.companyWorkDay!.headOffice === true) {
        this.formGroup.get('workModality')?.setValue('headOffice');
    }
    if (this.companyWorkDay!.branchAddress === true) {
        this.formGroup.get('workModality')?.setValue('branchAddress');
    }
    if (this.companyWorkDay!.skipAllValidation === true) {
        this.formGroup.get('workModality')?.setValue('skipAllValidation');
    }
  }

  public async onUpdate(): Promise<void> {
    let journeyData: any;

    if (this.journeyType === 'week') {
        journeyData = JSON.stringify(this.journeyByWeek)
    } else {
        journeyData = JSON.stringify(this.journeyByDay)
    }

    const {
        homeOffice,
        flexibleJourney,
        headOffice,
        branchAddress,
        skipAllValidation,
    } = this.formGroup.value;

    const company = {
        homeOffice,
        flexibleJourney,
        headOffice,
        branchAddress,
        skipAllValidation,
        journey: journeyData,
        journeyType: this.journeyType
    };

    try {
        await this.companyService.update(company as Company, this.company?.id as string).toPromise();

        this.messageService.add({
            severity: 'success',
            summary: 'Jornada criada',
            detail: ''
        });

        setTimeout(() => {
            this.onCancel();
        }, 2000)
    } catch (error) {
        console.log('Error: ', error);
    }
  }

  onCancel() {
    this.cancelUpdate.emit(false);
    this.ngOnInit();
  }

  branchAddressCoditionalValidators(branchAddress: any) {
    if (branchAddress === false) {
        this.formGroup.controls['contractZipcode'].clearValidators();
        this.formGroup.controls['contractAddress'].clearValidators();
        this.formGroup.controls['contractAddressNumber'].clearValidators();
        this.formGroup.controls['contractDistrict'].clearValidators();
        this.formGroup.controls['contractCity'].clearValidators();
        this.formGroup.controls['contractState'].clearValidators();
    }

    if (branchAddress === true){
        this.formGroup.controls['contractZipcode'].setValidators([Validators.required]);
        this.formGroup.controls['contractAddress'].setValidators([Validators.required]);
        this.formGroup.controls['contractAddressNumber'].setValidators([Validators.required]);
        this.formGroup.controls['contractDistrict'].setValidators([Validators.required]);
        this.formGroup.controls['contractCity'].setValidators([Validators.required]);
        this.formGroup.controls['contractState'].setValidators([Validators.required]);
    }

    this.formGroup.controls['contractZipcode'].updateValueAndValidity();
    this.formGroup.controls['contractAddress'].updateValueAndValidity();
    this.formGroup.controls['contractAddressNumber'].updateValueAndValidity();
    this.formGroup.controls['contractDistrict'].updateValueAndValidity();
    this.formGroup.controls['contractCity'].updateValueAndValidity();
    this.formGroup.controls['contractState'].updateValueAndValidity();
  }

  updatePeriodsValidation(enabled: boolean) {
    const fieldsToValidate = [
      'startTimeFirstPeriod',
      'endTimeFirstPeriod',
      'startTimeSecondPeriod',
      'endTimeSecondPeriod',
    ];

    this.updateValidation(fieldsToValidate, enabled);
  }

  updateValidation(fieldsToValidate: any[], enabled: boolean) {
    fieldsToValidate.forEach((field) => {
      this.formGroup.get(field)!.clearValidators();
      this.formGroup.get(field)!.updateValueAndValidity();
    });

    if (enabled) {
      fieldsToValidate.forEach((field) => {
        this.formGroup.get(field)!.setValidators([
            Validators.required,
            (control: AbstractControl) => {
              const value = control.value;
              if (value === '00:00') {
                return { invalidTime: true };
              }
              return null;
            }
          ]);
          this.formGroup.get(field)!.updateValueAndValidity();
      });
    }

    if (!enabled) {
      fieldsToValidate.forEach((field) => {
        this.formGroup.get(field)!.setValue('00:00');
        this.formGroup.get(field)!.updateValueAndValidity();
      });
    }
  }

  showAttentionMessage() {
    // Verifique se algum dos campos atende às condições (00:00 ou vazio)
    const startTimeFirstPeriodValue = this.formGroup.get('startTimeFirstPeriod')!.value;
    const endTimeFirstPeriodValue = this.formGroup.get('endTimeFirstPeriod')!.value;
    const startTimeSecondPeriodValue = this.formGroup.get('startTimeSecondPeriod')!.value;
    const endTimeSecondPeriodValue = this.formGroup.get('endTimeSecondPeriod')!.value;

    return (
      startTimeFirstPeriodValue === '00:00' || startTimeFirstPeriodValue === '' ||
      endTimeFirstPeriodValue === '00:00' || endTimeFirstPeriodValue === '' ||
      startTimeSecondPeriodValue === '00:00' || startTimeSecondPeriodValue === '' ||
      endTimeSecondPeriodValue === '00:00' || endTimeSecondPeriodValue === ''
    );
  }

  checkIfOneAnableExists() {
    if (
        this.formGroup.get('enabledMonday')!.value === false &&
        this.formGroup.get('enabledTuesday')!.value === false &&
        this.formGroup.get('enabledWednesday')!.value === false &&
        this.formGroup.get('enabledThursday')!.value === false &&
        this.formGroup.get('enabledFriday')!.value === false &&
        this.formGroup.get('enabledSaturday')!.value === false &&
        this.formGroup.get('enabledSunday')!.value === false
    ) {
        this.formStatus = false;
        this.activeJourney = false;
    } else {
        this.formStatus = true;
        this.activeJourney = true;
    }
  }
}
