import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';
import * as moment from 'moment';
import { forkJoin, map } from 'rxjs';
import JourneyHelp from 'src/app/shared/journey-helper';
import { Employee, HttpEmployeesResponse } from 'src/model/employee';
import HttpRegistersClockResponse, { RegisterClock } from 'src/model/register-clock';
import { AppStorageService } from 'src/services/app-storage.service';
import { EmployeeService } from 'src/services/employee.service';
import { RegisterClockService } from 'src/services/register-clock.service';

@Component({
  selector: 'app-register-clock-by-day-chart',
  templateUrl: './register-clock-by-day-chart.component.html',
  styleUrls: ['./register-clock-by-day-chart.component.scss']
})
export class RegisterClockByDayChartComponent implements OnInit {

  public employee: Employee | undefined;
  public groupEmployees: any[] = [];
  public onTimeEmployeesArray: any[] = [];
  public missingEmployeesArray: any[] = [];
  public lateEmployeesArray: any[] = [];
  public registerClockData: any[] = [];
  public startDate = '';
  public todayDate = '';
  public endDate = '';
  public dayChart: any;
  public dayChartData: any;
  public dayChartDataLabels: any[] = [];
  public correctClock: any = 0;
  public incorrectClock: any = 0;
  public zeroClock: any = 0;
  public totalOfClocks: number = 0
  public showDayBarChart = false;
  public showEmptDayBarChart = false;
  public loadingDayChart!: boolean;
  public display = false;
  public registerClockHeaderSubtitle = '';

  private clockResponse: any;
  private clockRegisters: RegisterClock[] = [];

  constructor(
    private appStorageService: AppStorageService,
    private registerClockService: RegisterClockService,
    private employeeService: EmployeeService,
  ) { }

  async ngOnInit(): Promise<void> {
    const today = moment().format('YYYY-MM-DD');
    this.todayDate = today;
    this.startDate = `${today} 00:00:00`;
    this.endDate = `${today} 23:59:59`;
    await this.fetchData();
    await this.renderClocksByDayChart();
  }

  async fetchData(): Promise<void> {
    this.loadingDayChart = true;
    this.showDayBarChart = false;
    this.showEmptDayBarChart = false
    this.employee = await this.appStorageService.getEmployee();

    const startDate = this.startDate;
    const endDate = this.endDate;

    await forkJoin(
        this.registerClockService.listByCompany(this.employee.companyId as string, startDate, endDate),
        this.employeeService.findAllByCompany(this.employee.companyId!)).pipe(
            map((response: any) => {
                const httpClockResponse: HttpRegistersClockResponse = response[0];
                const httpEmployeeResponse: HttpEmployeesResponse = response[1];

                httpClockResponse.data!.map((clock) => {
                    clock.employee = httpEmployeeResponse.data.find((el) => el.id === clock.employeeId)
                })
                this.clockResponse = httpClockResponse
            })
        ).toPromise();

    this.clockRegisters = this.clockResponse.data.filter((clocks: any) => {
        return clocks.status !== 'INVALID' && clocks.type === 'E';
    });

    // Agrupando os registros pelo "employeeId" e "recordDate"
    const groupedRecords: { [key: string]: any[] } = {};

    this.clockRegisters.forEach((record: any) => {
        const journeyDay = moment(record.recordDateTime, "YYYY-MM-DD HH:mm:ss").isoWeekday();

        const key = `${record.employeeId}-${record.recordDate}`;
        if (!groupedRecords[key]) {
          groupedRecords[key] = [];
        }

        const currentContractValue = JSON.parse(record.currentContract);
        const journeyJson: any =  JourneyHelp.getJourneyJson(currentContractValue.journey);
        const startTimeFirstPeriod = moment(journeyJson[journeyDay][0], 'HH:mm');
        const formattedStartTimeFirstPeriod = startTimeFirstPeriod.format('HH:mm');

        groupedRecords[key].push({
            id: record.id,
            companyId: record.companyId,
            contractId: record.contractId,
            employeeId: record.employee.id,
            name: record.employee.name,
            startTimeFirstPeriod: formattedStartTimeFirstPeriod,
            currentContract: record.currentContract,
            recordTime: record.recordTime,
            recordDate: record.recordDate,
            type: record.type,
        });
    });

    // Ordenandocada grupo pelo "recordTime"
    const sortedRecords: RegisterClock[][] = Object.values(groupedRecords).map(recordsGroup => {
        return recordsGroup.sort((a, b) => a.recordTime!.localeCompare(b.recordTime!));
    });

    const groupedAndCheckedRecords: any[] = this.groupAndCheckRecords(sortedRecords);

    Object.entries(groupedAndCheckedRecords).forEach(([recordDate, data]) => {
        this.dayChartDataLabels.push(moment(recordDate).format('DD/MM'));
    });

    if (Object.values(groupedAndCheckedRecords).length === 0) {
        this.showDayBarChart = false;
        this.showEmptDayBarChart = true
    } else {
        this.showDayBarChart = true;
        this.showEmptDayBarChart = false
    }

    this.dayChartData = this.createChartData(groupedAndCheckedRecords);
    this.correctClock = this.dayChartData.datasets[0].data.length > 0 ? this.dayChartData.datasets[0].data : 0;
    this.incorrectClock = this.dayChartData.datasets[1].data.length > 0 ? this.dayChartData.datasets[1].data : 0;
    this.zeroClock = this.dayChartData.datasets[2].data.length > 0 ? this.dayChartData.datasets[2].data : 0;
    this.totalOfClocks = this.correctClock[0] + this.incorrectClock[0] + this.zeroClock[0];
    this.registerClockService.groupRecordClocks = this.dayChart;
    this.loadingDayChart = false;
  }

  private isWithinTolerance(time1: string, time2: string): boolean {
    const diff = moment(time1, 'HH:mm:ss').diff(moment(time2, 'HH:mm:ss'), 'minutes');
    return Math.abs(diff) <= 10;
  }

  private groupAndCheckRecords(sortedRecords: any[][]): any {
    const groupedRecords: any = {};

    sortedRecords.forEach(recordsGroup => {
      recordsGroup.forEach(record => {
        this.groupEmployees.push({
            employeeId: record.employeeId,
            name: record.name
        })
        const { recordDate, employeeId } = record;

        if (!groupedRecords[recordDate]) {
          groupedRecords[recordDate] = {
            employees: new Set(),
            onTimeEmployees: new Set(),
            lateEmployees: new Set(),
            missingEmployees: new Set(),
          };
        }

        groupedRecords[recordDate].employees.add(employeeId);

        const { startTimeFirstPeriod, recordTime } = record;
        const isOnTime = this.isWithinTolerance(startTimeFirstPeriod, recordTime);

        if (recordTime === '00:00:00') {
          this.missingEmployeesArray.push(employeeId);
          groupedRecords[recordDate].missingEmployees.add(employeeId);
        } else if (isOnTime) {
          this.onTimeEmployeesArray.push(employeeId);
          groupedRecords[recordDate].onTimeEmployees.add(employeeId);
        } else {
            this.lateEmployeesArray.push(employeeId);
          groupedRecords[recordDate].lateEmployees.add(employeeId);
        }
      });
    });

    return groupedRecords;
  }

  private createChartData(groupedAndCheckedRecords: any) {
    const labels: string[] = [];
    const datasets: any[] = [];

    const labelsSet = new Set<string>();

    // Extrair todas as datas do groupedAndCheckedRecords e armazená-las em um conjunto
    Object.keys(groupedAndCheckedRecords).forEach(recordDate => {
        labelsSet.add(recordDate);
    });

    // Ordenar as datas do conjunto
    const sortedLabels = Array.from(labelsSet).sort((a, b) => a.localeCompare(b));

    // Criar o array labels com as datas ordenadas
    labels.push(...sortedLabels.map(label => moment(label).format('DD/MM')));

    // Criar o array datasets com os dados formatados
    const labelsMap: { [recordDate: string]: number } = {};

    sortedLabels.forEach((label, index) => {
        labelsMap[label] = index;
    });

    datasets.push(
        {
            label: 'Bateu ponto corretamente',
            backgroundColor: '#7BD261',
            data: sortedLabels.map(label => groupedAndCheckedRecords[label]?.onTimeEmployees?.size || 0),
        },
        {
            label: 'Bateu ponto incorretamente',
            backgroundColor: '#DBDBDB',
            data: sortedLabels.map(label => groupedAndCheckedRecords[label]?.lateEmployees?.size || 0),
        },
        {
            label: 'Não bateu o ponto',
            backgroundColor: '#EB6969',
            data: sortedLabels.map(label => groupedAndCheckedRecords[label]?.missingEmployees?.size || 0),
        }
    );

    return { labels, datasets };
  }

  public async renderClocksByDayChart() {
    this.dayChart = new Chart("BarDayChart", {
        type: 'doughnut',
        data: {
            datasets: [
                {
                    label: 'Funcionários',
                    data: [
                        +this.dayChartData.datasets[0].data,
                        +this.dayChartData.datasets[1].data,
                        +this.dayChartData.datasets[2].data,
                    ],
                    backgroundColor: [
                        '#7BD261',
                        '#DBDBDB',
                        '#EB6969',
                    ],
                    hoverBackgroundColor: [
                        '#7BD261',
                        '#DBDBDB',
                        '#EB6969',
                    ],
                },
            ],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            cutout: 55,
            layout: {
                autoPadding: true,
            },
            plugins: {
                title: {
                    display: false,
                    text: 'Jornadas de Trabalho',
                    font: {
                        size: 16,
                    },
                    align: 'center',
                },
                legend: {
                    display: false,
                },
            },
            onClick: (event, elements) => {
                if (elements  && elements.length > 0) {
                    this.registeredTheWorkPoint(elements[0]);
                }
            }
        },
    });
  }

  public registerClockModalHide() {
    this.display = false;
  }

  private registeredTheWorkPoint(elements: any) {
    if (elements.index === 0) {
        this.registerClockHeaderSubtitle = 'Bateu ponto corretamente';
        this.display = true;

        const filteredEmployees = this.onTimeEmployeesArray.map(
            employeeId => this.groupEmployees.find(employee => employee.employeeId === employeeId)
        );

        this.registerClockData = filteredEmployees.sort((a, b) => a.name.localeCompare(b.name));
    }

    if (elements.index === 1) {
        this.registerClockHeaderSubtitle = 'Bateu ponto incorretamente';
        this.display = true;

        const filteredEmployees = this.lateEmployeesArray.map(
            employeeId => this.groupEmployees.find(employee => employee.employeeId === employeeId)
        );

        this.registerClockData = filteredEmployees.sort((a, b) => a.name.localeCompare(b.name));
    }

    if (elements.index === 2) {
        this.registerClockHeaderSubtitle = 'Não bateu o ponto';
        this.display = true;

        const filteredEmployees = this.missingEmployeesArray.map(
            employeeId => this.groupEmployees.find(employee => employee.employeeId === employeeId)
        );

        this.registerClockData = filteredEmployees.sort((a, b) => a.name.localeCompare(b.name));
    }
  }
}
