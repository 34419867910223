import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Company } from 'src/model/company';
import { Department } from 'src/model/department';
import { AppStorageService } from 'src/services/app-storage.service';
import { CompanyService } from 'src/services/company.service';
import { DepartmentService } from 'src/services/department.service';

@Component({
    selector: 'app-time-bank-form-report',
    templateUrl: './time-bank-form-report.component.html',
    styleUrls: ['./time-bank-form-report.component.scss'],
})
export class TimeBankFormReportComponent implements OnInit {

    public appendTo = 'body';

    public formGroup!: FormGroup;

    public company: Company | undefined;

    public departments: Department[] = [];

    public formData: any;

    public companyList: any[] = [];
    public branchList: any[] = [];

    public loadDepartments = false;
    public loadCompany = false;
    public thereIsBranc = false;

    public departmentName = '';
    public companyIdTemp = '';

    constructor(
        private formBuilder: FormBuilder,
        private appStorageService: AppStorageService,
        private companyService: CompanyService,
        private departmentService: DepartmentService,
    ) {
        this.formGroup = this.formBuilder.group({
            type: ['simplified'],
            startDate: ['', Validators.required],
            endDate: ['', Validators.required],
            filters: ['headquarters'],
            companyId: ['', Validators.required],
            branchId: [''],
            departmentId: [''],
            path: [''],
        });
    }

    async ngOnInit(): Promise<void> {
        await this.getCompanies();
    }

    async getCompanies() {
        const employee = await this.appStorageService.getEmployee();
        this.companyService.get(employee.companyId!).subscribe((company: any) => {
            this.company = company.data
        });

        const httpCompanyEconomicGroupResponse = await this.companyService.getBranches(
            employee.companyId!, 'ASSOCIATED_COMPANY'
        ).toPromise();

        if(httpCompanyEconomicGroupResponse?.data) {
            const response: Company[] = [];
            response.push(httpCompanyEconomicGroupResponse.data)
            response.map((companiesGroup: any) => {
                const result = companiesGroup.Items;
                result.map((companyGroup: any) => {
                    this.companyList.push(companyGroup);
                })
            })
        }
    }

    async onChange(companyId: string) {
        this.loadCompany = true;
        this.branchList = [];
        this.formGroup.get('path')?.setValue('');
        const response: Company[] = [];
        const getBranchs = await this.companyService.getBranches(companyId, 'BRANCH').toPromise();

        if (getBranchs?.data) {
            response.push(getBranchs.data)
            response.map((companiesGroupBranch: any) => {
                const result = companiesGroupBranch.Items;
                result.map((companyGroupBranch: any, index: any) => {
                    if (result.length > 0 && result[index].status !== 'INACTIVE') {
                        this.branchList.push(companyGroupBranch);
                    }
                })
            })
        } else {
            this.branchList = [];
        }

        const conpanyIdValue = this.formGroup.get('companyId')!.value

        if (this.company?.id === conpanyIdValue) {
            this.formGroup.get('path')?.setValue(companyId)
        } else {
            const conpanyIdresult: any = this.companyList.filter((company: Company) => {
                return company.id === conpanyIdValue
            });
            this.formGroup.get('path')?.setValue(conpanyIdresult[0].path)
        }
        await this.selectedCompanyIdAndDepartmentId(this.formGroup.value)
        this.loadCompany = false;
    }

    public async onSelectBranch() {
        const conpanyIdValue = this.formGroup.get('branchId')!.value
        if (conpanyIdValue !== '') {
            const conpanyIdresult: any = this.branchList.filter((company: Company) => {
                return company.id === conpanyIdValue
            });
            this.formGroup.get('path')?.setValue(conpanyIdresult[0].path)
        } else {
            await this.onChange(this.formGroup.get('companyId')!.value)
        }
        await this.selectedCompanyIdAndDepartmentId(this.formGroup.value)
    }

    async selectedCompanyIdAndDepartmentId(values: any){
        this.formGroup.get('departmentId')?.setValue('');
        this.loadDepartments = true;

        if (values) {
            const {companyId, branchId, path} = values;

            let type = '';

            if (!branchId === false) {
                const selectedCompany = await this.companyService.get(branchId).toPromise();
                type! = selectedCompany?.data?.type!;
            }
            if (!branchId === true) {
                const selectedCompany = await this.companyService.get(companyId).toPromise();
                type! = selectedCompany?.data?.type!;
            }
            this.thereIsBranc = await this.companyService.thereIsBranch(companyId, type);
            if (companyId !== '' && branchId !== '') {
                this.companyIdTemp = branchId;
            } else {
                this.companyIdTemp = companyId;
            }
            // this.formGroup.get('companyId')?.setValue(this.companyIdTemp);
            await this.getDepartments(this.companyIdTemp)
            this.loadDepartments = false;
        } else {
            this.companyIdTemp = '';
            await this.getDepartments(this.companyIdTemp)
            this.loadDepartments = false;
        }
        this.updateFormValue();
    }

    async getDepartments(companyId: string) {
        this.departments = [];

        try {
            this.departmentService.list(companyId).subscribe( department => {
                const result: Department[] = [];
                department?.data?.map((department: Department) => {
                    if (department.status === 'ACTIVE') {
                        result.push(department);
                    }
                });
                this.departments = result;
            });
            this.loadDepartments = false;
        } catch (error: any) {}
    }

    getDepartmentName(departmentName: string) {
        this.departmentName = departmentName;
    }

    updateFormValue() {
        this.formData = this.formGroup.value;
    }
}
