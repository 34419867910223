<div>
    <p-toast></p-toast>
    <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
    <!-- <h1 class="h3 mb-2 text-gray-800">Alterar Senha</h1> -->
    <!-- <p class="mb-4">Altere aqui sua senha de acesso.</p> -->

    <form [formGroup]="changePasswordForm" #passwordForm="ngForm" (ngSubmit)="cleanForm()" novalidate>
        <div class="form-row">
            <div class="form-group col-lg-4">
                <label for="name">Senha antiga</label>
                <p-password
                    [toggleMask]="true"
                    [feedback]="false"
                    id="oldpassword"
                    type="password"
                    formControlName="oldPassword">
                </p-password>
                <div class="message-error"
                     *ngIf="changePasswordForm.get('oldPassword')?.hasError('required')
                     && changePasswordForm.get('oldPassword')?.touched">Nova senha é requerida
                </div>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-lg-4">
                <label for="name">Nova senha</label>
                <p-password
                    [toggleMask]="true"
                    [feedback]="false"
                    id="newpassword"
                    type="password"
                    class="p-inputtext-sm"
                    formControlName="newPassword">
                </p-password>
                <div class="message-error"
                     *ngIf="changePasswordForm.get('newPassword')?.hasError('minlength')
                     && changePasswordForm.get('newPassword')?.touched">A senha deve ter no mínimo 8 caracteres
                </div>
                <div class="message-error"
                     *ngIf="changePasswordForm.get('newPassword')?.hasError('maxlength')
                     && changePasswordForm.get('newPassword')?.touched">A senha deve ter no máximo 24 caracteres
                </div>
            </div>
        </div>
        <div class="text-right pb-5">
            <button type="button" class="btn btn-light btn-default font-weight-bold mr-2" (click)="onClose()">Cancelar</button>
            <button type="button" [disabled]="!changePasswordForm.valid" (click)="onChangePassword()" class="btn btn-primary btn-default">Alterar</button>
        </div>
    </form>
</div>
