import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import * as moment from 'moment';
import { Table } from 'primeng/table';
import HttpVacationResponse, { HttpVacationsResponse } from 'src/model/vacation';
import { AppStorageService } from 'src/services/app-storage.service';
import { VacationService } from 'src/services/vacation.service';
import { MessageService } from 'primeng/api';
import { Employee, HttpEmployeesResponse } from 'src/model/employee';
import { ValidateDatesService } from 'src/services/validate-dates.service';
import { ContractService } from 'src/services/contract.service';
import { forkJoin, map } from 'rxjs';
import { EmployeeService } from 'src/services/employee.service';
import { CompanyService } from 'src/services/company.service';
import { AbsenceService } from 'src/services/absence.service';
import { HttpAbsencesResponse } from 'src/model/absence';

@Component({
  selector: 'app-employee-vacations-table',
  templateUrl: './employee-vacations-table.component.html',
  styleUrls: ['./employee-vacations-table.component.scss'],
  providers: [MessageService]
})
export class EmployeeVacationsTableComponent implements OnInit {

  @ViewChild("dt") table?: Table;

  public title = 'Funcionários de Férias';
  public contracts: any[] = [];
  public vacations: any[] = [];
  public vacationResponse: any;
  public absenceResponse: any;
  public absences: any[] = [];
  public filterForm: FormGroup;
  public loading = false;
  private onVacation = false;
  private findContracts: any[] = [];

  constructor(
    private appStorageService: AppStorageService,
    private vacationService: VacationService,
    private absenceService: AbsenceService,
    private validateDatesService: ValidateDatesService,
    private contractService: ContractService,
    private employeeService: EmployeeService,
    private companyService: CompanyService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
    this.filterForm = this.formBuilder.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.fetchData().then(r => null);
  }

  async fetchData(): Promise<void> {
    this.loading = true;
    const employee: Employee = await this.appStorageService.getEmployee();
    const startOfMonth = moment().clone().startOf('month').subtract(2,'months').toISOString();
    const endOfMonth   = moment().clone().endOf('month').add(2,'months').toISOString();

    await forkJoin(
        this.absenceService.getAbsenceByCompany(employee.companyId!, startOfMonth, endOfMonth, 'ALL'),
        this.employeeService.findAllByCompany(employee.companyId!)).pipe(
            map((response: any) => {
                const httpAbsencesResponse: HttpAbsencesResponse = response[0];
                const httpEmployeeResponse: HttpEmployeesResponse = response[1];

                httpAbsencesResponse.data = httpAbsencesResponse.data.filter((absence) => absence.type === 'VACATION');

                httpAbsencesResponse.data.forEach((absence) => {
                    absence.employee = httpEmployeeResponse.data.find((el) => el.id === absence.employeeId);
                });

                this.absenceResponse = httpAbsencesResponse
            })
        ).toPromise();

    const response = this.absenceResponse;

    if (Object.keys(response.data).length !== 0) {
      response.data.map((absence: any) => {
        const today = moment();
        const startDate = moment(absence.startDate).subtract(1,'d')
        const endDate = moment(absence.endDate).add(1,'d')
        if(today.isBetween(startDate, endDate)) {
            if (absence.status === 'ACTIVE') {
                this.vacations.push({
                  id: absence.employee.id,
                  start: absence.startDate,
                  end: absence.endDate,
                  employee: absence.employee,
                  employeeOnVacation: this.checkOnVacation(absence.startDate, absence.endDate) === true ? true : false
                })
            }
        }
      });
    }
    this.loading = false;
  }

//   async getVacationById(employeeId: any, employee: any): Promise<void> {
//     this.loading = true;
//     const vacations: HttpVacationResponse | undefined = await this.vacationService.getVacationByEmployee(employeeId).toPromise() || undefined;

//     const today = moment();

//     const vacationsArray: any[] = [];
//     if (this.onVacation = today.isBetween(vacations?.data?.startDate, vacations?.data?.endDate)) {
//       vacationsArray.push({
//         id: vacations?.data?.employeeId,
//         start: vacations?.data?.startDate,
//         end: vacations?.data?.endDate,
//         employee: employee
//       });
//     }
//     this.loading = false;
//     this.vacations = vacationsArray;
//   }

  async getVacationsByEmployees(start: string, end: string) {
    this.loading = true;
    if (EmployeeVacationsTableComponent.validatePeriods(start, end)) {
      this.vacations = [];
      const employee: Employee = await this.appStorageService.getEmployee();

      const response: any = await this.vacationService.getVacationByCompany(employee.companyId!, start, end).toPromise();

      if (Object.keys(response.data).length > 0) {
        const vacationsArray: any[] = [];
        response.data.map((vacation: any) => {
        if (vacation.status === 'ACTIVE') {
            vacationsArray.push({
              id: vacation?.employeeId,
              start: vacation?.startDate,
              end: vacation?.endDate,
              employee: vacation?.employee.name,
              type: response.data[0]?.type,
              employeeOnVacation: this.checkOnVacation(vacation?.startDate, vacation?.endDate) === true ? true : false
            });
        }})
        this.loading = false;
        this.vacations = vacationsArray;
    } else {
        this.loading = false;
        this.messageService.add({
            severity:'warn',
            summary:'Nenhum resultado para o intervalo selecionado!',
        });
    }
    } else {
      this.loading = false;
      this.messageService.add({
        severity:'warn',
        summary:'Períodos Incorretos!',
        detail: 'Verifique se os intervalos dos períodos estão corretos'
      });
    }
  }

  async searchVacation(values: any) {
    this.absences = [];
    this.vacations = [];
    this.loading = true;
    const {employeeId, startDate, endDate} = values;

    const convertStartDataToSearch = moment(startDate, 'DD/MM/YYYY').startOf('hour').format(
        'YYYY-MM-DD 00:00:00'
    );
    const convertEtartDataToSearch = moment(endDate, 'DD/MM/YYYY').add(1, 'day').format(
        'YYYY-MM-DD 23:59:59'
    );

    const isDateValid = this.validateDatesService.validatePeriods(convertStartDataToSearch, convertEtartDataToSearch);

    if (isDateValid) {
      if (startDate && endDate && employeeId) {
        await forkJoin(
            this.absenceService.getAbsenceByEmployees(employeeId, convertStartDataToSearch, convertEtartDataToSearch, 'ALL'),
            this.employeeService.getOne(employeeId)).pipe(
                map((response: any) => {
                    const httpAbsencesResponse: HttpAbsencesResponse = response[0];
                    const httpEmployeeResponse: any = response[1];

                    httpAbsencesResponse.data = httpAbsencesResponse.data.filter((absence) => absence.type === 'VACATION');

                    httpAbsencesResponse.data.map((absence) => {
                        absence.employee = httpEmployeeResponse.data
                    });

                    this.absenceResponse = httpAbsencesResponse
                })
            ).toPromise();


        const response = this.absenceResponse;

        if (Object.keys(response.data).length > 0) {
            const absence: any[] = [];
            if (response.data[0].status === 'ACTIVE') {
                absence.push({
                    id: response.data[0]?.id,
                    start: response.data[0]?.startDate,
                    end: response.data[0]?.endDate,
                    employee: response.data[0]?.employee,
                    type: response.data[0]?.type,
                    employeeOnVacation: this.checkOnVacation(response.data[0]?.startDate, response.data[0]?.endDate) === true ? true : false
                })
            }
            this.loading = false;
            this.vacations = absence;
        } else {
            this.loading = false;
            this.messageService.add({
              severity:'warn',
              summary:'Nenhum resultado para o intervalo selecionado!',
          });
        }
        } else {
            this.loading = true;
            this.vacations = [];
            const employee: Employee = await this.appStorageService.getEmployee();

            await forkJoin(
                this.absenceService.getAbsenceByCompany(employee.companyId!, convertStartDataToSearch, convertEtartDataToSearch, 'ALL'),
                this.employeeService.findAllByCompany(employee.companyId!)).pipe(
                    map((response: any) => {
                        const httpAbsencesResponse: HttpAbsencesResponse = response[0];
                        const httpEmployeeResponse: HttpEmployeesResponse = response[1];

                        httpAbsencesResponse.data = httpAbsencesResponse.data.filter((absence) => absence.type === 'VACATION');

                        httpAbsencesResponse.data.forEach((absence) => {
                            absence.employee = httpEmployeeResponse.data.find((el) => el.id === absence.employeeId);
                        });

                        this.absenceResponse = httpAbsencesResponse
                    })
            ).toPromise();

            const response = this.absenceResponse;

            if (Object.keys(response.data).length > 0) {
              const absenceArray: any[] = [];
              response.data.map((absence: any) => {
                if (absence.status === 'ACTIVE') {
                    absenceArray.push({
                      id: absence?.employeeId,
                      start: absence?.startDate,
                      end: absence?.endDate,
                      employee: absence?.employee,
                      employeeOnVacation: this.checkOnVacation(absence?.startDate, absence?.endDate) === true ? true : false
                    });
                }
              })
              this.loading = false;
              this.vacations = absenceArray;
            } else {
              this.loading = false;
              this.messageService.add({
                severity:'warn',
                summary:'Nenhum resultado para o intervalo selecionado!',
            });
        }
      }
    } else {
      this.loading = false;
      this.messageService.add({
        severity:'warn',
        summary:'Períodos Incorretos!',
        detail: 'Verifique se os intervalos dos períodos estão corretos'
      });
    }
  }

  private static validatePeriods(
    startVacationDate: any,
    endVacationDate: any): boolean {

    const startDate = moment(startVacationDate);
    const endDate = moment(endVacationDate);

    const diff = moment(endDate).diff(startDate, 'days');
    const isbefore = startDate.isBefore(endDate);

    return (diff > 1 && isbefore);
  }

  async getSelectedContract(vacation: any) {
    this.goToVacation(vacation);
  }

  async goToVacation(data: any) {
    const contract = await this.contractService.getContractsByEmployee(data.id).toPromise();
    const company = await this.companyService.get(data.companyId).toPromise();

    const navigationExtras: NavigationExtras = {
        state: {
            employee: data,
            contract: contract!.data[0],
            company: company!.data
        }
    };

    await this.router.navigate(['/configure-employee'], navigationExtras);
  }

  public search(): void {
    const {
      startDate,
      endDate
    } = this.filterForm.value;

    this.getVacationsByEmployees(
      moment(startDate).subtract(1, "days").format('YYYY-MM-DD HH:mm:ss'),
      moment(endDate).add(1, "days").format('YYYY-MM-DD HH:mm:ss')
    );
  }

  onCleanForm() {
    this.ngOnDestroy();
  }

  ngOnDestroy() {
    this.vacations = [];
    this.ngOnInit();
  }

  checkOnVacation(startDateParam: string, endDateParam: string): boolean {
    const today = moment();
    const startDate = moment(startDateParam).format('YYYY-MM-DD');
    const endDate = moment(endDateParam).add(1, 'day').format('YYYY-MM-DD');
    if (today.isBetween(startDate, endDate)) {
        return true;
    } else {
        return false;
    }
  }
}
