import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {ConfirmationService, MessageService} from 'primeng/api';
import { Absence } from 'src/model/absence';
import {Contract} from 'src/model/contract';
import {Vacation} from 'src/model/vacation';
import { AbsenceService } from 'src/services/absence.service';
import {AppStorageService} from 'src/services/app-storage.service';
import {VacationService} from 'src/services/vacation.service';

@Component({
    selector: 'app-employee-vacations',
    templateUrl: './employee-vacations.component.html',
    styleUrls: ['./employee-vacations.component.scss'],
    providers: [MessageService]
})
export class EmployeeVacationsComponent implements OnInit {

    constructor(
        private formBuilder: FormBuilder,
        private vacationService: VacationService,
        private absenceService: AbsenceService,
        private appStorageService: AppStorageService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private router: Router,
    ) {
        if (this.router.getCurrentNavigation()?.extras.state) {
            this.contract = this.router.getCurrentNavigation()?.extras.state?.contract;
            this.showHeader = true;
        }

        const now = moment();
        this.date = now.format();
        this.form = this.formBuilder.group({
            startDateInput: ['', Validators.required],
            endDateInput: ['', Validators.required],
        });
        this.btnExcluir = 'none';
        this.btnReprogramar = 'none';
        this.btnProgramar = 'none';
        this.btnEditar = 'none';
    }

    @Input() contract: Contract = new Contract;

    public form: FormGroup;
    public vacation?: Vacation;
    public absence?: Absence;
    public date: string;
    public startDate?: string;
    public endDate?: string;
    public btnReprogramar?: string;
    public btnExcluir?: string;
    public btnProgramar?: string;
    public btnEditar?: string;
    public loadSkeleton!: boolean;
    public showHeader!: boolean;
    public headerTitle ? = '';
    public onVacation = false;

    public absenceData = [{
        contractId: '6234b143',
        employeeId: 'db934rgt',
        companyId: '94ca1c67',
        departmentId: 'efc0fcf7',
        type: 'MATERNITY',
        startDate: '2023-10-01T03:00:00.000Z',
        endDate: '2023-10-10T03:00:00.000Z',
    }];

    private static validateUpdatePeriods(
        updateStartVacationDate: any,
        updateEndVacationDate: any): boolean {

        const updateStartDate = moment(updateStartVacationDate);
        const updateEndDate = moment(updateEndVacationDate);

        return (updateStartDate.isBefore(updateEndDate));
    }

    private static validatePeriods(
        startVacationDate: any,
        endVacationDate: any): boolean {

        const startDate = moment(startVacationDate);
        const endDate = moment(endVacationDate);

        return (startDate.isBefore(endDate));
    }

    private static validateTotalOfDays(
        startVacationDate: any,
        endVacationDate: any): boolean {

        const startDate = moment(startVacationDate);
        const endDate = moment(endVacationDate);
        const totalOfDays = moment.duration(endDate.diff(startDate)).asDays();

        if (totalOfDays >= 30) {
            return true;
        }

        return false;
    }

    private static validateFutureDay(
        startVacationDate: any): boolean {

        const startDate = moment(startVacationDate);
        const isToday = moment(startDate).isSame(moment(), 'day');

        if (isToday) {
            return true;
        }

        return false;
    }

    private static validatePastDay(
        startVacationDate: any): boolean {

        const startDate = moment(startVacationDate);
        const isPast = moment(startDate).isBefore(moment(), 'day');

        if (isPast) {
            return true;
        }

        return false;
    }

    async ngOnInit(): Promise<void> {
        this.loadSkeleton = true;
        await this.initializePage();
    }

    async initializePage(): Promise<void> {
        const employee = await this.appStorageService.getEmployee();
        this.headerTitle = employee.name;
        await this.getVacationById();
    }

    async getVacationById(): Promise<void> {
        try {
            const getAbsence: any = await this.absenceService.getAbsenceByEmployee(this.contract.employeeId!, 'ALL').toPromise();
            const absence:any = getAbsence!.data[0];

            if (absence !== undefined) {
                if (this.checkOnVacation(absence!.startDate!, absence!.endDate!)) {
                    this.onVacation = true;
                } else {
                    this.btnProgramar = 'block';
                    this.btnReprogramar = 'none';
                    this.btnExcluir = 'none';
                }

                if (absence.type !== 'VACATION') {
                    const isScheduleAbsence = await this.absenceService.checkIfScheduleAbsence(this.contract.employeeId!, absence!.startDate!);
                    if (isScheduleAbsence) {
                        this.onVacation = true;
                        this.btnProgramar = 'block';
                        this.btnReprogramar = 'none';
                        this.btnExcluir = 'none';
                    }
                }

                if (absence.type === 'VACATION') {
                    if (absence !== undefined) {
                        this.absence = absence;
                        this.form.get('startDateInput')?.setValue(moment(this.absence!.startDate).format('DD/MM/YYYY'));
                        this.form.get('endDateInput')?.setValue(moment(this.absence!.endDate).format('DD/MM/YYYY'));
                        this.onVacation = this.checkOnVacation(this.absence!.startDate!, this.absence!.endDate!);
                        this.btnProgramar = 'none';
                        this.btnReprogramar = 'block';
                        this.btnExcluir = 'block';
                    } else {
                        this.onVacation = false;
                        this.btnProgramar = 'block';
                        this.btnReprogramar = 'none';
                        this.btnExcluir = 'none';
                    }
                }
            } else {
                this.onVacation = false;
                this.btnProgramar = 'block';
                this.btnReprogramar = 'none';
                this.btnExcluir = 'none';
            }
        } catch (error: any) {
            this.messageService.add({
                severity: 'error',
                summary: 'Error!',
                detail: error.mensagem
            });
        }
    }

    async onProgrammerVacation(): Promise<void> {
        const startDateField = this.form.get('startDateInput');
        const endDateField = this.form.get('endDateInput');

        const convertStartDataToSave = moment(startDateField?.value, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const convertEndDataToSave = moment(endDateField?.value, 'DD/MM/YYYY').format('YYYY-MM-DD');

        if (EmployeeVacationsComponent.validatePeriods(convertStartDataToSave, convertEndDataToSave)) {

            if (EmployeeVacationsComponent.validateTotalOfDays(convertStartDataToSave, convertEndDataToSave)) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Período Incorreto!',
                    detail: 'Período de férias não pode exceder 30 dias.'
                });
            } else if (EmployeeVacationsComponent.validateFutureDay(convertStartDataToSave)) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Período Incorreto!',
                    detail: 'A data de início deve ser uma data futura.'
                });
            } else if (EmployeeVacationsComponent.validatePastDay(convertStartDataToSave)) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Período Incorreto!',
                    detail: 'A data de início deve ser uma data futura.'
                });
            } else {
                const data: Absence = {
                    contractId: this.contract.id,
                    employeeId: this.contract.employeeId,
                    departmentId: this.contract.departmentId,
                    companyId: this.contract.companyId,
                    type: 'VACATION',
                    startDate: moment(convertStartDataToSave).toISOString(),
                    endDate: moment(convertEndDataToSave).toISOString(),
                };

                this.confirmationService.confirm({
                    message: `Confirmar programação de férias para o período de ${moment(data.startDate).format('DD/MM/YYYY')} à ${moment(data.endDate).format('DD/MM/YYYY')} ?`,
                    header: 'Programar Férias',
                    accept: () => {
                        this.absenceService.save(data).subscribe((response: any) => {
                            // close loading
                            if (response.success) {
                                this.messageService.add({
                                    life: 5000,
                                    severity: 'success',
                                    summary: 'Férias Programadas!',
                                    detail: 'O colaborador receberá um aviso por e-mail e não poderá bater o ponto nos dias programados para férias.'
                                });
                            } else {
                                this.messageService.add({
                                    severity: 'error',
                                    summary: 'Error!',
                                    detail: response.mensagem
                                });
                            }
                            this.ngOnInit();
                        });
                    },
                    reject: () => {
                        this.messageService.add({
                            severity: 'warn',
                            summary: 'Não confirmado',
                            detail: 'Férias não confirmada!'
                        });
                    }
                });
            }
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Período Incorreto!',
                detail: 'Verifique se os intervalos de datas estão corretos.'
            });
        }
    }

    async onUpdate(): Promise<void> {
        console.log('delete');
        const updateStartDateField = this.form.get('startDateInput');
        const updateEndDateField = this.form.get('endDateInput');

        const convertUpdateStartDataToSave = moment(updateStartDateField?.value, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const convertUpdateEndDataToSave = moment(updateEndDateField?.value, 'DD/MM/YYYY').format('YYYY-MM-DD');

        if (EmployeeVacationsComponent.validateUpdatePeriods(convertUpdateStartDataToSave, convertUpdateEndDataToSave)) {
            updateStartDateField?.setValue(updateStartDateField.value);
            updateEndDateField?.setValue(updateEndDateField.value);

            if (EmployeeVacationsComponent.validateTotalOfDays(convertUpdateStartDataToSave, convertUpdateEndDataToSave)) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Período Incorreto!',
                    detail: 'Período de férias não pode exceder 30 dias.'
                });
            } else if (EmployeeVacationsComponent.validateFutureDay(convertUpdateStartDataToSave)) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Período Incorreto!',
                    detail: 'A data de início deve ser uma data futura.'
                });
            } else if (EmployeeVacationsComponent.validatePastDay(convertUpdateStartDataToSave)) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Período Incorreto!',
                    detail: 'A data de início deve ser uma data futura.'
                });
            } else {
                const updateContract: any = {};
                updateContract.startDate = moment(convertUpdateStartDataToSave).toISOString();
                updateContract.endDate = moment(convertUpdateEndDataToSave).toISOString();
                const data = Object.assign(updateContract);

                this.confirmationService.confirm({
                    message: `Confirmar reprogramação de férias para o o período de ${moment(data.startDate).format('DD/MM/YYYY')} à ${moment(data.endDate).format('DD/MM/YYYY')} ?`,
                    header: 'Reprogramar Férias',
                    accept: () => {
                        this.absenceService.updateAbsence(this.absence?.id!, data).subscribe((response: any) => {
                            // loading here
                            if (response.success) {
                                this.messageService.add({
                                    life: 5000,
                                    severity: 'success',
                                    summary: 'Férias Reprogramadas!',
                                    detail: 'O colaborador receberá um aviso por e-mail e não poderá bater o ponto nos dias programados para férias.'
                                });
                                this.ngOnInit();
                                this.btnReprogramar = 'none';
                                this.btnExcluir = 'none';
                            } else {
                                this.messageService.add({
                                    severity: 'error',
                                    summary: 'Error!',
                                    detail: response.mensagem
                                });
                            }
                        });
                    },
                    reject: () => {
                        this.messageService.add({
                            severity: 'warn',
                            summary: 'Não confirmado',
                            detail: 'Férias não confirmada!'
                        });
                    }
                });
            }

        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Período Incorreto!',
                detail: 'Verifique se os intervalos de datas estão corretos.'
            });
        }
    }

    async onDelete(): Promise<void> {
        // open loading
        const deleteStartDateField = this.form.get('startDateInput');
        const deleteEndDateField = this.form.get('endDateInput');

        const convertDeleteStartDataToSave = moment(deleteStartDateField?.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
        const convertDeleteEndDataToSave = moment(deleteEndDateField?.value, 'DD/MM/YYYY').format('DD/MM/YYYY');

        this.confirmationService.confirm({
            message: `Deseja realmente excluir essas Férias já programadas? ${convertDeleteStartDataToSave} à ${convertDeleteEndDataToSave} ?`,
            header: 'Excluir Férias',
            accept: () => {
                this.absenceService.deleteAbsence(this.vacation?.id!).subscribe((response: any) => {
                    // close loading
                    if (response.success) {
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Férias Cancelada!',
                        });

                        this.ngOnInit();
                        this.btnProgramar = 'block';
                        this.btnReprogramar = 'none';
                        this.btnExcluir = 'none';
                    } else {
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error!',
                            detail: response.mensagem,
                        });
                    }
                });
            },
            reject: () => {
                this.messageService.add({
                    severity: 'warn',
                    summary: 'Não confirmado',
                    detail: 'Férias não confirmada!'
                });
            }
        });
    }

    onSelectStartDate(event: any): void {
        this.form.get('endDateInput')?.setValue(moment(event).format('DD/MM/YYYY'));
    }

    checkOnVacation(startDateParam: string, endDateParam: string): boolean {
        if (startDateParam && endDateParam) {
            const today = moment();
            const startDate = moment(startDateParam).format('YYYY-MM-DD');
            const endDate = moment(endDateParam).add(1, 'day').format('YYYY-MM-DD');
            if (today.isBetween(startDate, endDate)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }

    }
}
