import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {SERVER_URL} from '../environments/environment';
import HttpVacationResponse, { Vacation } from './../model/vacation';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VacationService {

    constructor(
        private httpClient: HttpClient) {
    }

    /**
     *  Save Vacation
     *
     */
    public save(data: Vacation) {
        return this.httpClient.post<HttpVacationResponse>(`${SERVER_URL}/vacation`, data);
    }

    /**
     *  Get Vacation by ID
     *
     */
    public getVacationByEmployee(employeeId: string): Observable<HttpVacationResponse> {
        return this.httpClient.get<HttpVacationResponse>(`${SERVER_URL}/vacation/employee/${employeeId}`);
    }

    /**
     *  Get vacation by employee and start/end date
     *
     */
    public getVacationByEmployees(employeeId: string, startDate: string, endDate: string): Observable<HttpVacationResponse> {
        return this.httpClient.get<HttpVacationResponse>(`${SERVER_URL}/vacation/employee/${employeeId}/startDate/${startDate}/endDate/${endDate}`);
    }

    /**
     *  Get vacation by Company
     *
     */
    public getVacationByCompany(companyId: string, startDate: string, endDate: string): Observable<HttpVacationResponse> {
        return this.httpClient.get<HttpVacationResponse>(`${SERVER_URL}/vacation/company/${companyId}/startDate/${startDate}/endDate/${endDate}`);
    }

    /**
     *  Update Vacation
     *
     */
    public update(id: string, data: any): Observable<HttpVacationResponse> {
        return this.httpClient.put<HttpVacationResponse>(`${SERVER_URL}/vacation/${id}`, data);
    }

    /**
     *  Delete Vacation
     *
     */
    public delete(employeeId: string): Observable<HttpVacationResponse> {
        return this.httpClient.delete<HttpVacationResponse>(`${SERVER_URL}/vacation/${employeeId}`);
    }
}
