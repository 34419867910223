import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of as observableOf} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import * as ContractAction from '../actions/contract.action';
import {ContractService} from '../../services/contract.service';
import {HttpContractsResponse} from '../../model/contract';

@Injectable()
export class ContractEffect {

    constructor(
        private contractService: ContractService,
        private actions$: Actions) {
    }

    loadContractRequestEffect$ = createEffect(() => this.actions$.pipe(
        ofType(ContractAction.loadContractRequestAction),
        switchMap(action => {
            return this.contractService.getContractsByCompany(action.id).pipe(
                map((response: HttpContractsResponse) => {
                    return ContractAction.loadContractSuccessAction({ contracts: response.data});
                }),
                catchError((error: any) => {
                    return observableOf(ContractAction.loadContractFailureAction({error}));
                })
            );
        })
    ));

}
