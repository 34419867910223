<p-toast></p-toast>

<div *ngIf="!editingPersonalBankdat">
    <div class="mb-3">
        <div class="row body-row">
            <div class="col-sm body-row-fields">
                <h3>Banco</h3>
                <span>{{ employee?.bank }}</span>
            </div>
            <div class="col-sm body-row-fields">
                <h3>Agência</h3>
                <span>{{ employee?.bankBranch }}</span>
            </div>
            <div class="col-sm body-row-fields">
                <h3>Tipo</h3>
                <span>{{ employee?.currentAccountType }}</span>
            </div>
        </div>
    </div>
    <div class="mb-3">
        <div class="row body-row">
            <div class="col-sm body-row-fields">
                <h3>Conta</h3>
                <span>{{ employee?.currentAccount || 'Não informado' }}</span>
            </div>
            <div class="col-sm body-row-fields">
                <h3>DV</h3>
                <span>{{ employee?.verificationDigit || 'Não informado' }}</span>
            </div>
            <div class="col-sm body-row-fields">
                <h3>Pix</h3>
                <span>{{ employee?.pix || 'Não informado' }}</span>
            </div>
        </div>
    </div>

    <div class="button-save-container d-flex justify-content-center align-items-center  flex-column">
        <button type="submit" class="btn btn-outline-primary btn-block" (click)="onEditingPersonalBankdata()">
            <div class="d-flex justify-content-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
                <span class="ml-2">Editar</span>
            </div>
        </button>
    </div>
</div>

<form [formGroup]="formUpdate" *ngIf="editingPersonalBankdat">
    <div class="w-100">
        <div class="mb-3">
            <!-- ROW-01 -->
            <div class="form-row">
                <div class="form-group col-lg-4">
                    <label for="bank">Banco</label>
                    <select
                        id="city" class="form-control"
                        formControlName="bank">
                        <option value="">Selecione um banco</option>
                        <option *ngFor="let bank of banks" [value]="bank.longname">{{ bank.longname }}</option>
                    </select>
                </div>

                <div class="form-group col-lg-4">
                    <label for="bankBranch">Agência</label>
                    <input
                        id="bankBranch"
                        type="text"
                        placeholder="Digite aqui"
                        class="form-control"
                        maxlength="4"
                        formControlName="bankBranch"
                        numbersOnly>
                </div>

                <div class="form-group col-lg-4">
                    <label for="current-account-type">Tipo</label>
                    <select
                        id="current-account-type"
                        class="form-control"
                        formControlName="currentAccountType">
                        <option value="">Selecione um tipo de conta</option>
                        <option *ngFor="let catype of currentAccountTypeField" [value]="catype">
                            {{ catype }}
                        </option>
                    </select>
                </div>
            </div>

            <!-- ROW-02 -->
            <div class="form-row">
                <div class="form-group col-lg-6">
                    <label for="currentAccount">Conta</label>
                    <input
                        id="currentAccount"
                            type="text"
                            maxlength="30"
                            class="form-control"
                            formControlName="currentAccount"
                            numbersOnly>
                </div>

                <div class="form-group col-lg-6">
                    <label for="dv">DV</label>
                    <input
                        id="dv"
                        type="text"
                        maxlength="1"
                        class="form-control"
                        placeholder="Digite aqui"
                        maxlength="1"
                        formControlName="verificationDigit">
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-lg-6">
                    <label for="pix-key">Chave Pix</label>
                    <select
                        id="pix-key"
                        class="form-control"
                        formControlName="pixKey"
                        (change)="autofocus.focus()">
                        <option value="">Selecione um tipo de chave</option>
                        <option
                            placeholder="Selecione uma chave"
                            *ngFor="let key of pixKeyField" [value]="key">{{ key }}
                        </option>
                    </select>
                </div>

                <div class="form-group col-lg-6">
                    <label for="street">PiX</label>
                    <input
                        id="pix"
                        #autofocus
                        type="text"
                        placeholder="Digite aqui"
                        class="form-control"
                        formControlName="pix">
                </div>
            </div>

            <div class="button-save-container d-flex justify-content-center align-items-center flex-column mb-3" >
                <button type="submit" class="btn btn-primary btn-block" (click)="onSave()" [disabled]="!formUpdate.valid">
                    Salvar
                </button>
            </div>

            <div class="button-save-container d-flex justify-content-center align-items-center  flex-column">
                <button type="submit" class="btn btn-outline-primary btn-block" (click)="onCancel()">
                    Cancelar
                </button>
            </div>
        </div>
    </div>
</form>
