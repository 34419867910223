export default class TranslateAbsenceTypeHelper {

    public static translateAbsenceType(type: string) {
        switch (type) {
            case 'MATERNITY':
              return 'Licença maternidade';
            case 'PATERNITY':
              return 'Licença paternidade';
            case 'ILLNESS':
              return 'Doença';
            case 'DECEASE':
              return 'Falecimento';
            case 'MARRIAGE':
              return 'Casamento';
            case 'BLOOD_DONATION':
              return 'Doação de sangue';
            case 'VOTER_REGISTRATION':
              return 'Alistamento como eleitor';
            case 'MILITARY_SERVICE':
              return 'Serviço militar';
            case 'COLLEGE_ENTRANCE_EXAM':
              return 'Vestibular';
            case 'COURT_APPEARANCE':
              return 'Comparecimento em juízo';
            case 'UNION_REPRESENTATION':
              return 'Representação sindical';
            case 'PRENATAL_APPOINTMENTS':
              return 'Consultas médicas de Pré-Natal';
            case 'CANCER_SCREENINGS':
              return 'Exames preventivos de câncer';
            default:
                return 'Acompanhamento de consulta médica do filho';
        }
    }
}
