import {CityBr} from '../../model/citiesbr';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import * as L from 'leaflet';
import * as moment from 'moment';
import {MessageService} from 'primeng/api';
import HttpRegistersClockResponse from 'src/model/register-clock';
import {AddressService} from 'src/services/address.service';
import {AppStorageService} from 'src/services/app-storage.service';
import {NotificationService} from 'src/services/notification.service';
import {RegisterClockService} from 'src/services/register-clock.service';
import {ValidateDatesService} from 'src/services/validate-dates.service';
import {Employee, HttpEmployeesResponse} from 'src/model/employee';
import { forkJoin, map } from 'rxjs';
import { EmployeeService } from 'src/services/employee.service';
import { SearchbarEmployeeComponent } from 'src/components/searchbar-employee/searchbar-employee.component';

const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const iconRedUrl = 'assets/marker-icon-red.png';
const shadowUrl = 'assets/marker-shadow.png';
const iconDefault = L.icon({
    iconRetinaUrl,
    iconUrl,
    shadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41]
});

const iconRed = L.icon({
    iconRetinaUrl,
    iconUrl: iconRedUrl,
    shadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41]
});

L.Marker.prototype.options.icon = iconDefault;

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    providers: [MessageService]
})
export class MapComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild(SearchbarEmployeeComponent) searchBar?: SearchbarEmployeeComponent;

    private map: any;
    public cities: any[] = [];
    public cityName = '';
    public mapForm: FormGroup;
    public loading = false;
    public clockResponse: any;
    private lat = 0;
    private lon = 0;
    private employee: Employee | undefined;


    constructor(
        private appStorageService: AppStorageService,
        private registerClockService: RegisterClockService,
        private validateDatesService: ValidateDatesService,
        private employeeService: EmployeeService,
        private messageService: MessageService,
        private notificationService: NotificationService,
        private addressService: AddressService,
        private formBuilder: FormBuilder,
    ) {
        this.mapForm = this.formBuilder.group({
            city: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.fetchData().then(r => null);
        // this.notificationService.messages.subscribe(() => {
        //     this.onCleanForm();
        // });
    }


     ngOnDestroy(): void {
        if (this.map) {
            this.map.off();
            this.map.remove();
        }
    }

    async fetchData(): Promise<void> {
        this.loading = true;
        this.employee = await this.appStorageService.getEmployee();
        const startDate = moment().clone().startOf('month').toISOString();
        const endDate = moment().clone().endOf('month').toISOString();

        await forkJoin(
            this.registerClockService.listByCompany(this.employee.companyId as string, startDate, endDate),
            this.employeeService.findAllByCompany(this.employee.companyId!)).pipe(
                map((response: any) => {
                    const httpClockResponse: HttpRegistersClockResponse = response[0];
                    const httpEmployeeResponse: HttpEmployeesResponse = response[1];

                    httpClockResponse.data!.map((clock) => {
                        clock.employee = httpEmployeeResponse.data.find((el) => el.id === clock.employeeId)
                    })
                    this.clockResponse = httpClockResponse
                })
            ).toPromise();

            const clocks = this.mapOfClocks(this.clockResponse.data);

        const locations = this.getUniqueLocation(clocks, 'name');

        if (locations.length !== 0) {
            this.loadMarkers(locations);
        } else {
            this.loading = false;
        }

        this.loadCities();
    }

    public getUniqueLocation(arr: any, key: any): unknown[] {
        return [...new Map(arr.map((item: any) => [item[key], item])).values()];
    }

    private initMap(): void {
        this.map = L.map('map', {
            center: [-3.7758148, -38.6346831],
            zoom: 10
        });

        const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 18,
            minZoom: 3,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        });

        tiles.addTo(this.map);
        this.loading = true;
    }

    ngAfterViewInit(): void {
        this.initMap();
    }

    async findEmployeeByDates(values: any): Promise<void> {
        this.loading = true;
        this.ngOnDestroy();
        const {employeeId, startDate, endDate} = values;

        const convertStartDataToSearch = moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const convertEndDataToSearch = moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

        const isDateValid = this.validateDatesService.validatePeriods(
            convertStartDataToSearch,
            convertEndDataToSearch
        );

        if (isDateValid) {

            if (convertStartDataToSearch && convertEndDataToSearch && employeeId) {

                await forkJoin(
                    this.registerClockService.getClockRegistersByEmployeeAndDates(employeeId, convertStartDataToSearch, convertEndDataToSearch),
                    this.employeeService.getOne(employeeId)).pipe(
                        map((response: any) => {
                            const httpClockResponse: HttpRegistersClockResponse = response[0];
                            const httpEmployeeResponse: any = response[1];

                            httpClockResponse.data!.map((clock) => {
                                clock.employee = httpEmployeeResponse.data
                            })
                            this.clockResponse = httpClockResponse
                        })
                    ).toPromise();

                // const response = await this.registerClockService.getClockRegistersByEmployeeAndDates(
                //     employeeId,
                //     moment(convertStartDataToSearch).toISOString(),
                //     moment(convertEndDataToSearch).toISOString()
                // ).toPromise() || null;

                if (this.clockResponse.data.length !== 0) {
                    const searchClocks = this.mapOfClocks(this.clockResponse.data);
                    this.initMap();
                    this.loadMarkers(searchClocks);
                } else {
                    this.initMap();
                    this.messageService.add({
                        severity: 'warn',
                        summary: 'Nenhum resultado para o intervalo selecionado!',
                    });
                    this.loading = false;
                }
            } else {
                const companyId = this.employee?.companyId;
                const response = await this.registerClockService.listByCompany(
                    companyId || '',
                    moment(convertStartDataToSearch).toISOString(),
                    moment(convertEndDataToSearch).toISOString()
                ).toPromise();

                if (response?.data?.length !== 0) {
                    const searchClocks = this.mapOfClocks(response?.data);
                    this.initMap();
                    this.loadMarkers(searchClocks);
                } else {
                    this.initMap();
                    this.messageService.add({
                        severity: 'warn',
                        summary: 'Nenhum resultado para o intervalo selecionado!',
                    });
                    this.loading = false;
                }
            }
        } else {
            this.initMap();
        }
    }

    public loadMarkers(locations: any): void {
        const markerArray = Array();

        if (locations.length !== 0) {
            this.loading = false;
        }

        locations.map((mark: any) => {
            const lon = mark.longitude;
            const lat = mark.latitude;
            const marker = L.marker([lat, lon]);
            markerArray.push(marker);

            mark.type === 'S' ? marker.setIcon(iconRed) : marker.setIcon(iconDefault);

            marker.bindPopup(this.makePopup(mark));
            marker.addTo(this.map);
        });

        const group = L.featureGroup(markerArray).addTo(this.map);
        this.map.fitBounds(group.getBounds());
    }

    public mapOfClocks(object: any): any[] {
        const result: any[] = [];

        const sorted = object.slice().sort((a: any, b: any) =>
            moment(a.recordDateTime, 'YYYY-MM-DD HH:mm:ss').toDate().getTime() -
            moment(b.recordDateTime, 'YYYY-MM-DD HH:mm:ss').toDate().getTime());



        sorted.forEach((clock: any) => {
            if (!isNaN(clock.latitude) && !isNaN(clock.longitude)) {
                result.push({
                    recordDate: clock.recordDate,
                    recordTime: clock.recordTime,
                    name: clock.employee.name || 'Não registrado',
                    address: clock.address || 'Não registrado',
                    latitude: parseFloat(`${clock.latitude}`),
                    longitude: parseFloat(`${clock.longitude}`),
                    type: clock.type,
                });
            }
        });

        return result;
    }

    makePopup(data: any): string {
        return `` +
            `<div>${data.type === 'E' ? 'Entrada de' : 'Saída de '} ${(data.name).split(' ').slice(0, -1).join(' ')}</div>` +
            `<div>Endereço: ${data.address}</div>` +
            `<div>às ${data.recordTime} do dia ${data.recordDate}</div>`;
    }

    public loadCities(): void {
        const stateId = 6;

        this.addressService.getCitiesBr(stateId).subscribe((city: CityBr[]) => {
            city.map(m => {
                this.cities.push(m);
            });
        });
    }

    public getFormValues(): void {
        this.loading = true;
        // this.ngOnDestroy();

        this.cityName = this.mapForm.value[Object.keys(this.mapForm.value)[0]];

        this.addressService.addressMapSearch(this.cityName).subscribe(results => {
            let validCoordinates = true;

            results.forEach((result: any) => {
                if (isNaN(result.latitude) || isNaN(result.longitude)) {
                    validCoordinates = false;
                } else {
                    this.lat = result.latitude;
                    this.lon = result.longitude;
                }
            });

            if (validCoordinates) {
                // this.initMap();
                const latlng = L.latLng(this.lat, this.lon);
                if (!isNaN(this.lat) && !isNaN(this.lon)) {
                    // this.map.setView(latlng, 15);
                    this.map.panTo([this.lat, this.lon]);
                } else {
                    console.error("Latitude ou longitude inválida");
                }
            } else {
                console.error("Latitude ou longitude inválida");
            }

            this.loading = false;
        });
    }

    public onCleanForm(): void {
        this.ngOnDestroy();
        this.initMap();
        this.fetchData().then(r => null);
    }

    public onClearLocalization() {
        this.mapForm.reset();
        this.searchBar?.onCleanForm();
    }
}
