import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {AppStorageService} from '../../services/app-storage.service';
import {Router} from '@angular/router';
import HttpEmployeeResponse, {Employee} from 'src/model/employee';
import {EmployeeService} from 'src/services/employee.service';
import {MessageService} from 'primeng/api';
import {Auth} from 'aws-amplify';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {CompleteNewPasswordComponent} from '../complete-new-password/complete-new-password.component';
import {CompanyService} from '../../services/company.service';
import HttpCompanyResponse, {Company, HttpCompanyPlanStatusResponse} from '../../model/company';
import {ContractService} from 'src/services/contract.service';
import {environment} from 'src/environments/environment';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [MessageService, DialogService]
})
export class LoginComponent implements OnInit {

    public loginForm: FormGroup;
    public loading = false;
    public showHidePassword = true;
    public lembrarLogin = false;
    public isCheck = false;
    public showWarning = false;
    public isAccountHolder = false;
    public ref: DynamicDialogRef = new DynamicDialogRef();
    private holderId: null | undefined;

    constructor(
        private appStorageService: AppStorageService,
        private authService: AuthService,
        private messageService: MessageService,
        private dialogService: DialogService,
        private employeeService: EmployeeService,
        private contractService: ContractService,
        private companyService: CompanyService,
        private formBuilder: FormBuilder,
        private router: Router
    ) {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.compose([
                Validators.required,
                Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
            ])],
            password: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.getLoggedInfo();
    }

    public async login(): Promise<any> {
        const {email, password} = this.loginForm.value;
        const encrypPassword = this.authService.Encrypt(password);

        if (this.lembrarLogin) {
            const check = this.lembrarLogin;
            localStorage.setItem('loginInfo', JSON.stringify({email, encrypPassword, check}));
        } else {
            localStorage.removeItem('loginInfo');
        }

        try {
            this.loading = true;

            const user = await Auth.signIn(email, password);

            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                this.ref = this.dialogService.open(CompleteNewPasswordComponent, {
                    header: 'Atualização de senha',
                    width: '50%',
                    contentStyle: {'max-height': '500px', overflow: 'auto'},
                    baseZIndex: 10000,
                    dismissableMask: true,
                    data: {value: {user, email}}
                });

                this.ref.onClose.subscribe((message) => {
                    this.messageService.add({severity: message[0].severity, summary: message[0].summary});
                    this.loading = false;
                });

            } else {
                await this.authService.signIn(email, password);

                const response: HttpEmployeeResponse | undefined = await this.employeeService.findByEmail(email).toPromise() || undefined;
                const contract: any = await this.contractService.getContractsByEmployee(
                    response?.data?.id
                ).toPromise();

                if (contract) {
                    this.holderId = contract.data[0]?.holderId;
                    const httpCompanyResponse: HttpCompanyResponse | undefined =
                        await this.companyService.get(contract.data[0]?.companyId).toPromise() || undefined;
                    const httpCompanyHolderResponse: HttpCompanyResponse | undefined =
                        await this.companyService.get(contract.data[0]?.holderId).toPromise() || undefined;
                    const productType = httpCompanyHolderResponse?.data?.productType;

                    await this.appStorageService.setEmployee(response!.data);
                    await this.appStorageService.setCurrentContract(contract.data[0]);
                    await this.appStorageService.setCompany(httpCompanyResponse?.data);
                    await this.appStorageService.setProductType(productType!);
                    await this.router.navigate(['/dashboard']);
                } else {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Não autorizado',
                        detail: 'para acessar Alphatime Gestor!'
                    });
                    this.loading = false;
                    return;
                }
            }

        } catch (error: any) {
            this.loading = false;

            if (error.code === 'UserNotConfirmedException') {
                return;
            }
            if (error.code === 'NotAuthorizedException') {
                const response: HttpCompanyPlanStatusResponse | undefined =
                    await this.companyService.getPlanStatus(email).toPromise();

                if (response?.success && response.data.status === Company.EXPIRED_STATUS) {
                    await this.appStorageService.setEmployee({email} as Employee);
                    this.showWarning = true;
                    this.isAccountHolder = response.data.isAccountHolder;
                } else {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Usuário ou senha incorretos.',
                    });
                }

                return;
            }
            if (error.code === 'UserNotFoundException') {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Usuário ou senha incorretos.',
                });
                return;
            }
        }
    }

    public onChangeStayLogged(): void {
        this.lembrarLogin = !this.lembrarLogin;
    }

    public async getLoggedInfo(): Promise<void> {
        const data = localStorage.getItem('loginInfo');
        if (data) {
            const decryptPassword = this.authService.Decrypt(JSON.parse(data).encrypPassword);
            this.isCheck = JSON.parse(data).check ? JSON.parse(data).check : false;
            this.loginForm.controls.email.setValue(JSON.parse(data).email);
            this.loginForm.controls.password.setValue(decryptPassword);
            this.lembrarLogin = true;
        } else {
            this.loginForm.reset();
            this.isCheck = false;
            this.lembrarLogin = false;
        }
    }

    public goToSignup(): void {
        const goToSignup = environment.production ? 'https://signup.alphatime.net/' : 'http://uat-register.alphatime.net/';
        window.open(goToSignup, 'blank');
    }

    public async goToCheckout(): Promise<void> {
        await this.router.navigate(['/checkout']);
    }

}
