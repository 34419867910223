
<p-toast></p-toast>
<div *ngIf="!editingPersonalData">
    <div class="mb-3">
        <div class="row body-row">
            <div class="col-sm body-row-fields">
                <h3>Nome</h3>
                <span>{{ employee?.name }}</span>
            </div>
            <div class="col-sm body-row-fields">
                <h3>E-mail</h3>
                <span>{{ employee?.email }}</span>
            </div>
            <div class="col-sm body-row-fields">
                <h3>Telefone</h3>
                <span>{{ employee?.phone | phone }}</span>
            </div>
        </div>
    </div>

    <div class="row body-row">
        <div class="col-sm body-row-fields">
            <h3>CNH</h3>
            <span>{{ employee?.cnh ? employee?.cnh : 'Sem dados' }}</span>
        </div>
        <div class="col-sm body-row-fields">
            <h3>CPF</h3>
            <span>{{ employee?.registryId }}</span>
        </div>
        <div class="col-sm body-row-fields">
            <h3>PIS</h3>
            <span>{{ employee?.pisPasep ? employee?.pisPasep : 'Sem dados' }}</span>
        </div>
    </div>

    <div class="row body-row">
        <div class="col-sm body-row-fields">
            <h3>CEP</h3>
            <span>{{ employee?.zipcode }}</span>
        </div>
        <div class="col-sm body-row-fields">
            <h3>Rua</h3>
            <span>{{ employee?.street }}</span>
        </div>
        <div class="col-sm body-row-fields">
            <h3>Número</h3>
            <span>{{ employee?.addressNumber }}</span>
        </div>
    </div>

    <div class="row body-row">
        <div class="col-sm body-row-fields">
            <h3>Complemento</h3>
            <span>{{ employee?.complement }}</span>
        </div>
        <div class="col-sm body-row-fields">
            <h3>Bairro</h3>
            <span>{{ employee?.district }}</span>
        </div>
        <div class="col-sm body-row-fields"></div>
    </div>

    <div class="row body-row">
        <div class="col-sm body-row-fields">
            <h3>Cidade</h3>
            <span>{{ employee?.city }}</span>
        </div>
        <div class="col-sm body-row-fields">
            <h3>Estado</h3>
            <span>{{ employee?.state }}</span>
        </div>
        <div class="col-sm body-row-fields"></div>
    </div>

    <div class="button-save-container d-flex justify-content-center align-items-center  flex-column">
        <button type="submit" class="btn btn-outline-primary btn-block" (click)="onEditingPersonalData()">
            <div class="d-flex justify-content-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
                <span class="ml-2">Editar</span>
            </div>
        </button>
    </div>
</div>

<form [formGroup]="formUpdate" class="update-form" *ngIf="editingPersonalData">
    <div class="w-100">
        <div class="mb-3">
            <!-- ROW-01 -->
            <div class="form-row">
                <div class="form-group col-lg-4">
                    <label for="name">Nome *</label>
                    <input
                        id="name"
                        type="text"
                        placeholder="Digite aqui"
                        class="form-control"
                        formControlName="name"
                        [ngClass]="{
                            'is-invalid': formUpdate.get('name')?.hasError('required') && formUpdate.get('name')?.touched,
                            'minlength-error': formUpdate.get('name')?.hasError('minlength') && formUpdate.get('name')?.touched,
                            'maxlength-error': formUpdate.get('name')?.hasError('maxlength') && formUpdate.get('name')?.touched
                        }">
                    <div class="invalid-feedback" *ngIf="formUpdate.get('name')?.hasError('required') && formUpdate.get('name')?.touched">
                        Nome é requerido
                    </div>
                    <div class="ng-invalid text-danger text-validation" *ngIf="formUpdate.get('name')?.hasError('minlength')">
                        Nome deve ter pelo menos 2 caracteres
                    </div>
                    <div class="ng-invalid text-danger text-validation" *ngIf="formUpdate.get('name')?.hasError('maxlength')">
                        Nome deve ter no máximo 80 caracteres
                    </div>
                    <div class="cng-invalid text-danger text-validation" *ngIf="formUpdate.hasError('isNameValid')">
                        Existem palavras muito longas
                    </div>
                </div>

                <div class="form-group col-lg-4">
                    <label for="email">E-mail *</label>
                    <input
                        id="email"
                        type="email"
                        placeholder="Digite aqui"
                        class="form-control"
                        formControlName="email"
                        [ngClass]="{
                        'is-invalid': formUpdate.get('email')?.invalid &&
                        formUpdate.get('email')?.touched
                    }">
                    <div class="invalid-feedback" *ngIf="formUpdate.get('email')?.hasError('required')
                        && formUpdate.get('email')?.touched">
                        Email é requerido
                    </div>
                    <div class="invalid-feedback" *ngIf="formUpdate.get('email')?.hasError('email')
                        && formUpdate.get('email')?.touched">
                        Email inválido
                    </div>
                </div>

                <div class="form-group col-lg-4">
                    <label for="phone">Telefone *</label>
                    <p-inputMask
                        id="phone"
                        styleClass="form-control"
                        placeholder="Ex: 85998564575"
                        mask="(99) 9 9999-9999"
                        formControlName="phone"
                        [ngClass]="{
                            'ng-invalid': formUpdate.get('phone')?.hasError('required') && formUpdate.get('phone')?.touched,
                            'ng-dirty': formUpdate.get('phone')?.hasError('required') && formUpdate.get('phone')?.touched
                        }">
                    </p-inputMask>
                    <div class="ng-invalid text-danger text-validation" *ngIf="formUpdate.get('phone')?.hasError('required') && formUpdate.get('phone')?.touched">
                        Numero de telefone é requerido
                    </div>
                </div>
            </div>

            <!-- ROW-02 -->
            <div class="form-row">
                <div class="form-group col-lg-4">
                    <label for="cnh">Número CNH</label>
                    <input
                        id="cnh"
                        type="text"
                        maxlength="30"
                        placeholder="Digite aqui"
                        class="form-control"
                        formControlName="cnh"
                        numbersOnly>
                </div>

                <div class="form-group col-lg-4">
                    <label for="cpf">CPF *</label>
                    <p-inputMask
                        id="cpf"
                        styleClass="form-control"
                        placeholder="Digite aqui"
                        mask="999.999.999-99"
                        formControlName="registryId"
                        [ngClass]="{
                            'ng-invalid': formUpdate.get('registryId')?.hasError('required') && formUpdate.get('registryId')?.touched,
                            'ng-dirty': formUpdate.get('registryId')?.hasError('required') && formUpdate.get('registryId')?.touched}">
                    </p-inputMask>
                    <div class="ng-invalid text-danger text-validation"
                         *ngIf="formUpdate.get('registryId')?.hasError('required') && formUpdate.get('registryId')?.touched">
                        CPF é requerido
                    </div>
                    <div class="ng-invalid text-danger text-validation" *ngIf="formUpdate.hasError('CPFNotValid')
                        && formUpdate.controls['registryId'].touched">
                        CPF não é válido
                    </div>
                </div>

                <div class="form-group col-lg-4">
                    <label for="pisPasep">PIS/PASEP</label>
                    <p-inputMask
                        id="pisPasep"
                        placeholder="Digite aqui"
                        styleClass="form-control"
                        mask="999.99999.99-9"
                        formControlName="pisPasep">
                    </p-inputMask>
                </div>
            </div>

            <!-- ROW-03 -->
            <div class="form-row">
                <div class="form-group col-lg-4">
                    <label for="zipcode">CEP*</label>
                    <p-inputMask
                        id="zipcode"
                        placeholder="Digite aqui"
                        styleClass="form-control"
                        mask="99999999"
                        formControlName="zipcode"
                        (onBlur)="findZipcode('employee')">
                    </p-inputMask>
                    <div class="ng-invalid text-danger text-validation"
                         *ngIf="formUpdate.get('zipcode')?.hasError('required') && formUpdate.get('zipcode')?.touched">
                        CEP é requerido
                    </div>
                </div>

                <div class="form-group col-lg-4">
                    <label for="street">Rua/Avenida *</label>
                    <input
                        id="street"
                        type="text"
                        placeholder="Digite aqui"
                        class="form-control"
                        formControlName="street">
                    <div class="ng-invalid text-danger text-validation"
                        *ngIf="formUpdate.get('street')?.hasError('required') && formUpdate.get('street')?.touched">
                        Este campo é requerido
                    </div>
                </div>

                <div class="form-group col-lg-4">
                    <label for="addressNumber">Número *</label>
                    <input
                        id="addressNumber"
                        type="text"
                        maxlength="10"
                        placeholder="Digite aqui"
                        class="form-control"
                        formControlName="addressNumber">
                    <div class="ng-invalid text-danger text-validation"
                        *ngIf="formUpdate.get('addressNumber')?.hasError('required') && formUpdate.get('addressNumber')?.touched">
                        Número é requerido
                    </div>
                </div>
            </div>

            <!-- ROW-04 -->
            <div class="form-row">
                <div class="form-group col-lg-6">
                    <label for="complement">Complemento</label>
                    <input
                        id="complement"
                        type="text"
                        maxlength="30"
                        placeholder="Digite aqui"
                        class="form-control"
                        formControlName="complement">
                </div>

                <div class="form-group col-lg-6">
                    <label for="district">Bairro *</label>
                    <input
                        id="district"
                        type="text"
                        maxlength="30"
                        placeholder="Digite aqui"
                        class="form-control"
                        formControlName="district">
                    <div class="ng-invalid text-danger text-validation"
                        *ngIf="formUpdate.get('district')?.hasError('required') && formUpdate.get('district')?.touched">
                        Bairro é requerido
                    </div>
                </div>
            </div>

            <!-- ROW-05 -->
            <div class="form-row">
                <div class="form-group col-lg-6">
                    <label for="city">Cidade *</label>
                    <input
                        id="city"
                        type="text"
                        placeholder="Digite aqui"
                        class="form-control"
                        formControlName="city">
                    <div class="ng-invalid text-danger text-validation"
                        *ngIf="formUpdate.get('city')?.hasError('required') && formUpdate.get('city')?.touched">
                        Cidade é requerido
                    </div>
                </div>

                <div class="form-group col-lg-6">
                    <label for="state">Estado *</label>
                    <select
                        #stateForm
                        class="form-control"
                        formControlName="state"
                        (change)="onStateChange(stateForm.value)"
                      >
                        <option value="">Selecione um estado</option>
                        <option *ngFor="let state of states"
                                [value]="state.sigla">{{ state.sigla }}</option>
    	            </select>
                    <div class="ng-invalid text-danger text-validation"
                        *ngIf="formUpdate.get('state')?.hasError('required') && formUpdate.get('state')?.touched">
                       Estado é requerido
                    </div>
                </div>
            </div>

            <div class="button-save-container d-flex justify-content-center align-items-center flex-column">
                <button type="submit" class="btn btn-primary btn-block" (click)="onSave()" [disabled]="!formUpdate.valid">
                    Salvar
                </button>
            </div>

            <div class="button-save-container d-flex justify-content-center align-items-center flex-column">
                <button type="submit" class="btn btn-outline-primary btn-block" (click)="onCancel()">
                    Cancelar
                </button>
            </div>
        </div>
    </div>
</form>
