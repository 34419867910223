<div>
    <form [formGroup]="formChangePwd">
      <div class="w-100">
        <div class="card bg-transparent mb-2">
          <div class="card-header">

          </div>

          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="password">Senha</label>
                <input id="password"
                       type="password"
                       class="form-control"
                       formControlName="password"
                       [ngClass]="{
                         'is-invalid': formChangePwd.get('password')?.invalid && formChangePwd.get('password')?.touched
                       }">
                <div class="invalid-feedback" *ngIf="formChangePwd.get('password')?.hasError('required')
                     && formChangePwd.get('password')?.touched">Uma senha é requerida
                </div>
                <div class="invalid-feedback" *ngIf="formChangePwd.get('password')?.hasError('minlength')
                                                   && formChangePwd.controls['password']?.touched">
                    A senha deve ter no mínimo 8 caracteres
                </div>
                <div class="invalid-feedback" *ngIf="formChangePwd.get('password')?.hasError('maxlength')
                                                    && formChangePwd.controls['password']?.touched">
                    A senha deve ter no máximo 24 caracteres
                </div>
              </div>

              <div class="form-group col-md-12">
                <label for="confirmPassword">Confirmar Senha</label>
                <input id="confirmPassword"
                       type="password"
                       class="form-control"
                       formControlName="confirmPassword"
                       [ngClass]="{
                         'is-invalid': formChangePwd.get('confirmPassword')?.invalid && formChangePwd.get('confirmPassword')?.touched
                       }">
                <div class="confirm-password-invalid-feedback" *ngIf="formChangePwd.hasError('passwordsNotEqual') && formChangePwd.controls['confirmPassword'].touched">
                    Senhas não conferem
                </div>
                <div class="invalid-feedback" *ngIf="formChangePwd.get('confirmPassword')?.hasError('minlength')
                                                    && formChangePwd.controls['confirmPassword']?.touched">
                    A senha deve ter no mínimo 8 caracteres
                </div>
                <div class="invalid-feedback" *ngIf="formChangePwd.get('confirmPassword')?.hasError('maxlength')
                                                    && formChangePwd.controls['confirmPassword']?.touched">
                    A senha deve ter no máximo 24 caracteres
                </div>
              </div>
            </div>

            <div class="border-top text-right mt-4 pt-3">
              <button type="submit" [disabled]="!formChangePwd.valid" (click)="completeNewPassword()" class="btn btn-primary btn-default">Salvar</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
