<p-toast></p-toast>
<p-confirmDialog [style]="{width: '432px'}" [baseZIndex]="10000"
            acceptButtonStyleClass="p-button-rounded p-button-danger"
            rejectButtonStyleClass="p-button-text"
            acceptLabel="Sim"
            rejectLabel="Não"
            acceptIcon="none"
            rejectIcon="none">
</p-confirmDialog>
<app-searchbar-employee
    (searchEvent)="searchEmployeeLeave($event)"
    (cleanForm)="onCleanForm()"
    #searchBar>
</app-searchbar-employee>

<div class="card shadow mb-4">
    <div class="card-body">
        <p-table #dt
            [value]="absences"
            dataKey="id"
            styleClass="p-datatable-employee"
            [rowHover]="true"
            [rows]="10"
            [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10,25,50]"
            [paginator]="true"
            [filterDelay]="0"
            editMode="row"
            [globalFilterFields]="['employee.name', 'type',  'startDate', 'endDate']">
            <ng-template pTemplate="caption">
                <div class="table-header">
                  <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input
                        #findAll
                        class="p-inputtext p-component inputsearch"
                        pInputText
                        type="text"
                        (input)="dt.filterGlobal(findAll.value, 'contains')"
                        placeholder="Pesquisar"/>
                  </span>
                  <!-- <div class="table-header d-flex align-items-center justify-content-end w-100">
                      <button type="button" class="btn btn-sm btn-link ml-3" (click)="searchBar.onCleanForm()">
                          Limpar filtro
                      </button>
                </div> -->
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr *ngIf="absences.length !== 0">
                  <th pSortableColumn="employee.name">Nome
                    <p-sortIcon field="employee.name"></p-sortIcon>
                  </th>
                  <th pSortableColumn="type">Motivo
                    <p-sortIcon field="type"></p-sortIcon>
                  </th>
                  <th pSortableColumn="startDate">Data Inicial
                    <p-sortIcon field="startDate"></p-sortIcon>
                  </th>
                  <th pSortableColumn="endDate">Data Final
                    <p-sortIcon field="endDate"></p-sortIcon>
                  </th>
                  <th style="text-align: center">Ações</th>
                </tr>
                <tr>
                  <div class="d-flex justify-content-center align-items-center p-3" *ngIf="loading" >
                    <span class="spinner-grow text-muted spinner-grow-sm m-2" role="status" aria-hidden="true"></span>
                    Aguarde estamos carregando os dados...
                  </div>
                </tr>
                <tr *ngIf="absences?.length === 0 && !loading">
                    <th colspan="4">
                        <div class="d-flex justify-content-center align-items-center text-muted not-found-text">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-1 h-1">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                              </svg>
                            <h6>Nenhum funcionário se encontra afastado</h6>
                        </div>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-leave let-i let-editing="editing" let-ri="rowIndex">
                <tr class="p-selectable-row animated--fade-in" [pEditableRow]="leave">
                  <td>
                    {{ leave.employee.name }}
                  </td>
                  <td>
                    {{ leave.type }}
                  </td>
                  <td>
                    {{ leave.startDate}}
                  </td>
                  <td>
                    {{ leave.endDate}}
                  </td>

                  <!-- <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <form [formGroup]="filterForm">
                                <select
                                    id="absenceTypes" class="form-control" formControlName="type" [(ngModel)]="leave.type"
                                    ormControlName="type">
                                    <option value="">Selecione um motivo</option>
                                    <option *ngFor="let type of absenceTypes" [value]="type">{{ type | absenceTypeTextConvert }}</option>
                                </select>
                            </form>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ leave.type }}
                        </ng-template>
                    </p-cellEditor>
                  </td>
                  <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <form [formGroup]="filterForm">
                                <p-calendar
                                    #startDate
                                    [(ngModel)]="leave.startDate"
                                    [showIcon]="false"
                                    placeholder="Selecione uma data"
                                    formControlName="startDate"
                                    dateFormat="dd/mm/yy"
                                    inputStyleClass="form-control">
                                </p-calendar>
                            </form>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ leave.startDate}}
                        </ng-template>
                    </p-cellEditor>
                  </td>
                  <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <form [formGroup]="filterForm">
                                <p-calendar
                                    #endDate
                                    [(ngModel)]="leave.endDate"
                                    [showIcon]="false"
                                    placeholder="Selecione uma data"
                                    formControlName="endDate"
                                    dateFormat="dd/mm/yy"
                                    inputStyleClass="form-control">
                                </p-calendar>
                            </form>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ leave.endDate}}
                        </ng-template>
                    </p-cellEditor>
                  </td>
                  <td  style="text-align:center">
                    <button *ngIf="!editing" [disabled]="leave.employeeOnVacation" type="button" class="btn btn-link btn-icon-danger p-0" (click)="confirmDelete(leave)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                    </button>
                    <button *ngIf="!editing" (click)="loadFormUpdate(leave)" [disabled]="leave.employeeOnVacation" type="button" pInitEditableRow class="btn btn-link btn-icon-primary p-0">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                    </button>
                    <button pButton pRipple *ngIf="editing" (click)="onCleanForm()" type="button" pCancelEditableRow icon="pi pi-times" class="p-button-rounded p-button-outlined mr-2"></button>
                    <button pButton pRipple style="background-color: #5055CB; border: #5055CB;" *ngIf="editing" type="button" (click)="onUpdate(leave)" pCancelEditableRow icon="pi pi-check" class="p-button-rounded"></button>
                  </td>
                </tr> -->
            </ng-template>
        </p-table>
    </div>
</div>
