<div>
    <form [formGroup]="noticesForm" #passwordForm="ngForm" (ngSubmit)="onSave()" novalidate>
        <div class="form-row">
            <div class="form-group col-lg-12">
                <label for="notice">Aviso</label>
                <textarea class="form-control" id="notice" rows="3" formControlName="notice"
                    [ngClass]="{
                    'is-invalid': noticesForm.get('notice')?.invalid && noticesForm.get('notice')?.touched
                  }"></textarea>
                 <div class="invalid-feedback" *ngIf="noticesForm.get('notice')?.hasError('required')
                     && noticesForm.get('notice')?.touched">Aviso é requerido
                 </div>
                 <div class="invalid-feedback" *ngIf="noticesForm.get('notice')?.hasError('maxlength')
                   && noticesForm.get('notice')?.touched">Campo excedeu o limite de 120 caracteres
                </div>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-lg-12">
                <label for="street">Prioridade</label>
                <div class="form-check">
                    <input class="form-check-input" type="radio" formControlName="priority" id="checkLow" value="LOW">
                    <label class="form-check-label" for="exampleRadios1">
                        Baixa
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" formControlName="priority" id="checkMedium" value="MEDIUM">
                    <label class="form-check-label" for="exampleRadios1">
                        Média
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" formControlName="priority" id="checkHight" value="HIGH">
                    <label class="form-check-label" for="exampleRadios1">
                        Alta
                    </label>
                </div>
            </div>
        </div>

        <div class="text-right pb-5">
            <button type="button" class="btn btn-link mr-2" (click)="onClose()">Cancelar</button>
            <button type="submit" [disabled]="!noticesForm.valid" class="btn btn-primary btn-default">Salvar</button>
        </div>
    </form>

</div>
