<form [formGroup]="filterForm" (ngSubmit)="getFormValues()" class="form-filter-employee form-row align-items-center">
    <div class="col-auto">
        <p class="searchbar-label">Funcionários</p>
        <p-dropdown
            placeholder="Selecione aqui"
            [options]="employees"
            optionLabel="name"
            optionValue="id"
            formControlName="employeeId">
        </p-dropdown>
    </div>

    <div class="col-auto">
        <p class="searchbar-label">Data Inicial</p>
        <p-calendar
            #startDate
            [showIcon]="false"
            placeholder="Selecione uma data"
            formControlName="startDate"
            dateFormat="dd/mm/yy"
            inputStyleClass="form-control">
        </p-calendar>
    </div>

    <div class="col-auto">
        <p class="searchbar-label">Data Final</p>
        <p-calendar
            [showIcon]="false"
            placeholder="Selecione uma data"
            [minDate]="startDate.value"
            formControlName="endDate"
            dateFormat="dd/mm/yy"
            inputStyleClass="form-control">
        </p-calendar>
    </div>

    <div class="col-auto">
        <p class="searchbar-label">&nbsp;</p>
        <button type="button" class="btn btn-sm btn-link ml-3" (click)="onCleanForm()">
            Limpar filtro
        </button>
    </div>
    <div class="col-auto">
        <p class="searchbar-label">&nbsp;</p>
        <button
            type="submit"
            class="btn btn-sm
            btn-primary"
            (click)="searchClick()"
            [disabled]="!filterForm.valid">Filtrar
        </button>
    </div>
</form>
