import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { timer } from 'rxjs';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class ChangePasswordComponent implements OnInit {

    @ViewChild('passwordForm') passwordForm: any;

    public changePasswordForm: FormGroup;
    private cancelMessage = [ {severity: 'info', summary: 'Alterar senha cancelada!'}]

    constructor(
        private formBuilder: FormBuilder,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public ref: DynamicDialogRef,
        private router: Router,
    ) {
        this.changePasswordForm = this.formBuilder.group({
            oldPassword: ['', Validators.required],
            newPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(24)]],
        })
    }

    ngOnInit(): void {
    }

    public onChangePassword() {
        const {oldPassword, newPassword} = this.changePasswordForm.value;

        this.confirmationService.confirm({
            message: `Tem certeza de que quer trocar a senha?`,
            header: 'Trocar senha',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.changePassword(oldPassword, newPassword);
            },
            reject: () => {
                this.messageService.add({
                  severity:'warn',
                  summary:'Não confirmado',
                  detail: `A senha não foi alterada!`
                });
            }
        });
    }

    public async changePassword(oldPassword: string, newPassword: string) {
        await Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, oldPassword, newPassword);
            })
            .then(data => {
                this.messageService.add({
                    severity:'success',
                    summary:'Senha alterada com sucesso!',
                });
                timer(2000).toPromise().then(() => {
                    this.onClose();
                    // this.router.navigateByUrl('/dashboard');
                })
            })
            .catch(err => {
                if (err.code === 'NotAuthorizedException') {
                    this.messageService.add({
                        severity:'error',
                        summary:'Senha antiga incorreta!',
                    });
                }else if(err.code === 'LimitExceededException'){
                    this.messageService.add({
                        severity:'error',
                        summary:'Excedeu o limite de tentativas, tente mais tarde!',
                    });
                } else {
                    this.messageService.add({
                        severity:'error',
                        summary:'Erro ao alterar a senha!',
                    });
                }
                this.cleanForm();
            });
    }

    public cleanForm() {
        this.passwordForm.resetForm();
        this.ngOnInit();
    }

    onClose() {
        // const message = this.cancelMessage;
        this.ref.close();
    }
}
