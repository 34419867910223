import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as moment from 'moment';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Employee, HttpEmployeesResponse } from 'src/model/employee';
import { AppStorageService } from './app-storage.service';
import { ContractService } from './contract.service';
import { EmployeeService } from './employee.service';
import { RegisterClockService } from './register-clock.service';
import { forkJoin, map } from 'rxjs';
import { HttpContractsResponse } from 'src/model/contract';
import HttpRegistersClockResponse from 'src/model/register-clock';
import JourneyHelp from 'src/app/shared/journey-helper';
import { Company } from 'src/model/company';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
    providedIn: 'root'
})
export class CompleteClockReportDepartmentService {

    private employee: Employee | undefined;
    private company: Company | undefined;

    public clocks: any[] = [];
    public contracts: any[] = [];
    public results: any[] = [];

    public departmentname = '';
    public totalHoursNegative = '';
    public totalHoursPositive = ''
    public totalHourBalance = '';

    public contractResponse: any;
    public logo: any;

    public showEmptResultMessage = false;

    private startDatePeriod = '';
    private endDatePeriod = '';

    public defaultCompanyLogo = '../assets/img/photography.png';

    constructor(
        private appStorageService: AppStorageService,
        private contractService: ContractService,
        private employeeService: EmployeeService,
        private http: HttpClient,
        private registerClockService: RegisterClockService,
    ) {}


    // Pesquisa inicial recebendo uma data incial e uma data final
    async fetchData(startDate: string, enddDate: string, departmentId: string, departmentName: string, companyObj: any): Promise<void> {
        this.startDatePeriod = startDate;
        this.endDatePeriod = enddDate;
        this.totalHoursNegative = '';
        this.totalHoursPositive = '';
        this.totalHourBalance = '';
        this.clocks = [];
        this.contracts = [];
        this.results = [];
        this.showEmptResultMessage = false;
        this.employee = await this.appStorageService.getEmployee();
        this.company = companyObj;
        this.departmentname = departmentName;

        if (companyObj.logo === undefined) {
            await this.loadLocalAssetToBase64(this.defaultCompanyLogo);
        } else {
            await this.loadLocalAssetToBase64(companyObj.logo);
        }

        await forkJoin(
            this.contractService.getContractsByCompany(this.employee.holderId),
            this.employeeService.findAllByCompany(this.employee.companyId!)).pipe(
            map((response: any) => {
                const httpContractResponse: HttpContractsResponse = response[0];
                const httpEmployeeResponse: HttpEmployeesResponse = response[1];

                httpContractResponse.data!.map((contract) => {
                    contract.employee = httpEmployeeResponse.data.find((el) => el.id === contract.employeeId);
                });
                this.contractResponse = httpContractResponse;
            })
        ).toPromise();

        // @ts-ignore
        this.contractResponse.data.map((contract: Contract) => {
            this.contracts.push({
                employeeId: contract.employeeId,
                id: contract.id,
                name: contract.employee.name,
                picture: contract.employee?.picture,
                journey: contract.journey,
                // startTimeFirstPeriod: contract.startTimeFirstPeriod,
                // endTimeFirstPeriod: contract.endTimeFirstPeriod,
                // startTimeSecondPeriod: contract.startTimeSecondPeriod,
                // endTimeSecondPeriod: contract.endTimeSecondPeriod,
            });
        });

        let result: any;

        await forkJoin(
            this.registerClockService.listByCompany(this.employee.companyId as string, startDate, enddDate),
            this.employeeService.findAllByCompany(this.employee.companyId!)).pipe(
            map((response: any) => {
                const httpRegisterClockResponse: HttpRegistersClockResponse = response[0];
                const httpEmployeeResponse: HttpEmployeesResponse = response[1];

                httpRegisterClockResponse.data!.map((clock) => {
                    clock.employee = httpEmployeeResponse.data.find((el) => el.id === clock.employeeId);
                });
                result = httpRegisterClockResponse;
            })
        ).toPromise();

        const filteredResut = result.data.filter((res: any) => {
            return res.departmentId === departmentId
        })

        if (filteredResut.length > 0) {
            this.showEmptResultMessage = false;
        } else {
            this.showEmptResultMessage = true;
        }

        this.groupEmployeeWithContract(filteredResut);
    }

    async groupEmployeeWithContract(response: any): Promise<void> {
        const registers: [] = [];

        const filterValids = await response.filter((clocks: any) => {
            return clocks.status !== 'INVALID';
        });

        const sorted = filterValids.sort((a: any, b: any) => {
            if (a.employeeId === b.employeeId) {
                return a.recordDateTime < b.recordDateTime ? -1 : 1;
            } else {
                return a.employeeId < b.employeeId ? -1 : 1;
            }
        });

        // @ts-ignore
        await sorted.forEach((el: any, idx: number) => {
            if (el.type === 'F') {
                const result = {entrada: '00:00', saida: '00:00'};
                el.total = this.minsToStr(this.strToMins(result.saida) - this.strToMins(result.entrada));
                el.recordTime = result;
            } else {
                if (idx > 0) {
                    el.address = el.address ? el.address.replace('Unnamed Road', 'Rua Desconhecida') : 'Rua Desconhecida';
                    el.recordTimeStr = el.recordDateTime;
                    el.recordTime = moment(el.recordDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
                    el.total = '00:00';
                    if (sorted[idx - 1].type === 'E' && el.type === 'S') {
                        const inputclock = moment(sorted[idx - 1].recordDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
                        const exitClock = moment(el.recordDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
                        const result = {entrada: inputclock, saida: exitClock};
                        el.total = this.minsToStr(this.strToMins(result.saida) - this.strToMins(result.entrada));
                        el.recordTime = result;
                    }
                }
            }

            // @ts-ignore
            registers.push(el);
        });

        registers.map((results: any) => {
            if (results.type === 'S' || results.type === 'F') {
                this.clocks.push({
                    employeeId: results.employee.id,
                    picture: results.employee.picture,
                    departmentId: results.departmentId,
                    name: results.employee.name,
                    address: results.address,
                    recordDate: results.recordDate,
                    recordTime: results.recordTime,
                    recordDateTime: results.recordDateTime,
                    currentContract: results.currentContract,
                    total: results.total,
                    type: results.type,
                    clockAdjustmentId: results.clockAdjustmentId,
                    status: results.status,
                });
            }
        });

        this.clocks = await this.clocks.reduce((clock, curr) => {
            const index = this.contracts.findIndex(employee => employee.employeeId === curr.employeeId);

            const journeyDay = moment(curr.recordDateTime, "YYYY-MM-DD HH:mm:ss").isoWeekday();
            const currentContractValue = JSON.parse(curr.currentContract);
            const journeyJson: any =  JourneyHelp.getJourneyJson(currentContractValue.journey);

            const startTimeFirstPeriod = moment(journeyJson[journeyDay][0], 'HH:mm');
            const endtTimeFirstPeriod = moment(journeyJson[journeyDay][1], 'HH:mm');
            const startTimeSecondPeriod = moment(journeyJson[journeyDay][2], 'HH:mm');
            const endTimeSecondPeriod = moment(journeyJson[journeyDay][3], 'HH:mm');

            if (index > -1) {
                curr.startTimeFirstPeriod = startTimeFirstPeriod.format('HH:mm');
                curr.endtTimeFirstPeriod = endtTimeFirstPeriod.format('HH:mm');
                curr.startTimeSecondPeriod = startTimeSecondPeriod.format('HH:mm');
                curr.endTimeSecondPeriod = endTimeSecondPeriod.format('HH:mm');

                const firstPeriod =
                    this.dateDifference(moment(curr.endtTimeFirstPeriod, 'HH:mm'), moment(curr.startTimeFirstPeriod, 'HH:mm'));
                const secondPeriod =
                    this.dateDifference(moment(curr.endTimeSecondPeriod, 'HH:mm'), moment(curr.startTimeSecondPeriod, 'HH:mm'));
                const cargaHoraria = firstPeriod + secondPeriod;
                const cargaHorariaFormatada = moment.utc(cargaHoraria * 60 * 1000).format('HH:mm');

                clock.push(
                    {
                        ...curr,
                        journey: cargaHorariaFormatada,
                        recordTime: {...curr.recordTime, total: curr.total},
                        address: curr.address,
                        hoursBalance: this.getBalance(curr.total, moment(cargaHorariaFormatada, 'HH').format('HH:mm')),
                    }
                );
            }

            return clock;
        }, []);

        // this.generateRsult(this.clocks);
        this.filterClock(this.clocks)
    }

    async filterClock(value: any): Promise<void> {
        const grouped = value.reduce((accumulator: any, element: any) => {
            const key = element.recordDate + element.name; // group by recordDate and name, to generate unique key
            accumulator[key] = accumulator[key] || []; // if is not already a grouping then empty array
            accumulator[key].push(element); // else add the element to group
            return accumulator; // and finally return the accumulator for the next iteration
        }, {});

        const finalResult: any[] = [];
        const keys = Object.keys(grouped);
        keys.forEach((key: any, index: any) => {
            finalResult.push({key, value: grouped[key]});
        });

        const result: any[] = [];

        finalResult.forEach(data => {
            const temp = {
                id: data.value[0].employeeId,
                recordDate: data.value[0].recordDate,
                departmentId: data.value[0].departmentId,
                picture: data.value[0].picture,
                name: data.value[0].name,
                address: data.value[0].address,
                journey: data.value[0].journey,
                recordTime: data.value.map((infor: any) => infor.recordTime),
                totalHours: this.totalClock(data.value.map((infor: any) => infor.total)),
                clockAdjustmentId: data.value[0].clockAdjustmentId,
                status: data.value[0].status,
            };

            result.push(
                {
                    ...temp,
                    hoursBalance: temp.totalHours < moment(temp.journey, 'HH:mm').format('HH:mm')
                        ? `-${moment(this.getBalance(temp.totalHours, moment(temp.journey, 'HH:mm').format('HH:mm')), 'HH:mm').format('HH:mm')}`
                        : `+${moment(this.getBalance(temp.totalHours, moment(temp.journey, 'HH:mm').format('HH:mm')), 'HH:mm').format('HH:mm')}`

                }
            );
        });

        const filterResult = result.filter(r => {
            return r.hoursBalance !== '+00:00' || r.status === 'WAITING_REVIEW';
        });

        let totalMinutesPositive = 0;
        let totalMinutesNegative = 0;

        filterResult.forEach(item => {
            const hoursBalance = moment.duration(item.hoursBalance).asMinutes();
            if (hoursBalance >= 0) {
                totalMinutesPositive += hoursBalance;
            } else {
                totalMinutesNegative -= hoursBalance; // Negativo para compensar
            }
        });

        const saldoHoras = totalMinutesPositive - totalMinutesNegative;
        const horas = Math.floor(Math.abs(saldoHoras) / 60);
        const minutos = Math.abs(saldoHoras) % 60;
        const saldoHorasFormatado = `${saldoHoras < 0 ? '-' : ''}${String(horas).padStart(2, '0')}:${String(minutos).padStart(2, '0')}`;

        this.totalHourBalance = saldoHorasFormatado;

        await this.createTable(filterResult);

        await this.generatePdf();
    }

    async createTable(result: any) {
        const tableResult: any[] = [];
        const saldoPorNome: any = {};
        const results = [];

        await result.forEach((data: any) => {
            const temp = {
                id: data.id,
                name: data.name,
                hoursBalance: data.hoursBalance,
                recordDate: data.recordDate,
            };
            tableResult.push(temp);
        });

        tableResult.forEach((item: any) => {
            const name = item.name;
            const hoursBalance = moment.duration(item.hoursBalance).asMinutes();

            if (!saldoPorNome[name]) {
                saldoPorNome[name] = 0;
            }

            saldoPorNome[name] += hoursBalance;
        });

        for (const name in saldoPorNome) {
            const totalMilliseconds = saldoPorNome[name];

            const totalDuration = moment.duration(Math.abs(totalMilliseconds), 'minutes');

            const totalHours = Math.floor(totalDuration.asHours()) * (totalMilliseconds < 0 ? -1 : 1);
            const totalMinutes = totalDuration.minutes();

            const formattedHours = `${totalHours >= 0 ? '+' : ''}${totalHours}:${Math.abs(totalMinutes).toString().padStart(2, '0')}`;

            results.push({ name, hoursBalance: formattedHours });
        }

        this.results = results;
    }

    totalClock(timesClock: any): string {
        let hours = 0;
        let minutes = 0;

        timesClock.forEach((time: any) => {
            const split = time.split(':');
            // tslint:disable-next-line:radix
            hours += parseInt(split[0]);
            // tslint:disable-next-line:radix
            minutes += parseInt(split[1]);
        });

        const formattedNumber = (num: any) => ('0' + num).slice(-2);
        hours += Math.floor(minutes / 60);
        minutes = minutes % 60;

        return formattedNumber(hours) + ':' + formattedNumber(minutes);
    }

    minsToStr(t: any): string {
        return Math.trunc(t / 60) + ':' + ('00' + t % 60).slice(-2);
    }

    strToMins(t: any): number {
        const s = t.split(':');
        return Number(s[0].trim()) * 60 + Number(s[1].trim());
    }

    dateDifference(startDate: any, endDate: any): number {
        const duration = moment.duration(endDate.diff(startDate));
        const totalMinutes = duration.asMinutes();
        return Math.abs(totalMinutes);
    }

    getBalance(total: any, journey: any): string {
        const totalHour = moment(total, 'HH:mm');
        const jornada = moment(journey, 'HH:mm');
        let duration: any;

        if (totalHour > jornada) {
            duration = moment.duration(totalHour.diff(jornada));
        } else {
            duration = moment.duration(jornada.diff(totalHour));
        }

        return moment.utc(duration.as('milliseconds')).format('HH:mm');
    }

    async loadLocalAssetToBase64(companyLogo: any) {
        return new Promise<void>((resolve, reject) => {
            this.http.get(companyLogo, { responseType: 'blob' }).subscribe(
                (res) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        this.logo = reader.result;
                        resolve();
                    };
                    reader.readAsDataURL(res);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    async generatePdf() {
        const docDefinition: any = {
            header: function(currentPage: any, pageCount: any, pageSize: any) {
                return [
                    { text: `Página ${currentPage.toString()} de ${pageCount}`, alignment: 'right', margin: [15, 15], },
                ]
            },
            pageOrientation: 'landscape', // portrait
            content: [
                {
                    table: {
                        widths: [100, 220, '*'],
                        body: [
                            [
                                {
                                    border: [false, false, false, false],
                                    alignment: 'center',
                                    image: this.logo,
                                    width: 80,
                                },
                                {
                                    border: [false, false, false, false],
                                    margin: [0, 15],
                                    stack: [
                                        {text: `Departamento: ${this.departmentname}`, bold: true, fontSize: 14},
                                        {text: `Emitido em ${moment().format('DD/MM/YYYY HH:mm:ss')}`},
                                        {text: `Período de ${moment(this.startDatePeriod).format('DD/MM/YYYY')} a ${moment(this.endDatePeriod).format('DD/MM/YYYY')}`},
                                    ],
                                },
                                {
                                    border: [false, false, false, false],
                                    margin: [0, 15],
                                    stack: [
                                    {text: `Razão Social: ${this.company?.name}`, italics: true},
                                    {text: `Endereço: ${this.company?.street}, ${this.company?.streetNumber}`, italics: true},
                                    {text: `CNPF: ${this.company?.cnpj}`, italics: true}
                                  ]
                                },
                            ],
                        ],
                    },
                },
                {
                    width: '100%',
                    alignment: 'center',
                    text: `Total de Horas: ${this.totalHourBalance}h`,
                    bold: true,
                    margin: [0, 10, 0, 10],
                    fontSize: 12,
                },
                {
                    layout: {
                        defaultBorder: false,
                        hLineWidth: (i: any, node: any) => {
                            return 1;
                        },
                        vLineWidth: (i: any, node: any) => {
                            return 1;
                        },
                        hLineColor: (i: any, node: any) => {
                            if (i === 1 || i === 0) {
                                return '#bfdde8';
                            }
                            return '#eaeaea';
                        },
                        vLineColor: (i: any, node: any) => {
                            return '#eaeaea';
                        },
                        hLineStyle: (i: any, node: any) => {
                            return null;
                        },
                        paddingLeft: (i: any, node: any) => {
                            return 1;
                        },
                        paddingRight: (i: any, node: any) => {
                            return 1;
                        },
                        paddingTop: (i: any, node: any) => {
                            return 1;
                        },
                        paddingBottom: (i: any, node: any) => {
                            return 1;
                        },
                        fillColor: (rowIndex: any, node: any, columnIndex: any) => {
                            return '#fff';
                        },
                    },
                    table: {
                        headerRows: 1,
                        // widths: ['100%'],
                        widths: ['*'],
                        body: [
                            [
                                {
                                    text: '',
                                    border: [false, false, false, false],
                                    alignment: 'center',
                                    textTransform: 'uppercase',
                                },
                            ],
                            [
                                [
                                    {
                                        layout: {
                                            defaultBorder: false,
                                            hLineWidth: (i: any, node: any) => {
                                                return 1;
                                            },
                                            vLineWidth: (i: any, node: any) => {
                                                return 1;
                                            },
                                            hLineColor: (i: any, node: any) => {
                                                return '#eaeaea';
                                            },
                                            vLineColor: (i: any, node: any) => {
                                                return '#eaeaea';
                                            },
                                            hLineStyle: (i: any, node: any) => {
                                                return null;
                                            },
                                            paddingLeft: (i: any, node: any) => {
                                                return 10;
                                            },
                                            paddingRight: (i: any, node: any) => {
                                                return 10;
                                            },
                                            paddingTop: (i: any, node: any) => {
                                                return 1;
                                            },
                                            paddingBottom: (i: any, node: any) => {
                                                return 1;
                                            },
                                            fillColor: (rowIndex: any, node: any, columnIndex: any) => {
                                                return '#fff';
                                            },
                                        },
                                        table: {
                                            headerRows: 1,
                                            widths: ['40%', '*'],
                                            body: [
                                                [
                                                    {
                                                        text: 'Nome',
                                                        fillColor: '#eaf2f5',
                                                        border: [false, true, false, true],
                                                        margin: [0, 3, 0, 3],
                                                        alignment: 'left',
                                                        textTransform: 'uppercase',
                                                        fontSize: 9,
                                                    },
                                                    {
                                                        text: 'Saldo',
                                                        fillColor: '#eaf2f5',
                                                        border: [false, true, false, true],
                                                        margin: [0, 3, 0, 3],
                                                        alignment: 'left',
                                                        textTransform: 'uppercase',
                                                        fontSize: 9,
                                                    },
                                                ],
                                                ...this.results.map((p: any) => (
                                                    [
                                                        {
                                                            text:`${ p.name }`,
                                                            border: [false, false, false, true],
                                                            margin: [0, 1, 0, 1],
                                                            alignment: 'left',
                                                            fontSize: 9,
                                                        },
                                                        {
                                                            text: `${p.hoursBalance}`,
                                                            border: [false, false, false, true],
                                                            margin: [0, 1, 0, 1],
                                                            alignment: 'left',
                                                            fontSize: 9,
                                                        },
                                                    ]
                                                )),
                                            ]
                                        },
                                    },
                                ],
                            ]
                        ],
                    }
                }
            ]
        };
        const pdfObject = pdfMake.createPdf(docDefinition);
        // pdfObject.download();
        pdfObject.open({}, window.open('', '_blank'));
    }
}
