import {Component, OnInit, ViewChild} from '@angular/core';
import {CompanyService} from 'src/services/company.service';
import {AppStorageService} from 'src/services/app-storage.service';
import {Table} from 'primeng/table';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Company} from '../../model/company';
import {Employee} from 'src/model/employee';
import { BehaviorSubject } from 'rxjs';
import { UploadService } from 'src/services/upload.service';
import {EMPLOYEE_IMG_URL} from 'src/environments/environment';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
    selector: 'app-company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.scss'],
    providers: [MessageService, DialogService, ConfirmationService]
})
export class CompanyComponent implements OnInit {

    @ViewChild('dt') table?: Table;

    public companies: Company[] = [];
    public company?: Company;
    public companyData = false;
    public loading = false;
    public ref: DynamicDialogRef = new DynamicDialogRef();
    public editingCompanyBranch = false;
    public editingCompany = false;
    public loadingWorkDay = false;
    public isVisible$ = new BehaviorSubject(true);
    public isCreateNewBranch = false;
    public isCreateNewCompany = false;
    public openUploadDialog = false;
    public branchesList: any[] = [];
    public companyList: any[] = [];
    public economicGroupBranchList: any[] = [];
    public companyIdTemp = '';
    public companyId = '';
    public newBranch = -1;
    public uploadFileName = '';
    public uploadFile: any;
    public logo = './assets/img/photography.png';
    public companyLogo: string | ArrayBuffer | null | undefined = '';
    public companyLogoTemp: string | ArrayBuffer | null | undefined = '';
    public dateNow = new Date().getTime();
    private employee: Employee | undefined;
    public isFreePlan = false;
    public currentPlan: string | undefined;

    public counterDays = 14;

    showWorkingDayComponent = true;

    constructor(
        private appStorageService: AppStorageService,
        private dialogService: DialogService,
        private companyService: CompanyService,
        private confirmationService: ConfirmationService,
        private uploadService: UploadService,
        private router: Router,
    ) { }

    async ngOnInit(): Promise<void> {
        this.showWorkingDayComponent = false;
        await this.initializePage();
    }

    async initializePage(): Promise<void> {
        this.companies = [];
        this.branchesList = [];
        this.companyList = [];
        this.economicGroupBranchList = [];
        this.loading = true;
        this.newBranch = -1;
        try {
            this.employee = await this.appStorageService.getEmployee();
            this.companyId = this.employee.companyId!;
            const httpCompanyResponse = await this.companyService.get(this.employee.companyId!).toPromise();
            // parametrizar type em getBranches
            const httpCompanyBranchResponse =
                await this.companyService.getBranches(this.employee.companyId!, 'BRANCH').toPromise();
            const httpCompanyEconomicGroupResponse =
                await this.companyService.getBranches(this.employee.companyId!, 'ASSOCIATED_COMPANY').toPromise();

            if (httpCompanyResponse?.data) {
                this.company = httpCompanyResponse.data;
                await this.appStorageService.setCompany(this.company);
                this.loading = false;
                this.companyData = true;
                this.isFreePlan = this.company.productType === 'FREE';
                this.currentPlan = this.company.productName;
                this.counterDays = 14 - moment().diff(moment(this.company.createdAt), 'days');
            } else {
                this.loading = false;
                this.companyData = true;
            }

            if (httpCompanyBranchResponse?.data){
                this.branchesList.push(httpCompanyBranchResponse.data);
            }

            if (httpCompanyEconomicGroupResponse?.data){
                this.companyList.push(httpCompanyEconomicGroupResponse.data);
                this.companyList.map((companiesGroup: any) => {
                    const result = companiesGroup.Items;
                    result.map((companyGroup: any) => {
                        const id = companyGroup.id;
                        this.companyService.getBranches(id, 'BRANCH').subscribe((resultBrach: any) => {
                            this.economicGroupBranchList.push(resultBrach.data);
                        });
                    });
                });
            }
            setTimeout(() => {
                this.showWorkingDayComponent = true;
            }, 0);
        } catch (error) {
            console.log('Error:', error);
            this.loading = false;
        }
    }

    public onEditingCompany(): void {
        this.editingCompany = true;
    }

    public onCreateNewBranch(): void{
        this.isCreateNewBranch = true;
        this.editingCompanyBranch = false;
    }

    public onCreateNewCompany(): void{
        this.isCreateNewCompany = true;
        this.editingCompany = false;
    }

    public onLoadWorkingDay(): void {
        this.loadingWorkDay = true;
    }

    public onOpenUploadDialog(companyId: string, logoUrl: string) {
        this.companyIdTemp = companyId;
        this.companyLogoTemp = logoUrl;
        this.openUploadDialog = true;
    }

    public onImageUpload(event: any) {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const fileReader = new FileReader();
            this.uploadFile = file;

            // tslint:disable-next-line:no-shadowed-variable
            fileReader.onload = (event) => {
                this.companyLogo = event?.target?.result;
                this.companyLogoTemp = this.companyLogo;
            };

            fileReader.readAsDataURL(file);
        }
    }

    public async onConfirmUpload() {
        const fileName = this.uploadFileName = this.companyIdTemp;
        const file = this.uploadFile;
        const response = await this.uploadService.uploadLogo(fileName, file);

        const logUrl = `${EMPLOYEE_IMG_URL}${response.key}`;

        const company: Company = {
            logo: logUrl
        } as Company;

        await this.companyService.update(company, this.companyIdTemp).toPromise();

        this.openUploadDialog = false;

        window.location.replace('/company');
    }

    public onDelete(companyId: string) {
        this.confirmationService.confirm({
            key: 'confirm-delete',
            message: `Você tem certeza que deseja excluir essa empresa?`,
            header: 'Excluir empresa',
            accept: async () => {
                await this.companyService.delete(companyId).toPromise();
                await this.initializePage();
            },
            reject: async () => {
                await this.initializePage();
            }
        });
    }

    handleChange(event: any): void {
        if (event.index === 3) {
            this.onLoadWorkingDay();
        }
    }

    public async onCancelCompany(event: boolean): Promise<void> {
        this.editingCompany = event;
        await this.ngOnInit();
    }

    public async onCancelCreateCompany(event: boolean): Promise<void> {
        this.isCreateNewCompany = event;
        await this.ngOnInit();
    }

    public async onCancelCompanyBranch(event: boolean): Promise<void>{
        this.isCreateNewBranch = event;
        this.newBranch = -1;
        await this.ngOnInit();
    }
}
