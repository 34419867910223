<form [formGroup]="formSelect" novalidate>
    <div class="form-row">
        <div class="form-group col-lg-6">
            <label for="companyPosition">{{company?.type === 'BRANCH' ? 'Filial' : 'Empresa *'}}</label>
            <div class="d-flex justify-content-start align-items-center">
                <select class="form-control w-100" id="company" formControlName="companyId"
                        style="width: 50%;"
                        (ngModelChange)="onChange($event)"
                        [ngClass]="{
                            'is-invalid': formSelect.get('companyId')?.hasError('required') && formSelect.get('companyId')?.touched
                        }">
                    <!-- <option value="">Escolha a empresa</option> -->
                    <option value="{{company?.id}}">{{company?.name}}</option>
                    <option *ngFor="let item of companyList"
                            value="{{item.id}}">{{item.name}}</option>
                </select>
            </div>
            <div class="custom-invalid-feedback" *ngIf="formSelect.get('companyId')?.hasError('required')
                && formSelect.get('companyId')?.touched">
                Empresa é requerido
            </div>
        </div>

        <div class="form-group col-lg-6" [style.display]="company?.type === 'BRANCH' ? 'none' : 'block'">
            <label for="companyPosition">Filial</label>
            <div class="d-flex justify-content-start align-items-center">
                <select class="form-control w-100" id="company" formControlName="branchId"
                        style="width: 50%;"
                        (ngModelChange)="onSelectBranch()"
                        [ngClass]="{
                            'is-invalid': formSelect.get('branchId')?.hasError('required') && formSelect.get('branchId')?.touched
                        }">
                    <option value="">Escolha a Filial</option>
                    <option *ngFor="let item of branchList"
                            value="{{item.id}}">{{item.name}}</option>
                </select>
            </div>
        </div>

        <div class="form-group col-lg-6">
            <label for="companyPosition">Departamento *</label>
            <div class="d-flex justify-content-start align-items-center">
                <select class="form-control w-100" formControlName="departmentId"
                        style="width: 50%;">
                    <option value="">Selecione um Departamento</option>
                    <option *ngFor="let department of departments"
                            value="{{department.id}}">{{department.name}}</option>
                </select>
            </div>
            <div class="custom-invalid-feedback" *ngIf="formSelect.get('departmentId')?.hasError('required')
                && formSelect.get('departmentId')?.touched">
                Departamento é requerido
            </div>
        </div>

        <div class="form-group col-lg-6">
            <label for="companyPosition">Cargo *</label>
            <div class="d-flex justify-content-start align-items-center">
                <select class="form-control w-100" id="cargo"
                    (ngModelChange)="getCompanyPositions()"
                    formControlName="companyPositionId"
                    style="width: 50%;" [ngClass]="{
                        'is-invalid': formSelect.get('companyPositionId')?.hasError('required') &&
                        formSelect.get('companyPositionId')?.touched
                    }">
                    <option value="">Selecione um cargo</option>
                    <option *ngFor="let item of companiesPositions" value="{{item.id}}">{{item.name}}</option>
                </select>
            </div>
            <div class="custom-invalid-feedback" *ngIf="formSelect.get('companyPositionId')?.hasError('required')
                && formSelect.get('companyPositionId')?.touched">
                Cargo é requerido
            </div>
        </div>
    </div>
</form>
