import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Employee } from 'src/model/employee';
import { Notice } from 'src/model/notice';
import { AppStorageService } from 'src/services/app-storage.service';
import { NotificationService } from 'src/services/notification.service';

@Component({
    selector: 'app-notices',
    templateUrl: './notices.component.html',
    styleUrls: ['./notices.component.scss'],
})
export class NoticesComponent implements OnInit {

    public notice: Notice;
    public noticesForm: FormGroup;
    public formTitle = '';
    public buttonLabel = '';
    public priorities = [
        { value: 'LOW',  label: 'Baixa'},
        { value: 'MEDIUM',  label: 'Média'},
        { value: 'HIGH',  label: 'Alta'},
    ];
    private saveMessage = [ {severity: 'success', summary: 'Aviso adicionado!'} ];
    private cancelMessage = [ {severity: 'info', summary: 'Aviso cancelado!'} ];
    private updateMessage = [ {severity: 'success', summary: 'Aviso atualizado!'} ]
    private employee: Employee | undefined;

    constructor(
        private appStorageService: AppStorageService,
        private formBuilder: FormBuilder,
        private notificationService: NotificationService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
    ) {
        this.noticesForm = this.formBuilder.group({
            notice: ['', [Validators.required, Validators.maxLength(120)]],
            priority: ['', Validators.required],
        });

        this.notice = config.data.values;
        this.noticesForm.patchValue(this.notice);
    }

    ngOnInit(): void {
        if (this.notice.id) {
            this.formTitle = 'Editar Cargo';
            this.buttonLabel = 'Atualizar'
        } else {
            this.formTitle = 'Novo Cargo';
            this.buttonLabel = 'Salvar'
            this.noticesForm.get('priority')?.setValue('LOW');
        }
    }

    async onSave() {
        if (!this.notice.id) {
            this.employee = await this.appStorageService.getEmployee();

            const { notice, priority } = this.noticesForm.value;
            const newNotice = {
                companyId: this.employee.companyId,
                createdById: this.employee.id,
                path: this.employee.path,
                notice,
                priority,
            }

            try {
                await this.notificationService.createNotice(newNotice as unknown as Notice).toPromise();
                const message = this.saveMessage;
                this.ref.close(message);
            } catch (error) {
                console.log('Erro ao cadastrar mensagem!', error);
            }
        } else {
            const message = this.updateMessage;

            const { notice, priority } = this.noticesForm.value;
            const updateNotice = {
                companyId: this.notice.companyId,
                createdById: this.notice.createdById,
                path: this.notice.path,
                notice,
                priority,
            }

            try {
                await this.notificationService.updateNotice(updateNotice as unknown as Notice, this.notice.id).toPromise();
                this.ref.close(message);
            } catch (error) {
                console.log('Error: ', error);
            }
        }
    }

    onClose() {
        const message = this.cancelMessage;
        this.ref.close(message);
    }

}
