<div class="d-flex justify-content-between align-items-center mb-4">
    <div style="color: #4D4F5C;">
        Ponto <span class="text-muted small">por semana</span>
    </div>
    <div class="d-flex align-items-center justify-content-end">
        <div class="col-auto">
            <form [formGroup]="selectMonthForm" class="form-row align-items-center">
                <div class="col-auto">
                    <div class="input-container">
                        <p-calendar
                            [showIcon]="false"
                            [maxDate]="maxSelectableDate"
                            [(ngModel)]="initialDate"
                            view="month"
                            placeholder="Selecione um mês"
                            formControlName="selectMonth"
                            dateFormat="mm/yy"
                            [readonlyInput]="true"
                            inputStyleClass="form-control"
                            (onSelect)="getSelectedMonth($event)">
                        </p-calendar>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-auto">
            <div class="input-container">
                <select class="form-control week-select" (change)="getSelectedWeek($event)">
                    <option *ngFor="let week of firstDatesOfWeeks; let i = index" [value]="i" [selected]="i === currentWeek">
                        Semana {{ week }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</div>

<div class="d-flex flex-column justify-content-between align-items-center">
    <div style="position: relative;">
        <div [ngClass]="{'showWeekBarChart': !showWeekBarChart}">
            <canvas id="BarWeekChart" style="width: 580px;">{{ weekChart }}</canvas>
        </div>
    </div>
    <div [ngClass]="{'showEmptWeekBarChart': !showEmptWeekBarChart}">
        <div class="d-flex flex-column justify-content-top align-items-center w-100">
            <img src="assets/img/chart/barra-nodata.svg" alt="">
        </div>
    </div>
    <div class="loading-box d-flex justify-content-center align-items-center text-muted p-3" *ngIf="loadingWeekChart">
        <span class="spinner-grow spinner-grow-sm m-2" role="status" aria-hidden="true"></span>
        Aguarde carregando os dados...
    </div>
</div>
