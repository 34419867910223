export default class Notification {

  public static REGISTER_CLOCK = 'REGISTER_CLOCK';

  id: string | undefined;
  type: string | undefined;
  userId: string | undefined;
  message: string | undefined;
  createdAt: string | undefined;
}
