import {Plugins} from '@capacitor/core';
import {Injectable} from '@angular/core';
import {Employee} from 'src/model/employee';
import {Company} from '../model/company';
import {Contract} from 'src/model/contract';
import {RegisterClock} from 'src/model/register-clock';


const {Storage} = Plugins;

@Injectable({
    providedIn: 'root'
})
export class AppStorageService {

    public readonly FIRST_USER_ACCESS = 'first_user_access';
    public readonly EMPLOYEE = 'employee';
    public readonly COMPANY = 'company';
    public readonly PRODUCT_TYPE = 'product_type';
    public readonly CONTRACT = 'contract';
    public readonly SESSION = 'session';
    public readonly CHRONOMETER = 'chronometer';
    public readonly LAST_CLOCK = 'last_register_clock';
    public readonly LAST_STOP_CLOCK = 'last_stop_register_clock';
    public readonly TIMER_MARKERS = 'timer_markers';

    public hours = 0;
    public minutes = 0;
    public seconds = 0;


    public setEmployee(employee: Employee | undefined): Promise<void> {
        return Storage.set({key: this.EMPLOYEE, value: JSON.stringify(employee)});
    }

    public setCompany(company: Company | undefined): Promise<void> {
        return Storage.set({key: this.COMPANY, value: JSON.stringify(company)});
    }

    public async getCompany(): Promise<Company> {
        const data = await Storage.get({key: this.COMPANY});
        return JSON.parse(data && data.value ? data.value : '');
    }

    public setProductType(productType: string): Promise<void> {
        return Storage.set({key: this.PRODUCT_TYPE, value: JSON.stringify(productType)});
    }

    public async getProductType(): Promise<string> {
        const data = await Storage.get({key: this.PRODUCT_TYPE});
        return JSON.parse(data && data.value ? data.value : '');
    }

    public async getEmployee(): Promise<Employee> {
        const data = await Storage.get({key: this.EMPLOYEE});
        return JSON.parse(data && data.value ? data.value : '');
    }
    public async getCurrentContract(): Promise<Contract | null>{
        try{
            const data = await Storage.get({key: this.CONTRACT});
            if (data && data.value){
                return JSON.parse(data.value);
            }
            return null;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    public async setCurrentContract(contract: Contract) {
        return Storage.set({key: this.CONTRACT, value: JSON.stringify(contract)});
    }
    public setLastChronometerTimer(data: any) {
        return Storage.set({key: this.CHRONOMETER, value: JSON.stringify(data)});
    }

    public async getLastChronometerTimer() {
        const data = await Storage.get({key: this.CHRONOMETER});
        return JSON.parse(data.value!);
    }

    public async removeLastRegisterStopClock(): Promise<void> {
        return Storage.remove({key: this.LAST_STOP_CLOCK});
    }

    public async setTimerMarker( marker: string) {
        let markers = [];
        const data = await Storage.get({key: this.TIMER_MARKERS});
        if (data && data.value) {
            markers = JSON.parse(data.value);
        }
        markers.push(marker);
        return await Storage.set({key: this.TIMER_MARKERS, value: JSON.stringify(markers)});
    }

    public async setLastRegisterStopClock(registerClock: RegisterClock): Promise<void> {
        return Storage.set({key: this.LAST_STOP_CLOCK, value: JSON.stringify(registerClock)});
    }

    public async setLastRegisterClock(registerClock: RegisterClock): Promise<void> {
        return Storage.set({key: this.LAST_CLOCK, value: JSON.stringify(registerClock)});
    }

    public async getLastRegisterStopClock(): Promise<RegisterClock> {
        const data = await Storage.get({key: this.LAST_STOP_CLOCK});
        return JSON.parse(data.value!);
    }

    public removeTimerMarkers() {
        return Storage.remove({key: this.TIMER_MARKERS});
    }

    public async removeLastRegisterClock(): Promise<void> {
        return Storage.remove({key: this.LAST_CLOCK});
    }

    public clear(): Promise<void> {
        return Storage.clear();
    }

    public async remove(): Promise<void> {
        const {keys} = await Storage.keys();

        const result = keys.filter(k => {
            return k !== 'loginInfoBoss';
        });

        return result.forEach(value => {
            return Storage.remove({key: value});
        });
    }
}
