import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {SERVER_URL} from '../environments/environment';
import { Observable } from 'rxjs';
import HttpClockAjustmentResponse, { ClockAdjustment } from 'src/model/clock-adjustment';

@Injectable({
    providedIn: 'root'
})
export class ClockAjustmentService {

    constructor(
        private httpClient: HttpClient) {
    }

    /**
     * Add Clock Adjustment
     */
     public addClockAdjustment(clockAdjustment: ClockAdjustment){
        return this.httpClient.post<HttpClockAjustmentResponse>(`${SERVER_URL}/clocks/adjustments/`, clockAdjustment);
    }

    /**
     * Update Clock Adjustment to Accept
     */
    public acceptClockAdjustment(clockAdjustment: ClockAdjustment, id: string){
        return this.httpClient
            .put<HttpClockAjustmentResponse>(`${SERVER_URL}/clocks/adjustments/accept/${id}`, clockAdjustment);
    }

    /**
     * Update Clock Adjustment to reject
     */
    public rejectClockAdjustment(clockAdjustment: ClockAdjustment, id: string){
        return this.httpClient
            .put<HttpClockAjustmentResponse>(`${SERVER_URL}/clocks/adjustments/reject/${id}`, clockAdjustment);
    }

    /**
     * Function to get an employee
     */
    public getClockAdjustment(clockAdjustmentId: string){
        return this.httpClient
            .get<HttpClockAjustmentResponse>(`${SERVER_URL}/clocks/adjustments/${clockAdjustmentId}`);
    }
}
