<p-confirmDialog [style]="{width: '432px'}" [baseZIndex]="10000"
            acceptButtonStyleClass="p-button-rounded p-button-danger"
            rejectButtonStyleClass="p-button-text"
            acceptLabel="Sim"
            rejectLabel="Não"
            acceptIcon="none"
            rejectIcon="none">
</p-confirmDialog>

<!-- <div class="text-right pb-5">
    <button type="button" class="btn btn-link mr-2">Cancelar</button>
    <button type="button" class="btn btn-link mr-2" (click)="onClose()">Cancelar</button>
    <button type="submit" [disabled]="!formRegister.valid" class="btn btn-primary btn-default">Salvar</button>
</div> -->

<div class="d-flex justify-content-end company-position-save-button m-3">
    <button type="button" class="btn btn-primary ml-3" (click)="onCreate()">
        <i class="bi bi-plus"></i> Cadastrar Departamento
    </button>
</div>

<div class="department-content">
    <p-table #dt
       [value]="departments"
       dataKey="id"
       styleClass="p-datatable-employee"
       [rowHover]="true"
       [rows]="10"
       [showCurrentPageReport]="true"
       [rowsPerPageOptions]="[10,25,50]"
       [paginator]="true"
       [filterDelay]="0"
       editMode="row"
       [globalFilterFields]="['name', 'createdAt']">

       <ng-template pTemplate="caption">
        <div class="table-header">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
                #findAll
                class="p-inputtext p-component inputsearch"
                pInputText
                type="text"
                (input)="dt.filterGlobal(findAll.value, 'contains')"
                placeholder="Pesquisar"/>
          </span>
        </div>
       </ng-template>

       <ng-template pTemplate="header">
        <tr *ngIf="departments.length !== 0">
          <th pSortableColumn="name">Nome
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="createdAt">Data de Criação
            <p-sortIcon field="createdAt"></p-sortIcon>
          </th>
          <th style="text-align: right">Ações</th>
        </tr>
        <tr>
          <!-- <div class="d-flex justify-content-center text-muted mt-5" *ngIf="companyPositionExists">
            <h6>Nenhum cargo encontrado</h6>
          </div> -->
          <div class="d-flex justify-content-center align-items-center p-3" *ngIf="loading" >
            <span class="spinner-grow text-muted spinner-grow-sm m-2" role="status" aria-hidden="true"></span>
            Aguarde estamos carregando os dados...
          </div>
        </tr>
       </ng-template>

       <ng-template pTemplate="body" let-department let-i let-editing="editing" let-ri="rowIndex">
        <tr class="p-selectable-row" [pEditableRow]="department">
          <td>
            <p-cellEditor>
                <ng-template pTemplate="input">
                    <form [formGroup]="formGroup">
                        <input pInputText type="text" formControlName="name" [(ngModel)]="department.name">
                    </form>
                </ng-template>
                <ng-template pTemplate="output">
                    {{ department.name }}
                </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <span class="p-column-title">Data de Criação</span>
            {{ department.createdAt | date:'dd/MM/yyyy'}}
          </td>
          <td  style="text-align:right">
            <button type="button" class="btn btn-link btn-icon-danger p-0" (click)="confirmDelete(department.id)">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
            </button>
            <button *ngIf="!editing" type="button" pInitEditableRow class="btn btn-link btn-icon-primary p-0">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
            </button>
            <button *ngIf="editing" type="button" (click)="reloadTable()" pCancelEditableRow class="btn btn-outline-primary btn-sm mr-2">
                Cancelar
            </button>
            <button *ngIf="editing" type="button" (click)="onUpdate(department)" pSaveEditableRow class="btn btn-primary btn-sm">
                Salvar
            </button>
          </td>
        </tr>
       </ng-template>
      </p-table>
</div>
