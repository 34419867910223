import {Component, OnInit} from '@angular/core';
import {IsActiveMatchOptions, Router} from '@angular/router';
import {AppStorageService} from '../../services/app-storage.service';
import {REGISTER_APP_URL} from '../../environments/environment';
import {Auth} from 'aws-amplify';
import {CognitoUserSession} from 'amazon-cognito-identity-js';
import {Employee} from 'src/model/employee';
import {EventEmitterService} from '../../services/event-emitter.service';
import { ProfileGuardService } from 'src/services/profile-guard.service';

declare let $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {

    public employee: Employee | undefined;
    public enableEditing = false;
    public toggled = false;
    public logo: string | undefined;
    public defaultLogo = './assets/img/photography.png';
    public dateNow = new Date().getTime();
    public showlink = true;
    public isFreePlan = false;

    constructor(
        private router: Router,
        private authGuard: ProfileGuardService,
        private appStorageService: AppStorageService) {

        EventEmitterService.get(EventEmitterService.COMPANY_EVENT_UPDATED).subscribe((data: any) => {
            this.appStorageService.getCompany().then((company) => {
                this.enableEditing = company.hasOwnProperty('street');
                this.isFreePlan = company.productType === 'FREE';
            });
        });

        this.hideLinks();
    }


    // tslint:disable-next-line:typedef
    public ngOnInit() {

        this.appStorageService.getEmployee().then((employee) => {
            this.employee = employee;
        });

        this.appStorageService.getCompany().then((company) => {
            this.enableEditing = company.hasOwnProperty('street');
            this.isFreePlan = company.productType === 'FREE';
        });

        this.appStorageService.getCompany().then((company) => {
            this.enableEditing = company.hasOwnProperty('street');
            this.logo = company.logo;
        });

        const sidebar = $('.sidebar');
        const cardBody = $('.sidebar-card-body');
        $('.sidebar-brand-icon').hide();
        $('#sidebarToggle, #sidebarToggleTop').on('click', (e: any) => {
            $('body').toggleClass('sidebar-toggled');
            sidebar.toggleClass('toggled');
            $('a.nav-link').toggleClass('toggled');
            cardBody.toggleClass('toggled');
            if (sidebar.hasClass('toggled')) {
                this.toggled = true;
                $('.sidebar .collapse').collapse('hide');
                $('.sidebar-brand-icon').show();
                $('#sidebarToggle').removeClass('btn-toggle');
            } else {
                this.toggled = false;
                $('.sidebar-brand-icon').hide();
                $('#sidebarToggle').addClass('btn-toggle');
            }
        });

        // Close any open menu accordions when window is resized below 768px
        $(window).resize(() => {
            if ($(window).width() < 768) {
                $('.sidebar .collapse').collapse('hide');
            }

            // Toggle the side navigation when window is resized below 480px
            if ($(window).width() < 480 && !sidebar.hasClass('toggled')) {
                $('body').addClass('sidebar-toggled');
                $('.sidebar').addClass('toggled');
                $('.sidebar .collapse').collapse('hide');
            }
        });

        $(() => {
            $('#collapsePages').on('click', (e: { stopPropagation: () => void; }) => {
                $('#collapsePages').removeClass('show');
                e.stopPropagation();
            });
        });
    }

    public goToCheckoutPage = (): void => {

        Auth.currentSession().then((session: CognitoUserSession) => {
            const jwt = session.getIdToken().getJwtToken();
            window.open(`${REGISTER_APP_URL}/products?key=${jwt}`, '_blank');
        });
    }

    public async hideLinks(): Promise<void> {
        this.showlink = await this.authGuard.getContract();
    }

}
