<div>
    <form [formGroup]="formGroup" #formReport>
        <div class="form-row">
            <div class="form-group col-lg-12">
                <label class="row-label">Tipo*</label>
                <div class="d-flex mt-2">
                    <div class="form-check mr-3">
                        <input class="form-check-input"
                            formControlName="type"
                            (change)="updateFormValue()"
                            type="radio"
                            id="simplified"
                            value="simplified">
                        <label for="simplified">
                            Simples
                        </label>
                    </div>
                    <div class="form-check mr-3">
                        <input class="form-check-input"
                            formControlName="type"
                            (change)="updateFormValue()"
                            type="radio"
                            id="detailed"
                            value="detailed">
                        <label for="detailed">
                            Detalhado
                        </label>
                    </div>
                </div>
            </div>

            <div class="form-group col-lg-6">
                <p class="searchbar-label">Data Inicial *</p>
                <p-calendar
                    #startDate
                    [showIcon]="false"
                    (onSelect)="updateFormValue()"
                    class="input-date"
                    [appendTo]="appendTo"
                    placeholder="Selecione uma data"
                    formControlName="startDate"
                    inputStyleClass="form-control"
                    dateFormat="dd/mm/yy"
                    [readonlyInput]="true">
                </p-calendar>
                <div class="custom-invalid-feedback" *ngIf="formGroup.get('startDate')?.hasError('required')
                    && formGroup.get('startDate')?.touched">Data inicial é requerida
                </div>
            </div>
            <div class="form-group col-lg-6">
                <p class="searchbar-label">Data Final *</p>
                <p-calendar
                    #startDate
                    [showIcon]="false"
                    (onSelect)="updateFormValue()"
                    class="input-date"
                    [appendTo]="appendTo"
                    [minDate]="startDate.value"
                    placeholder="Selecione uma data"
                    formControlName="endDate"
                    inputStyleClass="form-control"
                    dateFormat="dd/mm/yy"
                    [readonlyInput]="true">
                </p-calendar>
                <div class="custom-invalid-feedback" *ngIf="formGroup.get('endDate')?.hasError('required')
                    && formGroup.get('endDate')?.touched">Data final é requerida
                </div>
            </div>

            <div class="form-group col-lg-12">
                <label class="row-label">Filtro*</label>
                <div class="d-flex mt-2">
                    <div class="form-check mr-3">
                        <input class="form-check-input"
                            formControlName="filters"
                            (change)="updateFormValue()"
                            type="radio"
                            id="headquarters"
                            value="1">
                        <label for="headquarters">
                            Matriz
                        </label>
                    </div>
                    <div class="form-check mr-3">
                        <input class="form-check-input"
                            formControlName="filters"
                            (change)="updateFormValue()"
                            type="radio"
                            id="branch"
                            value="2">
                        <label for="branch">
                            Filial
                        </label>
                    </div>
                    <div class="form-check mr-3">
                        <input class="form-check-input"
                            formControlName="filters"
                            (change)="updateFormValue()"
                            type="radio"
                            id="department"
                            value="3">
                        <label for="department">
                            Departamento
                        </label>
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-sm animated--fade-in" *ngIf="formGroup.get('filters')?.value >= 1">
                    <label for="companyPosition">Empresa *</label>
                    <div class="spinner-border spinner-border-sm text-muted ml-2" *ngIf="loadCompany" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="d-flex justify-content-start align-items-center">
                        <select class="form-control w-100" id="companyId" formControlName="companyId"
                                style="width: 50%;"
                                (ngModelChange)="onChange($event)"
                                [ngClass]="{
                                    'is-invalid': formGroup.get('companyId')?.hasError('required') && formGroup.get('companyId')?.touched
                                }">
                            <option value="" disabled>Escolha a empresa</option>
                            <option value="{{company?.id}}">{{company?.name}}</option>
                            <option *ngFor="let item of companyList"
                                    value="{{item.id}}">{{item.name}}</option>
                        </select>
                    </div>
                    <div class="custom-invalid-feedback" *ngIf="formGroup.get('companyId')?.hasError('required')
                        && formGroup.get('companyId')?.touched">
                        Empresa é requerido
                    </div>
                </div>

                <div class="form-group col-sm animated--fade-in" *ngIf="formGroup.get('filters')?.value >= 2">
                    <label for="companyPosition">Filial</label>
                    <div class="d-flex justify-content-start align-items-center">
                        <select class="form-control w-100" id="branchId" formControlName="branchId"
                                style="width: 50%;"  #department (change)="getDepartmentName(department.selectedOptions[0].text)"
                                (ngModelChange)="onSelectBranch()"
                                [ngClass]="{
                                    'is-invalid': formGroup.get('branchId')?.hasError('required') && formGroup.get('branchId')?.touched
                                }">
                            <option value="">Escolha a Filial</option>
                            <option *ngFor="let item of branchList" value="{{item.id}}">
                                {{item.name}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-group col-sm animated--fade-in" *ngIf="formGroup.get('filters')?.value >= 3">
                    <label for="companyPosition">Departamento*</label>
                    <div class="spinner-border spinner-border-sm text-muted ml-2" *ngIf="loadDepartments" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="d-flex justify-content-start align-items-center">
                        <select class="form-control w-100" id="departmentId"
                            formControlName="departmentId"
                            (change)="updateFormValue()"
                            style="width: 50%;">
                            <option value="">Escolha o departamento</option>
                            <option *ngFor="let department of departments" value="{{department.id}}">
                                {{department.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
