<div>
    <p-toast [style]="{marginTop: '80px'}"></p-toast>
    <p-confirmDialog [style]="{width: '432px'}" [baseZIndex]="10000"
            acceptButtonStyleClass="p-button-rounded p-button-danger"
            rejectButtonStyleClass="p-button-text"
            acceptLabel="Sim"
            rejectLabel="Não"
            acceptIcon="none"
            rejectIcon="none">
    </p-confirmDialog>

    <p-dialog header="Limite de cadastro atingido" [(visible)]="showWarning" [style]="{width: '560px'}">
        <div>
            O número de cadastros atingiu o limite, assim,
            não é possível cadastrar novos funcionários
        </div>
        <p-footer>

        </p-footer>
    </p-dialog>

    <!-- Create employee Options -->
    <p-dialog header="Cadastrar funcionários" [(visible)]="showCreatedEmployeeModal" [style]="{width: '560px'}">
        <div>
            <span style="font: normal normal normal 16px/22px Nunito Sans; color: #4D4D4D; opacity: 1;">
                Como você deseja cadastrar?
            </span>
        </div>
        <div class="d-flex justify-content-between created-employee-modal-container">
            <div class="created-employee-modal-card w-100 p-3 mr-2">
                <div class="mb-2 text-center">
                    <button class="btn btn-link btn-icon-primary p-0" (click)="onNewEmployee()">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="#5055CB" class="card-cursor-arrow-rays">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59" />
                        </svg>
                    </button>
                </div>
                <div class="text-center mb-4">
                    <p style="font: normal normal 600 14px/19px Nunito Sans; color: #4D4D4D; opacity: 1;">
                        Manualmente
                    </p>
                    <span style="font: normal normal normal 14px/19px Nunito Sans; color: #6C7868; opacity: 1;">
                        Insira os dados individualmente
                    </span>
                </div>
            </div>
            <div class="created-employee-modal-card w-100 p-3">
                <div class="mb-2 text-center">
                    <button class="btn btn-link btn-icon-primary p-0" (click)="onShowUploadModal()">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="#5055CB" class="card-cursor-arrow-rays">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                        </svg>
                    </button>
                </div>
                <div class="text-center mb-4">
                    <p style="font: normal normal 600 14px/19px Nunito Sans; color: #4D4D4D; opacity: 1;">
                        Enviando arquivo
                    </p>
                    <span style="font: normal normal normal 14px/19px Nunito Sans; color: #6C7868; opacity: 1;">
                        Envie um arquivo no formato csv para cadastrar em lote
                    </span>
                </div>
            </div>
        </div>
    </p-dialog>

    <!-- Create employee by CSV File -->
    <p-dialog header="Cadastrar funcionários" [(visible)]="showUploadModal" [style]="{width: '560px'}">
        <div>
            <div>
                <app-select-companies
                    *ngIf="showSelectCompanies"
                    (formStatusChange)="onFormStatusChange($event)"
                    (selectEvent)="selectValues($event)">
                </app-select-companies>
            </div>
            <app-file-input
                *ngIf="showFileInput"
                [hasError]="hasCSVError"
                [messageError]="csvErrorMessage"
                [allowedExtensions]="['.csv']"
                (fileSelected)="onFileSelected($event)">
            </app-file-input>
        </div>
        <div class="d-flex justify-content-start align-items-center mt-3 mb-2">
            <div>
                <a class="btn btn-outline-primary"
                    download="alphatime_arquivo_import_coloboradores.csv" target="_blank" href="/assets/template/employee.csv">
                    Baixar template do arquivo
                </a>
            </div>
            <div style="width: 25px; margin-left: 10px;">
                <span (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.6" stroke="#5055CB" class="w-18 h-18">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg>
                </span>
            </div>
        </div>
        <div style="height: 60px;">
            <div class="toolTipInfo" *ngIf="showTooltip">
                Informações obrigatórias: Nome, CPF, E-mail, Número do Telemóvel, Endereço, Número do Endereço, Bairro, Cidade, CEP, Estado e País.
            </div>
        </div>
        <p-footer>
            <button class="btn btn-secondary" (click)="onCloseUploadModal()">Fechar</button>
            <button class="btn btn-primary" [disabled]="!(isFormValid && !hasCSVError && selectFile != null)" (click)="sendUploadFile()">Cadastrar</button>
        </p-footer>
    </p-dialog>

    <p-dialog [(visible)]="processingDialogFile"
              [position]="'bottom-left'"
              [closable]="true"
              [styleClass]="'custom-processing-file-dialog'"
              [modal]="false"
              [style]="{width: '500px'}"
              [draggable]="false" [resizable]="false">
        <div class="d-flex justify-content-between align-items-center" style="padding: 0px 20px 15px 20px;">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="#5055CB" class="w-1 h-1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>
                <span class="ml-2" style="font: normal normal normal 14px/19px Nunito Sans; color: #4D4D4D; opacity: 1;">{{fileName}}</span>
            </div>
            <div style="color: #5055CB; font: normal normal 600 16px/22px Nunito Sans; opacity: 1;">
                {{processingDialogMsg}}
            </div>
        </div>
        <div class="progress" style="width: 100%; height: 0.6rem; border-radius: 0;">
            <div class="progress-bar" role="progressbar" [style.width]="processingDialogPercent"  aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    </p-dialog>

    <div class="card shadow mb-4">
        <div class="card-body">
            <p-table #dt
                     [value]="employees"
                     dataKey="id"
                     styleClass="p-datatable-employee"
                     [rowHover]="true"
                     [rows]="10"
                     [showCurrentPageReport]="true"
                     [rowsPerPageOptions]="[10,25,50]"
                     [paginator]="true"
                     [filterDelay]="0"
                     sortField="employee.name" [sortOrder]="-1"
                     [globalFilterFields]="['name','email', 'status']">

                <ng-template pTemplate="caption">

                    <div class="table-header">
                        <div class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input
                                #findAll
                                class="p-inputtext p-component inputsearch"
                                pInputText
                                type="text"
                                (input)="dt.filterGlobal(findAll.value, 'contains')"
                                placeholder="Pesquisar"/>
                        </div>

                        <div class="d-flex">

                            <!-- <button
                                [disabled]="loadingEmployees"
                                *ngIf="isManager" (click)="onNewEmployee()" class="btn btn-primary ml-3 btn-icon" style="width: 230px;">
                                <div class="d-flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" />
                                    </svg>
                                    <span>Cadastrar Funcionário</span>
                                </div>
                            </button> -->
                            <button
                                [disabled]="loadingEmployees"
                                *ngIf="isManager" (click)="onBtnCreateEmployee()" class="btn btn-primary ml-3 btn-icon" style="width: 230px;">
                                <div class="d-flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" />
                                    </svg>
                                    <span>Cadastrar Funcionário</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr *ngIf="!changeViewValue">
                        <th pSortableColumn="status">Status
                            <p-sortIcon field="status"></p-sortIcon>
                        </th>
                        <th pSortableColumn="name">Nome
                            <p-sortIcon field="name"></p-sortIcon>
                        </th>
                        <th pSortableColumn="email">Email
                            <p-sortIcon field="email"></p-sortIcon>
                        </th>
                        <th>Telefone
                            <!-- <p-sortIcon field="phone"></p-sortIcon> -->
                        </th>
                        <th>CPF
                            <!-- <p-sortIcon field="registryId"></p-sortIcon> -->
                        </th>
                        <th class="text-center">Ações</th>
                    </tr>
                    <tr *ngIf="loadingEmployees">
                        <th colspan="6">
                            <div class="d-flex justify-content-center text-muted align-items-center p-3">
                                <span class="spinner-grow spinner-grow-sm m-2" role="status" aria-hidden="true"></span>
                                Aguarde estamos carregando os dados...
                            </div>
                        </th>
                      </tr>
                </ng-template>

                <ng-template pTemplate="body" let-employee let-i>
                    <!-- TABLES CARDS -->
                    <div class="table-card-container table-fade-in" *ngIf="changeViewValue">
                        <div class="d-flex justify-content-end">
                            <div *ngIf="employee.accountType !== 'ACCOUNT_HOLDER'">
                                <button class="btn btn-link btn-icon-danger p-0" *ngIf="employee?.contract.enabled"
                                    (click)="confirmFireEmployee(employee)">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-0 w-0" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor" stroke-width="1.6">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                </button>
                                <button class="btn btn-link btn-icon-success p-0" *ngIf="!employee?.contract.enabled"
                                    (click)="confirmReemployEmployee(employee)">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-0 w-0" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor" stroke-width="1.6">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </button>
                            </div>
                            <button class="btn btn-link btn-icon-primary p-0" (click)="goToConfigureEmployee(employee)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-0 w-0" fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor" stroke-width="1.6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            </button>
                        </div>

                        <div class="d-flex flex-column align-items-center">
                            <div style="margin-top: -15px;">
                                <img class="rounded-circle-picture" width="35.03" height="35.03" [alt]="employee.name"
                                    [src]="employee.picture ? employee.picture : picture"
                                    onerror="this.src='assets/icon/Avatar/user.png'" />
                            </div>
                            <div class="table-card-data-name">
                                {{ employee.name }}
                            </div>
                            <div class="table-card-data-email">
                                {{ employee.email }}
                            </div>
                            <div style="margin-top: 10px;">
                                <span [ngClass]="employee?.contract.enabled === true ? 'badge badge-green': 'badge badge-red'">
                                    {{
                                    (employee?.contract.enabled === true) ? 'Ativo' :
                                    (employee?.contract.enabled === false) ? 'Inativo' :
                                    'E-mail Pendente'
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <!-- LINE TABLE -->
                    <tr class="p-selectable-row table-fade-in" *ngIf="!changeViewValue">
                        <td>
                            <span class="p-column-title">Status</span>
                            <span [ngClass]="employee?.contract.enabled === true ? 'badge badge-green': 'badge badge-red'">
                                {{
                                    (employee?.contract.enabled === true) ? 'Ativo' :
                                    (employee?.contract.enabled === false) ? 'Inativo' :
                                    'E-mail Pendente'
                                }}
                            </span>
                        </td>
                        <td>
                            <img
                                class="rounded-circle-picture"
                                width="35.03"
                                height="35.03"
                                [alt]="employee.name"
                                [src]="employee.picture ? employee.picture : picture"
                                onerror="this.src='assets/icon/Avatar/user.png'"/>
                            {{ employee.name }}
                        </td>
                        <td>
                            <span class="p-column-title">Email</span>
                            {{ employee.email }}
                        </td>
                        <td>
                            <span class="p-column-title">Telefone</span>
                            {{ employee.phone | phone }}
                        </td>
                        <td>
                            <span class="p-column-title">CPFS</span>
                            {{ employee.registryId }}
                        </td>
                        <td class="flex justify-content-end" style="text-align: center">
                            <div *ngIf="employee.accountType !== 'ACCOUNT_HOLDER'">
                                <button
                                    class="btn btn-link btn-icon-danger p-0"
                                    *ngIf="employee?.contract.enabled" (click)="confirmFireEmployee(employee)">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-0 w-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                </button>
                                <button
                                    class="btn btn-link btn-icon-success p-0"
                                    *ngIf="!employee?.contract.enabled" (click)="confirmReemployEmployee(employee)">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-0 w-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </button>
                            </div>
                            <button
                                class="btn btn-link btn-icon-primary p-0"
                                (click)="goToConfigureEmployee(employee)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-0 w-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
