import * as moment from "moment";
import { Contract } from "src/model/contract";

export default class JourneyHelp {

    public static getContractConfig(contract: Contract){

        const configJourney = JSON.parse(contract.journey!);
        const weekday = moment().weekday();
        const config = configJourney[weekday].split("-");

        return {
            startTimeFirstPeriod: config[0],
            endTimeFirstPeriod: config[1],
            startTimeSecondPeriod: config[2],
            endTimeSecondPeriod: config[3],
            enabled: config[4]
        }
    }

    public static getJourneyJson(journey: any) {
        const parse = JSON.parse(journey)
        const journeyByWeek: { [key: string]: string[] } = {};

        for (let i = 1; i <= 7; i++) {
            journeyByWeek[i.toString()] = parse[i.toString()].split('-');
        }

        return journeyByWeek;
    }
}
