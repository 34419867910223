import {Component, OnInit} from '@angular/core';
import {AppStorageService} from '../../services/app-storage.service';
import {Employer} from '../../model/employer';
import {AddressService} from '../../services/address.service';
import {UploadService} from '../../services/upload.service';
import {StateBr} from '../../model/statesbr';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RegisterIdValidator} from '../app.validators';
import {CityBr} from 'src/model/citiesbr';
import {MessageService} from 'primeng/api';
import HttpEmployeeResponse, {Employee} from 'src/model/employee';
import {EmployeeService} from 'src/services/employee.service';
import {EMPLOYEE_IMG_URL} from 'src/environments/environment';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';

@Component({
    selector: 'app-employee',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    providers: [MessageService, DialogService]
})
export class ProfileComponent implements OnInit {

    public employer: Employer | undefined;
    public employee: Employee | undefined;
    public profileForm: FormGroup;
    public states: StateBr[] = [];
    public cities: CityBr[] = [];
    public stateId ? = 0;
    public picture = './assets/icon/Avatar/user.png';
    public profilePicture: string | ArrayBuffer | null | undefined = '';
    public ref: DynamicDialogRef = new DynamicDialogRef();
    public profilePictureTemp = '';
    public selectedFiles: File | undefined;
    public isNumber = true;
    public toEdit = false;

    constructor(
        private formBuilder: FormBuilder,
        private appStorageService: AppStorageService,
        private dropDownService: AddressService,
        private employeeService: EmployeeService,
        private dialogService: DialogService,
        private upload: UploadService,
        private messageService: MessageService,
        private http: HttpClient,
    ) {

        this.profileForm = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phone: [''],
            registryId: ['', Validators.required],
            zipcode: ['', Validators.required],
            street: ['', Validators.required],
            addressNumber: ['', [Validators.required]],
            complement: [''],
            district: ['', Validators.required],
            state: [''],
            city: ['', Validators.required],
            country: ['Brasil'],
            // areaCode: ['', [Validators.required, Validators.minLength(2)]],
        }, {validator: [RegisterIdValidator]});
    }

    public async ngOnInit(): Promise<void> {
        await this.appStorageService.getEmployee().then((employee) => {
            this.employee = employee;
            this.profileForm.patchValue({
                ...this.employee,
                state: this.employee?.state || 'CE',
                city: this.employee?.city
            });

            if (this.employee?.picture !== undefined) {
                this.profilePicture = `${this.employee?.picture}?${new Date().getTime()}`;
            } else {
                this.profilePicture = this.picture;
            }
        });

        this.dropDownService.getStatesBr().subscribe(data => {
            this.states = data;
        });

        await this.getInitialState();
    }

    public async getInitialState(): Promise<void> {
        const states = await this.dropDownService.getStatesBr().toPromise() || [];
        const actualState = this.profileForm.get('state');
        const stateFind = states.find( state => state.sigla === actualState?.value);

        this.cities = await this.dropDownService.getCitiesBr(Number(stateFind?.id)).toPromise() || [];
    }

    public async onStateChange(value: string): Promise<void> {

        const states =  await this.dropDownService.getStatesBr().toPromise() || [];
        this.stateId = states.find( (state: any) => state.sigla === value)?.id || 0;
        this.cities = await this.dropDownService.getCitiesBr(Number(this.stateId)).toPromise() || [];
    }

    async onChange(event: any): Promise<void> {

        if (event.target.files && event.target.files[0]) {
            const fileName = this.employee?.id;
            const file = event.target.files[0];
            const fileReader = new FileReader();

            // tslint:disable-next-line:no-shadowed-variable
            fileReader.onload = (event) => {
                this.profilePicture = event?.target?.result;
            };

            fileReader.readAsDataURL(file);

            const response = await this.upload.upload(fileName, file);

            this.profilePictureTemp = `${EMPLOYEE_IMG_URL}${response.key}`;

            this.upload.refreshProfilePicture.emit(this.profilePictureTemp);
        }
    }

    public async onSubmit(): Promise<void> {
        await this.updateProfile(this.profileForm.value);
    }

    private async updateProfile(data: any): Promise<void> {
        const {
            addressNumber,
            accountType,
            city,
            country,
            complement,
            contractId,
            createdAt,
            customerId,
            device,
            district,
            email,
            name,
            paymentStatus,
            phone,
            picture,
            zipcode,
            registryId,
            state,
            status,
            street,
            streetNumber,
            updatedAt,
        } = data;

        const employee = {
            name,
            email,
            phone,
            registryId: registryId.match(/[^_\W]+/g).join(''),
            zipcode,
            street,
            addressNumber,
            complement,
            district,
            state,
            city,
            country,
            // picture: this.profilePictureTemp,
            status: this.employee?.status,
        };

        const properties = Object.assign(employee);

        try {
            const response: HttpEmployeeResponse = await this.employeeService.update(this.employee?.id, properties).toPromise();
            if (response.success) {
                await this.appStorageService.setEmployee(response?.data);
                this.employee = response.data;
                this.messageService.add({
                    severity: 'success',
                    summary: 'Cadastro atualizado!',
                });
            }
            this.onCancel()
            // this.ngOnInit();
        } catch (error) {
            console.log(error);
        }
    }

    public onChangePassword(): void {
        this.ref = this.dialogService.open(ChangePasswordComponent, {
            header: 'Alterar senha',
            width: '50%',
            contentStyle: {'max-height': '500px', overflow: 'auto'},
            baseZIndex: 10000,
            dismissableMask: true,
            data: {values: ''}
        });

        this.ref.onClose.subscribe(() => {
            // this.messageService.add({severity: message[0].severity, summary: message[0].summary});
        });
    }

    public keyPressNumbers(event: any): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        // Only Numbers 0-9
        if ((charCode < 48 || charCode > 57)) {
            event.preventDefault();
            this.isNumber = false;
            return false;
        } else {
            this.isNumber = true;
            return true;
        }
    }

    public findZipcode(): void {
        let cep = '';
        cep = this.profileForm.get('zipcode')?.value;

        if (cep !== '') {
            cep = cep.replace(/\D/g, '');
        }

        if (cep !== '') {
            const validaCep = /^[0-9]{8}$/;

            if (validaCep.test(cep)) {
                // this.resetContractAddressForm();

                const options = {headers: {Anonymous: ''}};

                this.http.get(`https://viacep.com.br/ws/${cep}/json`, options)
                    .pipe(
                        map((dados: any) => {
                            if (dados.erro === true) {
                                this.messageService.add({
                                    severity: 'error',
                                    summary: 'CEP inválido ou inexistente',
                                    detail: 'Verifique o número do CEP novamentes'
                                });
                                this.profileForm.get('zipcode')?.setValue('');
                            }

                            this.populateAddressForm(dados);
                        })
                    ).subscribe();
            }
        } else {
            this.resetAddressForm();
        }
    }

    populateAddressForm(data: any): void {
        this.profileForm.patchValue({
            street: data.logradouro,
            district: data.bairro,
            state: data.uf,
            city: data.localidade,
        });
    }

    resetAddressForm(): void {
        this.profileForm.patchValue({
            street: null,
            addressNumber: null,
            district: null,
            complement: null,
            city: null,
            state: null,
        });
    }

    onEdit(): void {
        this.toEdit = true;
    }

    public async onCancel() {
        this.toEdit = false;
        await this.ngOnInit();
    }
}
