<p-toast></p-toast>

<div>

    <img src="../../assets/img/login/element-a.png" class="imagem-a" alt="">
    <img src="../../assets/img/login/element-b.png" class="imagem-b" alt="">
    <img src="../../assets/img/login/logo-descrition.png" class="imagem-c" alt="">
    <img src="../../assets/img/login/mockup3.png" class="imagem-d" alt="">

    <div class="accessWrapper d-flex justify-content-end align-items-center">

        <div class="overlay p-5">
            <div>
                <div style="margin-bottom: 50px;">
                    <div class="d-flex justify-content-center align-items-center mb-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="none" viewBox="0 0 24 24" stroke-width="0.5" stroke="#5055cb" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 20.25h12m-7.5-3v3m3-3v3m-10.125-3h17.25c.621 0 1.125-.504 1.125-1.125V4.875c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125z" />
                        </svg>
                    </div>
                    <div class="text-center">
                        <h5 style="font-size: 16px; font-weight: 500;">
                            Por favor, faça o login pelo computador para acessar a plataforma.
                        </h5>
                        <h6 style="font-size: 14px;">
                            Assim, você terá uma melhor experiência na navegaçao
                        </h6>
                    </div>
                </div>

                <div>
                    <div class="d-flex justify-content-center align-items-center mb-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="none" viewBox="0 0 24 24" stroke-width="0.5" stroke="#5055cb" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                        </svg>
                    </div>
                    <div class="text-center mb-3">
                        <h5 style="font-size: 16px; font-weight: 500;">
                            Deseja bater o ponto? Acesse abaixo nossos aplicativos
                        </h5>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="google-play-store-img">
                            <a href="https://play.google.com/store/apps/details?id=com.alphatime.ponto">
                                <img class="w-100" src="assets/img/google-play-store.png" alt="Google Play Store">
                            </a>
                        </div>
                        <div class="app-store-img">
                            <a href="https://apps.apple.com/br/app/alphatime/id1481797887">
                                <img class="w-100" src="assets/img/app-store.png" alt="App Store">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="conteudo">
            <label class="create-account-label mt-5">Não tem conta?
                <span class="cursor" style="color: #4e73df;" (click)="goToSignup()">
                    Crie aqui
                </span>
            </label>
            <h1>Bem-vindo(a) de volta :)</h1>
            <form class="user" [formGroup]="loginForm" (ngSubmit)="login()">
                <div class="form-group">
                    <label>E-mail</label>
                    <input type="email" class="form-control form-control-user"
                           id="inputEmail" aria-describedby="emailHelp"
                           formControlName="email" name="email"
                           placeholder="Digite aqui seu e-mail"/>
                    <div
                        *ngIf="!loginForm.controls['email'].valid && loginForm.controls['email'].touched"
                        class="text-danger small text ml-4">Email é requerido ou está inválido
                    </div>
                </div>
                <div class="input-group">
                    <label id="labelPassword">Senha</label>
                    <input type="password" class="form-control form-control-user"
                           id="inputPassword" placeholder="Digite aqui sua senha"
                           formControlName="password" name="password"
                           [type]="showHidePassword ? 'password' : 'text'" style="font-size: calc(70% + 1px) !important;
                             padding: 1px 9px;font-family: 'Nunito Sans', sans-serif;"/>
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <i [class]="showHidePassword ? 'bi bi-eye-slash' : 'bi bi-eye'"
                               (click)="showHidePassword = !showHidePassword"></i>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="!loginForm.controls['password'].valid && loginForm.controls['password'].touched"
                    class="text-danger small text ml-4">Senha é requerida
                </div>
                <div class="form-group check-login">
                    <input type="checkbox" class="form-check-input" id="Rememberlogin"
                           [checked]="isCheck" (change)="onChangeStayLogged()">
                    <label class="form-check-label" for="Rememberlogin">Lembrar login</label>

                    <a routerLink="/password-recovery" style="font-size: .8rem;">Esqueci a senha</a>

                </div>

                <div class="form-group mb-5">
                    <button [disabled]="!loginForm.valid"
                            class="btn btn-primary btn-user btn-block">
                        <span *ngIf="!loading">Entrar</span>
                        <p-progressSpinner
                            styleClass="custom-spinner"
                            [style]="{width: '20px', height: '20px'}"
                            *ngIf="loading"></p-progressSpinner>
                    </button>
                </div>
            </form>
        </div>
    </div>
    <p-dialog header="Caro Cliente" [(visible)]="showWarning" [style]="{width: '560px'}">
        <div *ngIf="isAccountHolder">
            <p> Estamos muito contentes que tenha iniciado seu período de testes do Alphatime, mas lamentamos informar
                que o período
                terminou.
            </p>
            <p>
                Nosso Comercial entrará em contato contigo e esperamos que você permaneça com o melhor sistema de ponto
                em uso,
                pois temos certeza de que o Alphatime poderá ajuda-lo(a) muito na gestão de seus colaboradores.
            </p>
            <p>
                Porém, se preferir,
                poderá contratar um dos nossos planos clicando no botão abaixo.
            </p>
        </div>
        <div *ngIf="!isAccountHolder">
            <p> Não foi possível realizar login. Contate o administrador!</p>
        </div>

        <ng-template pTemplate="footer" >
            <button *ngIf="isAccountHolder" class="btn btn-success btn-block btn-plan" (click)="goToCheckout()">
                <span>Contratar Plano</span>
            </button>
        </ng-template>

    </p-dialog>
</div>
