import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {AppStorageService} from '../../services/app-storage.service';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import Notification from '../../model/notification';
import {NotificationService} from '../../services/notification.service';
import {Employee} from 'src/model/employee';
import {UploadService} from 'src/services/upload.service';
import {PaymentService} from '../../services/payment.service';
import {Store} from '@ngrx/store';
import {logout} from '../../stores/actions/meta.action';
import {LoaderService} from '../../services/loader-service';
import {EventEmitterService} from 'src/services/event-emitter.service';
import * as moment from 'moment/moment';
import {AblyService} from '../../services/ably-service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

    public employee: Employee | undefined;
    public picture = './assets/icon/Avatar/user.png';
    public isFreePlan = false;
    public isAccountHolder = false;
    public notifications: Notification[] = [];
    public profilePicture = '';
    public pageTitle = 'Início';
    public showEmployeeBtn = false;
    public counterDays = 14;
    public showCounter = true;

    constructor(
        private ablyService: AblyService,
        private location: Location,
        private loader: LoaderService,
        private authService: AuthService,
        private router: Router,
        private appStorageService: AppStorageService,
        private upload: UploadService,
        private paymentService: PaymentService,
        private store: Store<any>) {
        this.refreshProfilePicture();
    }

    ngOnInit(): void {
        this.appStorageService.getCompany().then((company) => {
            this.isFreePlan = company.productType === 'FREE';
        });
        this.appStorageService.getEmployee().then((employee) => {
            this.employee = employee;
            this.ablyService.addListenerEmployeeChannel(employee.id);
            this.employee.accountType === 'ACCOUNT_HOLDER' ? this.isAccountHolder = true : this.isAccountHolder = false;
            if (this.employee.picture !== undefined) {
                this.profilePicture = `${this.employee.picture}?${new Date().getTime()}`;
            } else {
                this.profilePicture = this.picture;
            }
        });
        this.setPageTitle(this.router.url);
        this.location.onUrlChange((val) => {
            this.setPageTitle(val);
        });
        EventEmitterService.get(EventEmitterService.COMPANY_EVENT_UPDATED).subscribe((data: any) => {
            this.getInfoCompany();
        });
        EventEmitterService.get(EventEmitterService.EMPLOYEE_CHANNEL).subscribe((msg: any) => {
            const payload = JSON.parse(msg.data);
            this.notifications.unshift({
                type: payload.type,
                message: payload.description,
                createdAt: payload.createdAt
            } as Notification);
        });
    }

    public async signOut(): Promise<void> {
        await this.authService.signOut();
        await this.appStorageService.clear();
        this.store.dispatch(logout());
        await this.router.navigate(['/login']);
    }

    public clearNotifications(): void {
        this.notifications = [];
    }

    public refreshProfilePicture(): void {
        this.upload.refreshProfilePicture.subscribe(
            (valor: string) => {
                if (valor) {
                    this.profilePicture = `${valor}?${new Date().getTime()}`;
                }
            }
        );
    }

    public async openCustomerPortal(): Promise<void> {
        this.loader.setLoading(true);
        this.paymentService.createCustomerPortal(this.employee?.customerId).subscribe(
            (response: any) => {
                window.open(response.data, '_self');
            },
            err => this.loader.setLoading(false),
            () => this.loader.setLoading(false)
        );
    }

    private setPageTitle(path: string): void {
        this.showEmployeeBtn = false;
        this.showCounter = !(this.router.url === '/company');
        console.log(path);
        switch (path) {
            case '/employee/list' : {
                this.showEmployeeBtn = true;
                this.pageTitle = 'Funcionários';
                break;
            }
            case '/employee/grid' : {
                this.showEmployeeBtn = true;
                this.pageTitle = 'Funcionários';
                break;
            }
            case '/configure-employee' : {
                this.pageTitle = 'Funcionários';
                break;
            }
            case '/employee-create' : {
                this.pageTitle = 'Funcionários';
                break;
            }
            case '/comp-time' : {
                this.pageTitle = 'Banco de Horas';
                break;
            }
            case '/time-management' : {
                this.pageTitle = 'Banco de Horas';
                break;
            }
            case '/employee-justification' : {
                this.pageTitle = 'Justificativas';
                break;
            }
            case '/history' : {
                this.pageTitle = 'Histórico de Registros de Pontos';
                break;
            }
            case '/company' : {
                this.pageTitle = 'Empresa';
                break;
            }
            case '/profile' : {
                this.pageTitle = 'Perfil';
                break;
            }
            case '/employee-vacations-table' : {
                this.pageTitle = 'Férias';
                break;
            }
            case '/signature' : {
                this.pageTitle = 'Assinatura';
                break;
            }
            case '/register-time' : {
                this.pageTitle = 'Bater Ponto';
                break;
            }
            default: {
                this.pageTitle = 'Início';
                break;
            }
        }
    }
    public getInfoCompany(): void {
        this.appStorageService.getCompany().then((company) => {
            this.isFreePlan = company.productType === 'FREE';
            const rest = 14 - moment().diff(moment(company.createdAt), 'days');
            this.counterDays = rest < 0 ? 0 : rest;
        });
    }

    public timeSince(date: string | undefined): string {
        moment.locale('pt-br');
        return moment(date).fromNow();
    }

    ngOnDestroy(): void {
        // this.event$.unsubscribe();
    }
}
