import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Company} from 'src/model/company';
import HttpCompanyPositionResponse, {CompanyPosition} from 'src/model/company-position';
import {Department} from 'src/model/department';
import {Employee} from 'src/model/employee';
import {AppStorageService} from 'src/services/app-storage.service';
import {CompanyPositionService} from 'src/services/company-position.service';
import {CompanyService} from 'src/services/company.service';
import {DepartmentService} from 'src/services/department.service';

@Component({
    selector: 'app-select-companies',
    templateUrl: './select-companies.component.html',
    styleUrls: ['./select-companies.component.scss'],
})
export class SelectCompaniesComponent implements OnInit {
    @Output() selectEvent = new EventEmitter();
    @Output() formStatusChange = new EventEmitter<boolean>();

    public formSelect: FormGroup;
    public companies: Company[] = [];
    public departments: Department[] = [];
    public companiesPositions: CompanyPosition[] = [];
    public company: Company | undefined;
    public employee: Employee | undefined;
    public companyList: any[] = [];
    public branchList: any[] = [];

    constructor(
        private appStorageService: AppStorageService,
        private formBuilder: FormBuilder,
        private companyService: CompanyService,
        private departmentService: DepartmentService,
        private companyPositionService: CompanyPositionService,
    ) {
        this.formSelect = this.formBuilder.group({
            companyId: ['', Validators.required],
            branchId: [''],
            departmentId: ['', Validators.required],
            companyPositionId: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(180)]],
            path: [''],
        });

        this.formSelect.valueChanges.subscribe(() => {
            this.formStatusChange.emit(this.formSelect.valid);
        });
    }

    async ngOnInit(): Promise<void> {
        await this.initializePage();
    }

    async initializePage(): Promise<void> {
        await this.getCompanyPositions();
        this.employee = await this.appStorageService.getEmployee();
        this.companyService.get(this.employee.companyId).subscribe((company: any) => {
            this.company = company.data;
            this.loadCompanyList(this.company?.id);
            this.managerType();
        });
    }

    async loadCompanyList(companyId: string | undefined): Promise<void> {
        const httpCompanyEconomicGroupResponse = await this.companyService.getBranches(
            companyId, 'ASSOCIATED_COMPANY'
        ).toPromise();

        if (httpCompanyEconomicGroupResponse?.data) {
            const response: Company[] = [];
            response.push(httpCompanyEconomicGroupResponse.data);
            response.map((companiesGroup: any) => {
                const result = companiesGroup.Items;
                result.map((companyGroup: any) => {
                    this.companyList.push(companyGroup);
                });
            });
        }
    }

    async onChange(companyId: string): Promise<void> {
        this.branchList = [];
        this.formSelect.get('path')?.setValue('');
        const response: Company[] = [];
        const getBranchs = await this.companyService.getBranches(companyId, 'BRANCH').toPromise();

        if (getBranchs?.data) {
            response.push(getBranchs.data);
            response.map((companiesGroupBranch: any) => {
                const result = companiesGroupBranch.Items;
                result.map((companyGroupBranch: any, index: any) => {
                    if (result.length > 0 && result[index].status !== 'INACTIVE') {
                        this.branchList.push(companyGroupBranch);
                    }
                });
            });
        } else {
            this.branchList = [];
        }

        const companyIdValue = this.formSelect.get('companyId')?.value;

        if (this.company?.id === companyIdValue) {
            this.formSelect.get('path')?.setValue(this.company?.path);
        } else {
            const companyIdResult: any = this.companyList.filter((company: Company) => {
                return company.id === companyIdValue;
            });
            if (companyIdResult.length > 0) {
                this.formSelect.get('path')?.setValue(companyIdResult[0].path);
            }
        }

        await this.getDepartments(companyIdValue);
    }

    public async onSelectBranch(): Promise<void> {
        if (this.branchList.length > 0) {
            const companyIdValue = this.formSelect.get('branchId')?.value;
            const companyIdResult: any = this.branchList.filter((company: Company) => {
                return company.id === companyIdValue;
            });

            if (companyIdResult.length > 0) {
                this.formSelect.get('path')?.setValue(companyIdResult[0].path);
                await this.getDepartments(companyIdValue);

                // this.selectEvent.emit(this.formSelect.value);
            }
        }
    }

    async getDepartments(companyId: string): Promise<void> {
        if (companyId){
            this.departments = [];
            this.departmentService.list(companyId).subscribe(department => {
                const result: Department[] = [];
                department?.data?.map((dep: Department) => {
                    if (dep.status === 'ACTIVE') {
                        result.push(dep);
                    }
                });

                this.departments = result;
            });
        }
    }

    async getCompanyPositions(): Promise<void> {
        this.companiesPositions = [];
        this.employee = await this.appStorageService.getEmployee();
        const path = this.employee.path;
        const companyId = path?.split('#', 1) ?? [];

        const response: HttpCompanyPositionResponse | undefined = await this.companyPositionService.list(
            companyId[0]
        ).toPromise() || undefined;

        response?.data?.map((companiesPosition: CompanyPosition) => {
            this.companiesPositions.push(companiesPosition);
        });

        this.selectEvent.emit(this.formSelect.value);
    }

    public async managerType(): Promise<void> {
        this.formSelect.get('companyId')?.setValue(this.company?.id);
    }
}
